import { Trophy } from 'lucide-react';

interface ScoreBoardProps {
  teams: string[];
  scores: number[];
  currentTeamIndex: number;
  pointsToWin: number;
}

export function ScoreBoard({
  teams,
  scores,
  currentTeamIndex,
  pointsToWin,
}: ScoreBoardProps) {
  return (
    <div className="game-card p-6">
      <div className={`grid gap-4 ${
        teams.length <= 4 ? 'grid-cols-2 md:grid-cols-4' :
        teams.length <= 6 ? 'grid-cols-2 md:grid-cols-3' :
        'grid-cols-2 md:grid-cols-4'
      }`}>
        {teams.map((team, index) => (
          <div
            key={index}
            className={`text-center p-4 rounded-lg transition-all duration-300 ${
              currentTeamIndex === index ? 'current-team' : ''
            }`}
          >
            <div className="text-lg font-bold truncate">{team}</div>
            <div className="text-4xl font-black flex items-center justify-center gap-2 my-2">
              {scores[index]}
              {scores[index] >= pointsToWin && (
                <Trophy className="h-8 w-8 text-[#FFD233] animate-bounce" />
              )}
            </div>
            <div className="text-sm font-semibold text-black/60">
              {pointsToWin - scores[index]} to win
            </div>
          </div>
        ))}
      </div>
      <div className="mt-6 text-center text-lg font-bold text-black/80">
        {teams[currentTeamIndex]}'s turn
      </div>
    </div>
  );
}