import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { createUserFromSession } from '@/lib/payment/session';
import { formatError } from '@/lib/payment/utils';
import type { PaymentState } from '@/types/payment';

export function usePaymentSession() {
  const [searchParams] = useSearchParams();
  const [state, setState] = useState<PaymentState>({
    accessCode: '',
    error: '',
    isLoading: true
  });

  useEffect(() => {
    async function processPayment() {
      try {
        const sessionId = searchParams.get('session_id');
        const { accessCode, isExisting } = await createUserFromSession(sessionId);
        
        setState({
          accessCode,
          error: '',
          isLoading: false,
          isExisting
        });

        localStorage.setItem('gameAccessCode', accessCode);
      } catch (err) {
        console.error('Payment processing error:', err);
        
        setState({
          accessCode: '',
          error: formatError(err),
          isLoading: false
        });
      }
    }

    processPayment();
  }, [searchParams]);

  return state;
}