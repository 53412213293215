import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import App from './App';
import { HomePage } from '@/pages/HomePage';
import { PaymentSuccess } from '@/components/payment/PaymentSuccess';
import { PaymentCancel } from '@/components/payment/PaymentCancel';
import { PaymentProvider } from '@/contexts/PaymentContext';
import './index.css';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Root element not found');

createRoot(rootElement).render(
  <StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/payment/success" element={
          <PaymentProvider>
            <PaymentSuccess />
          </PaymentProvider>
        } />
        <Route path="/payment/cancel" element={<PaymentCancel />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  </StrictMode>
);