export interface Movie {
  id: string;
  title: string;
  year: number;
  difficulty: 'easy' | 'medium' | 'hard';
  genre: string[];
}

export const movies: Movie[] = [
  {
    id: '1',
    title: 'Dilwale Dulhania Le Jayenge',
    year: 1995,
    difficulty: 'easy',
    genre: ['Romance', 'Drama']
  },
  {
    id: '2',
    title: '3 Idiots',
    year: 2009,
    difficulty: 'easy',
    genre: ['Comedy', 'Drama']
  },
  {
    id: '3',
    title: 'Lagaan',
    year: 2001,
    difficulty: 'medium',
    genre: ['Drama', 'Sport']
  },
  {
    id: '4',
    title: 'Kabhi Khushi Kabhie Gham',
    year: 2001,
    difficulty: 'medium',
    genre: ['Drama', 'Romance']
  },
  {
    id: '5',
    title: 'Mughal-e-Azam',
    year: 1960,
    difficulty: 'hard',
    genre: ['Drama', 'Historical']
  },
  {
    id: '6',
    title: 'Sholay',
    year: 1975,
    difficulty: 'medium',
    genre: ['Action', 'Drama']
  },
  {
    id: '7',
    title: 'PK',
    year: 2014,
    difficulty: 'easy',
    genre: ['Comedy', 'Drama']
  },
  {
    id: '8',
    title: 'Dangal',
    year: 2016,
    difficulty: 'easy',
    genre: ['Sport', 'Biography']
  },
  {
    id: '9',
    title: 'Andhadhun',
    year: 2018,
    difficulty: 'hard',
    genre: ['Thriller', 'Crime']
  },
  {
    id: '10',
    title: 'Queen',
    year: 2014,
    difficulty: 'easy',
    genre: ['Comedy', 'Drama']
  },
  {
    id: '11',
    title: 'Zindagi Na Milegi Dobara',
    year: 2011,
    difficulty: 'easy',
    genre: ['Drama', 'Comedy']
  },
  {
    id: '12',
    title: 'Gangs of Wasseypur',
    year: 2012,
    difficulty: 'hard',
    genre: ['Crime', 'Drama']
  },
  {
    id: '13',
    title: 'Dil Chahta Hai',
    year: 2001,
    difficulty: 'easy',
    genre: ['Comedy', 'Drama']
  },
  {
    id: '14',
    title: 'Barfi!',
    year: 2012,
    difficulty: 'medium',
    genre: ['Comedy', 'Romance']
  },
  {
    id: '15',
    title: 'Rang De Basanti',
    year: 2006,
    difficulty: 'medium',
    genre: ['Drama', 'History']
  },
  {
    id: '16',
    title: 'Bajrangi Bhaijaan',
    year: 2015,
    difficulty: 'easy',
    genre: ['Drama', 'Action']
  },
  {
    id: '17',
    title: 'Gully Boy',
    year: 2019,
    difficulty: 'easy',
    genre: ['Drama', 'Musical']
  },
  {
    id: '18',
    title: 'Devdas',
    year: 2002,
    difficulty: 'medium',
    genre: ['Drama', 'Romance']
  },
  {
    id: '19',
    title: 'Chak De! India',
    year: 2007,
    difficulty: 'easy',
    genre: ['Sport', 'Drama']
  },
  {
    id: '20',
    title: 'Black Friday',
    year: 2004,
    difficulty: 'hard',
    genre: ['Crime', 'Drama']
  },
  {
    id: '21',
    title: 'Kahaani',
    year: 2012,
    difficulty: 'medium',
    genre: ['Mystery', 'Thriller']
  },
  {
    id: '22',
    title: 'Swades',
    year: 2004,
    difficulty: 'medium',
    genre: ['Drama', 'Social']
  },
  {
    id: '23',
    title: 'Taare Zameen Par',
    year: 2007,
    difficulty: 'easy',
    genre: ['Drama', 'Family']
  },
  {
    id: '24',
    title: 'Udaan',
    year: 2010,
    difficulty: 'medium',
    genre: ['Drama', 'Coming-of-age']
  },
  {
    id: '25',
    title: 'Article 15',
    year: 2019,
    difficulty: 'medium',
    genre: ['Crime', 'Drama']
  },
  {
    id: '26',
    title: 'Paan Singh Tomar',
    year: 2012,
    difficulty: 'hard',
    genre: ['Biography', 'Sport']
  },
  {
    id: '27',
    title: 'Omkara',
    year: 2006,
    difficulty: 'hard',
    genre: ['Crime', 'Drama']
  },
  {
    id: '28',
    title: 'Drishyam',
    year: 2015,
    difficulty: 'medium',
    genre: ['Crime', 'Thriller']
  },
  {
    id: '29',
    title: 'Bajirao Mastani',
    year: 2015,
    difficulty: 'medium',
    genre: ['Historical', 'Romance']
  },
  {
    id: '30',
    title: 'A Wednesday',
    year: 2008,
    difficulty: 'medium',
    genre: ['Thriller', 'Crime']
  },
  {
    id: '31',
    title: 'Wake Up Sid',
    year: 2009,
    difficulty: 'easy',
    genre: ['Comedy', 'Romance']
  },
  {
    id: '32',
    title: 'Rockstar',
    year: 2011,
    difficulty: 'medium',
    genre: ['Drama', 'Musical']
  },
  {
    id: '33',
    title: 'Haider',
    year: 2014,
    difficulty: 'hard',
    genre: ['Crime', 'Drama']
  },
  {
    id: '34',
    title: 'Masaan',
    year: 2015,
    difficulty: 'hard',
    genre: ['Drama', 'Romance']
  },
  {
    id: '35',
    title: 'Delhi Belly',
    year: 2011,
    difficulty: 'easy',
    genre: ['Comedy', 'Crime']
  },
  {
    id: '36',
    title: 'Guru',
    year: 2007,
    difficulty: 'medium',
    genre: ['Biography', 'Drama']
  },
  {
    id: '37',
    title: 'Pink',
    year: 2016,
    difficulty: 'medium',
    genre: ['Drama', 'Thriller']
  },
  {
    id: '38',
    title: 'Barfi!',
    year: 2012,
    difficulty: 'medium',
    genre: ['Comedy', 'Romance']
  },
  {
    id: '39',
    title: 'Talvar',
    year: 2015,
    difficulty: 'hard',
    genre: ['Crime', 'Drama']
  },
  {
    id: '40',
    title: 'Special 26',
    year: 2013,
    difficulty: 'medium',
    genre: ['Crime', 'Thriller']
  },
  {
    id: '41',
    title: 'Dev.D',
    year: 2009,
    difficulty: 'hard',
    genre: ['Drama', 'Romance']
  },
  {
    id: '42',
    title: 'Maqbool',
    year: 2003,
    difficulty: 'hard',
    genre: ['Crime', 'Drama']
  },
  {
    id: '43',
    title: 'Lunchbox',
    year: 2013,
    difficulty: 'easy',
    genre: ['Drama', 'Romance']
  },
  {
    id: '44',
    title: 'Jab We Met',
    year: 2007,
    difficulty: 'easy',
    genre: ['Comedy', 'Romance']
  },
  {
    id: '45',
    title: 'Tumbbad',
    year: 2018,
    difficulty: 'hard',
    genre: ['Horror', 'Fantasy']
  },
  {
    id: '46',
    title: 'Newton',
    year: 2017,
    difficulty: 'medium',
    genre: ['Comedy', 'Drama']
  },
  {
    id: '47',
    title: 'Vicky Donor',
    year: 2012,
    difficulty: 'easy',
    genre: ['Comedy', 'Romance']
  },
  {
    id: '48',
    title: 'Company',
    year: 2002,
    difficulty: 'hard',
    genre: ['Crime', 'Thriller']
  },
  {
    id: '49',
    title: 'Black',
    year: 2005,
    difficulty: 'medium',
    genre: ['Drama', 'Biography']
  },
  {
    id: '50',
    title: 'Raazi',
    year: 2018,
    difficulty: 'medium',
    genre: ['Action', 'Thriller']
  },
  {
    id: '51',
    title: 'Badhaai Ho',
    year: 2018,
    difficulty: 'easy',
    genre: ['Comedy', 'Drama']
  },
  {
    id: '52',
    title: 'Stree',
    year: 2018,
    difficulty: 'easy',
    genre: ['Comedy', 'Horror']
  },
  {
    id: '53',
    title: 'Andaz Apna Apna',
    year: 1994,
    difficulty: 'easy',
    genre: ['Comedy', 'Romance']
  },
  {
    id: '54',
    title: 'Mother India',
    year: 1957,
    difficulty: 'hard',
    genre: ['Drama', 'Family']
  },
  {
    id: '55',
    title: 'Uri: The Surgical Strike',
    year: 2019,
    difficulty: 'easy',
    genre: ['Action', 'War']
  },
  {
    id: '56',
    title: 'Hum Aapke Hain Koun..!',
    year: 1994,
    difficulty: 'easy',
    genre: ['Romance', 'Drama']
  },
  {
    id: '57',
    title: 'Kuch Kuch Hota Hai',
    year: 1998,
    difficulty: 'easy',
    genre: ['Romance', 'Drama']
  },
  {
    id: '58',
    title: 'Border',
    year: 1997,
    difficulty: 'medium',
    genre: ['War', 'Action']
  },
  {
    id: '59',
    title: 'Hera Pheri',
    year: 2000,
    difficulty: 'easy',
    genre: ['Comedy', 'Crime']
  },
  {
    id: '60',
    title: 'Mohabbatein',
    year: 2000,
    difficulty: 'medium',
    genre: ['Drama', 'Romance']
  },
  {
    id: '61',
    title: 'Munna Bhai M.B.B.S.',
    year: 2003,
    difficulty: 'easy',
    genre: ['Comedy', 'Drama']
  },
  {
    id: '62',
    title: 'Dhoom',
    year: 2004,
    difficulty: 'easy',
    genre: ['Action', 'Thriller']
  },
  {
    id: '63',
    title: 'Lage Raho Munna Bhai',
    year: 2006,
    difficulty: 'easy',
    genre: ['Comedy', 'Drama']
  },
  {
    id: '64',
    title: 'Don',
    year: 2006,
    difficulty: 'medium',
    genre: ['Action', 'Crime']
  },
  {
    id: '65',
    title: 'Bhool Bhulaiyaa',
    year: 2007,
    difficulty: 'easy',
    genre: ['Horror', 'Comedy']
  },
  {
    id: '66',
    title: 'Welcome',
    year: 2007,
    difficulty: 'easy',
    genre: ['Comedy', 'Crime']
  },
  {
    id: '67',
    title: 'Rock On!!',
    year: 2008,
    difficulty: 'easy',
    genre: ['Drama', 'Musical']
  },
  {
    id: '68',
    title: 'Fashion',
    year: 2008,
    difficulty: 'medium',
    genre: ['Drama']
  },
  {
    id: '69',
    title: 'Ghajini',
    year: 2008,
    difficulty: 'medium',
    genre: ['Action', 'Drama']
  },
  {
    id: '70',
    title: 'Rab Ne Bana Di Jodi',
    year: 2008,
    difficulty: 'easy',
    genre: ['Comedy', 'Romance']
  },
  {
    id: '71',
    title: 'Love Aaj Kal',
    year: 2009,
    difficulty: 'easy',
    genre: ['Romance', 'Drama']
  },
  {
    id: '72',
    title: 'My Name Is Khan',
    year: 2010,
    difficulty: 'medium',
    genre: ['Drama']
  },
  {
    id: '73',
    title: 'Band Baaja Baaraat',
    year: 2010,
    difficulty: 'easy',
    genre: ['Comedy', 'Romance']
  },
  {
    id: '74',
    title: 'Ra.One',
    year: 2011,
    difficulty: 'easy',
    genre: ['Action', 'Sci-Fi']
  },
  {
    id: '75',
    title: 'Agneepath',
    year: 2012,
    difficulty: 'medium',
    genre: ['Action', 'Crime']
  },
  {
    id: '76',
    title: 'Cocktail',
    year: 2012,
    difficulty: 'easy',
    genre: ['Romance', 'Drama']
  },
  {
    id: '77',
    title: 'Student of the Year',
    year: 2012,
    difficulty: 'easy',
    genre: ['Romance', 'Comedy']
  },
  {
    id: '78',
    title: 'Yeh Jawaani Hai Deewani',
    year: 2013,
    difficulty: 'easy',
    genre: ['Romance', 'Drama']
  },
  {
    id: '79',
    title: 'Chennai Express',
    year: 2013,
    difficulty: 'easy',
    genre: ['Action', 'Comedy']
  },
  {
    id: '80',
    title: 'Goliyon Ki Raasleela Ram-Leela',
    year: 2013,
    difficulty: 'medium',
    genre: ['Drama', 'Romance']
  },
  {
    id: '81',
    title: 'Highway',
    year: 2014,
    difficulty: 'medium',
    genre: ['Drama', 'Romance']
  },
  {
    id: '82',
    title: '2 States',
    year: 2014,
    difficulty: 'easy',
    genre: ['Romance', 'Drama']
  },
  {
    id: '83',
    title: 'Kick',
    year: 2014,
    difficulty: 'easy',
    genre: ['Action', 'Comedy']
  },
  {
    id: '84',
    title: 'Happy New Year',
    year: 2014,
    difficulty: 'easy',
    genre: ['Action', 'Comedy']
  },
  {
    id: '85',
    title: 'Baby',
    year: 2015,
    difficulty: 'medium',
    genre: ['Action', 'Thriller']
  },
  {
    id: '86',
    title: 'Piku',
    year: 2015,
    difficulty: 'easy',
    genre: ['Comedy', 'Drama']
  },
  {
    id: '87',
    title: 'Tamasha',
    year: 2015,
    difficulty: 'medium',
    genre: ['Drama', 'Romance']
  },
  {
    id: '88',
    title: 'Dilwale',
    year: 2015,
    difficulty: 'easy',
    genre: ['Action', 'Comedy']
  },
  {
    id: '89',
    title: 'Airlift',
    year: 2016,
    difficulty: 'medium',
    genre: ['Drama', 'History']
  },
  {
    id: '90',
    title: 'Kapoor & Sons',
    year: 2016,
    difficulty: 'medium',
    genre: ['Drama', 'Family']
  },
  {
    id: '91',
    title: 'Sultan',
    year: 2016,
    difficulty: 'easy',
    genre: ['Action', 'Sport']
  },
  {
    id: '92',
    title: 'Dear Zindagi',
    year: 2016,
    difficulty: 'medium',
    genre: ['Drama', 'Romance']
  },
  {
    id: '93',
    title: 'Ae Dil Hai Mushkil',
    year: 2016,
    difficulty: 'medium',
    genre: ['Drama', 'Romance']
  },
  {
    id: '94',
    title: 'Kaabil',
    year: 2017,
    difficulty: 'medium',
    genre: ['Action', 'Drama']
  },
  {
    id: '95',
    title: 'Hindi Medium',
    year: 2017,
    difficulty: 'easy',
    genre: ['Comedy', 'Drama']
  },
  {
    id: '96',
    title: 'Tiger Zinda Hai',
    year: 2017,
    difficulty: 'easy',
    genre: ['Action', 'Thriller']
  },
  {
    id: '97',
    title: 'Pad Man',
    year: 2018,
    difficulty: 'easy',
    genre: ['Biography', 'Drama']
  },
  {
    id: '98',
    title: 'Sanju',
    year: 2018,
    difficulty: 'medium',
    genre: ['Biography', 'Drama']
  },
  {
    id: '99',
    title: 'Andhadhun',
    year: 2018,
    difficulty: 'hard',
    genre: ['Crime', 'Thriller']
  },
  {
    id: '100',
    title: 'Kedarnath',
    year: 2018,
    difficulty: 'medium',
    genre: ['Drama', 'Romance']
  },
  {
    id: '101',
    title: 'Manikarnika',
    year: 2019,
    difficulty: 'medium',
    genre: ['Action', 'Biography']
  },
  {
    id: '102',
    title: 'Gully Boy',
    year: 2019,
    difficulty: 'medium',
    genre: ['Drama', 'Musical']
  },
  {
    id: '103',
    title: 'Kabir Singh',
    year: 2019,
    difficulty: 'medium',
    genre: ['Drama', 'Romance']
  },
  {
    id: '104',
    title: 'War',
    year: 2019,
    difficulty: 'easy',
    genre: ['Action', 'Thriller']
  },
  {
    id: '105',
    title: 'Housefull 4',
    year: 2019,
    difficulty: 'easy',
    genre: ['Comedy', 'Fantasy']
  },
  {
    id: '106',
    title: 'Taal',
    year: 1999,
    difficulty: 'medium',
    genre: ['Drama', 'Musical']
  },
  {
    id: '107',
    title: 'Sarfarosh',
    year: 1999,
    difficulty: 'medium',
    genre: ['Action', 'Drama']
  },
  {
    id: '108',
    title: 'Baghban',
    year: 2003,
    difficulty: 'easy',
    genre: ['Drama', 'Family']
  },
  {
    id: '109',
    title: 'Kal Ho Naa Ho',
    year: 2003,
    difficulty: 'easy',
    genre: ['Drama', 'Romance']
  },
  {
    id: '110',
    title: 'Main Hoon Na',
    year: 2004,
    difficulty: 'easy',
    genre: ['Action', 'Comedy']
  },
  {
    id: '111',
    title: 'Veer-Zaara',
    year: 2004,
    difficulty: 'medium',
    genre: ['Drama', 'Romance']
  },
  {
    id: '112',
    title: 'Page 3',
    year: 2005,
    difficulty: 'medium',
    genre: ['Drama']
  },
  {
    id: '113',
    title: 'Bunty Aur Babli',
    year: 2005,
    difficulty: 'easy',
    genre: ['Comedy', 'Crime']
  },
  {
    id: '114',
    title: 'Parineeta',
    year: 2005,
    difficulty: 'medium',
    genre: ['Drama', 'Romance']
  },
  {
    id: '115',
    title: 'Krrish',
    year: 2006,
    difficulty: 'easy',
    genre: ['Action', 'Sci-Fi']
  },
  {
    id: '116',
    title: 'Fanaa',
    year: 2006,
    difficulty: 'medium',
    genre: ['Drama', 'Romance']
  },
  {
    id: '117',
    title: 'Dhoom 2',
    year: 2006,
    difficulty: 'easy',
    genre: ['Action', 'Thriller']
  },
  {
    id: '118',
    title: 'Life in a... Metro',
    year: 2007,
    difficulty: 'medium',
    genre: ['Drama', 'Romance']
  },
  {
    id: '119',
    title: 'Partner',
    year: 2007,
    difficulty: 'easy',
    genre: ['Comedy', 'Romance']
  },
  {
    id: '120',
    title: 'Om Shanti Om',
    year: 2007,
    difficulty: 'easy',
    genre: ['Drama', 'Romance']
  },
  {
    id: '121',
    title: 'Singh Is Kinng',
    year: 2008,
    difficulty: 'easy',
    genre: ['Action', 'Comedy']
  },
  {
    id: '122',
    title: 'Dostana',
    year: 2008,
    difficulty: 'easy',
    genre: ['Comedy', 'Romance']
  },
  {
    id: '123',
    title: 'Ajab Prem Ki Ghazab Kahani',
    year: 2009,
    difficulty: 'easy',
    genre: ['Comedy', 'Romance']
  },
  {
    id: '124',
    title: 'Paa',
    year: 2009,
    difficulty: 'medium',
    genre: ['Comedy', 'Drama']
  },
  {
    id: '125',
    title: 'Once Upon a Time in Mumbai',
    year: 2010,
    difficulty: 'medium',
    genre: ['Crime', 'Drama']
  },
  {
    id: '126',
    title: 'Dabangg',
    year: 2010,
    difficulty: 'easy',
    genre: ['Action', 'Comedy']
  },
  {
    id: '127',
    title: 'Kaminey',
    year: 2009,
    difficulty: 'hard',
    genre: ['Action', 'Crime']
  },
  {
    id: '128',
    title: 'Ishqiya',
    year: 2010,
    difficulty: 'medium',
    genre: ['Comedy', 'Drama']
  },
  {
    id: '129',
    title: 'No One Killed Jessica',
    year: 2011,
    difficulty: 'medium',
    genre: ['Biography', 'Crime']
  },
  {
    id: '130',
    title: 'Zindagi Na Milegi Dobara',
    year: 2011,
    difficulty: 'easy',
    genre: ['Drama', 'Comedy']
  },
  {
    id: '131',
    title: 'The Dirty Picture',
    year: 2011,
    difficulty: 'medium',
    genre: ['Biography', 'Drama']
  },
  {
    id: '132',
    title: 'Bodyguard',
    year: 2011,
    difficulty: 'easy',
    genre: ['Action', 'Romance']
  }
    ]