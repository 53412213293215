import { AlertTriangle } from 'lucide-react';
import { Button } from '@/components/ui/button';

interface PaymentErrorProps {
  error: string;
  onRetry: () => void;
}

export function PaymentError({ error, onRetry }: PaymentErrorProps) {
  return (
    <div className="min-h-screen pattern-bg flex items-center justify-center p-4">
      <div className="game-card p-8 text-center max-w-md w-full">
        <AlertTriangle className="w-16 h-16 text-red-500 mx-auto mb-6" />
        <h2 className="text-2xl font-bold mb-4 text-red-500">Error</h2>
        <p className="text-black/70 mb-6">{error}</p>
        <Button onClick={onRetry} className="btn-primary">
          Return Home
        </Button>
      </div>
    </div>
  );
}