import { useState, useEffect } from 'react';
import { Gamepad2 } from 'lucide-react';
import { GameFeatures } from './GameFeatures';
import { AccessCodeForm } from './AccessCodeForm';
import { SocialProofBar } from './SocialProofBar';
import { PricingSection } from './PricingSection';
import { VideoPreview } from './VideoPreview';
import { stripe } from '@/lib/stripe';

interface PaywallPageProps {
  onAccessGranted: (accessCode?: string) => void;
}

export function PaywallPage({ onAccessGranted }: PaywallPageProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [activePlayers, setActivePlayers] = useState(150);

  useEffect(() => {
    const interval = setInterval(() => {
      setActivePlayers(prev => {
        const variance = Math.floor(Math.random() * 20) - 10;
        return Math.max(100, prev + variance);
      });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleCheckout = async () => {
    try {
      setIsLoading(true);
      stripe.redirectToPayment();
    } catch (error) {
      console.error('Checkout error:', error);
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen pattern-bg flex items-center justify-center p-4">
      <div className="w-full max-w-4xl mx-auto">
        <div className="game-card p-8 space-y-8">
          {/* Header */}
          <div className="text-center space-y-4">
            <Gamepad2 className="w-16 h-16 mx-auto text-[#FFD233]" />
            <h1 className="text-4xl md:text-5xl font-bold tracking-tight">
              Because screen time is
              <br />
              better together.
            </h1>
            <p className="text-xl text-black/70 max-w-2xl mx-auto">
              No phones. No apps. Just friends & family playing on the TV screen.
            </p>
            <div className="text-lg font-semibold text-[#FFD233]">
              {activePlayers} families having fun right now
            </div>
          </div>

          <SocialProofBar />

          <VideoPreview 
            videoId="okFzny-5880"
            title="See MirFam Games in Action"
          />

          <GameFeatures />

          <PricingSection 
            onCheckout={handleCheckout}
            isLoading={isLoading}
          />

          <div className="border-t border-black/10 pt-8">
            <AccessCodeForm onAccessGranted={onAccessGranted} />
          </div>
        </div>
      </div>
    </div>
  );
}