import { supabase } from '@/lib/supabase';
import { PaymentErrors } from '@/types/payment';
import type { PaymentSessionResult } from '@/types/payment';

export async function findUserBySession(sessionId: string) {
  const { data, error } = await supabase
    .from('users')
    .select('access_code')
    .eq('stripe_payment_intent', sessionId)
    .single();

  if (error && error.code !== 'PGRST116') {
    console.error('Database lookup error:', error);
    throw PaymentErrors.DATABASE_ERROR;
  }

  return data;
}

export async function createUser(sessionId: string, accessCode: string): Promise<PaymentSessionResult> {
  const { data, error } = await supabase
    .from('users')
    .insert({
      access_code: accessCode,
      stripe_payment_intent: sessionId,
      has_active_subscription: true
    })
    .select()
    .single();

  if (error) {
    console.error('Database insert error:', error);
    throw PaymentErrors.DATABASE_ERROR;
  }

  return {
    accessCode: data.access_code,
    isExisting: false
  };
}