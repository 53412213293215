import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PaywallPage } from '@/components/paywall/PaywallPage';
import { checkUserAccess, setStoredAccessCode, getStoredAccessCode } from '@/lib/auth';

export function HomePage() {
  const navigate = useNavigate();

  useEffect(() => {
    const checkExisting = async () => {
      const accessCode = getStoredAccessCode();
      if (!accessCode) return;

      const hasAccess = await checkUserAccess(accessCode);
      if (hasAccess) {
        navigate('/', { replace: true });
      }
    };

    checkExisting();
  }, []);

  const handleAccessGranted = (accessCode?: string) => {
    if (accessCode) {
      setStoredAccessCode(accessCode);
      navigate('/', { replace: true });
    }
  };

  return <PaywallPage onAccessGranted={handleAccessGranted} />;
}