import { loadStripe } from '@stripe/stripe-js';

// Payment link URL from your Stripe dashboard
const PAYMENT_LINK = 'https://buy.stripe.com/bIY9B0gp491bci49AI';

export const stripe = {
  redirectToPayment: () => {
    window.location.href = PAYMENT_LINK;
  },
  config: {
    product: {
      name: 'MirFam Games Access',
      description: 'Lifetime access to all MirFam Games',
      price: 899, // $8.99
      currency: 'usd'
    }
  },
  utils: {
    formatPrice: (amount: number, currency: string = 'usd'): string => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency.toUpperCase(),
      }).format(amount / 100);
    }
  }
};