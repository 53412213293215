import { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { TriviaQuestion } from '@/data/trivia';
import { Timer } from '@/components/Timer';
import { SkipForward } from 'lucide-react';
import { cn, shuffleArray } from '@/lib/utils';

interface TriviaCardProps {
  question: TriviaQuestion;
  onNextQuestion: () => void;
  onScorePoint: () => void;
}

export function TriviaCard({ question, onNextQuestion, onScorePoint }: TriviaCardProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [hasScored, setHasScored] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [shuffledOptions, setShuffledOptions] = useState<string[]>([]);

  useEffect(() => {
    if (question) {
      // Create a new array with the correct answer and other options
      const allOptions = [question.correctAnswer, ...question.options.filter(opt => opt !== question.correctAnswer)];
      // Shuffle the options
      setShuffledOptions(shuffleArray(allOptions));
      // Reset states for new question
      setIsPlaying(true);
      setSelectedAnswer(null);
      setHasScored(false);
      setShowResult(false);
    }
  }, [question]);

  const handleTimeUp = () => {
    setIsPlaying(false);
    setShowResult(true);
  };

  const handleAnswerSelect = (answer: string) => {
    if (!isPlaying || showResult) return;
    
    setSelectedAnswer(answer);
    setShowResult(true);
    setIsPlaying(false);
    
    if (answer === question.correctAnswer && !hasScored) {
      setHasScored(true);
      onScorePoint();
    }
  };

  const handleNext = () => {
    onNextQuestion();
  };

  return (
    <div className="game-card p-8 space-y-6">
      <div className="flex flex-wrap gap-3 justify-between items-center">
        <span className={cn(
          "difficulty-badge",
          question.difficulty === 'easy' ? 'difficulty-easy' :
          question.difficulty === 'medium' ? 'difficulty-medium' : 'difficulty-hard'
        )}>
          {question.difficulty}
        </span>
        <span className="genre-badge">{question.category}</span>
      </div>

      <div className="min-h-[240px] flex flex-col items-center justify-center gap-6 p-6">
        <h2 className="text-2xl font-bold text-center">{question.question}</h2>
        
        <div className="w-full max-w-md space-y-4">
          {shuffledOptions.map((option) => (
            <Button
              key={option}
              onClick={() => handleAnswerSelect(option)}
              className={cn(
                "w-full text-lg py-6 transition-all",
                !showResult ? "btn-secondary" :
                option === question.correctAnswer ? "bg-green-500 hover:bg-green-600 text-white border-green-700" :
                option === selectedAnswer ? "bg-red-500 hover:bg-red-600 text-white border-red-700" :
                "btn-secondary opacity-50"
              )}
              disabled={!isPlaying || showResult}
            >
              {option}
            </Button>
          ))}
        </div>
      </div>

      <div className="space-y-6">
        <Timer
          duration={30}
          isRunning={isPlaying}
          onTimeUp={handleTimeUp}
        />

        <div className="flex flex-wrap gap-4 justify-center">
          {showResult && (
            <Button
              onClick={handleNext}
              className="btn-secondary min-w-[200px]"
            >
              <SkipForward className="mr-2 h-5 w-5" />
              Next Question
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}