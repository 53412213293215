import { useEffect, useRef, useState } from 'react';

interface TimerProps {
  duration: number;
  isRunning: boolean;
  onTimeUp: () => void;
  onTimeUpdate?: (secondsLeft: number) => void;
}

export function Timer({ duration, isRunning, onTimeUp, onTimeUpdate }: TimerProps) {
  const progressRef = useRef<HTMLDivElement>(null);
  const timerRef = useRef<NodeJS.Timeout>();
  const startTimeRef = useRef<number>();
  const remainingRef = useRef<number>(duration);
  const [displayTime, setDisplayTime] = useState(duration);

  useEffect(() => {
    if (!isRunning) {
      remainingRef.current = duration;
      setDisplayTime(duration);
      if (progressRef.current) {
        progressRef.current.style.width = '100%';
      }
      return () => {
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
      };
    }

    startTimeRef.current = Date.now() - (duration - remainingRef.current) * 1000;
    
    const updateTimer = () => {
      if (!startTimeRef.current) return;
      
      const elapsed = Math.floor((Date.now() - startTimeRef.current) / 1000);
      const remaining = Math.max(0, duration - elapsed);
      remainingRef.current = remaining;
      setDisplayTime(remaining);
      
      if (progressRef.current) {
        progressRef.current.style.width = `${(remaining / duration) * 100}%`;
      }
      
      if (onTimeUpdate) {
        onTimeUpdate(remaining);
      }
      
      if (remaining <= 0) {
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
        onTimeUp();
      }
    };

    timerRef.current = setInterval(updateTimer, 100);
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [duration, isRunning, onTimeUp, onTimeUpdate]);

  return (
    <div className="space-y-2">
      <div className="flex justify-between text-sm font-medium">
        <span>Time Remaining</span>
        <span>{displayTime}s</span>
      </div>
      <div className="progress-bar">
        <div 
          ref={progressRef}
          className="progress-bar-fill"
          style={{ width: '100%' }}
        />
      </div>
    </div>
  );
}