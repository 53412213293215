import { createContext, useContext, ReactNode } from 'react';
import { usePaymentSession } from '@/hooks/usePaymentSession';
import type { PaymentContextType } from '@/types/payment';

const PaymentContext = createContext<PaymentContextType | null>(null);

export function PaymentProvider({ children }: { children: ReactNode }) {
  const paymentState = usePaymentSession();
  return (
    <PaymentContext.Provider value={paymentState}>
      {children}
    </PaymentContext.Provider>
  );
}

export function usePayment() {
  const context = useContext(PaymentContext);
  if (!context) {
    throw new Error('usePayment must be used within PaymentProvider');
  }
  return context;
}