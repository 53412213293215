import { useGameLogic } from '@/hooks/useGameLogic';
import { GameBoard } from '@/components/GameBoard';
import { WinnerScreen } from '@/components/WinnerScreen';
import type { GameType, GameConfig } from '@/types/game';

interface ActiveGameProps {
  gameType: GameType;
  gameConfig: GameConfig;
  items: any[];
  teamHints: { [key: string]: number };
  onUseHint: (team: string) => void;
  onPlayAgain: () => void;
}

export function ActiveGame({
  gameType,
  gameConfig,
  items,
  teamHints,
  onUseHint,
  onPlayAgain
}: ActiveGameProps) {
  const gameLogic = useGameLogic({
    items,
    teams: gameConfig.teams,
    pointsToWin: gameConfig.pointsToWin
  });

  if (gameLogic.hasWinner && gameLogic.winningTeam) {
    return (
      <WinnerScreen
        winnerName={gameLogic.winningTeam}
        onPlayAgain={onPlayAgain}
      />
    );
  }

  return (
    <GameBoard
      gameType={gameType}
      teams={gameConfig.teams}
      scores={gameLogic.scores}
      currentTeamIndex={gameLogic.currentTeamIndex}
      pointsToWin={gameConfig.pointsToWin}
      currentItem={items[gameLogic.currentIndex]}
      teamHints={teamHints}
      onNext={gameLogic.handleNext}
      onScorePoint={gameLogic.handleScorePoint}
      onUseHint={onUseHint}
    />
  );
}