import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Timer } from '@/components/Timer';
import { WordProblem } from '@/data/word-problems';
import { SkipForward, Calculator } from 'lucide-react';
import { cn } from '@/lib/utils';

interface WordProblemCardProps {
  problem?: WordProblem;
  onNextProblem: () => void;
  onScorePoint: () => void;
}

export function WordProblemCard({ problem, onNextProblem, onScorePoint }: WordProblemCardProps) {
  const [answer, setAnswer] = useState('');
  const [isAnswering, setIsAnswering] = useState(false);
  const [isRevealed, setIsRevealed] = useState(false);
  const [hasScored, setHasScored] = useState(false);

  if (!problem) {
    return (
      <div className="game-card p-8 text-center">
        <p className="text-lg text-black/70">Loading...</p>
      </div>
    );
  }

  const handleStart = () => {
    setIsAnswering(true);
    setAnswer('');
    setHasScored(false);
  };

  const handleTimeUp = () => {
    setIsAnswering(false);
    setIsRevealed(true);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsAnswering(false);
    setIsRevealed(true);
    
    const numericAnswer = parseFloat(answer);
    if (!isNaN(numericAnswer) && Math.abs(numericAnswer - problem.answer) < 0.01) {
      setHasScored(true);
      onScorePoint();
    }
  };

  const handleNext = () => {
    setIsRevealed(false);
    setIsAnswering(false);
    setHasScored(false);
    setAnswer('');
    onNextProblem();
  };

  return (
    <div className="game-card p-8 space-y-6">
      <div className="flex flex-wrap gap-3 justify-between items-center">
        <span className={cn(
          "difficulty-badge",
          problem.difficulty === 'easy' ? 'difficulty-easy' :
          problem.difficulty === 'medium' ? 'difficulty-medium' : 'difficulty-hard'
        )}>
          {problem.difficulty}
        </span>
      </div>

      <div className="min-h-[240px] flex flex-col items-center justify-center gap-6">
        <div className="text-center space-y-4">
          <h2 className="text-2xl font-bold">{problem.question}</h2>
          
          {isAnswering && (
            <form onSubmit={handleSubmit} className="max-w-xs mx-auto space-y-4">
              <Input
                type="number"
                step="0.01"
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
                placeholder="Enter your answer"
                className="text-center text-xl"
                autoFocus
              />
              <Button type="submit" className="btn-primary w-full">
                Submit Answer
              </Button>
            </form>
          )}

          {isRevealed && (
            <div className="space-y-4">
              <p className="text-xl">
                Correct Answer: <span className="font-bold">{problem.answer}</span>
              </p>
              {hasScored ? (
                <p className="text-2xl font-bold text-green-600">Correct!</p>
              ) : (
                <p className="text-2xl font-bold text-red-600">
                  {answer ? 'Incorrect!' : 'Time\'s up!'}
                </p>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="space-y-6">
        <Timer
          duration={60}
          isRunning={isAnswering}
          onTimeUp={handleTimeUp}
        />

        <div className="flex flex-wrap gap-4 justify-center">
          {!isAnswering && !isRevealed && (
            <Button 
              onClick={handleStart}
              className="btn-primary min-w-[200px]"
            >
              <Calculator className="mr-2 h-5 w-5" />
              Start Solving
            </Button>
          )}
          
          {isRevealed && (
            <Button
              onClick={handleNext}
              className="btn-secondary min-w-[200px]"
            >
              <SkipForward className="mr-2 h-5 w-5" />
              Next Problem
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}