import {
  Gamepad2,
  Brain,
  Pencil,
  Puzzle,
  Film,
  Calculator,
  Check,
} from 'lucide-react';

const features = [
  {
    icon: Film,
    title: 'Movie Charades',
    description: 'Act out scenes from popular Bollywood and Hollywood movies',
  },
  {
    icon: Pencil,
    title: 'Pictionary',
    description: 'Draw and guess words from various categories',
  },
  {
    icon: Puzzle,
    title: 'Word Scramble',
    description: 'Unscramble words with helpful hints',
  },
  {
    icon: Brain,
    title: 'Smart Trivia',
    description: 'Test your knowledge across different topics',
  },
  {
    icon: Calculator,
    title: 'Word Problems',
    description: 'Solve engaging mathematical word problems',
  },
  {
    icon: Gamepad2,
    title: 'Team Play',
    description: 'Compete with friends and family in teams',
  },
];

export function GameFeatures() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {features.map((feature, index) => (
        <div
          key={index}
          className="bg-cream-dark p-6 rounded-xl border-3 border-black space-y-4"
        >
          <feature.icon className="w-8 h-8 text-[#FFD233]" />
          <h3 className="text-xl font-bold">{feature.title}</h3>
          <p className="text-black/70">{feature.description}</p>
          <div className="flex items-center gap-2 text-sm font-medium">
            <Check className="w-4 h-4 text-green-500" />
            Included
          </div>
        </div>
      ))}
    </div>
  );
}