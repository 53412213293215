import { useState } from 'react';
import { Film, Users, Trophy, Clapperboard, ArrowLeft, BrainCircuit, Pencil } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { GameType } from '@/App';

interface GameSetupProps {
  onGameStart: (config: {
    teams: string[];
    category: string;
    pointsToWin: number;
  }) => void;
  gameType: GameType;
  onBack: () => void;
}

export function GameSetup({ onGameStart, gameType, onBack }: GameSetupProps) {
  const [numTeams, setNumTeams] = useState<string>('2');
  const [teamNames, setTeamNames] = useState<string[]>(['', '']);
  const [category, setCategory] = useState<string>(
    gameType === 'charades' ? 'bollywood' : 
    gameType === 'trivia' ? 'Movies' :
    gameType === 'pictionary' ? 'Mix' :
    'bollywood'
  );
  const [pointsToWin, setPointsToWin] = useState<string>('10');

  const handleNumTeamsChange = (value: string) => {
    const num = parseInt(value);
    setNumTeams(value);
    setTeamNames(Array(num).fill(''));
  };

  const handleTeamNameChange = (index: number, value: string) => {
    const newTeamNames = [...teamNames];
    newTeamNames[index] = value;
    setTeamNames(newTeamNames);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (teamNames.every(name => name.trim())) {
      onGameStart({
        teams: teamNames.map(name => name.trim()),
        category,
        pointsToWin: Number(pointsToWin)
      });
    }
  };

  const getIcon = () => {
    switch (gameType) {
      case 'charades':
        return [<Film key="1" className="w-12 h-12 text-[#FFD233]" />, <Clapperboard key="2" className="w-12 h-12 text-[#FFD233]" />];
      case 'pictionary':
        return [<Pencil key="1" className="w-12 h-12 text-[#FFD233]" />];
      case 'trivia':
        return [<BrainCircuit key="1" className="w-12 h-12 text-[#FFD233]" />];
      default:
        return [<Trophy key="1" className="w-12 h-12 text-[#FFD233]" />];
    }
  };

  return (
    <div className="min-h-screen pattern-bg flex items-center justify-center p-4">
      <div className="w-full max-w-lg mx-auto px-4 py-8">
        <div className="game-card p-8 space-y-8">
          <div className="space-y-4 text-center relative">
            <Button 
              variant="ghost" 
              className="absolute left-0 top-0 hover:bg-transparent"
              onClick={onBack}
            >
              <ArrowLeft className="h-6 w-6" />
            </Button>
            <div className="flex justify-center gap-4">
              {getIcon()}
            </div>
            <h1 className="text-4xl font-bold tracking-tight">Game Setup</h1>
            <p className="text-lg text-black/70">
              Configure your game settings
            </p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-4">
              {(gameType === 'charades' || gameType === 'trivia' || gameType === 'pictionary') && (
                <label className="block space-y-2">
                  <span className="text-sm font-medium flex items-center gap-2">
                    {gameType === 'charades' ? (
                      <>
                        <Film className="w-4 h-4" />
                        Movie Category
                      </>
                    ) : gameType === 'pictionary' ? (
                      <>
                        <Pencil className="w-4 h-4" />
                        Drawing Category
                      </>
                    ) : (
                      <>
                        <BrainCircuit className="w-4 h-4" />
                        Quiz Category
                      </>
                    )}
                  </span>
                  <Select
                    value={category}
                    onValueChange={(value: string) => setCategory(value)}
                  >
                    <SelectTrigger className="btn-secondary">
                      <SelectValue placeholder="Select category" />
                    </SelectTrigger>
                    <SelectContent>
                      {gameType === 'charades' ? (
                        <>
                          <SelectItem value="bollywood">Bollywood</SelectItem>
                          <SelectItem value="hollywood">Hollywood</SelectItem>
                        </>
                      ) : gameType === 'pictionary' ? (
                        <>
                          <SelectItem value="Mix">Mix (All Categories)</SelectItem>
                          <SelectItem value="Animals">Animals</SelectItem>
                          <SelectItem value="Objects">Objects</SelectItem>
                          <SelectItem value="Actions">Actions</SelectItem>
                          <SelectItem value="Nature">Nature</SelectItem>
                          <SelectItem value="Food">Food</SelectItem>
                          <SelectItem value="Sports">Sports</SelectItem>
                          <SelectItem value="Emotions">Emotions</SelectItem>
                          <SelectItem value="Places">Places</SelectItem>
                          <SelectItem value="Professions">Professions</SelectItem>
                          <SelectItem value="Entertainment">Entertainment</SelectItem>
                        </>
                      ) : (
                        <>
                          <SelectItem value="Movies">Movies</SelectItem>
                          <SelectItem value="Sports">Sports</SelectItem>
                          <SelectItem value="Geography">Geography</SelectItem>
                          <SelectItem value="History">History</SelectItem>
                          <SelectItem value="Science">Science</SelectItem>
                        </>
                      )}
                    </SelectContent>
                  </Select>
                </label>
              )}

              <label className="block space-y-2">
                <span className="text-sm font-medium flex items-center gap-2">
                  <Users className="w-4 h-4" />
                  Number of Teams
                </span>
                <Select
                  value={numTeams}
                  onValueChange={handleNumTeamsChange}
                >
                  <SelectTrigger className="btn-secondary">
                    <SelectValue placeholder="Select number of teams" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="2">2 Teams</SelectItem>
                    <SelectItem value="3">3 Teams</SelectItem>
                    <SelectItem value="4">4 Teams</SelectItem>
                    <SelectItem value="5">5 Teams</SelectItem>
                    <SelectItem value="6">6 Teams</SelectItem>
                    <SelectItem value="7">7 Teams</SelectItem>
                  </SelectContent>
                </Select>
              </label>

              <label className="block space-y-2">
                <span className="text-sm font-medium flex items-center gap-2">
                  <Trophy className="w-4 h-4" />
                  Points to Win
                </span>
                <Select
                  value={pointsToWin}
                  onValueChange={setPointsToWin}
                >
                  <SelectTrigger className="btn-secondary">
                    <SelectValue placeholder="Select points to win" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="5">5 Points</SelectItem>
                    <SelectItem value="10">10 Points</SelectItem>
                    <SelectItem value="15">15 Points</SelectItem>
                    <SelectItem value="20">20 Points</SelectItem>
                  </SelectContent>
                </Select>
              </label>

              <div className="space-y-4 pt-2">
                {teamNames.map((name, index) => (
                  <label key={index} className="block space-y-2">
                    <span className="text-sm font-medium">Team {index + 1} Name</span>
                    <Input
                      required
                      placeholder={`Enter team ${index + 1} name`}
                      value={name}
                      onChange={(e) => handleTeamNameChange(index, e.target.value)}
                      className="btn-secondary"
                    />
                  </label>
                ))}
              </div>
            </div>

            <Button type="submit" className="btn-primary w-full text-lg py-6">
              Start Game
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}