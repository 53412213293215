export interface TriviaQuestion {
  id: string;
  category: 'Movies' | 'Sports' | 'Geography' | 'History' | 'Science';
  question: string;
  options: string[];
  correctAnswer: string;
  difficulty: 'easy' | 'medium' | 'hard';
}

export const triviaQuestions: TriviaQuestion[] = [
  {
    id: 'm1',
    question: 'Which movie won the Academy Award for Best Picture in 2020?',
    options: ['Parasite', '1917', 'Joker', 'Once Upon a Time in Hollywood'],
    correctAnswer: 'Parasite',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm2',
    question: 'Who directed the film "Inception"?',
    options: ['Christopher Nolan', 'Steven Spielberg', 'Martin Scorsese', 'James Cameron'],
    correctAnswer: 'Christopher Nolan',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm3',
    question: 'Which actor plays Iron Man in the Marvel Cinematic Universe?',
    options: ['Robert Downey Jr.', 'Chris Evans', 'Chris Hemsworth', 'Mark Ruffalo'],
    correctAnswer: 'Robert Downey Jr.',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm4',
    question: 'What is the highest-grossing film of all time?',
    options: ['Avatar', 'Avengers: Endgame', 'Titanic', 'Star Wars: Episode VII'],
    correctAnswer: 'Avatar',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm5',
    question: 'Which film features the character Jack Dawson?',
    options: ['Titanic', 'The Great Gatsby', 'Inception', 'The Departed'],
    correctAnswer: 'Titanic',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm6',
    question: 'Who directed "Jurassic Park"?',
    options: ['Steven Spielberg', 'James Cameron', 'George Lucas', 'Peter Jackson'],
    correctAnswer: 'Steven Spielberg',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm7',
    question: 'Which movie features a character named Luke Skywalker?',
    options: ['Star Wars', 'Star Trek', 'The Matrix', 'Avatar'],
    correctAnswer: 'Star Wars',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm8',
    question: 'Who played the Joker in "The Dark Knight"?',
    options: ['Heath Ledger', 'Jack Nicholson', 'Jared Leto', 'Joaquin Phoenix'],
    correctAnswer: 'Heath Ledger',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm9',
    question: 'Which movie won the first Academy Award for Best Animated Feature?',
    options: ['Shrek', 'Toy Story', 'Finding Nemo', 'Monsters, Inc.'],
    correctAnswer: 'Shrek',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm10',
    question: 'In which year was the first "Harry Potter" film released?',
    options: ['2001', '2000', '2002', '1999'],
    correctAnswer: '2001',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm11',
    question: 'Who directed "The Godfather"?',
    options: ['Francis Ford Coppola', 'Martin Scorsese', 'Stanley Kubrick', 'Alfred Hitchcock'],
    correctAnswer: 'Francis Ford Coppola',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm12',
    question: 'Which actress won an Oscar for her role in "La La Land"?',
    options: ['Emma Stone', 'Emma Watson', 'Jennifer Lawrence', 'Natalie Portman'],
    correctAnswer: 'Emma Stone',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm13',
    question: 'What is the name of the fictional country in "Black Panther"?',
    options: ['Wakanda', 'Zamunda', 'Genovia', 'Latveria'],
    correctAnswer: 'Wakanda',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm14',
    question: 'Which movie features the quote "Life is like a box of chocolates"?',
    options: ['Forrest Gump', 'The Notebook', 'Chocolat', 'Big'],
    correctAnswer: 'Forrest Gump',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm15',
    question: 'Who directed "E.T. the Extra-Terrestrial"?',
    options: ['Steven Spielberg', 'George Lucas', 'Robert Zemeckis', 'Tim Burton'],
    correctAnswer: 'Steven Spielberg',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm16',
    question: 'Which actor played Neo in "The Matrix"?',
    options: ['Keanu Reeves', 'Tom Cruise', 'Brad Pitt', 'Will Smith'],
    correctAnswer: 'Keanu Reeves',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm17',
    question: 'What was the first Pixar feature film?',
    options: ['Toy Story', 'A Bugs Life', 'Monsters Inc.', 'Finding Nemo'],
    correctAnswer: 'Toy Story',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm18',
    question: 'Who played Wolverine in the X-Men film series?',
    options: ['Hugh Jackman', 'Russell Crowe', 'Gerard Butler', 'Christian Bale'],
    correctAnswer: 'Hugh Jackman',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm19',
    question: 'Which film won the Academy Award for Best Picture in 2019?',
    options: ['Green Book', 'Roma', 'The Favourite', 'A Star Is Born'],
    correctAnswer: 'Green Book',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm20',
    question: 'Who directed "Pulp Fiction"?',
    options: ['Quentin Tarantino', 'Martin Scorsese', 'David Fincher', 'Christopher Nolan'],
    correctAnswer: 'Quentin Tarantino',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm21',
    question: 'Which actress plays Katniss Everdeen in "The Hunger Games"?',
    options: ['Jennifer Lawrence', 'Emma Watson', 'Shailene Woodley', 'Dakota Fanning'],
    correctAnswer: 'Jennifer Lawrence',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm22',
    question: 'What is the name of Andy\'s toy cowboy in "Toy Story"?',
    options: ['Woody', 'Buzz', 'Rex', 'Hamm'],
    correctAnswer: 'Woody',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm23',
    question: 'Who directed "The Shawshank Redemption"?',
    options: ['Frank Darabont', 'Steven Spielberg', 'Martin Scorsese', 'Rob Reiner'],
    correctAnswer: 'Frank Darabont',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm24',
    question: 'Which actor played James Bond in "Casino Royale" (2006)?',
    options: ['Daniel Craig', 'Pierce Brosnan', 'Sean Connery', 'Roger Moore'],
    correctAnswer: 'Daniel Craig',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm25',
    question: 'What is the name of the theme park in "Jurassic Park"?',
    options: ['Isla Nublar', 'Jurassic World', 'Dino Park', 'Prehistoric Paradise'],
    correctAnswer: 'Isla Nublar',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm26',
    question: 'Who played the role of the Wicked Witch of the West in "The Wizard of Oz"?',
    options: ['Margaret Hamilton', 'Judy Garland', 'Billie Burke', 'Betty Garde'],
    correctAnswer: 'Margaret Hamilton',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm27',
    question: 'Which movie features the character Jason Bourne?',
    options: ['The Bourne Identity', 'Mission Impossible', 'James Bond', 'Jack Ryan'],
    correctAnswer: 'The Bourne Identity',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm28',
    question: 'Who directed "Schindler\'s List"?',
    options: ['Steven Spielberg', 'Roman Polanski', 'Stanley Kubrick', 'Martin Scorsese'],
    correctAnswer: 'Steven Spielberg',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm29',
    question: 'What is the name of Thor\'s hammer in the Marvel movies?',
    options: ['Mjolnir', 'Stormbreaker', 'Gungnir', 'Hofund'],
    correctAnswer: 'Mjolnir',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm30',
    question: 'Which actor won an Oscar for his role in "The Revenant"?',
    options: ['Leonardo DiCaprio', 'Matthew McConaughey', 'Eddie Redmayne', 'Michael Keaton'],
    correctAnswer: 'Leonardo DiCaprio',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm31',
    question: 'Which movie features the famous line "Here\'s looking at you, kid"?',
    options: ['Casablanca', 'Gone with the Wind', 'Citizen Kane', 'The Maltese Falcon'],
    correctAnswer: 'Casablanca',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm32',
    question: 'Who directed "The Dark Knight" trilogy?',
    options: ['Christopher Nolan', 'Zack Snyder', 'Tim Burton', 'Joel Schumacher'],
    correctAnswer: 'Christopher Nolan',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm33',
    question: 'Which actress plays Wonder Woman in the DC Extended Universe?',
    options: ['Gal Gadot', 'Scarlett Johansson', 'Emily Blunt', 'Margot Robbie'],
    correctAnswer: 'Gal Gadot',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm34',
    question: 'What was the first feature-length film to be completely computer-animated?',
    options: ['Toy Story', 'Antz', 'A Bugs Life', 'The Prince of Egypt'],
    correctAnswer: 'Toy Story',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm35',
    question: 'Who played Hermione Granger in the Harry Potter films?',
    options: ['Emma Watson', 'Emma Stone', 'Emma Roberts', 'Emily Browning'],
    correctAnswer: 'Emma Watson',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm36',
    question: 'Which film features a giant ape climbing the Empire State Building?',
    options: ['King Kong', 'Mighty Joe Young', 'Godzilla', 'Planet of the Apes'],
    correctAnswer: 'King Kong',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm37',
    question: 'Who directed "The Silence of the Lambs"?',
    options: ['Jonathan Demme', 'David Fincher', 'Brian De Palma', 'Alfred Hitchcock'],
    correctAnswer: 'Jonathan Demme',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm38',
    question: 'Which actor plays Captain Jack Sparrow in "Pirates of the Caribbean"?',
    options: ['Johnny Depp', 'Orlando Bloom', 'Geoffrey Rush', 'Bill Nighy'],
    correctAnswer: 'Johnny Depp',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm39',
    question: 'What is the name of the lion cub in "The Lion King"?',
    options: ['Simba', 'Mufasa', 'Scar', 'Kimba'],
    correctAnswer: 'Simba',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm40',
    question: 'Which movie won the Academy Award for Best Animated Feature in 2023?',
    options: ['Guillermo del Toro\'s Pinocchio', 'Turning Red', 'Puss in Boots: The Last Wish', 'The Sea Beast'],
    correctAnswer: 'Guillermo del Toro\'s Pinocchio',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm41',
    question: 'Who directed "Avatar"?',
    options: ['James Cameron', 'Peter Jackson', 'Steven Spielberg', 'George Lucas'],
    correctAnswer: 'James Cameron',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm42',
    question: 'Which actress won an Oscar for her role in "Black Swan"?',
    options: ['Natalie Portman', 'Mila Kunis', 'Jennifer Lawrence', 'Anne Hathaway'],
    correctAnswer: 'Natalie Portman',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm43',
    question: 'What is the name of Tony Stark\'s AI assistant in the Iron Man movies?',
    options: ['JARVIS', 'FRIDAY', 'ALFRED', 'HAL'],
    correctAnswer: 'JARVIS',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm44',
    question: 'Who directed "The Lord of the Rings" trilogy?',
    options: ['Peter Jackson', 'J.J. Abrams', 'Ridley Scott', 'Christopher Nolan'],
    correctAnswer: 'Peter Jackson',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm45',
    question: 'Which actor played T\'Challa in "Black Panther"?',
    options: ['Chadwick Boseman', 'Michael B. Jordan', 'Daniel Kaluuya', 'Sterling K. Brown'],
    correctAnswer: 'Chadwick Boseman',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm46',
    question: 'What is the name of the island where "Jurassic World" is located?',
    options: ['Isla Nublar', 'Isla Sorna', 'Skull Island', 'Paradise Island'],
    correctAnswer: 'Isla Nublar',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm47',
    question: 'Who played Vito Corleone in "The Godfather"?',
    options: ['Marlon Brando', 'Al Pacino', 'Robert De Niro', 'James Caan'],
    correctAnswer: 'Marlon Brando',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm48',
    question: 'What is the name of the fictional metal in "Black Panther"?',
    options: ['Vibranium', 'Adamantium', 'Unobtanium', 'Kryptonite'],
    correctAnswer: 'Vibranium',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm49',
    question: 'Who directed "The Grand Budapest Hotel"?',
    options: ['Wes Anderson', 'Quentin Tarantino', 'Paul Thomas Anderson', 'Joel Coen'],
    correctAnswer: 'Wes Anderson',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm50',
    question: 'Which actor plays Spider-Man in the Marvel Cinematic Universe?',
    options: ['Tom Holland', 'Andrew Garfield', 'Tobey Maguire', 'Miles Teller'],
    correctAnswer: 'Tom Holland',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm51',
    question: 'What is the name of the space station in "2001: A Space Odyssey"?',
    options: ['Space Station V', 'Deep Space Nine', 'Babylon 5', 'The Citadel'],
    correctAnswer: 'Space Station V',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm52',
    question: 'Who played the lead role in "The Matrix Resurrections"?',
    options: ['Keanu Reeves', 'Laurence Fishburne', 'Hugo Weaving', 'Yahya Abdul-Mateen II'],
    correctAnswer: 'Keanu Reeves',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm53',
    question: 'Which studio produced "Spirited Away"?',
    options: ['Studio Ghibli', 'Pixar', 'DreamWorks', 'Walt Disney Animation'],
    correctAnswer: 'Studio Ghibli',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm54',
    question: 'Who directed "Goodfellas"?',
    options: ['Martin Scorsese', 'Francis Ford Coppola', 'Brian De Palma', 'Sidney Lumet'],
    correctAnswer: 'Martin Scorsese',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm55',
    question: 'What is the name of the AI system in "Ex Machina"?',
    options: ['Ava', 'Eva', 'Ada', 'Alice'],
    correctAnswer: 'Ava',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm56',
    question: 'Which actor played Batman in "The Batman" (2022)?',
    options: ['Robert Pattinson', 'Christian Bale', 'Ben Affleck', 'Michael Keaton'],
    correctAnswer: 'Robert Pattinson',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm57',
    question: 'What is the name of the ship in "Titanic"?',
    options: ['RMS Titanic', 'HMS Titanic', 'SS Titanic', 'MS Titanic'],
    correctAnswer: 'RMS Titanic',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm58',
    question: 'Who directed "Blade Runner" (1982)?',
    options: ['Ridley Scott', 'Stanley Kubrick', 'David Lynch', 'Terry Gilliam'],
    correctAnswer: 'Ridley Scott',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm59',
    question: 'Which actress plays Rey in the Star Wars sequel trilogy?',
    options: ['Daisy Ridley', 'Felicity Jones', 'Emma Watson', 'Jennifer Lawrence'],
    correctAnswer: 'Daisy Ridley',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm60',
    question: 'What is the name of the prison in "The Shawshank Redemption"?',
    options: ['Shawshank State Penitentiary', 'Alcatraz', 'Sing Sing', 'San Quentin'],
    correctAnswer: 'Shawshank State Penitentiary',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm61',
    question: 'Which actor played the character of Tyler Durden in "Fight Club"?',
    options: ['Brad Pitt', 'Edward Norton', 'Jared Leto', 'Matt Damon'],
    correctAnswer: 'Brad Pitt',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm62',
    question: 'What is the name of the fictional African kingdom in "Coming to America"?',
    options: ['Zamunda', 'Wakanda', 'Genovia', 'Latveria'],
    correctAnswer: 'Zamunda',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm63',
    question: 'Who directed "The Terminator" (1984)?',
    options: ['James Cameron', 'John Carpenter', 'Paul Verhoeven', 'Ridley Scott'],
    correctAnswer: 'James Cameron',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm64',
    question: 'Which actress won an Oscar for her role in "The Queen"?',
    options: ['Helen Mirren', 'Meryl Streep', 'Judi Dench', 'Cate Blanchett'],
    correctAnswer: 'Helen Mirren',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm65',
    question: 'What is the name of the main antagonist in "The Little Mermaid"?',
    options: ['Ursula', 'Maleficent', 'Cruella', 'Mother Gothel'],
    correctAnswer: 'Ursula',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm66',
    question: 'Which movie features the character Ellen Ripley?',
    options: ['Alien', 'Predator', 'The Thing', 'Total Recall'],
    correctAnswer: 'Alien',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm67',
    question: 'Who played the role of Maximus in "Gladiator"?',
    options: ['Russell Crowe', 'Gerard Butler', 'Joaquin Phoenix', 'Brad Pitt'],
    correctAnswer: 'Russell Crowe',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm68',
    question: 'What is the name of the summer camp in "Friday the 13th"?',
    options: ['Camp Crystal Lake', 'Camp Blackwood', 'Camp Green Lake', 'Camp Hope'],
    correctAnswer: 'Camp Crystal Lake',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm69',
    question: 'Which actress plays Mary Poppins in the 1964 film?',
    options: ['Julie Andrews', 'Angela Lansbury', 'Debbie Reynolds', 'Mary Tyler Moore'],
    correctAnswer: 'Julie Andrews',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm70',
    question: 'What is the name of the snowman in "Frozen"?',
    options: ['Olaf', 'Sven', 'Kristoff', 'Hans'],
    correctAnswer: 'Olaf',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm71',
    question: 'Who directed "Kill Bill: Vol. 1" and "Kill Bill: Vol. 2"?',
    options: ['Quentin Tarantino', 'Robert Rodriguez', 'Martin Scorsese', 'Guy Ritchie'],
    correctAnswer: 'Quentin Tarantino',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm72',
    question: 'Which film features the character Travis Bickle?',
    options: ['Taxi Driver', 'Mean Streets', 'Raging Bull', 'Goodfellas'],
    correctAnswer: 'Taxi Driver',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm73',
    question: 'What is the name of the space station in "Elysium"?',
    options: ['Elysium', 'Zion', 'Axiom', 'Terra Prime'],
    correctAnswer: 'Elysium',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm74',
    question: 'Who plays the lead role in "Drive" (2011)?',
    options: ['Ryan Gosling', 'Bradley Cooper', 'Jake Gyllenhaal', 'Christian Bale'],
    correctAnswer: 'Ryan Gosling',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm75',
    question: 'What is the name of the main character in "The Big Lebowski"?',
    options: ['The Dude', 'The Guy', 'The Man', 'The Boss'],
    correctAnswer: 'The Dude',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm76',
    question: 'Which actor plays John McClane in the "Die Hard" series?',
    options: ['Bruce Willis', 'Sylvester Stallone', 'Arnold Schwarzenegger', 'Mel Gibson'],
    correctAnswer: 'Bruce Willis',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm77',
    question: 'What is the name of the hotel in "The Shining"?',
    options: ['The Overlook Hotel', 'The Stanley Hotel', 'The Dolphin Hotel', 'The Bates Motel'],
    correctAnswer: 'The Overlook Hotel',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm78',
    question: 'Who directed "Back to the Future"?',
    options: ['Robert Zemeckis', 'Steven Spielberg', 'George Lucas', 'Ivan Reitman'],
    correctAnswer: 'Robert Zemeckis',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm79',
    question: 'What is the name of the fictional town in "Jaws"?',
    options: ['Amity Island', 'Crystal Lake', 'Haddonfield', 'Woodsboro'],
    correctAnswer: 'Amity Island',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm80',
    question: 'Which actor plays Indiana Jones?',
    options: ['Harrison Ford', 'Sean Connery', 'Michael Douglas', 'Kurt Russell'],
    correctAnswer: 'Harrison Ford',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm81',
    question: 'What is the name of the computer system in "WarGames"?',
    options: ['WOPR', 'HAL 9000', 'Skynet', 'Mother'],
    correctAnswer: 'WOPR',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm82',
    question: 'Who directed "Apocalypse Now"?',
    options: ['Francis Ford Coppola', 'Martin Scorsese', 'Stanley Kubrick', 'Oliver Stone'],
    correctAnswer: 'Francis Ford Coppola',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm83',
    question: 'What is the name of the main character in "A Clockwork Orange"?',
    options: ['Alex', 'Pete', 'Dim', 'Georgie'],
    correctAnswer: 'Alex',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm84',
    question: 'Which actress plays Sarah Connor in "The Terminator"?',
    options: ['Linda Hamilton', 'Sigourney Weaver', 'Jamie Lee Curtis', 'Sharon Stone'],
    correctAnswer: 'Linda Hamilton',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm85',
    question: 'What is the name of the doll in "Child\'s Play"?',
    options: ['Chucky', 'Billy', 'Annabelle', 'Robert'],
    correctAnswer: 'Chucky',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm86',
    question: 'Who plays the character of Rose in "Titanic"?',
    options: ['Kate Winslet', 'Claire Danes', 'Nicole Kidman', 'Julia Roberts'],
    correctAnswer: 'Kate Winslet',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm87',
    question: 'What is the name of the warrior tribe in "Mad Max: Fury Road"?',
    options: ['War Boys', 'Road Warriors', 'Desert Raiders', 'Dust Devils'],
    correctAnswer: 'War Boys',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm88',
    question: 'Which actor plays Andy Dufresne in "The Shawshank Redemption"?',
    options: ['Tim Robbins', 'Morgan Freeman', 'Bob Gunton', 'William Sadler'],
    correctAnswer: 'Tim Robbins',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm89',
    question: 'What is the name of the island in "Cast Away"?',
    options: ['None (It\'s unnamed)', 'Wilson Island', 'Paradise Island', 'FedEx Island'],
    correctAnswer: 'None (It\'s unnamed)',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm90',
    question: 'Who directed "The Sixth Sense"?',
    options: ['M. Night Shyamalan', 'James Wan', 'Guillermo del Toro', 'David Fincher'],
    correctAnswer: 'M. Night Shyamalan',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm91',
    question: 'Who directed "Get Out" (2017)?',
    options: ['Jordan Peele', 'Ari Aster', 'Robert Eggers', 'James Wan'],
    correctAnswer: 'Jordan Peele',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm92',
    question: 'What is the name of the secret spy organization in "Kingsman"?',
    options: ['Kingsman', 'MI6', 'IMF', 'U.N.C.L.E'],
    correctAnswer: 'Kingsman',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm93',
    question: 'Which actor plays Gandalf in "The Lord of the Rings" trilogy?',
    options: ['Ian McKellen', 'Christopher Lee', 'Michael Gambon', 'Patrick Stewart'],
    correctAnswer: 'Ian McKellen',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm94',
    question: 'What is the name of the boat in "Jaws"?',
    options: ['Orca', 'Neptune', 'Mary Jane', 'Sally Ann'],
    correctAnswer: 'Orca',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm95',
    question: 'Who played Morpheus in "The Matrix"?',
    options: ['Laurence Fishburne', 'Samuel L. Jackson', 'Morgan Freeman', 'Denzel Washington'],
    correctAnswer: 'Laurence Fishburne',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm96',
    question: 'What is the name of the alien species in "District 9"?',
    options: ['Prawns', 'Visitors', 'Xenomorphs', 'Predators'],
    correctAnswer: 'Prawns',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm97',
    question: 'Which actress plays Imperator Furiosa in "Mad Max: Fury Road"?',
    options: ['Charlize Theron', 'Emily Blunt', 'Jessica Chastain', 'Michelle Rodriguez'],
    correctAnswer: 'Charlize Theron',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm98',
    question: 'What is the name of Andy\'s next-door neighbor in "Toy Story"?',
    options: ['Sid', 'Stinky Pete', 'Al', 'Lotso'],
    correctAnswer: 'Sid',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm99',
    question: 'Who directed "The Shape of Water" (2017)?',
    options: ['Guillermo del Toro', 'Alfonso Cuarón', 'Alejandro González Iñárritu', 'Pedro Almodóvar'],
    correctAnswer: 'Guillermo del Toro',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm100',
    question: 'What is the name of the virtual reality world in "Ready Player One"?',
    options: ['OASIS', 'Matrix', 'The Grid', 'Cyberspace'],
    correctAnswer: 'OASIS',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm101',
    question: 'Which actor plays Doctor Strange in the Marvel Cinematic Universe?',
    options: ['Benedict Cumberbatch', 'Tom Hiddleston', 'Martin Freeman', 'Michael Fassbender'],
    correctAnswer: 'Benedict Cumberbatch',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm102',
    question: 'What is the name of the night club in "John Wick"?',
    options: ['The Continental', 'The Red Circle', 'The Russian Tea Room', 'The High Table'],
    correctAnswer: 'The Continental',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm103',
    question: 'Who directed "La La Land"?',
    options: ['Damien Chazelle', 'Barry Jenkins', 'Denis Villeneuve', 'Tom Hooper'],
    correctAnswer: 'Damien Chazelle',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm104',
    question: 'What is the name of the android in "Prometheus"?',
    options: ['David', 'Walter', 'Ash', 'Bishop'],
    correctAnswer: 'David',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm105',
    question: 'Which actress plays Mia Wallace in "Pulp Fiction"?',
    options: ['Uma Thurman', 'Lucy Liu', 'Sharon Stone', 'Michelle Pfeiffer'],
    correctAnswer: 'Uma Thurman',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm106',
    question: 'What is the name of the organization in "Men in Black"?',
    options: ['MIB', 'CIA', 'FBI', 'NSA'],
    correctAnswer: 'MIB',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm107',
    question: 'Who plays Deadpool in the "Deadpool" movies?',
    options: ['Ryan Reynolds', 'Chris Pratt', 'Chris Evans', 'Chris Hemsworth'],
    correctAnswer: 'Ryan Reynolds',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm108',
    question: 'What is the name of the main character in "Arrival"?',
    options: ['Louise Banks', 'Amy Adams', 'Ellen Ripley', 'Sarah Connor'],
    correctAnswer: 'Louise Banks',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm109',
    question: 'Which actor plays James Bond in "GoldenEye"?',
    options: ['Pierce Brosnan', 'Timothy Dalton', 'Roger Moore', 'Sean Connery'],
    correctAnswer: 'Pierce Brosnan',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm110',
    question: 'What is the name of the main antagonist in "Black Panther"?',
    options: ['Killmonger', 'Klaw', 'M\'Baku', 'W\'Kabi'],
    correctAnswer: 'Killmonger',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm111',
    question: 'Which movie featured the song "Chaiyya Chaiyya" filmed on top of a train?',
    options: ['Dil Se', 'Swades', 'Chennai Express', 'Darr'],
    correctAnswer: 'Dil Se',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm112',
    question: 'Who directed "3 Idiots"?',
    options: ['Rajkumar Hirani', 'Karan Johar', 'Sanjay Leela Bhansali', 'Rohit Shetty'],
    correctAnswer: 'Rajkumar Hirani',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm113',
    question: 'Which actress played Piku in the movie "Piku"?',
    options: ['Deepika Padukone', 'Anushka Sharma', 'Priyanka Chopra', 'Vidya Balan'],
    correctAnswer: 'Deepika Padukone',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm114',
    question: 'Which was the first Bollywood film to be nominated for an Oscar?',
    options: ['Mother India', 'Lagaan', 'Salaam Bombay!', 'The Guide'],
    correctAnswer: 'Mother India',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm115',
    question: 'Who played the lead role in "Mughal-e-Azam"?',
    options: ['Dilip Kumar', 'Raj Kapoor', 'Dev Anand', 'Guru Dutt'],
    correctAnswer: 'Dilip Kumar',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm116',
    question: 'Which film featured Amitabh Bachchan\'s iconic character "Vijay Deenanath Chauhan"?',
    options: ['Agneepath', 'Deewar', 'Zanjeer', 'Shakti'],
    correctAnswer: 'Agneepath',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm117',
    question: 'Who directed "Dilwale Dulhania Le Jayenge"?',
    options: ['Aditya Chopra', 'Yash Chopra', 'Karan Johar', 'Subhash Ghai'],
    correctAnswer: 'Aditya Chopra',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm118',
    question: 'Which actor played the role of Rancho in "3 Idiots"?',
    options: ['Aamir Khan', 'Shah Rukh Khan', 'Salman Khan', 'Hrithik Roshan'],
    correctAnswer: 'Aamir Khan',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm119',
    question: 'Who composed the music for "Lagaan"?',
    options: ['A.R. Rahman', 'Shankar-Ehsaan-Loy', 'Pritam', 'Vishal Bhardwaj'],
    correctAnswer: 'A.R. Rahman',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm120',
    question: 'Which film won the National Award for Best Feature Film in 2019?',
    options: ['Andhadhun', 'Uri: The Surgical Strike', 'Badhaai Ho', 'Padmaavat'],
    correctAnswer: 'Andhadhun',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm121',
    question: 'Who played the lead role in "Queen"?',
    options: ['Kangana Ranaut', 'Deepika Padukone', 'Alia Bhatt', 'Vidya Balan'],
    correctAnswer: 'Kangana Ranaut',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm122',
    question: 'Which film features the song "Kal Ho Naa Ho"?',
    options: ['Kal Ho Naa Ho', 'Kabhi Khushi Kabhie Gham', 'Kuch Kuch Hota Hai', 'Kabhi Alvida Naa Kehna'],
    correctAnswer: 'Kal Ho Naa Ho',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm123',
    question: 'Who directed "Black"?',
    options: ['Sanjay Leela Bhansali', 'Karan Johar', 'Rajkumar Hirani', 'Anurag Kashyap'],
    correctAnswer: 'Sanjay Leela Bhansali',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm124',
    question: 'Which actor played the role of Kabir Khan in "Chak De! India"?',
    options: ['Shah Rukh Khan', 'Aamir Khan', 'Salman Khan', 'Akshay Kumar'],
    correctAnswer: 'Shah Rukh Khan',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm125',
    question: 'What was the name of Amitabh Bachchan\'s character in "Sholay"?',
    options: ['Jai', 'Veeru', 'Thakur', 'Gabbar'],
    correctAnswer: 'Jai',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm126',
    question: 'Who directed "Dil Chahta Hai"?',
    options: ['Farhan Akhtar', 'Zoya Akhtar', 'Reema Kagti', 'Rakeysh Omprakash Mehra'],
    correctAnswer: 'Farhan Akhtar',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm127',
    question: 'Which actress played Naina in "Kal Ho Naa Ho"?',
    options: ['Preity Zinta', 'Rani Mukerji', 'Kajol', 'Kareena Kapoor'],
    correctAnswer: 'Preity Zinta',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm128',
    question: 'What is the name of Hrithik Roshan\'s character in "Koi... Mil Gaya"?',
    options: ['Rohit', 'Raj', 'Rahul', 'Rohan'],
    correctAnswer: 'Rohit',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm129',
    question: 'Which film features the song "Mere Naam Tu"?',
    options: ['Zero', 'Raees', 'Fan', 'Dear Zindagi'],
    correctAnswer: 'Zero',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm130',
    question: 'Who played the role of Sehmat in "Raazi"?',
    options: ['Alia Bhatt', 'Deepika Padukone', 'Anushka Sharma', 'Shraddha Kapoor'],
    correctAnswer: 'Alia Bhatt',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm131',
    question: 'Which film won the National Award for Best Popular Film in 2018?',
    options: ['Baahubali 2', 'Dangal', 'Sultan', 'Pink'],
    correctAnswer: 'Baahubali 2',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm132',
    question: 'Who directed "Bajirao Mastani"?',
    options: ['Sanjay Leela Bhansali', 'Ashutosh Gowariker', 'Rakeysh Omprakash Mehra', 'Vishal Bhardwaj'],
    correctAnswer: 'Sanjay Leela Bhansali',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm133',
    question: 'What was the name of Aamir Khan\'s character in "Dangal"?',
    options: ['Mahavir Singh Phogat', 'Geeta Phogat', 'Babita Phogat', 'Prakash Phogat'],
    correctAnswer: 'Mahavir Singh Phogat',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm134',
    question: 'Which film features the song "Gerua"?',
    options: ['Dilwale', 'Chennai Express', 'Happy New Year', 'Raees'],
    correctAnswer: 'Dilwale',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm135',
    question: 'Who played the lead role in "PK"?',
    options: ['Aamir Khan', 'Shah Rukh Khan', 'Ranbir Kapoor', 'Ranveer Singh'],
    correctAnswer: 'Aamir Khan',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm136',
    question: 'Which film was India\'s official entry to Oscars 2021?',
    options: ['Jallikattu', 'The Disciple', 'Gully Boy', 'Andhadhun'],
    correctAnswer: 'Jallikattu',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm137',
    question: 'Who directed "Gangubai Kathiawadi"?',
    options: ['Sanjay Leela Bhansali', 'Karan Johar', 'Zoya Akhtar', 'Meghna Gulzar'],
    correctAnswer: 'Sanjay Leela Bhansali',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm138',
    question: 'What is the name of Shah Rukh Khan\'s character in "My Name Is Khan"?',
    options: ['Rizwan Khan', 'Rahul Khan', 'Raj Khan', 'Rohan Khan'],
    correctAnswer: 'Rizwan Khan',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm139',
    question: 'Which actress played Mastani in "Bajirao Mastani"?',
    options: ['Deepika Padukone', 'Priyanka Chopra', 'Anushka Sharma', 'Katrina Kaif'],
    correctAnswer: 'Deepika Padukone',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm140',
    question: 'Who played the role of M.S. Dhoni in his biopic?',
    options: ['Sushant Singh Rajput', 'Sidharth Malhotra', 'Rajkummar Rao', 'Aditya Roy Kapur'],
    correctAnswer: 'Sushant Singh Rajput',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm141',
    question: 'Which film features the song "Channa Mereya"?',
    options: ['Ae Dil Hai Mushkil', 'Raabta', 'Badrinath Ki Dulhania', 'Kapoor & Sons'],
    correctAnswer: 'Ae Dil Hai Mushkil',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm142',
    question: 'Who directed "Gully Boy"?',
    options: ['Zoya Akhtar', 'Reema Kagti', 'Gauri Shinde', 'Meghna Gulzar'],
    correctAnswer: 'Zoya Akhtar',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm143',
    question: 'What was the name of Ranbir Kapoor\'s character in "Sanju"?',
    options: ['Sanjay Dutt', 'Munna Bhai', 'Raghu', 'Sameer'],
    correctAnswer: 'Sanjay Dutt',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm144',
    question: 'Which actress played Rani Padmavati in "Padmaavat"?',
    options: ['Deepika Padukone', 'Priyanka Chopra', 'Aishwarya Rai', 'Kareena Kapoor'],
    correctAnswer: 'Deepika Padukone',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm145',
    question: 'Who composed the music for "Dil Se"?',
    options: ['A.R. Rahman', 'Pritam', 'Vishal-Shekhar', 'Shankar-Ehsaan-Loy'],
    correctAnswer: 'A.R. Rahman',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm146',
    question: 'What is the name of Alia Bhatt\'s character in "Highway"?',
    options: ['Veera', 'Sehmat', 'Safeena', 'Shanaya'],
    correctAnswer: 'Veera',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm147',
    question: 'Which actor played Sultan Ali Khan in "Sultan"?',
    options: ['Salman Khan', 'Aamir Khan', 'Shah Rukh Khan', 'Hrithik Roshan'],
    correctAnswer: 'Salman Khan',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm148',
    question: 'Who directed "Barfi!"?',
    options: ['Anurag Basu', 'Imtiaz Ali', 'Anurag Kashyap', 'Dibakar Banerjee'],
    correctAnswer: 'Anurag Basu',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm149',
    question: 'Which film stars Hrithik Roshan as a quadriplegic magician?',
    options: ['Guzaarish', 'Kites', 'Kaabil', 'Bang Bang'],
    correctAnswer: 'Guzaarish',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm150',
    question: 'Who directed "Wake Up Sid"?',
    options: ['Ayan Mukerji', 'Karan Johar', 'Zoya Akhtar', 'Shakun Batra'],
    correctAnswer: 'Ayan Mukerji',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm151',
    question: 'Which actress played Silk Smitha in "The Dirty Picture"?',
    options: ['Vidya Balan', 'Kareena Kapoor', 'Priyanka Chopra', 'Kangana Ranaut'],
    correctAnswer: 'Vidya Balan',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm152',
    question: 'What is the name of Farhan Akhtar\'s character in "Bhaag Milkha Bhaag"?',
    options: ['Milkha Singh', 'Akash Mehra', 'Aditya Roy', 'Imran Khan'],
    correctAnswer: 'Milkha Singh',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm153',
    question: 'Which film stars Ranbir Kapoor as a musician who loses his hearing?',
    options: ['Rockstar', 'Tamasha', 'Ae Dil Hai Mushkil', 'Roy'],
    correctAnswer: 'Rockstar',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm154',
    question: 'Who directed "Student of the Year" (2012)?',
    options: ['Karan Johar', 'Punit Malhotra', 'Shakun Batra', 'Ayan Mukerji'],
    correctAnswer: 'Karan Johar',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm155',
    question: 'Which film features Deepika Padukone as Veronica?',
    options: ['Cocktail', 'Love Aaj Kal', 'Break Ke Baad', 'Finding Fanny'],
    correctAnswer: 'Cocktail',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm156',
    question: 'What is the name of Ranveer Singh\'s character in "Band Baaja Baaraat"?',
    options: ['Bittoo Sharma', 'Ram', 'Kabir', 'Varun'],
    correctAnswer: 'Bittoo Sharma',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm157',
    question: 'Which film features the song "Mitwa"?',
    options: ['Kabhi Alvida Naa Kehna', 'Kal Ho Naa Ho', 'Chalte Chalte', 'Veer-Zaara'],
    correctAnswer: 'Kabhi Alvida Naa Kehna',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm158',
    question: 'Who played the role of Akash in "Dil Chahta Hai"?',
    options: ['Aamir Khan', 'Saif Ali Khan', 'Akshaye Khanna', 'Hrithik Roshan'],
    correctAnswer: 'Aamir Khan',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm159',
    question: 'Which film stars Shahid Kapoor as a drug-addicted rock star?',
    options: ['Udta Punjab', 'Haider', 'Kabir Singh', 'Jab We Met'],
    correctAnswer: 'Udta Punjab',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm160',
    question: 'Who directed "Rang De Basanti"?',
    options: ['Rakeysh Omprakash Mehra', 'Ashutosh Gowariker', 'Rajkumar Hirani', 'Farhan Akhtar'],
    correctAnswer: 'Rakeysh Omprakash Mehra',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm161',
    question: 'What is the name of Kajol\'s character in "My Name Is Khan"?',
    options: ['Mandira', 'Anjali', 'Simran', 'Naina'],
    correctAnswer: 'Mandira',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm162',
    question: 'Which film features Ranbir Kapoor and Priyanka Chopra as deaf-mute people?',
    options: ['Barfi!', 'Ajab Prem Ki Ghazab Kahani', 'Anjaana Anjaani', 'Bachna Ae Haseeno'],
    correctAnswer: 'Barfi!',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm163',
    question: 'Who played the role of Kashibai in "Bajirao Mastani"?',
    options: ['Priyanka Chopra', 'Deepika Padukone', 'Anushka Sharma', 'Kangana Ranaut'],
    correctAnswer: 'Priyanka Chopra',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm164',
    question: 'Which film stars Hrithik Roshan as a blind man seeking revenge?',
    options: ['Kaabil', 'Krrish', 'Dhoom 2', 'Bang Bang'],
    correctAnswer: 'Kaabil',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm165',
    question: 'What is the name of Aishwarya Rai\'s character in "Devdas" (2002)?',
    options: ['Paro', 'Chandramukhi', 'Nandini', 'Sophia'],
    correctAnswer: 'Paro',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm166',
    question: 'Who directed "Fashion" (2008)?',
    options: ['Madhur Bhandarkar', 'Karan Johar', 'Sanjay Leela Bhansali', 'Zoya Akhtar'],
    correctAnswer: 'Madhur Bhandarkar',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm167',
    question: 'Which film features the song "Kabira"?',
    options: ['Yeh Jawaani Hai Deewani', 'Ae Dil Hai Mushkil', 'Rockstar', 'Tamasha'],
    correctAnswer: 'Yeh Jawaani Hai Deewani',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm168',
    question: 'Who played the lead role in "Swades"?',
    options: ['Shah Rukh Khan', 'Aamir Khan', 'Hrithik Roshan', 'Salman Khan'],
    correctAnswer: 'Shah Rukh Khan',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm169',
    question: 'What is the name of Kareena Kapoor\'s character in "Jab We Met"?',
    options: ['Geet', 'Pooja', 'Simran', 'Tina'],
    correctAnswer: 'Geet',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm170',
    question: 'Which film stars Aamir Khan as an alien?',
    options: ['PK', 'Dhoom 3', 'Talaash', 'Ghajini'],
    correctAnswer: 'PK',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm171',
    question: 'Who directed "Zindagi Na Milegi Dobara"?',
    options: ['Zoya Akhtar', 'Farhan Akhtar', 'Reema Kagti', 'Anurag Kashyap'],
    correctAnswer: 'Zoya Akhtar',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm172',
    question: 'What is the name of Vidya Balan\'s character in "Kahaani"?',
    options: ['Vidya Bagchi', 'Sabrina Roy', 'Reshma Khan', 'Maya Verma'],
    correctAnswer: 'Vidya Bagchi',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm173',
    question: 'Which film features Ranveer Singh as a street rapper?',
    options: ['Gully Boy', 'Band Baaja Baaraat', 'Ladies vs Ricky Bahl', 'Dil Dhadakne Do'],
    correctAnswer: 'Gully Boy',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm174',
    question: 'Who played the role of Sonia in "Aitraaz"?',
    options: ['Priyanka Chopra', 'Kareena Kapoor', 'Aishwarya Rai', 'Bipasha Basu'],
    correctAnswer: 'Priyanka Chopra',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm175',
    question: 'Which film features Ranbir Kapoor as Jordan?',
    options: ['Rockstar', 'Wake Up Sid', 'Tamasha', 'Ae Dil Hai Mushkil'],
    correctAnswer: 'Rockstar',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm176',
    question: 'Who directed "Lootera"?',
    options: ['Vikramaditya Motwane', 'Anurag Kashyap', 'Dibakar Banerjee', 'Abhishek Chaubey'],
    correctAnswer: 'Vikramaditya Motwane',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm177',
    question: 'What is the name of Hrithik Roshan\'s character in "Lakshya"?',
    options: ['Karan Shergill', 'Rohit Mehra', 'Arjun Rampal', 'Akash Sinha'],
    correctAnswer: 'Karan Shergill',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm178',
    question: 'Which film stars Irrfan Khan as a widowed father?',
    options: ['The Lunchbox', 'Piku', 'Hindi Medium', 'Life in a Metro'],
    correctAnswer: 'The Lunchbox',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm179',
    question: 'Who played the role of Aisha in "Wake Up Sid"?',
    options: ['Konkona Sen Sharma', 'Deepika Padukone', 'Anushka Sharma', 'Kalki Koechlin'],
    correctAnswer: 'Konkona Sen Sharma',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm180',
    question: 'Which film features the song "Tum Hi Ho"?',
    options: ['Aashiqui 2', 'Ek Villain', 'Half Girlfriend', 'Hamari Adhuri Kahani'],
    correctAnswer: 'Aashiqui 2',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm181',
    question: 'Who directed "Dev.D"?',
    options: ['Anurag Kashyap', 'Dibakar Banerjee', 'Vishal Bhardwaj', 'Imtiaz Ali'],
    correctAnswer: 'Anurag Kashyap',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm182',
    question: 'What is the name of Shah Rukh Khan\'s character in "Chak De! India"?',
    options: ['Kabir Khan', 'Rahul Khan', 'Sameer Khan', 'Arjun Khan'],
    correctAnswer: 'Kabir Khan',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm183',
    question: 'Which film features Deepika Padukone as Meera?',
    options: ['Love Aaj Kal', 'Cocktail', 'Break Ke Baad', 'Bachna Ae Haseeno'],
    correctAnswer: 'Love Aaj Kal',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm184',
    question: 'Who played the lead role in "Ghajini"?',
    options: ['Aamir Khan', 'Shah Rukh Khan', 'Salman Khan', 'Hrithik Roshan'],
    correctAnswer: 'Aamir Khan',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm185',
    question: 'Which film won the National Award for Best Hindi Film in 2019?',
    options: ['Andhadhun', 'Badhaai Ho', 'Padmaavat', 'Raazi'],
    correctAnswer: 'Andhadhun',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm186',
    question: 'What is the name of Katrina Kaif\'s character in "Zindagi Na Milegi Dobara"?',
    options: ['Laila', 'Firdaus', 'Meera', 'Aaliya'],
    correctAnswer: 'Laila',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm189',
    question: 'Which film features Ranbir Kapoor as Ved Vardhan Sahni?',
    options: ['Tamasha', 'Rockstar', 'Ae Dil Hai Mushkil', 'Bachna Ae Haseeno'],
    correctAnswer: 'Tamasha',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm190',
    question: 'Who directed "Lootera"?',
    options: ['Vikramaditya Motwane', 'Anurag Kashyap', 'Abhishek Chaubey', 'Dibakar Banerjee'],
    correctAnswer: 'Vikramaditya Motwane',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm191',
    question: 'What is the name of Hrithik Roshan\'s character in "Jodhaa Akbar"?',
    options: ['Akbar', 'Jalal', 'Salim', 'Shah Jahan'],
    correctAnswer: 'Akbar',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm192',
    question: 'Which film features the song "Malang"?',
    options: ['Dhoom 3', 'Kick', 'Bang Bang', 'Race 3'],
    correctAnswer: 'Dhoom 3',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm193',
    question: 'Who played the role of Meera in "Cocktail"?',
    options: ['Diana Penty', 'Deepika Padukone', 'Anushka Sharma', 'Alia Bhatt'],
    correctAnswer: 'Diana Penty',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm194',
    question: 'Which actor played Langda Tyagi in "Omkara"?',
    options: ['Saif Ali Khan', 'Ajay Devgn', 'Vivek Oberoi', 'Abhishek Bachchan'],
    correctAnswer: 'Saif Ali Khan',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm195',
    question: 'Who directed "Haider"?',
    options: ['Vishal Bhardwaj', 'Anurag Kashyap', 'Imtiaz Ali', 'Sanjay Leela Bhansali'],
    correctAnswer: 'Vishal Bhardwaj',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm196',
    question: 'What is the name of Aishwarya Rai\'s character in "Ae Dil Hai Mushkil"?',
    options: ['Saba', 'Anushka', 'Alizeh', 'Priya'],
    correctAnswer: 'Saba',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm197',
    question: 'Which film features Ranveer Singh as Peshwa Bajirao?',
    options: ['Bajirao Mastani', 'Padmaavat', 'Ram-Leela', 'Simmba'],
    correctAnswer: 'Bajirao Mastani',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm198',
    question: 'Who played the role of Vaidehi in "Badrinath Ki Dulhania"?',
    options: ['Alia Bhatt', 'Shraddha Kapoor', 'Parineeti Chopra', 'Kriti Sanon'],
    correctAnswer: 'Alia Bhatt',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm199',
    question: 'Which film stars Sushant Singh Rajput as Sarfaraz?',
    options: ['PK', 'Kedarnath', 'Raabta', 'Dil Bechara'],
    correctAnswer: 'PK',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm200',
    question: 'Who directed "Kapoor & Sons"?',
    options: ['Shakun Batra', 'Karan Johar', 'Punit Malhotra', 'Ayan Mukerji'],
    correctAnswer: 'Shakun Batra',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm201',
    question: 'What is the name of Rajkummar Rao\'s character in "Newton"?',
    options: ['Nutan Kumar', 'Ashok Kumar', 'Raj Kumar', 'Arun Kumar'],
    correctAnswer: 'Nutan Kumar',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm202',
    question: 'Which film features the song "Nashe Si Chadh Gayi"?',
    options: ['Befikre', 'Sultan', 'Ae Dil Hai Mushkil', 'Dear Zindagi'],
    correctAnswer: 'Befikre',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm203',
    question: 'Who played the role of Phogat sisters\' coach in "Dangal"?',
    options: ['Girish Kulkarni', 'Pankaj Tripathi', 'Nawazuddin Siddiqui', 'Rajkummar Rao'],
    correctAnswer: 'Girish Kulkarni',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm204',
    question: 'Which film features Shahid Kapoor as Haider Meer?',
    options: ['Haider', 'Kaminey', 'Udta Punjab', 'Rangoon'],
    correctAnswer: 'Haider',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm205',
    question: 'Who directed "Dear Zindagi"?',
    options: ['Gauri Shinde', 'Zoya Akhtar', 'Meghna Gulzar', 'Reema Kagti'],
    correctAnswer: 'Gauri Shinde',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm206',
    question: 'What is the name of Anushka Sharma\'s character in "Ae Dil Hai Mushkil"?',
    options: ['Alizeh', 'Saba', 'Meera', 'Priya'],
    correctAnswer: 'Alizeh',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm207',
    question: 'Which film features Aamir Khan as a circus performer?',
    options: ['Dhoom 3', 'PK', 'Dangal', 'Secret Superstar'],
    correctAnswer: 'Dhoom 3',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm208',
    question: 'Who played the role of Vidya Vincent in "Sherni"?',
    options: ['Vidya Balan', 'Taapsee Pannu', 'Radhika Apte', 'Konkona Sen Sharma'],
    correctAnswer: 'Vidya Balan',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm209',
    question: 'What is the name of Deepika Padukone\'s character in "Tamasha"?',
    options: ['Tara', 'Leela', 'Mastani', 'Veronica'],
    correctAnswer: 'Tara',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm210',
    question: 'Which film features Varun Dhawan as twins?',
    options: ['Judwaa 2', 'October', 'Badlapur', 'Dilwale'],
    correctAnswer: 'Judwaa 2',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm211',
    question: 'Who directed "Article 15"?',
    options: ['Anubhav Sinha', 'Anurag Kashyap', 'Hansal Mehta', 'Tigmanshu Dhulia'],
    correctAnswer: 'Anubhav Sinha',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm212',
    question: 'What is the name of Sidharth Malhotra\'s character in "Kapoor & Sons"?',
    options: ['Arjun Kapoor', 'Rahul Kapoor', 'Rohan Kapoor', 'Karan Kapoor'],
    correctAnswer: 'Arjun Kapoor',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm213',
    question: 'Which film features the song "Bulleya"?',
    options: ['Ae Dil Hai Mushkil', 'Sultan', 'Raees', 'Fan'],
    correctAnswer: 'Ae Dil Hai Mushkil',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm214',
    question: 'Who played the role of Manya Surve in "Shootout at Wadala"?',
    options: ['John Abraham', 'Ranveer Singh', 'Varun Dhawan', 'Sidharth Malhotra'],
    correctAnswer: 'John Abraham',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm215',
    question: 'Which film features Sushant Singh Rajput as a detective?',
    options: ['Detective Byomkesh Bakshy!', 'Raabta', 'Kedarnath', 'Chhichhore'],
    correctAnswer: 'Detective Byomkesh Bakshy!',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm216',
    question: 'Who directed "Trapped"?',
    options: ['Vikramaditya Motwane', 'Anurag Kashyap', 'Dibakar Banerjee', 'Hansal Mehta'],
    correctAnswer: 'Vikramaditya Motwane',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm217',
    question: 'What is the name of Ranveer Singh\'s character in "Padmaavat"?',
    options: ['Alauddin Khilji', 'Bajirao', 'Ram', 'Kabir'],
    correctAnswer: 'Alauddin Khilji',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm218',
    question: 'Which film features Ayushmann Khurrana as a blind pianist?',
    options: ['Andhadhun', 'Article 15', 'Badhaai Ho', 'Dream Girl'],
    correctAnswer: 'Andhadhun',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm219',
    question: 'Who played the role of Zafira in "Thugs of Hindostan"?',
    options: ['Fatima Sana Shaikh', 'Katrina Kaif', 'Sanya Malhotra', 'Kriti Sanon'],
    correctAnswer: 'Fatima Sana Shaikh',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm220',
    question: 'What is the name of Ranbir Kapoor\'s character in "Yeh Jawaani Hai Deewani"?',
    options: ['Bunny', 'Ayan', 'Kabir', 'Ved'],
    correctAnswer: 'Bunny',
    difficulty: 'easy',
    category: 'Movies'
  },
  {
    id: 'm221',
    question: 'Which film features Irrfan Khan as Champak Bansal?',
    options: ['Angrezi Medium', 'Hindi Medium', 'Karwaan', 'Qarib Qarib Singlle'],
    correctAnswer: 'Angrezi Medium',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm222',
    question: 'Who directed "Talvar"?',
    options: ['Meghna Gulzar', 'Vishal Bhardwaj', 'Gauri Shinde', 'Zoya Akhtar'],
    correctAnswer: 'Meghna Gulzar',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm223',
    question: 'What is the name of Tiger Shroff\'s character in "Baaghi"?',
    options: ['Ronnie', 'Jai', 'Sahir', 'Rohan'],
    correctAnswer: 'Ronnie',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm224',
    question: 'Which film features the song "Kar Har Maidaan Fateh"?',
    options: ['Sanju', 'Raazi', 'Uri: The Surgical Strike', 'Kesari'],
    correctAnswer: 'Sanju',
    difficulty: 'medium',
    category: 'Movies'
  },
  {
    id: 'm225',
    question: 'Who played the role of Insaan in "Bhavesh Joshi Superhero"?',
    options: ['Harshvardhan Kapoor', 'Vicky Kaushal', 'Rajkummar Rao', 'Ayushmann Khurrana'],
    correctAnswer: 'Harshvardhan Kapoor',
    difficulty: 'hard',
    category: 'Movies'
  },
  {
    id: 'm226',
    question: 'What is the name of Alia Bhatt\'s character in "Gully Boy"?',
    options: ['Safeena', 'Sakina', 'Safiya', 'Sana'],
    correctAnswer: 'Safeena',
    difficulty: 'medium',
    category: 'Movies'
  },
  // Sports
  {
    id: 's1',
    question: 'Which country won the FIFA World Cup 2018?',
    options: ['France', 'Croatia', 'Brazil', 'Germany'],
    correctAnswer: 'France',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's2',
    question: 'How many players are there in a cricket team?',
    options: ['11', '10', '9', '12'],
    correctAnswer: '11',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's3',
    question: 'Who won the Formula 1 World Championship in 2021?',
    options: [
      'Max Verstappen',
      'Lewis Hamilton',
      'Valtteri Bottas',
      'Sergio Perez'
    ],
    correctAnswer: 'Max Verstappen',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's4',
    question: 'Which country won the Cricket World Cup in 2019?',
    options: ['England', 'New Zealand', 'Australia', 'India'],
    correctAnswer: 'England',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's5',
    question: 'What is the name of the biggest cricket stadium in India?',
    options: [
      'Narendra Modi Stadium',
      'Eden Gardens',
      'Wankhede Stadium',
      'M.Chinnaswamy Stadium'
    ],
    correctAnswer: 'Narendra Modi Stadium',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's6',
    question: 'Which team has won the most Formula 1 Constructors\' Championships?',
    options: ['Ferrari', 'Mercedes', 'McLaren', 'Williams'],
    correctAnswer: 'Ferrari',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's7',
    question: 'Who is the leading run-scorer in One Day International cricket?',
    options: [
      'Sachin Tendulkar',
      'Virat Kohli',
      'Ricky Ponting',
      'Kumar Sangakkara'
    ],
    correctAnswer: 'Sachin Tendulkar',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's8',
    question: 'Which country has won the most Cricket World Cups?',
    options: ['Australia', 'India', 'West Indies', 'England'],
    correctAnswer: 'Australia',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's9',
    question: 'What is the highest individual score in Test cricket?',
    options: ['400*', '380', '375', '365*'],
    correctAnswer: '400*',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's10',
    question: 'Who was the first driver to have won seven Formula 1 World Championships?',
    options: [
      'Michael Schumacher',
      'Lewis Hamilton',
      'Ayrton Senna',
      'Alain Prost'
    ],
    correctAnswer: 'Michael Schumacher',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's11',
    question: 'What is the term for a score of zero in cricket?',
    options: ['Duck', 'Golden duck', 'Diamond duck', 'Platinum duck'],
    correctAnswer: 'Duck',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's12',
    question: 'What does DRS stand for in Formula 1?',
    options: [
      'Drag Reduction System',
      'Driver Racing System',
      'Downforce Reduction System',
      'Dynamic Racing System'
    ],
    correctAnswer: 'Drag Reduction System',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's13',
    question: 'Which cricket team is known as the "Proteas"?',
    options: [
      'South Africa',
      'Australia',
      'England',
      'New Zealand'
    ],
    correctAnswer: 'South Africa',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's14',
    question: 'What is the name of the trophy awarded to the winner of the Formula 1 World Championship?',
    options: [
      'FIA Formula One World Championship Trophy',
      'Constructors\' Trophy',
      'Laureus World Sports Award',
      'Pole Position Trophy'
    ],
    correctAnswer: 'FIA Formula One World Championship Trophy',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's15',
    question: 'Which bowler has taken the most wickets in Test cricket history?',
    options: [
      'Muttiah Muralitharan',
      'Shane Warne',
      'James Anderson',
      'Anil Kumble'
    ],
    correctAnswer: 'Muttiah Muralitharan',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's16',
    question: 'What is the name of the first corner at the Circuit de Monaco?',
    options: [
      'Sainte Devote',
      'Beau Rivage',
      'Massenet',
      'Casino Square'
    ],
    correctAnswer: 'Sainte Devote',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's17',
    question: 'In which year was the first official Formula 1 World Championship race held?',
    options: ['1950', '1946', '1955', '1960'],
    correctAnswer: '1950',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's18',
    question: 'Which cricketer is known as the "God of Cricket"?',
    options: [
      'Sachin Tendulkar',
      'Virat Kohli',
      'Sir Donald Bradman',
      'Jacques Kallis'
    ],
    correctAnswer: 'Sachin Tendulkar',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's19',
    question: 'What is the term for a batsman being dismissed without scoring a run in Test cricket on the first ball they face?',
    options: [
      'Golden duck',
      'Duck',
      'Diamond duck',
      'Platinum duck'
    ],
    correctAnswer: 'Golden duck',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's20',
    question: 'Which Formula 1 team is based in Woking, England?',
    options: ['McLaren', 'Williams', 'Red Bull Racing', 'Mercedes'],
    correctAnswer: 'McLaren',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's21',
    question: 'Which country won the first ever Cricket World Cup in 1975?',
    options: ['West Indies', 'Australia', 'England', 'India'],
    correctAnswer: 'West Indies',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's22',
    question: 'What does the term "LBW" stand for in cricket?',
    options: [
      'Leg Before Wicket',
      'Leg Behind Wicket',
      'Left Batting Wicket',
      'Long Ball Wicket'
    ],
    correctAnswer: 'Leg Before Wicket',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's23',
    question: 'Which Formula 1 driver holds the record for the most race wins?',
    options: [
      'Lewis Hamilton',
      'Michael Schumacher',
      'Sebastian Vettel',
      'Alain Prost'
    ],
    correctAnswer: 'Lewis Hamilton',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's24',
    question: 'What is the name of the cricket ground in London that hosts the final of the Cricket World Cup?',
    options: [
      'Lord\'s Cricket Ground',
      'The Oval',
      'Trent Bridge',
      'Edgbaston'
    ],
    correctAnswer: 'Lord\'s Cricket Ground',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's25',
    question: 'Which Formula 1 team is known as the "Scuderia"?',
    options: ['Ferrari', 'McLaren', 'Williams', 'Mercedes'],
    correctAnswer: 'Ferrari',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's26',
    question: 'Which cricketer scored the fastest century in One Day International history?',
    options: [
      'AB de Villiers',
      'Corey Anderson',
      'Shahid Afridi',
      'Glenn Maxwell'
    ],
    correctAnswer: 'AB de Villiers',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's27',
    question: 'What is the name of the only driver to have won the Indianapolis 500 and the Formula 1 World Championship in the same year?',
    options: [
      'Graham Hill',
      'Mario Andretti',
      'A.J. Foyt',
      'Emerson Fittipaldi'
    ],
    correctAnswer: 'Graham Hill',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's28',
    question: 'Which cricketer holds the record for the most sixes in Test cricket?',
    options: [
      'Ben Stokes',
      'Brendon McCullum',
      'Adam Gilchrist',
      'Chris Gayle'
    ],
    correctAnswer: 'Ben Stokes',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's29',
    question: 'What is the name of the Formula 1 race held in Monte Carlo?',
    options: [
      'Monaco Grand Prix',
      'Italian Grand Prix',
      'British Grand Prix',
      'Spanish Grand Prix'
    ],
    correctAnswer: 'Monaco Grand Prix',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's30',
    question: 'Which cricketer is known as the "Sultan of Swing"?',
    options: [
      'Wasim Akram',
      'Waqar Younis',
      'Shoaib Akhtar',
      'Brett Lee'
    ],
    correctAnswer: 'Wasim Akram',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's31',
    question: 'What is the name of the Formula 1 team that was founded by Enzo Ferrari?',
    options: ['Scuderia Ferrari', 'McLaren', 'Williams', 'Mercedes'],
    correctAnswer: 'Scuderia Ferrari',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's32',
    question: 'Which cricketer has scored the most runs in a single Test match?',
    options: [
      'Brian Lara',
      'Matthew Hayden',
      'Mahela Jayawardene',
      'Sir Donald Bradman'
    ],
    correctAnswer: 'Brian Lara',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's33',
    question: 'What is the name of the Formula 1 circuit that hosts the British Grand Prix?',
    options: [
      'Silverstone Circuit',
      'Brands Hatch',
      'Donington Park',
      'Oulton Park'
    ],
    correctAnswer: 'Silverstone Circuit',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's34',
    question: 'Which cricketer is known as "Mr. 360" for his ability to hit the ball all around the ground?',
    options: [
      'AB de Villiers',
      'Virat Kohli',
      'Glenn Maxwell',
      'Jos Buttler'
    ],
    correctAnswer: 'AB de Villiers',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's35',
    question: 'What is the name of the youngest Formula 1 World Champion?',
    options: [
      'Sebastian Vettel',
      'Lewis Hamilton',
      'Fernando Alonso',
      'Max Verstappen'
    ],
    correctAnswer: 'Sebastian Vettel',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's36',
    question: 'Which cricketer holds the record for the most dismissals by a wicket-keeper in Test cricket?',
    options: [
      'Mark Boucher',
      'Adam Gilchrist',
      'Ian Healy',
      'MS Dhoni'
    ],
    correctAnswer: 'Mark Boucher',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's37',
    question: 'What is the name of the Formula 1 team that is based in Brackley, England?',
    options: ['Mercedes', 'McLaren', 'Williams', 'Red Bull Racing'],
    correctAnswer: 'Mercedes',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's38',
    question: 'Which cricketer is known as the "Rawalpindi Express" for his fast bowling speed?',
    options: [
      'Shoaib Akhtar',
      'Wasim Akram',
      'Waqar Younis',
      'Brett Lee'
    ],
    correctAnswer: 'Shoaib Akhtar',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's39',
    question: 'What is the name of the first Formula 1 driver to win the World Championship with a turbocharged car?',
    options: [
      'Nelson Piquet',
      'Alain Prost',
      'Ayrton Senna',
      'Niki Lauda'
    ],
    correctAnswer: 'Nelson Piquet',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's40',
    question: 'Which cricketer captained Australia to three consecutive World Cup titles?',
    options: [
      'Ricky Ponting',
      'Steve Waugh',
      'Michael Clarke',
      'Allan Border'
    ],
    correctAnswer: 'Ricky Ponting',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's41',
    question: 'What is the name of the Formula 1 race held in Monza, Italy?',
    options: [
      'Italian Grand Prix',
      'Monaco Grand Prix',
      'British Grand Prix',
      'Spanish Grand Prix'
    ],
    correctAnswer: 'Italian Grand Prix',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's42',
    question: 'Which cricketer is known as the "King of Spin"?',
    options: [
      'Shane Warne',
      'Muttiah Muralitharan',
      'Anil Kumble',
      'Harbhajan Singh'
    ],
    correctAnswer: 'Shane Warne',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's43',
    question: 'What is the name of the Formula 1 team that was founded by Frank Williams?',
    options: ['Williams Racing', 'McLaren', 'Ferrari', 'Mercedes'],
    correctAnswer: 'Williams Racing',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's44',
    question: 'Which cricketer holds the record for the highest individual score in One Day International cricket?',
    options: [
      'Rohit Sharma',
      'Martin Guptill',
      'Virender Sehwag',
      'Chris Gayle'
    ],
    correctAnswer: 'Rohit Sharma',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's45',
    question: 'What is the name of the Formula 1 circuit that hosts the Canadian Grand Prix?',
    options: [
      'Circuit Gilles Villeneuve',
      'Circuit de Spa-Francorchamps',
      'Suzuka Circuit',
      'Autodromo Nazionale Monza'
    ],
    correctAnswer: 'Circuit Gilles Villeneuve',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's46',
    question: 'Which country won the first ever Rugby World Cup in 1987?',
    options: ['New Zealand', 'Australia', 'France', 'England'],
    correctAnswer: 'New Zealand',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's47',
    question: 'How many gold medals did Usain Bolt win at the Olympics?',
    options: ['8', '6', '9', '7'],
    correctAnswer: '8',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's48',
    question: 'Who is the all-time leading scorer in NBA history?',
    options: [
      'Kareem Abdul-Jabbar',
      'LeBron James',
      'Karl Malone',
      'Kobe Bryant'
    ],
    correctAnswer: 'Kareem Abdul-Jabbar',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's49',
    question: 'What is the name of the golf tournament that is played annually at Augusta National Golf Club?',
    options: [
      'The Masters Tournament',
      'The U.S. Open',
      'The Open Championship',
      'The PGA Championship'
    ],
    correctAnswer: 'The Masters Tournament',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's50',
    question: 'Which country won the UEFA European Championship in 2020?',
    options: ['Italy', 'England', 'Spain', 'Germany'],
    correctAnswer: 'Italy',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's51',
    question: 'Who is the only player to have scored 100 international centuries in cricket?',
    options: [
      'Sachin Tendulkar',
      'Virat Kohli',
      'Ricky Ponting',
      'Kumar Sangakkara'
    ],
    correctAnswer: 'Sachin Tendulkar',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's52',
    question: 'What is the name of the trophy awarded to the winner of the Wimbledon Championships?',
    options: [
      'The Gentlemen\'s Singles Trophy',
      'The Ladies\' Singles Trophy',
      'The Davis Cup',
      'The Fed Cup'
    ],
    correctAnswer: 'The Gentlemen\'s Singles Trophy',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's53',
    question: 'Which team has won the most Super Bowl championships?',
    options: [
      'Pittsburgh Steelers',
      'New England Patriots',
      'Green Bay Packers',
      'Dallas Cowboys'
    ],
    correctAnswer: 'Pittsburgh Steelers',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's54',
    question: 'What is the name of the oldest and most prestigious tennis tournament in the world?',
    options: [
      'Wimbledon Championships',
      'French Open',
      'U.S. Open',
      'Australian Open'
    ],
    correctAnswer: 'Wimbledon Championships',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's55',
    question: 'Which country won the most medals at the 2020 Summer Olympics?',
    options: [
      'United States',
      'China',
      'Japan',
      'Great Britain'
    ],
    correctAnswer: 'United States',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's56',
    question: 'In what sport is the term "grand slam" used to describe winning all four major tournaments in a calendar year?',
    options: ['Tennis', 'Golf', 'Rugby Union', 'Baseball'],
    correctAnswer: 'Tennis',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's57',
    question: 'What is the name of the stadium where the Super Bowl is held?',
    options: [
      'Super Bowl Stadium',
      'NFL Stadium',
      'Championship Stadium',
      'It varies each year'
    ],
    correctAnswer: 'It varies each year',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's58',
    question: 'Which country is credited with inventing the sport of golf?',
    options: ['Scotland', 'England', 'Ireland', 'Wales'],
    correctAnswer: 'Scotland',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's59',
    question: 'What is the name of the trophy awarded to the winner of the Rugby World Cup?',
    options: [
      'Webb Ellis Cup',
      'Calcutta Cup',
      'Bledisloe Cup',
      'Heineken Cup'
    ],
    correctAnswer: 'Webb Ellis Cup',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's60',
    question: 'Who is the only boxer to have won the world heavyweight championship three times?',
    options: [
      'Muhammad Ali',
      'Joe Frazier',
      'George Foreman',
      'Mike Tyson'
    ],
    correctAnswer: 'Muhammad Ali',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's61',
    question: 'What is the name of the racecourse where the Kentucky Derby is held?',
    options: [
      'Churchill Downs',
      'Pimlico Race Course',
      'Belmont Park',
      'Santa Anita Park'
    ],
    correctAnswer: 'Churchill Downs',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's62',
    question: 'Which country won the first ever FIFA Women\'s World Cup in 1991?',
    options: [
      'United States',
      'Norway',
      'Germany',
      'Sweden'
    ],
    correctAnswer: 'United States',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's63',
    question: 'What is the name of the highest governing body for association football (soccer)?',
    options: ['FIFA', 'UEFA', 'CONCACAF', 'AFC'],
    correctAnswer: 'FIFA',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's64',
    question: 'What is the name of the annual cycling race that is considered to be the most prestigious in the world?',
    options: [
      'Tour de France',
      'Giro d\'Italia',
      'Vuelta a España',
      'Paris-Roubaix'
    ],
    correctAnswer: 'Tour de France',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's65',
    question: 'Which country won the most gold medals at the 2022 Winter Olympics?',
    options: ['Norway', 'Germany', 'Canada', 'United States'],
    correctAnswer: 'Norway',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's66',
    question: 'What is the name of the first female athlete to win an Olympic gold medal?',
    options: [
      'Charlotte Cooper',
      'Hélène de Pourtalès',
      'Margaret Abbott',
      'Ethel Haydon'
    ],
    correctAnswer: 'Hélène de Pourtalès',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's67',
    question: 'In what sport is the term "love" used to represent a score of zero?',
    options: ['Tennis', 'Badminton', 'Table tennis', 'Squash'],
    correctAnswer: 'Tennis',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's68',
    question: 'What is the name of the only horse to have won the Triple Crown of Thoroughbred Racing twice?',
    options: [
      'Affirmed',
      'Seattle Slew',
      'Secretariat',
      'American Pharoah'
    ],
    correctAnswer: 'Affirmed',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's69',
    question: 'Which country won the first ever Cricket World Cup for women in 1973?',
    options: ['England', 'Australia', 'New Zealand', 'India'],
    correctAnswer: 'England',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's70',
    question: 'What is the name of the trophy awarded to the winner of the ICC Men\'s T20 World Cup?',
    options: [
      'ICC Men\'s T20 World Cup Trophy',
      'Champions Trophy',
      'World Cup Trophy',
      'Border-Gavaskar Trophy'
    ],
    correctAnswer: 'ICC Men\'s T20 World Cup Trophy',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's71',
    question: 'Who is the only player to have won the FIFA World Cup as both a player and a manager?',
    options: [
      'Franz Beckenbauer',
      'Mario Zagallo',
      'Didier Deschamps',
      'Mário Lobo Zagallo'
    ],
    correctAnswer: 'Franz Beckenbauer',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's72',
    question: 'What is the name of the oldest golf course in the world?',
    options: [
      'Old Course at St Andrews',
      'Muirfield',
      'Royal Troon',
      'Carnoustie Golf Links'
    ],
    correctAnswer: 'Old Course at St Andrews',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's73',
    question: 'Which country has won the most Olympic gold medals in swimming?',
    options: [
      'United States',
      'Australia',
      'China',
      'Russia'
    ],
    correctAnswer: 'United States',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's74',
    question: 'What is the name of the first African American to play Major League Baseball?',
    options: [
      'Jackie Robinson',
      'Satchel Paige',
      'Hank Aaron',
      'Willie Mays'
    ],
    correctAnswer: 'Jackie Robinson',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's75',
    question: 'What is the name of the trophy awarded to the winner of the UEFA Champions League?',
    options: [
      'European Champion Clubs\' Cup',
      'UEFA Cup',
      'Europa League Trophy',
      'Super Cup'
    ],
    correctAnswer: 'European Champion Clubs\' Cup',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's76',
    question: 'Which country has won the most Olympic gold medals in gymnastics?',
    options: [
      'Soviet Union',
      'United States',
      'China',
      'Romania'
    ],
    correctAnswer: 'Soviet Union',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's77',
    question: 'What is the name of the first woman to run a marathon in under three hours?',
    options: [
      'Grete Waitz',
      'Joan Benoit Samuelson',
      'Ingrid Kristiansen',
      'Rosa Mota'
    ],
    correctAnswer: 'Grete Waitz',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's78',
    question: 'Which country has won the most Olympic gold medals in athletics (track and field)?',
    options: [
      'United States',
      'Soviet Union',
      'Great Britain',
      'Germany'
    ],
    correctAnswer: 'United States',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's79',
    question: 'What is the name of the first openly gay athlete to win an Olympic gold medal?',
    options: [
      'Matthew Mitcham',
      'Greg Louganis',
      'Mark Tewksbury',
      'Ian Thorpe'
    ],
    correctAnswer: 'Matthew Mitcham',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's80',
    question: 'Which country has won the most Olympic gold medals in ice hockey?',
    options: ['Canada', 'Soviet Union', 'United States', 'Sweden'],
    correctAnswer: 'Canada',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's81',
    question: 'What is the name of the first woman to swim the English Channel?',
    options: [
      'Gertrude Ederle',
      'Florence Chadwick',
      'Mercedes Gleitze',
      'Annette Kellerman'
    ],
    correctAnswer: 'Gertrude Ederle',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's82',
    question: 'Which country has won the most Olympic gold medals in skiing?',
    options: ['Norway', 'Austria', 'Sweden', 'Switzerland'],
    correctAnswer: 'Norway',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's83',
    question: 'What is the name of the first person to climb Mount Everest?',
    options: [
      'Sir Edmund Hillary',
      'Tenzing Norgay',
      'George Mallory',
      'Andrew Irvine'
    ],
    correctAnswer: 'Sir Edmund Hillary',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's84',
    question: 'Which country has won the most Olympic gold medals in cycling?',
    options: ['France', 'Italy', 'Great Britain', 'Netherlands'],
    correctAnswer: 'France',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's85',
    question: 'What is the name of the first Black athlete to win an Olympic gold medal?',
    options: [
      'John Taylor',
      'George Poage',
      'Jesse Owens',
      'Jackie Robinson'
    ],
    correctAnswer: 'John Taylor',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's86',
    question: 'Which country has won the most Olympic gold medals in judo?',
    options: ['Japan', 'France', 'South Korea', 'Russia'],
    correctAnswer: 'Japan',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's87',
    question: 'What is the name of the first woman to win a Grand Slam singles title in tennis?',
    options: [
      'Suzanne Lenglen',
      'Helen Wills Moody',
      'Maureen Connolly',
      'Althea Gibson'
    ],
    correctAnswer: 'Suzanne Lenglen',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's88',
    question: 'Which country has won the most Olympic gold medals in boxing?',
    options: [
      'United States',
      'Cuba',
      'Soviet Union',
      'Great Britain'
    ],
    correctAnswer: 'United States',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's89',
    question: 'What is the name of the first person to run a mile in under four minutes?',
    options: [
      'Roger Bannister',
      'John Landy',
      'Wes Santee',
      'Gunnar Nielsen'
    ],
    correctAnswer: 'Roger Bannister',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's90',
    question: 'Which country has won the most Olympic gold medals in equestrian?',
    options: ['Germany', 'Great Britain', 'Sweden', 'France'],
    correctAnswer: 'Germany',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's91',
    question: 'Which team won the UEFA Champions League in 2023?',
    options: ['Manchester City', 'Inter Milan', 'Real Madrid', 'Bayern Munich'],
    correctAnswer: 'Manchester City',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's92',
    question: 'Who broke the men\'s marathon world record in Berlin in 2022?',
    options: ['Eliud Kipchoge', 'Kelvin Kiptum', 'Kenenisa Bekele', 'Wilson Kipsang'],
    correctAnswer: 'Eliud Kipchoge',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's93',
    question: 'Which country hosted the 2022 FIFA World Cup?',
    options: ['Qatar', 'Russia', 'Brazil', 'United Arab Emirates'],
    correctAnswer: 'Qatar',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's94',
    question: 'Who won the 2023 Formula 1 World Championship?',
    options: ['Max Verstappen', 'Lewis Hamilton', 'Charles Leclerc', 'Sergio Perez'],
    correctAnswer: 'Max Verstappen',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's95',
    question: 'Which team won the NBA Championship in 2023?',
    options: ['Denver Nuggets', 'Miami Heat', 'Golden State Warriors', 'Boston Celtics'],
    correctAnswer: 'Denver Nuggets',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's96',
    question: 'Who won the men\'s singles title at Wimbledon 2023?',
    options: ['Carlos Alcaraz', 'Novak Djokovic', 'Daniil Medvedev', 'Rafael Nadal'],
    correctAnswer: 'Carlos Alcaraz',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's97',
    question: 'Which nation won the 2023 FIFA Women\'s World Cup?',
    options: ['Spain', 'England', 'USA', 'Sweden'],
    correctAnswer: 'Spain',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's98',
    question: 'Who became the first gymnast to perform a Yurchenko double pike vault in competition?',
    options: ['Simone Biles', 'Suni Lee', 'Rebeca Andrade', 'Jade Carey'],
    correctAnswer: 'Simone Biles',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's99',
    question: 'Which city will host the 2024 Summer Olympics?',
    options: ['Paris', 'Los Angeles', 'Brisbane', 'Tokyo'],
    correctAnswer: 'Paris',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's100',
    question: 'Who won the 2022 FIFA World Cup?',
    options: ['Argentina', 'France', 'Brazil', 'Croatia'],
    correctAnswer: 'Argentina',
    category: 'Sports',
    difficulty: 'easy'
  },
  {
    id: 's101',
    question: 'Which NFL team won Super Bowl LVII (2023)?',
    options: ['Kansas City Chiefs', 'Philadelphia Eagles', 'San Francisco 49ers', 'Cincinnati Bengals'],
    correctAnswer: 'Kansas City Chiefs',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's102',
    question: 'Who holds the record for most goals in men\'s international football as of 2023?',
    options: ['Cristiano Ronaldo', 'Lionel Messi', 'Ali Daei', 'Robert Lewandowski'],
    correctAnswer: 'Cristiano Ronaldo',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's103',
    question: 'Which team won the 2023 Cricket World Cup?',
    options: ['Australia', 'India', 'England', 'New Zealand'],
    correctAnswer: 'Australia',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's104',
    question: 'Who won the 2023 US Open women\'s singles title?',
    options: ['Coco Gauff', 'Aryna Sabalenka', 'Iga Świątek', 'Elena Rybakina'],
    correctAnswer: 'Coco Gauff',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's105',
    question: 'Which country won the most medals at the 2020 Tokyo Olympics?',
    options: ['United States', 'China', 'Japan', 'Great Britain'],
    correctAnswer: 'United States',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's106',
    question: 'Who became the first female referee to officiate a men\'s World Cup match in 2022?',
    options: ['Stephanie Frappart', 'Bibiana Steinhaus', 'Esther Staubli', 'Karen Diaz'],
    correctAnswer: 'Stephanie Frappart',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's107',
    question: 'Which team won the 2023 Stanley Cup?',
    options: ['Vegas Golden Knights', 'Florida Panthers', 'Dallas Stars', 'Edmonton Oilers'],
    correctAnswer: 'Vegas Golden Knights',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's108',
    question: 'Who won the 2023 Ballon d\'Or?',
    options: ['Lionel Messi', 'Erling Haaland', 'Kylian Mbappé', 'Kevin De Bruyne'],
    correctAnswer: 'Lionel Messi',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's109',
    question: 'Which team won the 2023 Women\'s NBA Championship?',
    options: ['Las Vegas Aces', 'New York Liberty', 'Connecticut Sun', 'Seattle Storm'],
    correctAnswer: 'Las Vegas Aces',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's110',
    question: 'Who broke the NBA all-time scoring record in 2023?',
    options: ['LeBron James', 'Kevin Durant', 'Stephen Curry', 'James Harden'],
    correctAnswer: 'LeBron James',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's111',
    question: 'Which country won the 2023 Rugby World Cup?',
    options: ['South Africa', 'New Zealand', 'France', 'Ireland'],
    correctAnswer: 'South Africa',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's112',
    question: 'Who won the 2023 Masters Tournament in golf?',
    options: ['Jon Rahm', 'Brooks Koepka', 'Phil Mickelson', 'Scottie Scheffler'],
    correctAnswer: 'Jon Rahm',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's113',
    question: 'Which team won the 2023 UEFA Europa League?',
    options: ['Sevilla', 'Roma', 'Manchester United', 'Juventus'],
    correctAnswer: 'Sevilla',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's114',
    question: 'Who won the 2023 Formula 1 Constructors Championship?',
    options: ['Red Bull Racing', 'Mercedes', 'Ferrari', 'McLaren'],
    correctAnswer: 'Red Bull Racing',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's115',
    question: 'Which player won the Golden Boot at the 2022 FIFA World Cup?',
    options: ['Kylian Mbappé', 'Lionel Messi', 'Oliver Giroud', 'Marcus Rashford'],
    correctAnswer: 'Kylian Mbappé',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's116',
    question: 'Who won the women\'s singles title at the 2023 Australian Open?',
    options: ['Aryna Sabalenka', 'Elena Rybakina', 'Iga Świątek', 'Jessica Pegula'],
    correctAnswer: 'Aryna Sabalenka',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's117',
    question: 'Which team won the 2023 ICC World Test Championship?',
    options: ['Australia', 'India', 'England', 'New Zealand'],
    correctAnswer: 'Australia',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's118',
    question: 'Who won the 2023 Women\'s Ballon d\'Or?',
    options: ['Aitana Bonmatí', 'Sam Kerr', 'Alexia Putellas', 'Mary Earps'],
    correctAnswer: 'Aitana Bonmatí',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's119',
    question: 'Which country won the 2023 FIBA Basketball World Cup?',
    options: ['Germany', 'Serbia', 'USA', 'Canada'],
    correctAnswer: 'Germany',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's120',
    question: 'Who won the 2023 Tour de France?',
    options: ['Jonas Vingegaard', 'Tadej Pogačar', 'Adam Yates', 'Simon Yates'],
    correctAnswer: 'Jonas Vingegaard',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's121',
    question: 'Which team won the 2022-23 UEFA Women\'s Champions League?',
    options: ['Barcelona', 'Wolfsburg', 'Lyon', 'Chelsea'],
    correctAnswer: 'Barcelona',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's122',
    question: 'Who became the first NFL player to rush for over 1000 yards in his first 8 seasons?',
    options: ['Derrick Henry', 'Ezekiel Elliott', 'Christian McCaffrey', 'Dalvin Cook'],
    correctAnswer: 'Derrick Henry',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's123',
    question: 'Which country won the 2023 Davis Cup in tennis?',
    options: ['Italy', 'Australia', 'Serbia', 'Finland'],
    correctAnswer: 'Italy',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's124',
    question: 'Who won the 2023 NASCAR Cup Series Championship?',
    options: ['Ryan Blaney', 'Kyle Larson', 'Christopher Bell', 'William Byron'],
    correctAnswer: 'Ryan Blaney',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's125',
    question: 'Which boxer unified all four major heavyweight titles in 2023?',
    options: ['Oleksandr Usyk', 'Tyson Fury', 'Anthony Joshua', 'Deontay Wilder'],
    correctAnswer: 'Oleksandr Usyk',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's126',
    question: 'Who won the 2023 MotoGP World Championship?',
    options: ['Francesco Bagnaia', 'Jorge Martin', 'Marco Bezzecchi', 'Brad Binder'],
    correctAnswer: 'Francesco Bagnaia',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's127',
    question: 'Which team won the 2023 Asian Cup in football?',
    options: ['Qatar', 'Japan', 'South Korea', 'Saudi Arabia'],
    correctAnswer: 'Qatar',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's128',
    question: 'Who won the men\'s 100m at the 2023 World Athletics Championships?',
    options: ['Noah Lyles', 'Christian Coleman', 'Fred Kerley', 'Trayvon Bromell'],
    correctAnswer: 'Noah Lyles',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's129',
    question: 'Which team won the 2023 Copa Libertadores?',
    options: ['Fluminense', 'Boca Juniors', 'Palmeiras', 'River Plate'],
    correctAnswer: 'Fluminense',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's130',
    question: 'Who won the 2023 World Snooker Championship?',
    options: ['Luca Brecel', 'Mark Selby', 'Ronnie O\'Sullivan', 'Judd Trump'],
    correctAnswer: 'Luca Brecel',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's131',
    question: 'Who was named the NFL MVP for the 2022 season?',
    options: ['Patrick Mahomes', 'Josh Allen', 'Jalen Hurts', 'Joe Burrow'],
    correctAnswer: 'Patrick Mahomes',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's132',
    question: 'Which football club set the English Premier League record for most points in a season (100) in 2018?',
    options: ['Manchester City', 'Liverpool', 'Chelsea', 'Manchester United'],
    correctAnswer: 'Manchester City',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's133',
    question: 'Who became the first tennis player to win 21 Grand Slam men\'s singles titles?',
    options: ['Novak Djokovic', 'Rafael Nadal', 'Roger Federer', 'Andy Murray'],
    correctAnswer: 'Novak Djokovic',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's134',
    question: 'Which team won the 2023 WNBA Coach of the Year Award?',
    options: ['Becky Hammon', 'Cheryl Reeve', 'Stephanie White', 'Sandy Brondello'],
    correctAnswer: 'Becky Hammon',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's135',
    question: 'Who won the 2023 PGA Championship?',
    options: ['Brooks Koepka', 'Viktor Hovland', 'Rory McIlroy', 'Scottie Scheffler'],
    correctAnswer: 'Brooks Koepka',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's136',
    question: 'Which team won the 2023 World Baseball Classic?',
    options: ['Japan', 'USA', 'Mexico', 'Venezuela'],
    correctAnswer: 'Japan',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's137',
    question: 'Who became the youngest Formula 1 race winner in 2016?',
    options: ['Max Verstappen', 'Charles Leclerc', 'Lando Norris', 'George Russell'],
    correctAnswer: 'Max Verstappen',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's138',
    question: 'Which country hosted the 2023 FIFA Women\'s World Cup?',
    options: ['Australia and New Zealand', 'France', 'Germany', 'Canada'],
    correctAnswer: 'Australia and New Zealand',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's139',
    question: 'Who won the 2023 US Open men\'s singles title?',
    options: ['Novak Djokovic', 'Daniil Medvedev', 'Carlos Alcaraz', 'Stefanos Tsitsipas'],
    correctAnswer: 'Novak Djokovic',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's140',
    question: 'Which NFL team relocated to Las Vegas in 2020?',
    options: ['Raiders', 'Chargers', 'Rams', 'Cardinals'],
    correctAnswer: 'Raiders',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's141',
    question: 'Who was named the 2023 NBA Rookie of the Year?',
    options: ['Paolo Banchero', 'Jalen Williams', 'Keegan Murray', 'Bennedict Mathurin'],
    correctAnswer: 'Paolo Banchero',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's142',
    question: 'Which country won the 2023 Billie Jean King Cup?',
    options: ['Canada', 'Italy', 'Czech Republic', 'Spain'],
    correctAnswer: 'Canada',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's143',
    question: 'Who won the 2023 Indianapolis 500?',
    options: ['Josef Newgarden', 'Marcus Ericsson', 'Scott Dixon', 'Alex Palou'],
    correctAnswer: 'Josef Newgarden',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's144',
    question: 'Which team won the 2023 AFL Grand Final?',
    options: ['Collingwood', 'Brisbane Lions', 'Melbourne', 'Carlton'],
    correctAnswer: 'Collingwood',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's145',
    question: 'Who won the 2023 British Open Golf Championship?',
    options: ['Brian Harman', 'Jon Rahm', 'Rory McIlroy', 'Cameron Smith'],
    correctAnswer: 'Brian Harman',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's146',
    question: 'Which NHL team won their first Stanley Cup in 2018?',
    options: ['Washington Capitals', 'St. Louis Blues', 'Tampa Bay Lightning', 'Vegas Golden Knights'],
    correctAnswer: 'Washington Capitals',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's147',
    question: 'Who broke the women\'s marathon world record in 2023?',
    options: ['Tigist Assefa', 'Brigid Kosgei', 'Ruth Chepngetich', 'Sifan Hassan'],
    correctAnswer: 'Tigist Assefa',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's148',
    question: 'Which team won the 2023 Super Rugby Championship?',
    options: ['Crusaders', 'Chiefs', 'Brumbies', 'Blues'],
    correctAnswer: 'Crusaders',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's149',
    question: 'Who won the 2023 Formula E World Championship?',
    options: ['Jake Dennis', 'Pascal Wehrlein', 'Nick Cassidy', 'Mitch Evans'],
    correctAnswer: 'Jake Dennis',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's150',
    question: 'Which country won the 2023 men\'s Volleyball Nations League?',
    options: ['Poland', 'USA', 'Japan', 'Italy'],
    correctAnswer: 'Poland',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's151',
    question: 'Who won the 2023 World Athletics Championships women\'s 100m?',
    options: ['Sha\'Carri Richardson', 'Shericka Jackson', 'Shelly-Ann Fraser-Pryce', 'Marie-Josée Ta Lou'],
    correctAnswer: 'Sha\'Carri Richardson',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's152',
    question: 'Which baseball player hit his 700th home run in 2022?',
    options: ['Albert Pujols', 'Miguel Cabrera', 'Aaron Judge', 'Mike Trout'],
    correctAnswer: 'Albert Pujols',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's153',
    question: 'Who won the 2023 Vuelta a España cycling race?',
    options: ['Sepp Kuss', 'Jonas Vingegaard', 'Primoz Roglic', 'Juan Ayuso'],
    correctAnswer: 'Sepp Kuss',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's154',
    question: 'Which team won the 2023 CONCACAF Gold Cup?',
    options: ['Mexico', 'USA', 'Canada', 'Panama'],
    correctAnswer: 'Mexico',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's155',
    question: 'Who won the 2023 World Surfing League men\'s championship?',
    options: ['Filipe Toledo', 'Gabriel Medina', 'John John Florence', 'Italo Ferreira'],
    correctAnswer: 'Filipe Toledo',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's156',
    question: 'Which country won the 2023 Women\'s Hockey World Cup?',
    options: ['Netherlands', 'Argentina', 'Germany', 'Australia'],
    correctAnswer: 'Netherlands',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's157',
    question: 'Who won the 2023 Diamond League athletics final 200m men\'s race?',
    options: ['Noah Lyles', 'Erriyon Knighton', 'Andre De Grasse', 'Kenny Bednarek'],
    correctAnswer: 'Noah Lyles',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's158',
    question: 'Which team won the 2023 French Ligue 1?',
    options: ['PSG', 'Lens', 'Marseille', 'Monaco'],
    correctAnswer: 'PSG',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's159',
    question: 'Who won the 2023 World Table Tennis Championships men\'s singles?',
    options: ['Fan Zhendong', 'Ma Long', 'Wang Chuqin', 'Liang Jingkun'],
    correctAnswer: 'Fan Zhendong',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's160',
    question: 'Which team won the 2023 Netball World Cup?',
    options: ['Australia', 'England', 'New Zealand', 'Jamaica'],
    correctAnswer: 'Australia',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's161',
    question: 'Who won the 2023 World Darts Championship?',
    options: ['Michael Smith', 'Michael van Gerwen', 'Peter Wright', 'Gerwyn Price'],
    correctAnswer: 'Michael Smith',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's162',
    question: 'Which team won the 2023 Champions League of basketball (EuroLeague)?',
    options: ['Real Madrid', 'Olympiacos', 'Barcelona', 'Monaco'],
    correctAnswer: 'Real Madrid',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's163',
    question: 'Who won the 2023 World Boxing Council heavyweight title?',
    options: ['Tyson Fury', 'Deontay Wilder', 'Anthony Joshua', 'Oleksandr Usyk'],
    correctAnswer: 'Tyson Fury',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's164',
    question: 'Which country won the 2023 Women\'s Volleyball Nations League?',
    options: ['Turkey', 'China', 'USA', 'Brazil'],
    correctAnswer: 'Turkey',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's165',
    question: 'Who won the 2023 UCI Road World Championships men\'s road race?',
    options: ['Mathieu van der Poel', 'Wout van Aert', 'Tadej Pogačar', 'Remco Evenepoel'],
    correctAnswer: 'Mathieu van der Poel',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's166',
    question: 'Which team won the 2023 Major League Soccer (MLS) Cup?',
    options: ['Columbus Crew', 'LAFC', 'Cincinnati FC', 'Orlando City'],
    correctAnswer: 'Columbus Crew',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's167',
    question: 'Who won the 2023 US Women\'s Open Golf Championship?',
    options: ['Allisen Corpuz', 'Charley Hull', 'Jiyai Shin', 'Rose Zhang'],
    correctAnswer: 'Allisen Corpuz',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's168',
    question: 'Which country won the 2023 Africa Cup of Nations?',
    options: ['Ivory Coast', 'Nigeria', 'South Africa', 'DR Congo'],
    correctAnswer: 'Ivory Coast',
    category: 'Sports',
    difficulty: 'medium'
  },
  {
    id: 's169',
    question: 'Who won the 2023 World Badminton Championships men\'s singles?',
    options: ['Kunlavut Vitidsarn', 'Viktor Axelsen', 'An Se-young', 'Kodai Naraoka'],
    correctAnswer: 'Kunlavut Vitidsarn',
    category: 'Sports',
    difficulty: 'hard'
  },
  {
    id: 's170',
    question: 'Which team won the 2023 Serie A title?',
    options: ['Napoli', 'Inter Milan', 'AC Milan', 'Juventus'],
    correctAnswer: 'Napoli',
    category: 'Sports',
    difficulty: 'medium'
  },
  // Geography
  {
    id: 'g1',
    question: 'What is the capital of New Zealand?',
    options: ['Wellington', 'Auckland', 'Christchurch', 'Hamilton'],
    correctAnswer: 'Wellington',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g2',
    question: 'Which is the smallest country in the world?',
    options: ['Vatican City', 'Monaco', 'San Marino', 'Liechtenstein'],
    correctAnswer: 'Vatican City',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g3',
    question: 'What is the capital of Morocco?',
    options: ['Rabat', 'Casablanca', 'Marrakech', 'Fez'],
    correctAnswer: 'Rabat',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g4',
    question: 'Which is the longest river in the world?',
    options: ['Nile', 'Amazon', 'Yangtze', 'Mississippi'],
    correctAnswer: 'Nile',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g5',
    question: 'What is the capital of Iceland?',
    options: ['Reykjavik', 'Oslo', 'Helsinki', 'Copenhagen'],
    correctAnswer: 'Reykjavik',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g6',
    question: 'Which is the largest desert in the world?',
    options: ['Antarctic Desert', 'Sahara Desert', 'Arctic Desert', 'Arabian Desert'],
    correctAnswer: 'Antarctic Desert',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g7',
    question: 'What is the capital of Vietnam?',
    options: ['Hanoi', 'Ho Chi Minh City', 'Da Nang', 'Haiphong'],
    correctAnswer: 'Hanoi',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g8',
    question: 'Which continent is the least populated?',
    options: ['Antarctica', 'Australia', 'South America', 'Europe'],
    correctAnswer: 'Antarctica',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g9',
    question: 'What is the capital of Argentina?',
    options: ['Buenos Aires', 'Santiago', 'Montevideo', 'Lima'],
    correctAnswer: 'Buenos Aires',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g10',
    question: 'Which country has the most islands in the world?',
    options: ['Sweden', 'Norway', 'Finland', 'Indonesia'],
    correctAnswer: 'Sweden',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g11',
    question: 'What is the capital of South Korea?',
    options: ['Seoul', 'Busan', 'Incheon', 'Daegu'],
    correctAnswer: 'Seoul',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g12',
    question: 'Which is the highest waterfall in the world?',
    options: ['Angel Falls', 'Niagara Falls', 'Victoria Falls', 'Iguazu Falls'],
    correctAnswer: 'Angel Falls',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g13',
    question: 'What is the capital of Kenya?',
    options: ['Nairobi', 'Mombasa', 'Kisumu', 'Nakuru'],
    correctAnswer: 'Nairobi',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g14',
    question: 'Which country has the most lakes?',
    options: ['Canada', 'Russia', 'United States', 'Finland'],
    correctAnswer: 'Canada',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g15',
    question: 'What is the capital of Portugal?',
    options: ['Lisbon', 'Porto', 'Coimbra', 'Braga'],
    correctAnswer: 'Lisbon',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g16',
    question: 'Which is the deepest ocean on Earth?',
    options: ['Pacific Ocean', 'Atlantic Ocean', 'Indian Ocean', 'Arctic Ocean'],
    correctAnswer: 'Pacific Ocean',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g17',
    question: 'What is the capital of Nigeria?',
    options: ['Abuja', 'Lagos', 'Kano', 'Ibadan'],
    correctAnswer: 'Abuja',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g18',
    question: 'Which country borders the most other countries?',
    options: ['China', 'Russia', 'Brazil', 'Kazakhstan'],
    correctAnswer: 'China',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g19',
    question: 'What is the capital of Iran?',
    options: ['Tehran', 'Isfahan', 'Mashhad', 'Shiraz'],
    correctAnswer: 'Tehran',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g20',
    question: 'Which is the largest lake by surface area in Africa?',
    options: ['Lake Victoria', 'Lake Tanganyika', 'Lake Malawi', 'Lake Chad'],
    correctAnswer: 'Lake Victoria',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g21',
    question: 'What is the capital of Ukraine?',
    options: ['Kyiv', 'Lviv', 'Kharkiv', 'Odesa'],
    correctAnswer: 'Kyiv',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g22',
    question: 'Which country has the most volcanoes?',
    options: ['Indonesia', 'Japan', 'Chile', 'United States'],
    correctAnswer: 'Indonesia',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g23',
    question: 'What is the capital of Finland?',
    options: ['Helsinki', 'Tampere', 'Turku', 'Espoo'],
    correctAnswer: 'Helsinki',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g24',
    question: 'Which mountain range is the longest in the world?',
    options: ['Andes', 'Himalayas', 'Rocky Mountains', 'Alps'],
    correctAnswer: 'Andes',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g25',
    question: 'What is the capital of Malaysia?',
    options: ['Kuala Lumpur', 'Penang', 'Johor Bahru', 'Malacca'],
    correctAnswer: 'Kuala Lumpur',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g26',
    question: 'Which country has the longest coastline?',
    options: ['Canada', 'Russia', 'Indonesia', 'Norway'],
    correctAnswer: 'Canada',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g27',
    question: 'What is the capital of Egypt?',
    options: ['Cairo', 'Alexandria', 'Giza', 'Luxor'],
    correctAnswer: 'Cairo',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g28',
    question: 'Which is the largest island in the world?',
    options: ['Greenland', 'New Guinea', 'Borneo', 'Madagascar'],
    correctAnswer: 'Greenland',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g29',
    question: 'What is the capital of Peru?',
    options: ['Lima', 'Cusco', 'Arequipa', 'Trujillo'],
    correctAnswer: 'Lima',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g30',
    question: 'Which country has the most time zones?',
    options: ['France', 'Russia', 'United States', 'China'],
    correctAnswer: 'France',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g31',
    question: 'What is the capital of Turkey?',
    options: ['Ankara', 'Istanbul', 'Izmir', 'Bursa'],
    correctAnswer: 'Ankara',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g32',
    question: 'Which is the saltiest sea in the world?',
    options: ['Dead Sea', 'Red Sea', 'Mediterranean Sea', 'Black Sea'],
    correctAnswer: 'Dead Sea',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g33',
    question: 'What is the capital of Sweden?',
    options: ['Stockholm', 'Gothenburg', 'Malmö', 'Uppsala'],
    correctAnswer: 'Stockholm',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g34',
    question: 'Which country is home to Angkor Wat?',
    options: ['Cambodia', 'Thailand', 'Vietnam', 'Laos'],
    correctAnswer: 'Cambodia',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g35',
    question: 'What is the capital of Brazil?',
    options: ['Brasília', 'Rio de Janeiro', 'São Paulo', 'Salvador'],
    correctAnswer: 'Brasília',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g36',
    question: 'Which is the largest gulf in the world?',
    options: ['Gulf of Mexico', 'Persian Gulf', 'Gulf of California', 'Gulf of Bengal'],
    correctAnswer: 'Gulf of Mexico',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g37',
    question: 'What is the capital of Saudi Arabia?',
    options: ['Riyadh', 'Jeddah', 'Mecca', 'Medina'],
    correctAnswer: 'Riyadh',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g38',
    question: 'Which country is known as the Land of Fire and Ice?',
    options: ['Iceland', 'Norway', 'Finland', 'Sweden'],
    correctAnswer: 'Iceland',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g39',
    question: 'What is the capital of Greece?',
    options: ['Athens', 'Thessaloniki', 'Patras', 'Heraklion'],
    correctAnswer: 'Athens',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g40',
    question: 'Which continent contains the most countries?',
    options: ['Africa', 'Europe', 'Asia', 'North America'],
    correctAnswer: 'Africa',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g41',
    question: 'What is the capital of New Zealand?',
    options: ['Wellington', 'Auckland', 'Christchurch', 'Hamilton'],
    correctAnswer: 'Wellington',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g42',
    question: 'Which is the smallest country in the world?',
    options: ['Vatican City', 'Monaco', 'San Marino', 'Liechtenstein'],
    correctAnswer: 'Vatican City',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g43',
    question: 'What is the capital of Morocco?',
    options: ['Rabat', 'Casablanca', 'Marrakech', 'Fez'],
    correctAnswer: 'Rabat',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g44',
    question: 'Which is the longest river in the world?',
    options: ['Nile', 'Amazon', 'Yangtze', 'Mississippi'],
    correctAnswer: 'Nile',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g45',
    question: 'What is the capital of Iceland?',
    options: ['Reykjavik', 'Oslo', 'Helsinki', 'Copenhagen'],
    correctAnswer: 'Reykjavik',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g46',
    question: 'Which is the largest desert in the world?',
    options: ['Antarctic Desert', 'Sahara Desert', 'Arctic Desert', 'Arabian Desert'],
    correctAnswer: 'Antarctic Desert',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g47',
    question: 'What is the capital of Vietnam?',
    options: ['Hanoi', 'Ho Chi Minh City', 'Da Nang', 'Haiphong'],
    correctAnswer: 'Hanoi',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g48',
    question: 'Which continent is the least populated?',
    options: ['Antarctica', 'Australia', 'South America', 'Europe'],
    correctAnswer: 'Antarctica',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g49',
    question: 'What is the capital of Argentina?',
    options: ['Buenos Aires', 'Santiago', 'Montevideo', 'Lima'],
    correctAnswer: 'Buenos Aires',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g50',
    question: 'Which country has the most islands in the world?',
    options: ['Sweden', 'Norway', 'Finland', 'Indonesia'],
    correctAnswer: 'Sweden',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g51',
    question: 'What is the capital of South Korea?',
    options: ['Seoul', 'Busan', 'Incheon', 'Daegu'],
    correctAnswer: 'Seoul',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g52',
    question: 'Which is the highest waterfall in the world?',
    options: ['Angel Falls', 'Niagara Falls', 'Victoria Falls', 'Iguazu Falls'],
    correctAnswer: 'Angel Falls',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g53',
    question: 'What is the capital of Kenya?',
    options: ['Nairobi', 'Mombasa', 'Kisumu', 'Nakuru'],
    correctAnswer: 'Nairobi',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g54',
    question: 'Which country has the most lakes?',
    options: ['Canada', 'Russia', 'United States', 'Finland'],
    correctAnswer: 'Canada',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g55',
    question: 'What is the capital of Portugal?',
    options: ['Lisbon', 'Porto', 'Coimbra', 'Braga'],
    correctAnswer: 'Lisbon',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g56',
    question: 'Which is the deepest ocean on Earth?',
    options: ['Pacific Ocean', 'Atlantic Ocean', 'Indian Ocean', 'Arctic Ocean'],
    correctAnswer: 'Pacific Ocean',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g57',
    question: 'What is the capital of Nigeria?',
    options: ['Abuja', 'Lagos', 'Kano', 'Ibadan'],
    correctAnswer: 'Abuja',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g58',
    question: 'Which country borders the most other countries?',
    options: ['China', 'Russia', 'Brazil', 'Kazakhstan'],
    correctAnswer: 'China',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g59',
    question: 'What is the capital of Iran?',
    options: ['Tehran', 'Isfahan', 'Mashhad', 'Shiraz'],
    correctAnswer: 'Tehran',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g60',
    question: 'Which is the largest lake by surface area in Africa?',
    options: ['Lake Victoria', 'Lake Tanganyika', 'Lake Malawi', 'Lake Chad'],
    correctAnswer: 'Lake Victoria',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g61',
    question: 'What is the capital of Ukraine?',
    options: ['Kyiv', 'Lviv', 'Kharkiv', 'Odesa'],
    correctAnswer: 'Kyiv',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g62',
    question: 'Which country has the most volcanoes?',
    options: ['Indonesia', 'Japan', 'Chile', 'United States'],
    correctAnswer: 'Indonesia',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g63',
    question: 'What is the capital of Finland?',
    options: ['Helsinki', 'Tampere', 'Turku', 'Espoo'],
    correctAnswer: 'Helsinki',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g64',
    question: 'Which mountain range is the longest in the world?',
    options: ['Andes', 'Himalayas', 'Rocky Mountains', 'Alps'],
    correctAnswer: 'Andes',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g65',
    question: 'What is the capital of Malaysia?',
    options: ['Kuala Lumpur', 'Penang', 'Johor Bahru', 'Malacca'],
    correctAnswer: 'Kuala Lumpur',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g66',
    question: 'Which country has the longest coastline?',
    options: ['Canada', 'Russia', 'Indonesia', 'Norway'],
    correctAnswer: 'Canada',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g67',
    question: 'What is the capital of Egypt?',
    options: ['Cairo', 'Alexandria', 'Giza', 'Luxor'],
    correctAnswer: 'Cairo',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g68',
    question: 'Which is the largest island in the world?',
    options: ['Greenland', 'New Guinea', 'Borneo', 'Madagascar'],
    correctAnswer: 'Greenland',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g69',
    question: 'What is the capital of Peru?',
    options: ['Lima', 'Cusco', 'Arequipa', 'Trujillo'],
    correctAnswer: 'Lima',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g70',
    question: 'Which country has the most time zones?',
    options: ['France', 'Russia', 'United States', 'China'],
    correctAnswer: 'France',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g71',
    question: 'What is the capital of Turkey?',
    options: ['Ankara', 'Istanbul', 'Izmir', 'Bursa'],
    correctAnswer: 'Ankara',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g72',
    question: 'Which is the saltiest sea in the world?',
    options: ['Dead Sea', 'Red Sea', 'Mediterranean Sea', 'Black Sea'],
    correctAnswer: 'Dead Sea',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g73',
    question: 'What is the capital of Sweden?',
    options: ['Stockholm', 'Gothenburg', 'Malmö', 'Uppsala'],
    correctAnswer: 'Stockholm',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g74',
    question: 'Which country is home to Angkor Wat?',
    options: ['Cambodia', 'Thailand', 'Vietnam', 'Laos'],
    correctAnswer: 'Cambodia',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g75',
    question: 'What is the capital of Brazil?',
    options: ['Brasília', 'Rio de Janeiro', 'São Paulo', 'Salvador'],
    correctAnswer: 'Brasília',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g76',
    question: 'Which is the largest gulf in the world?',
    options: ['Gulf of Mexico', 'Persian Gulf', 'Gulf of California', 'Gulf of Bengal'],
    correctAnswer: 'Gulf of Mexico',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g77',
    question: 'What is the capital of Saudi Arabia?',
    options: ['Riyadh', 'Jeddah', 'Mecca', 'Medina'],
    correctAnswer: 'Riyadh',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g78',
    question: 'Which country is known as the Land of Fire and Ice?',
    options: ['Iceland', 'Norway', 'Finland', 'Sweden'],
    correctAnswer: 'Iceland',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g79',
    question: 'What is the capital of Greece?',
    options: ['Athens', 'Thessaloniki', 'Patras', 'Heraklion'],
    correctAnswer: 'Athens',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g80',
    question: 'Which continent contains the most countries?',
    options: ['Africa', 'Europe', 'Asia', 'North America'],
    correctAnswer: 'Africa',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g81',
    question: 'What is the capital of Colombia?',
    options: ['Bogotá', 'Medellín', 'Cali', 'Cartagena'],
    correctAnswer: 'Bogotá',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g82',
    question: 'Which mountain is the highest in Africa?',
    options: ['Mount Kilimanjaro', 'Mount Kenya', 'Mount Stanley', 'Mount Meru'],
    correctAnswer: 'Mount Kilimanjaro',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g83',
    question: 'What is the capital of Pakistan?',
    options: ['Islamabad', 'Karachi', 'Lahore', 'Peshawar'],
    correctAnswer: 'Islamabad',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g84',
    question: 'Which is the largest freshwater lake by surface area in the world?',
    options: ['Lake Superior', 'Lake Victoria', 'Lake Huron', 'Lake Michigan'],
    correctAnswer: 'Lake Superior',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g85',
    question: 'What is the capital of Austria?',
    options: ['Vienna', 'Salzburg', 'Graz', 'Innsbruck'],
    correctAnswer: 'Vienna',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g86',
    question: 'Which country is the only one to border both the Atlantic and Indian Oceans?',
    options: ['South Africa', 'India', 'Brazil', 'Australia'],
    correctAnswer: 'South Africa',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g87',
    question: 'What is the capital of Denmark?',
    options: ['Copenhagen', 'Aarhus', 'Odense', 'Aalborg'],
    correctAnswer: 'Copenhagen',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g88',
    question: 'Which strait separates Asia from North America?',
    options: ['Bering Strait', 'Strait of Gibraltar', 'Strait of Malacca', 'Strait of Hormuz'],
    correctAnswer: 'Bering Strait',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g89',
    question: 'What is the capital of Algeria?',
    options: ['Algiers', 'Oran', 'Constantine', 'Annaba'],
    correctAnswer: 'Algiers',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g90',
    question: 'Which country is the largest producer of coffee in the world?',
    options: ['Brazil', 'Colombia', 'Vietnam', 'Indonesia'],
    correctAnswer: 'Brazil',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g91',
    question: 'What is the capital of Romania?',
    options: ['Bucharest', 'Cluj-Napoca', 'Timișoara', 'Iași'],
    correctAnswer: 'Bucharest',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g92',
    question: 'Which mountain range runs through Spain and France?',
    options: ['Pyrenees', 'Alps', 'Carpathians', 'Apennines'],
    correctAnswer: 'Pyrenees',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g93',
    question: 'What is the capital of the Philippines?',
    options: ['Manila', 'Quezon City', 'Cebu', 'Davao'],
    correctAnswer: 'Manila',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g94',
    question: 'Which sea is located between Italy and the Balkans?',
    options: ['Adriatic Sea', 'Tyrrhenian Sea', 'Ionian Sea', 'Aegean Sea'],
    correctAnswer: 'Adriatic Sea',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g95',
    question: 'What is the capital of Chile?',
    options: ['Santiago', 'Valparaíso', 'Concepción', 'Antofagasta'],
    correctAnswer: 'Santiago',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g96',
    question: 'Which country is home to Mount Everest?',
    options: ['Nepal', 'China', 'India', 'Bhutan'],
    correctAnswer: 'Nepal',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g97',
    question: 'What is the capital of Hungary?',
    options: ['Budapest', 'Debrecen', 'Szeged', 'Miskolc'],
    correctAnswer: 'Budapest',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g98',
    question: 'Which is the largest bay in the world?',
    options: ['Bay of Bengal', 'Hudson Bay', 'Chesapeake Bay', 'San Francisco Bay'],
    correctAnswer: 'Bay of Bengal',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g99',
    question: 'What is the capital of Bulgaria?',
    options: ['Sofia', 'Plovdiv', 'Varna', 'Burgas'],
    correctAnswer: 'Sofia',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g100',
    question: 'Which desert covers much of northern China?',
    options: ['Gobi Desert', 'Taklamakan Desert', 'Ordos Desert', 'Kumtag Desert'],
    correctAnswer: 'Gobi Desert',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g101',
    question: 'What is the capital of New Mexico?',
    options: ['Santa Fe', 'Albuquerque', 'Las Cruces', 'Roswell'],
    correctAnswer: 'Santa Fe',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g102',
    question: 'Which country has the most UNESCO World Heritage Sites?',
    options: ['Italy', 'China', 'Spain', 'Germany'],
    correctAnswer: 'Italy',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g103',
    question: 'What is the capital of Latvia?',
    options: ['Riga', 'Daugavpils', 'Liepāja', 'Jelgava'],
    correctAnswer: 'Riga',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g104',
    question: 'Which river flows through Baghdad?',
    options: ['Tigris', 'Euphrates', 'Nile', 'Jordan'],
    correctAnswer: 'Tigris',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g105',
    question: 'What is the capital of Croatia?',
    options: ['Zagreb', 'Split', 'Rijeka', 'Dubrovnik'],
    correctAnswer: 'Zagreb',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g106',
    question: 'Which is the windiest continent?',
    options: ['Antarctica', 'North America', 'Europe', 'Asia'],
    correctAnswer: 'Antarctica',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g107',
    question: 'What is the capital of Ecuador?',
    options: ['Quito', 'Guayaquil', 'Cuenca', 'Machala'],
    correctAnswer: 'Quito',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g108',
    question: 'Which country is known as the Land of a Thousand Lakes?',
    options: ['Finland', 'Sweden', 'Norway', 'Canada'],
    correctAnswer: 'Finland',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g109',
    question: 'What is the capital of Slovenia?',
    options: ['Ljubljana', 'Maribor', 'Celje', 'Kranj'],
    correctAnswer: 'Ljubljana',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g110',
    question: 'Which is the largest coral reef system in the world?',
    options: ['Great Barrier Reef', 'Mesoamerican Reef', 'Red Sea Coral Reef', 'New Caledonia Barrier Reef'],
    correctAnswer: 'Great Barrier Reef',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g111',
    question: 'What is the capital of Ethiopia?',
    options: ['Addis Ababa', 'Gondar', 'Mekelle', 'Dire Dawa'],
    correctAnswer: 'Addis Ababa',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g112',
    question: 'Which is the longest river in Europe?',
    options: ['Volga', 'Danube', 'Rhine', 'Seine'],
    correctAnswer: 'Volga',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g113',
    question: 'What is the capital of Afghanistan?',
    options: ['Kabul', 'Kandahar', 'Herat', 'Mazar-i-Sharif'],
    correctAnswer: 'Kabul',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g114',
    question: 'Which country has the most natural lakes?',
    options: ['Canada', 'United States', 'Russia', 'China'],
    correctAnswer: 'Canada',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g115',
    question: 'What is the capital of Wales?',
    options: ['Cardiff', 'Swansea', 'Newport', 'Bangor'],
    correctAnswer: 'Cardiff',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g116',
    question: 'Which mountain range is nicknamed "the roof of the world"?',
    options: ['Himalayas', 'Andes', 'Alps', 'Rocky Mountains'],
    correctAnswer: 'Himalayas',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g117',
    question: 'What is the capital of Uruguay?',
    options: ['Montevideo', 'Salto', 'Paysandú', 'Las Piedras'],
    correctAnswer: 'Montevideo',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g118',
    question: 'Which country is the largest producer of diamonds?',
    options: ['Russia', 'Botswana', 'South Africa', 'Democratic Republic of Congo'],
    correctAnswer: 'Russia',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g119',
    question: 'What is the capital of Slovakia?',
    options: ['Bratislava', 'Košice', 'Prešov', 'Žilina'],
    correctAnswer: 'Bratislava',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g120',
    question: 'Which is the largest archipelago in the world?',
    options: ['Indonesia', 'Philippines', 'Japan', 'Maldives'],
    correctAnswer: 'Indonesia',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g121',
    question: 'What is the capital of Madagascar?',
    options: ['Antananarivo', 'Toamasina', 'Mahajanga', 'Fianarantsoa'],
    correctAnswer: 'Antananarivo',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g122',
    question: 'Which is the largest fjord in the world?',
    options: ['Scoresby Sund', 'Sognefjord', 'Hardangerfjord', 'Geirangerfjord'],
    correctAnswer: 'Scoresby Sund',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g123',
    question: 'What is the capital of Angola?',
    options: ['Luanda', 'Huambo', 'Lubango', 'Benguela'],
    correctAnswer: 'Luanda',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g124',
    question: 'Which is the oldest active volcano in the world?',
    options: ['Mount Etna', 'Mount Fuji', 'Mount Vesuvius', 'Stromboli'],
    correctAnswer: 'Mount Etna',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g125',
    question: 'What is the capital of Uzbekistan?',
    options: ['Tashkent', 'Samarkand', 'Bukhara', 'Namangan'],
    correctAnswer: 'Tashkent',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g126',
    question: 'Which country is home to the most active volcanoes?',
    options: ['Indonesia', 'Japan', 'United States', 'Philippines'],
    correctAnswer: 'Indonesia',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g127',
    question: 'What is the capital of Senegal?',
    options: ['Dakar', 'Saint-Louis', 'Thiès', 'Rufisque'],
    correctAnswer: 'Dakar',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g128',
    question: 'Which sea is the world\'s largest inland body of water?',
    options: ['Caspian Sea', 'Black Sea', 'Mediterranean Sea', 'Baltic Sea'],
    correctAnswer: 'Caspian Sea',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g129',
    question: 'What is the capital of Yemen?',
    options: ['Sana\'a', 'Aden', 'Taiz', 'Hodeidah'],
    correctAnswer: 'Sana\'a',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g130',
    question: 'Which is the deepest lake in the world?',
    options: ['Lake Baikal', 'Lake Tanganyika', 'Caspian Sea', 'Lake Superior'],
    correctAnswer: 'Lake Baikal',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g131',
    question: 'What is the capital of Ghana?',
    options: ['Accra', 'Kumasi', 'Tamale', 'Sekondi-Takoradi'],
    correctAnswer: 'Accra',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g132',
    question: 'Which country is known as the Land of the Rising Sun?',
    options: ['Japan', 'China', 'South Korea', 'Vietnam'],
    correctAnswer: 'Japan',
    difficulty: 'easy',
    category: 'Geography'
  },
  {
    id: 'g133',
    question: 'What is the capital of Bolivia?',
    options: ['Sucre', 'La Paz', 'Santa Cruz', 'Cochabamba'],
    correctAnswer: 'Sucre',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g134',
    question: 'Which is the largest river delta in the world?',
    options: ['Ganges Delta', 'Nile Delta', 'Mississippi Delta', 'Amazon Delta'],
    correctAnswer: 'Ganges Delta',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g135',
    question: 'What is the capital of Jordan?',
    options: ['Amman', 'Zarqa', 'Irbid', 'Aqaba'],
    correctAnswer: 'Amman',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g136',
    question: 'Which mountain is the highest in South America?',
    options: ['Aconcagua', 'Ojos del Salado', 'Monte Pissis', 'Huascarán'],
    correctAnswer: 'Aconcagua',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g137',
    question: 'What is the capital of Costa Rica?',
    options: ['San José', 'Alajuela', 'Cartago', 'Heredia'],
    correctAnswer: 'San José',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g138',
    question: 'Which is the only continent that lies in all four hemispheres?',
    options: ['Africa', 'Asia', 'North America', 'South America'],
    correctAnswer: 'Africa',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g139',
    question: 'What is the capital of Mongolia?',
    options: ['Ulaanbaatar', 'Erdenet', 'Darkhan', 'Choibalsan'],
    correctAnswer: 'Ulaanbaatar',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g140',
    question: 'Which country has the most geysers in the world?',
    options: ['United States', 'Iceland', 'New Zealand', 'Russia'],
    correctAnswer: 'United States',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g141',
    question: 'What is the capital of Tunisia?',
    options: ['Tunis', 'Sfax', 'Sousse', 'Kairouan'],
    correctAnswer: 'Tunis',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g142',
    question: 'Which is the largest plateau in the world?',
    options: ['Tibetan Plateau', 'Colorado Plateau', 'Deccan Plateau', 'Antarctic Plateau'],
    correctAnswer: 'Tibetan Plateau',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g143',
    question: 'What is the capital of Oman?',
    options: ['Muscat', 'Salalah', 'Sohar', 'Nizwa'],
    correctAnswer: 'Muscat',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g144',
    question: 'Which strait connects the Mediterranean Sea to the Atlantic Ocean?',
    options: ['Strait of Gibraltar', 'Strait of Messina', 'Strait of Sicily', 'Dardanelles Strait'],
    correctAnswer: 'Strait of Gibraltar',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g145',
    question: 'What is the capital of Cambodia?',
    options: ['Phnom Penh', 'Siem Reap', 'Battambang', 'Sihanoukville'],
    correctAnswer: 'Phnom Penh',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g146',
    question: 'Which country is the world\'s largest producer of olive oil?',
    options: ['Spain', 'Italy', 'Greece', 'Turkey'],
    correctAnswer: 'Spain',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g147',
    question: 'What is the capital of Laos?',
    options: ['Vientiane', 'Pakse', 'Luang Prabang', 'Savannakhet'],
    correctAnswer: 'Vientiane',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g148',
    question: 'Which is the largest estuary in the world?',
    options: ['Gulf of Saint Lawrence', 'Chesapeake Bay', 'Rio de la Plata', 'Amazon River Mouth'],
    correctAnswer: 'Rio de la Plata',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g149',
    question: 'What is the capital of Estonia?',
    options: ['Tallinn', 'Tartu', 'Narva', 'Pärnu'],
    correctAnswer: 'Tallinn',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g150',
    question: 'Which country has the most earthquakes?',
    options: ['Indonesia', 'Japan', 'Chile', 'United States'],
    correctAnswer: 'Indonesia',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g151',
    question: 'What is the capital of Paraguay?',
    options: ['Asunción', 'Ciudad del Este', 'San Lorenzo', 'Luque'],
    correctAnswer: 'Asunción',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g152',
    question: 'Which is the largest ice sheet in the world?',
    options: ['Antarctic Ice Sheet', 'Greenland Ice Sheet', 'Vatnajökull', 'Patagonian Ice Sheet'],
    correctAnswer: 'Antarctic Ice Sheet',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g153',
    question: 'What is the capital of Mali?',
    options: ['Bamako', 'Sikasso', 'Mopti', 'Gao'],
    correctAnswer: 'Bamako',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g154',
    question: 'Which country has the most sheep?',
    options: ['China', 'Australia', 'New Zealand', 'Iran'],
    correctAnswer: 'China',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g155',
    question: 'What is the capital of Kuwait?',
    options: ['Kuwait City', 'Jahrah', 'Salmiya', 'Hawalli'],
    correctAnswer: 'Kuwait City',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g156',
    question: 'Which is the largest rainforest outside of the Amazon?',
    options: ['Congo Rainforest', 'Daintree Rainforest', 'Southeast Asian Rainforest', 'Valdivian Rainforest'],
    correctAnswer: 'Congo Rainforest',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g157',
    question: 'What is the capital of Uganda?',
    options: ['Kampala', 'Gulu', 'Mbarara', 'Jinja'],
    correctAnswer: 'Kampala',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g158',
    question: 'Which country has the longest land border?',
    options: ['China', 'Russia', 'Brazil', 'Canada'],
    correctAnswer: 'China',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g159',
    question: 'What is the capital of Albania?',
    options: ['Tirana', 'Durrës', 'Vlorë', 'Elbasan'],
    correctAnswer: 'Tirana',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g160',
    question: 'Which is the largest lake in South America?',
    options: ['Lake Titicaca', 'Lake Maracaibo', 'Lake Poopó', 'Lake Buenos Aires'],
    correctAnswer: 'Lake Titicaca',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g161',
    question: 'What is the capital of Mozambique?',
    options: ['Maputo', 'Matola', 'Nampula', 'Beira'],
    correctAnswer: 'Maputo',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g162',
    question: 'Which country is the world\'s largest producer of vanilla?',
    options: ['Madagascar', 'Indonesia', 'Mexico', 'Tahiti'],
    correctAnswer: 'Madagascar',
    difficulty: 'hard',
    category: 'Geography'
  },
  {
    id: 'g163',
    question: 'What is the capital of Cyprus?',
    options: ['Nicosia', 'Limassol', 'Larnaca', 'Paphos'],
    correctAnswer: 'Nicosia',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g164',
    question: 'Which mountain range separates Europe from Asia?',
    options: ['Ural Mountains', 'Caucasus Mountains', 'Altai Mountains', 'Carpathian Mountains'],
    correctAnswer: 'Ural Mountains',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g165',
    question: 'What is the capital of Azerbaijan?',
    options: ['Baku', 'Ganja', 'Sumqayit', 'Mingachevir'],
    correctAnswer: 'Baku',
    difficulty: 'medium',
    category: 'Geography'
  },
  {
    id: 'g166',
    question: 'Which is the windiest continent on Earth?',
    options: ['Antarctica', 'North America', 'Europe', 'Australia'],
    correctAnswer: 'Antarctica',
    difficulty: 'medium',
    category: 'Geography'
  },
  // History
  {
    id: 'h1',
    question: 'In which year did World War I begin?',
    options: ['1914', '1915', '1913', '1916'],
    correctAnswer: '1914',
    difficulty: 'easy',
    category: 'History'
  },
  {
    id: 'h2',
    question: 'Who was the founder of Pakistan?',
    options: [
      'Muhammad Ali Jinnah',
      'Allama Iqbal',
      'Liaquat Ali Khan',
      'Fatima Jinnah'
    ],
    correctAnswer: 'Muhammad Ali Jinnah',
    difficulty: 'easy',
    category: 'History'
  },
  {
    id: 'h3',
    question: 'When did Pakistan gain independence?',
    options: ['1947', '1948', '1946', '1949'],
    correctAnswer: '1947',
    difficulty: 'easy',
    category: 'History'
  },
  {
    id: 'h4',
    question: 'What is the capital of Malaysia?',
    options: ['Kuala Lumpur', 'Putrajaya', 'Johor Bahru', 'George Town'],
    correctAnswer: 'Kuala Lumpur',
    difficulty: 'easy',
    category: 'History'
  },
  {
    id: 'h5',
    question: 'Who was the first Prime Minister of Canada?',
    options: [
      'Sir John A. Macdonald',
      'Alexander Mackenzie',
      'Wilfrid Laurier',
      'Robert Borden'
    ],
    correctAnswer: 'Sir John A. Macdonald',
    difficulty: 'easy',
    category: 'History'
  },
  {
    id: 'h6',
    question: 'Who is the current monarch of the United Kingdom?',
    options: [
      'King Charles III',
      'Queen Elizabeth II',
      'King George VI',
      'Queen Victoria'
    ],
    correctAnswer: 'King Charles III',
    difficulty: 'easy',
    category: 'History'
  },
  {
    id: 'h7',
    question: 'Who was the founder of the Ottoman Empire?',
    options: ['Osman I', 'Mehmed II', 'Suleiman I', 'Selim I'],
    correctAnswer: 'Osman I',
    difficulty: 'easy',
    category: 'History'
  },
  {
    id: 'h8',
    question: 'In what year did World War II end?',
    options: ['1945', '1944', '1946', '1943'],
    correctAnswer: '1945',
    difficulty: 'easy',
    category: 'History'
  },
  {
    id: 'h9',
    question: 'Who was the first president of the United States?',
    options: [
      'George Washington',
      'Thomas Jefferson',
      'Abraham Lincoln',
      'John Adams'
    ],
    correctAnswer: 'George Washington',
    difficulty: 'easy',
    category: 'History'
  },
  {
    id: 'h10',
    question: 'What year did the French Revolution begin?',
    options: ['1789', '1787', '1792', '1799'],
    correctAnswer: '1789',
    difficulty: 'easy',
    category: 'History'
  },
  {
    id: 'h11',
    question: 'Which Mughal emperor built the Taj Mahal?',
    options: [
      'Shah Jahan',
      'Akbar the Great',
      'Aurangzeb',
      'Jahangir'
    ],
    correctAnswer: 'Shah Jahan',
    difficulty: 'medium',
    category: 'History'
  },
  {
    id: 'h12',
    question: 'Who was the first woman Prime Minister of Pakistan?',
    options: [
      'Benazir Bhutto',
      'Fatima Jinnah',
      'Khaleda Zia',
      'Indira Gandhi'
    ],
    correctAnswer: 'Benazir Bhutto',
    difficulty: 'medium',
    category: 'History'
  },
  {
    id: 'h13',
    question: 'When did Malaysia achieve independence?',
    options: ['1957', '1963', '1948', '1945'],
    correctAnswer: '1957',
    difficulty: 'medium',
    category: 'History'
  },
  {
    id: 'h14',
    question: 'Who was the longest-reigning British monarch?',
    options: [
      'Queen Victoria',
      'Queen Elizabeth II',
      'King George III',
      'King Henry VIII'
    ],
    correctAnswer: 'Queen Elizabeth II',
    difficulty: 'medium',
    category: 'History'
  },
  {
    id: 'h15',
    question: 'What was the official language of the Ottoman Empire?',
    options: ['Ottoman Turkish', 'Arabic', 'Persian', 'Greek'],
    correctAnswer: 'Ottoman Turkish',
    difficulty: 'medium',
    category: 'History'
  },
  {
    id: 'h16',
    question: 'Who wrote the "Communist Manifesto"?',
    options: [
      'Karl Marx',
      'Vladimir Lenin',
      'Joseph Stalin',
      'Leon Trotsky'
    ],
    correctAnswer: 'Karl Marx',
    difficulty: 'medium',
    category: 'History'
  },
  {
    id: 'h17',
    question: 'In what year did the American Civil War end?',
    options: ['1865', '1861', '1863', '1864'],
    correctAnswer: '1865',
    difficulty: 'medium',
    category: 'History'
  },
  {
    id: 'h18',
    question: 'Who was the first emperor of China?',
    options: ['Qin Shi Huang', 'Han Gaozu', 'Tang Taizong', 'Kublai Khan'],
    correctAnswer: 'Qin Shi Huang',
    difficulty: 'medium',
    category: 'History'
  },
  {
    id: 'h19',
    question: 'In what year did the Russian Revolution take place?',
    options: ['1917', '1914', '1918', '1916'],
    correctAnswer: '1917',
    difficulty: 'medium',
    category: 'History'
  },
  {
    id: 'h20',
    question: 'Who was the leader of the Soviet Union during World War II?',
    options: [
      'Joseph Stalin',
      'Vladimir Lenin',
      'Nikita Khrushchev',
      'Mikhail Gorbachev'
    ],
    correctAnswer: 'Joseph Stalin',
    difficulty: 'medium',
    category: 'History'
  },
  {
    id: 'h21',
    question: 'Which country colonized Malaysia?',
    options: [
      'United Kingdom',
      'Netherlands',
      'Portugal',
      'Spain'
    ],
    correctAnswer: 'United Kingdom',
    difficulty: 'medium',
    category: 'History'
  },
  {
    id: 'h22',
    question: 'What was the name of the first Muslim dynasty in India?',
    options: [
      'Slave Dynasty',
      'Khilji Dynasty',
      'Tughlaq Dynasty',
      'Mughal Dynasty'
    ],
    correctAnswer: 'Slave Dynasty',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h23',
    question: 'Who was the first Governor-General of Pakistan?',
    options: [
      'Muhammad Ali Jinnah',
      'Liaquat Ali Khan',
      'Khawaja Nazimuddin',
      'Ghulam Muhammad'
    ],
    correctAnswer: 'Muhammad Ali Jinnah',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h24',
    question: 'In which year did the Lahore Resolution pass?',
    options: ['1940', '1947', '1930', '1946'],
    correctAnswer: '1940',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h25',
    question: 'Who was the first Yang di-Pertuan Agong of Malaysia?',
    options: [
      'Tuanku Abdul Rahman',
      'Sultan Hisamuddin Alam Shah',
      'Tuanku Syed Putra',
      'Sultan Ismail Nasiruddin Shah'
    ],
    correctAnswer: 'Tuanku Abdul Rahman',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h26',
    question: 'Which act officially made Canada a dominion?',
    options: [
      'British North America Act, 1867',
      'Statute of Westminster, 1931',
      'Canada Act 1982',
      'Constitution Act, 1867'
    ],
    correctAnswer: 'British North America Act, 1867',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h27',
    question: 'Which British monarch dissolved the monasteries?',
    options: [
      'King Henry VIII',
      'King Edward VI',
      'Queen Mary I',
      'Queen Elizabeth I'
    ],
    correctAnswer: 'King Henry VIII',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h28',
    question: 'What was the name of the final Ottoman sultan?',
    options: [
      'Mehmed VI',
      'Abdul Hamid II',
      'Abdulmejid II',
      'Suleiman I'
    ],
    correctAnswer: 'Mehmed VI',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h29',
    question: 'Who led the Bolsheviks during the Russian Revolution?',
    options: [
      'Vladimir Lenin',
      'Joseph Stalin',
      'Leon Trotsky',
      'Alexander Kerensky'
    ],
    correctAnswer: 'Vladimir Lenin',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h30',
    question: 'In what year did the Berlin Wall fall?',
    options: ['1989', '1990', '1988', '1991'],
    correctAnswer: '1989',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h31',
    question: 'Who was the first woman to win a Nobel Prize?',
    options: [
      'Marie Curie',
      'Mother Teresa',
      'Florence Nightingale',
      'Rosa Parks'
    ],
    correctAnswer: 'Marie Curie',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h32',
    question: 'What year did the Titanic sink?',
    options: ['1912', '1911', '1913', '1910'],
    correctAnswer: '1912',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h33',
    question: 'Who painted the Mona Lisa?',
    options: [
      'Leonardo da Vinci',
      'Michelangelo',
      'Raphael',
      'Donatello'
    ],
    correctAnswer: 'Leonardo da Vinci',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h34',
    question: 'Who wrote "Hamlet"?',
    options: [
      'William Shakespeare',
      'Christopher Marlowe',
      'Ben Jonson',
      'John Webster'
    ],
    correctAnswer: 'William Shakespeare',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h35',
    question: 'Who composed "Moonlight Sonata"?',
    options: [
      'Ludwig van Beethoven',
      'Wolfgang Amadeus Mozart',
      'Johann Sebastian Bach',
      'Franz Schubert'
    ],
    correctAnswer: 'Ludwig van Beethoven',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h36',
    question: 'What was the capital of the Byzantine Empire?',
    options: ['Constantinople', 'Rome', 'Athens', 'Alexandria'],
    correctAnswer: 'Constantinople',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h37',
    question: 'Who was the first Holy Roman Emperor?',
    options: [
      'Charlemagne',
      'Otto I',
      'Frederick Barbarossa',
      'Charles V'
    ],
    correctAnswer: 'Charlemagne',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h38',
    question: 'In what year did the Spanish Armada sail?',
    options: ['1588', '1587', '1589', '1586'],
    correctAnswer: '1588',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h39',
    question: 'Who was the first Mughal emperor?',
    options: ['Babur', 'Humayun', 'Akbar', 'Jahangir'],
    correctAnswer: 'Babur',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h40',
    question: 'What was the name of the Islamic prophet Muhammad\'s first wife?',
    options: ['Khadijah', 'Aisha', 'Hafsa', 'Safiyya'],
    correctAnswer: 'Khadijah',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h41',
    question: 'In what year did the Prophet Muhammad die?',
    options: ['632 CE', '622 CE', '610 CE', '630 CE'],
    correctAnswer: '632 CE',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h42',
    question: 'What is the name of the first mosque built by the Prophet Muhammad?',
    options: [
      'Masjid al-Quba',
      'Masjid al-Nabawi',
      'Masjid al-Haram',
      'Masjid al-Aqsa'
    ],
    correctAnswer: 'Masjid al-Quba',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h43',
    question: 'Which battle marked the beginning of the Islamic calendar?',
    options: [
      'Battle of Badr',
      'Battle of Uhud',
      'Battle of the Trench',
      'Conquest of Mecca'
    ],
    correctAnswer: 'Battle of Badr',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h44',
    question: 'Who was the first caliph after the Prophet Muhammad?',
    options: [
      'Abu Bakr',
      'Umar ibn al-Khattab',
      'Uthman ibn Affan',
      'Ali ibn Abi Talib'
    ],
    correctAnswer: 'Abu Bakr',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h45',
    question: 'Which dynasty overthrew the Umayyad Caliphate?',
    options: [
      'Abbasid Caliphate',
      'Fatimid Caliphate',
      'Ottoman Empire',
      'Seljuk Empire'
    ],
    correctAnswer: 'Abbasid Caliphate',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h46',
    question: 'Who was the famous Muslim traveler and scholar who wrote "Rihla"?',
    options: [
      'Ibn Battuta',
      'Ibn Khaldun',
      'Al-Biruni',
      'Avicenna'
    ],
    correctAnswer: 'Ibn Battuta',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h47',
    question: 'Which city served as the capital of the Abbasid Caliphate?',
    options: ['Baghdad', 'Damascus', 'Cordoba', 'Cairo'],
    correctAnswer: 'Baghdad',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h49',
    question: 'What is the name of the highest mountain range in the world?',
    options: [
      'Himalayas',
      'Andes',
      'Rocky Mountains',
      'Alps'
    ],
    correctAnswer: 'Himalayas',
    difficulty: 'easy',
    category: 'History'
  },
  {
    id: 'h50',
    question: 'Which country gifted the Statue of Liberty to the United States?',
    options: ['France', 'England', 'Spain', 'Italy'],
    correctAnswer: 'France',
    difficulty: 'easy',
    category: 'History'
  },
  {
    id: 'h51',
    question: 'What is the oldest civilization in the world?',
    options: [
      'Sumerian civilization',
      'Egyptian civilization',
      'Indus Valley Civilization',
      'Chinese civilization'
    ],
    correctAnswer: 'Sumerian civilization',
    difficulty: 'medium',
    category: 'History'
  },
  {
    id: 'h52',
    question: 'Who was the first woman to fly solo across the Atlantic Ocean?',
    options: [
      'Amelia Earhart',
      'Bessie Coleman',
      'Ruth Elder',
      'Jacqueline Cochran'
    ],
    correctAnswer: 'Amelia Earhart',
    difficulty: 'medium',
    category: 'History'
  },
  {
    id: 'h53',
    question: 'What was the name of the ship that Charles Darwin sailed on?',
    options: ['HMS Beagle', 'HMS Victory', 'HMS Bounty', 'HMS Endeavour'],
    correctAnswer: 'HMS Beagle',
    difficulty: 'medium',
    category: 'History'
  },
  {
    id: 'h54',
    question: 'Which country was the first to reach space?',
    options: [
      'Soviet Union',
      'United States',
      'China',
      'United Kingdom'
    ],
    correctAnswer: 'Soviet Union',
    difficulty: 'medium',
    category: 'History'
  },
  {
    id: 'h55',
    question: 'Who painted the ceiling of the Sistine Chapel?',
    options: [
      'Michelangelo',
      'Leonardo da Vinci',
      'Raphael',
      'Donatello'
    ],
    correctAnswer: 'Michelangelo',
    difficulty: 'medium',
    category: 'History'
  },
  {
    id: 'h56',
    question: 'Which ancient civilization built the Machu Picchu complex?',
    options: ['Inca', 'Maya', 'Aztec', 'Olmec'],
    correctAnswer: 'Inca',
    difficulty: 'medium',
    category: 'History'
  },
  {
    id: 'h57',
    question: 'What was the main cause of the American Civil War?',
    options: ['Slavery', 'Taxes', 'Land disputes', 'Religious differences'],
    correctAnswer: 'Slavery',
    difficulty: 'medium',
    category: 'History'
  },
  {
    id: 'h58',
    question: 'Which country was responsible for the construction of the Panama Canal?',
    options: [
      'United States',
      'France',
      'Panama',
      'Colombia'
    ],
    correctAnswer: 'United States',
    difficulty: 'medium',
    category: 'History'
  },
  {
    id: 'h59',
    question: 'Who was the leader of the Mongol Empire at its peak?',
    options: [
      'Genghis Khan',
      'Kublai Khan',
      'Ogedei Khan',
      'Mongke Khan'
    ],
    correctAnswer: 'Genghis Khan',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h60',
    question: 'What was the name of the first satellite launched into space?',
    options: ['Sputnik 1', 'Explorer 1', 'Vanguard 1', 'Luna 1'],
    correctAnswer: 'Sputnik 1',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h61',
    question: 'Who was the first person to circumnavigate the globe?',
    options: [
      'Ferdinand Magellan',
      'Christopher Columbus',
      'Vasco da Gama',
      'Amerigo Vespucci'
    ],
    correctAnswer: 'Ferdinand Magellan',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h62',
    question: 'Which civilization developed the first known writing system?',
    options: [
      'Sumerians',
      'Egyptians',
      'Chinese',
      'Indus Valley Civilization'
    ],
    correctAnswer: 'Sumerians',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h63',
    question: 'Who was the leader of the Aztecs during the Spanish conquest?',
    options: [
      'Montezuma II',
      'Cuauhtémoc',
      'Ahuitzotl',
      'Moctezuma I'
    ],
    correctAnswer: 'Montezuma II',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h64',
    question: 'What was the name of the treaty that ended World War I?',
    options: [
      'Treaty of Versailles',
      'Treaty of Brest-Litovsk',
      'Treaty of Trianon',
      'Treaty of Saint-Germain-en-Laye'
    ],
    correctAnswer: 'Treaty of Versailles',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h65',
    question: 'Who wrote "The Origin of Species"?',
    options: [
      'Charles Darwin',
      'Alfred Russel Wallace',
      'Jean-Baptiste Lamarck',
      'Gregor Mendel'
    ],
    correctAnswer: 'Charles Darwin',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h66',
    question: 'Who was the first person to walk on the moon?',
    options: [
      'Neil Armstrong',
      'Buzz Aldrin',
      'Michael Collins',
      'Yuri Gagarin'
    ],
    correctAnswer: 'Neil Armstrong',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h67',
    question: 'Which country was the first to use the atomic bomb in warfare?',
    options: [
      'United States',
      'Soviet Union',
      'United Kingdom',
      'Germany'
    ],
    correctAnswer: 'United States',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h68',
    question: 'Who was the leader of the civil rights movement in the United States?',
    options: [
      'Martin Luther King Jr.',
      'Malcolm X',
      'Rosa Parks',
      'Nelson Mandela'
    ],
    correctAnswer: 'Martin Luther King Jr.',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h69',
    question: 'What event marked the beginning of World War II?',
    options: [
      'Invasion of Poland',
      'Attack on Pearl Harbor',
      'Battle of Britain',
      'Invasion of France'
    ],
    correctAnswer: 'Invasion of Poland',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h70',
    question: 'Who was the first female Prime Minister of the United Kingdom?',
    options: [
      'Margaret Thatcher',
      'Theresa May',
      'Queen Elizabeth I',
      'Queen Victoria'
    ],
    correctAnswer: 'Margaret Thatcher',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h71',
    question: 'Who was the last Viceroy of India?',
    options: [
      'Lord Mountbatten',
      'Lord Curzon',
      'Lord Dalhousie',
      'Lord William Bentinck'
    ],
    correctAnswer: 'Lord Mountbatten',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h72',
    question: 'What was the name of the ancient Egyptian writing system?',
    options: [
      'Hieroglyphics',
      'Cuneiform',
      'Linear B',
      'Runes'
    ],
    correctAnswer: 'Hieroglyphics',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h73',
    question: 'Who was the first emperor of Rome?',
    options: ['Augustus', 'Julius Caesar', 'Nero', 'Caligula'],
    correctAnswer: 'Augustus',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h74',
    question: 'What was the name of the famous library in ancient Alexandria?',
    options: [
      'Library of Alexandria',
      'Library of Pergamum',
      'Library of Celsus',
      'Library of Ashurbanipal'
    ],
    correctAnswer: 'Library of Alexandria',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h75',
    question: 'Who was the Greek philosopher who wrote "The Republic"?',
    options: ['Plato', 'Socrates', 'Aristotle', 'Pythagoras'],
    correctAnswer: 'Plato',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h76',
    question: 'What was the name of the first civilization to develop democracy?',
    options: ['Athens', 'Sparta', 'Corinth', 'Thebes'],
    correctAnswer: 'Athens',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h77',
    question: 'Who was the leader of the Carthaginians during the Punic Wars?',
    options: ['Hannibal', 'Hamilcar Barca', 'Hasdrubal', 'Mago Barca'],
    correctAnswer: 'Hannibal',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h78',
    question: 'What was the name of the longest continuous empire in history?',
    options: [
      'Roman Empire',
      'Byzantine Empire',
      'Persian Empire',
      'Chinese Empire'
    ],
    correctAnswer: 'Byzantine Empire',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h79',
    question: 'Who was the first woman to rule England in her own right?',
    options: [
      'Queen Mary I',
      'Queen Elizabeth I',
      'Queen Victoria',
      'Queen Anne'
    ],
    correctAnswer: 'Queen Mary I',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h80',
    question: 'What was the name of the war between England and France that lasted from 1337 to 1453?',
    options: [
      'Hundred Years\' War',
      'War of the Roses',
      'Thirty Years\' War',
      'Seven Years\' War'
    ],
    correctAnswer: 'Hundred Years\' War',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h81',
    question: 'Who was the king of England during the American Revolution?',
    options: [
      'King George III',
      'King George II',
      'King George IV',
      'King William IV'
    ],
    correctAnswer: 'King George III',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h82',
    question: 'What was the name of the battle that ended the Napoleonic Wars?',
    options: [
      'Battle of Waterloo',
      'Battle of Trafalgar',
      'Battle of Austerlitz',
      'Battle of Jena-Auerstedt'
    ],
    correctAnswer: 'Battle of Waterloo',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h83',
    question: 'Who was the leader of the Indian independence movement?',
    options: [
      'Mahatma Gandhi',
      'Jawaharlal Nehru',
      'Sardar Vallabhbhai Patel',
      'Subhas Chandra Bose'
    ],
    correctAnswer: 'Mahatma Gandhi',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h84',
    question: 'In what year did India gain independence from British rule?',
    options: ['1947', '1948', '1946', '1949'],
    correctAnswer: '1947',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h85',
    question: 'Who was the first Prime Minister of India?',
    options: [
      'Jawaharlal Nehru',
      'Mahatma Gandhi',
      'Sardar Vallabhbhai Patel',
      'Indira Gandhi'
    ],
    correctAnswer: 'Jawaharlal Nehru',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h86',
    question: 'What was the name of the ancient civilization that flourished in the Indus Valley?',
    options: [
      'Indus Valley Civilization',
      'Mesopotamian Civilization',
      'Egyptian Civilization',
      'Chinese Civilization'
    ],
    correctAnswer: 'Indus Valley Civilization',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h87',
    question: 'Who was the founder of the Maurya Empire in India?',
    options: [
      'Chandragupta Maurya',
      'Ashoka the Great',
      'Bindusara',
      'Samudragupta'
    ],
    correctAnswer: 'Chandragupta Maurya',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h88',
    question: 'Which Chinese dynasty is known for its construction of the Great Wall?',
    options: ['Qin Dynasty', 'Han Dynasty', 'Tang Dynasty', 'Ming Dynasty'],
    correctAnswer: 'Qin Dynasty',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h89',
    question: 'Who was the first emperor of Japan?',
    options: [
      'Emperor Jimmu',
      'Emperor Sujin',
      'Emperor Keiko',
      'Emperor Suinin'
    ],
    correctAnswer: 'Emperor Jimmu',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h90',
    question: 'What was the name of the ancient city that was destroyed by a volcanic eruption in 79 CE?',
    options: ['Pompeii', 'Herculaneum', 'Stabiae', 'Oplontis'],
    correctAnswer: 'Pompeii',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h91',
    question: 'Who was the famous Carthaginian general who crossed the Alps with elephants?',
    options: ['Hannibal', 'Hamilcar Barca', 'Hasdrubal', 'Mago Barca'],
    correctAnswer: 'Hannibal',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h92',
    question: 'What was the name of the battle in which the Greeks defeated the Persians in 490 BCE?',
    options: [
      'Battle of Marathon',
      'Battle of Thermopylae',
      'Battle of Salamis',
      'Battle of Plataea'
    ],
    correctAnswer: 'Battle of Marathon',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h93',
    question: 'Who was the Macedonian king who conquered much of the known world in the 4th century BCE?',
    options: [
      'Alexander the Great',
      'Philip II of Macedon',
      'Antigonus I Monophthalmus',
      'Seleucus I Nicator'
    ],
    correctAnswer: 'Alexander the Great',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h94',
    question: 'What was the name of the period of peace and prosperity in the Roman Empire?',
    options: ['Pax Romana', 'Pax Britannica', 'Pax Mongolica', 'Pax Americana'],
    correctAnswer: 'Pax Romana',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h95',
    question: 'Who was the Roman Emperor who issued the Edict of Milan, legalizing Christianity?',
    options: ['Constantine the Great', 'Diocletian', 'Nero', 'Marcus Aurelius'],
    correctAnswer: 'Constantine the Great',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h96',
    question: 'What was the name of the series of religious wars fought by European Christians against Muslims in the Middle Ages?',
    options: ['The Crusades', 'The Hundred Years\' War', 'The Thirty Years\' War', 'The War of the Roses'],
    correctAnswer: 'The Crusades',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h97',
    question: 'Who was the leader of the Protestant Reformation?',
    options: ['Martin Luther', 'John Calvin', 'Huldrych Zwingli', 'John Knox'],
    correctAnswer: 'Martin Luther',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h98',
    question: 'What was the name of the conflict between the English Parliament and King Charles I that led to his execution?',
    options: ['English Civil War', 'Glorious Revolution', 'War of the Roses', 'Hundred Years\' War'],
    correctAnswer: 'English Civil War',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h99',
    question: 'Who was the French military and political leader who rose to prominence during the French Revolution and its associated wars?',
    options: ['Napoleon Bonaparte', 'Louis XIV', 'Charles de Gaulle', 'Joan of Arc'],
    correctAnswer: 'Napoleon Bonaparte',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h100',
    question: 'What was the name of the period of rapid technological advancement in Europe during the 18th and 19th centuries?',
    options: ['Industrial Revolution', 'Renaissance', 'Enlightenment', 'Scientific Revolution'],
    correctAnswer: 'Industrial Revolution',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h101',
    question: 'Which country initiated the policy of Apartheid, a system of institutionalized racial segregation and discrimination?',
    options: ['South Africa', 'United States', 'Australia', 'Rhodesia'],
    correctAnswer: 'South Africa',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h102',
    question: 'Who was the leader of the Soviet Union during the Cold War?',
    options: ['Joseph Stalin', 'Vladimir Lenin', 'Nikita Khrushchev', 'Mikhail Gorbachev'],
    correctAnswer: 'Joseph Stalin',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h103',
    question: 'What event marked the end of the Cold War?',
    options: ['Fall of the Berlin Wall', 'Dissolution of the Soviet Union', 'Cuban Missile Crisis', 'Korean War'],
    correctAnswer: 'Fall of the Berlin Wall',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h104',
    question: 'Who was the first Chancellor of Germany?',
    options: ['Otto von Bismarck', 'Adolf Hitler', 'Konrad Adenauer', 'Helmut Kohl'],
    correctAnswer: 'Otto von Bismarck',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h105',
    question: 'What was the name of the political ideology that promotes extreme nationalism and authoritarianism?',
    options: ['Fascism', 'Communism', 'Socialism', 'Capitalism'],
    correctAnswer: 'Fascism',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h106',
    question: 'Which country was led by Adolf Hitler during World War II?',
    options: ['Germany', 'Italy', 'Japan', 'Soviet Union'],
    correctAnswer: 'Germany',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h107',
    question: 'What was the name of the genocide of European Jews during World War II?',
    options: ['The Holocaust', 'The Armenian Genocide', 'The Rwandan Genocide', 'The Cambodian Genocide'],
    correctAnswer: 'The Holocaust',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h108',
    question: 'What was the name of the international organization formed after World War II to promote peace and cooperation?',
    options: ['United Nations', 'League of Nations', 'NATO', 'Warsaw Pact'],
    correctAnswer: 'United Nations',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h109',
    question: 'Who was the leader of the Soviet Union during the Cuban Missile Crisis?',
    options: ['Nikita Khrushchev', 'Joseph Stalin', 'Vladimir Lenin', 'Mikhail Gorbachev'],
    correctAnswer: 'Nikita Khrushchev',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h110',
    question: 'What was the name of the first artificial satellite to orbit the Earth?',
    options: ['Sputnik 1', 'Explorer 1', 'Vanguard 1', 'Luna 1'],
    correctAnswer: 'Sputnik 1',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h111',
    question: 'Who was the first human to travel into space?',
    options: ['Yuri Gagarin', 'Alan Shepard', 'John Glenn', 'Neil Armstrong'],
    correctAnswer: 'Yuri Gagarin',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h112',
    question: 'What was the name of the U.S. space program that landed the first humans on the Moon?',
    options: ['Apollo program', 'Gemini program', 'Mercury program', 'Space Shuttle program'],
    correctAnswer: 'Apollo program',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h113',
    question: 'Who was the U.S. president during the American Civil War?',
    options: ['Abraham Lincoln', 'George Washington', 'Thomas Jefferson', 'Andrew Jackson'],
    correctAnswer: 'Abraham Lincoln',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h114',
    question: 'What was the name of the document that abolished slavery in the United States?',
    options: ['Emancipation Proclamation', 'Declaration of Independence', 'U.S. Constitution', 'Bill of Rights'],
    correctAnswer: 'Emancipation Proclamation',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h115',
    question: 'Who was the leader of the Confederate States of America during the American Civil War?',
    options: ['Jefferson Davis', 'Robert E. Lee', 'Stonewall Jackson', 'Ulysses S. Grant'],
    correctAnswer: 'Jefferson Davis',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h116',
    question: 'What was the name of the battle that marked the turning point of the American Civil War?',
    options: ['Battle of Gettysburg', 'Battle of Antietam', 'Battle of Vicksburg', 'Battle of Shiloh'],
    correctAnswer: 'Battle of Gettysburg',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h117',
    question: 'Who was the U.S. president during World War I?',
    options: ['Woodrow Wilson', 'Theodore Roosevelt', 'William Howard Taft', 'Warren G. Harding'],
    correctAnswer: 'Woodrow Wilson',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h118',
    question: 'What was the name of the organization formed after World War I to promote international cooperation?',
    options: ['League of Nations', 'United Nations', 'NATO', 'Warsaw Pact'],
    correctAnswer: 'League of Nations',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h119',
    question: 'Who was the U.S. president during the Great Depression and World War II?',
    options: ['Franklin D. Roosevelt', 'Herbert Hoover', 'Harry S. Truman', 'Dwight D. Eisenhower'],
    correctAnswer: 'Franklin D. Roosevelt',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h120',
    question: 'What was the name of the economic crisis that began in 1929 and lasted for a decade?',
    options: ['The Great Depression', 'The Panic of 1907', 'The Great Recession', 'The Long Depression'],
    correctAnswer: 'The Great Depression',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h121',
    question: 'Who was the leader of the Soviet Union during World War II?',
    options: ['Joseph Stalin', 'Vladimir Lenin', 'Nikita Khrushchev', 'Mikhail Gorbachev'],
    correctAnswer: 'Joseph Stalin',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h122',
    question: 'What was the name of the battle that marked the turning point of World War II in Europe?',
    options: ['Battle of Stalingrad', 'Battle of Kursk', 'Battle of Normandy', 'Battle of Berlin'],
    correctAnswer: 'Battle of Stalingrad',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h123',
    question: 'What event marked the end of World War II?',
    options: ['Atomic bombings of Hiroshima and Nagasaki', 'Surrender of Germany', 'Surrender of Japan', 'V-E Day'],
    correctAnswer: 'Atomic bombings of Hiroshima and Nagasaki',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h124',
    question: 'Who was the U.S. president during the Cold War?',
    options: ['Harry S. Truman', 'Dwight D. Eisenhower', 'John F. Kennedy', 'Lyndon B. Johnson'],
    correctAnswer: 'Harry S. Truman',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h125',
    question: 'What was the name of the military alliance formed by the United States and its allies during the Cold War?',
    options: ['NATO', 'Warsaw Pact', 'League of Nations', 'United Nations'],
    correctAnswer: 'NATO',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h126',
    question: 'What was the name of the military alliance formed by the Soviet Union and its allies during the Cold War?',
    options: ['Warsaw Pact', 'NATO', 'League of Nations', 'United Nations'],
    correctAnswer: 'Warsaw Pact',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h127',
    question: 'Who was the leader of the People\'s Republic of China during the Cold War?',
    options: ['Mao Zedong', 'Deng Xiaoping', 'Jiang Zemin', 'Hu Jintao'],
    correctAnswer: 'Mao Zedong',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h128',
    question: 'What was the name of the conflict between North and South Korea that occurred during the Cold War?',
    options: ['Korean War', 'Vietnam War', 'Cuban Missile Crisis', 'Berlin Blockade'],
    correctAnswer: 'Korean War',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h129',
    question: 'Who was the leader of North Vietnam during the Vietnam War?',
    options: ['Ho Chi Minh', 'Ngo Dinh Diem', 'Vo Nguyen Giap', 'Le Duan'],
    correctAnswer: 'Ho Chi Minh',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h130',
    question: 'What was the name of the conflict between the United States and the Soviet Union that almost led to nuclear war?',
    options: ['Cuban Missile Crisis', 'Korean War', 'Vietnam War', 'Berlin Blockade'],
    correctAnswer: 'Cuban Missile Crisis',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h131',
    question: 'Who was the U.S. president during the Vietnam War?',
    options: ['Lyndon B. Johnson', 'John F. Kennedy', 'Richard Nixon', 'Gerald Ford'],
    correctAnswer: 'Lyndon B. Johnson',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h132',
    question: 'What event marked the end of the Vietnam War?',
    options: ['Fall of Saigon', 'Tet Offensive', 'Paris Peace Accords', 'My Lai Massacre'],
    correctAnswer: 'Fall of Saigon',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h133',
    question: 'Who was the leader of the Soviet Union during the period of perestroika and glasnost?',
    options: ['Mikhail Gorbachev', 'Joseph Stalin', 'Vladimir Lenin', 'Nikita Khrushchev'],
    correctAnswer: 'Mikhail Gorbachev',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h134',
    question: 'What event marked the end of the Soviet Union?',
    options: ['Dissolution of the Soviet Union', 'Fall of the Berlin Wall', 'Cuban Missile Crisis', 'August Coup'],
    correctAnswer: 'Dissolution of the Soviet Union',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h135',
    question: 'Who was the first president of Russia after the collapse of the Soviet Union?',
    options: ['Boris Yeltsin', 'Vladimir Putin', 'Dmitry Medvedev', 'Mikhail Gorbachev'],
    correctAnswer: 'Boris Yeltsin',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h136',
    question: 'What was the name of the terrorist attacks that occurred in the United States on September 11, 2001?',
    options: ['September 11 attacks', 'Oklahoma City bombing', 'Pearl Harbor attack', 'Boston Marathon bombing'],
    correctAnswer: 'September 11 attacks',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h137',
    question: 'Who was the U.S. president during the September 11 attacks?',
    options: ['George W. Bush', 'Bill Clinton', 'Barack Obama', 'Donald Trump'],
    correctAnswer: 'George W. Bush',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h138',
    question: 'What was the name of the U.S.-led military operation in Afghanistan following the September 11 attacks?',
    options: ['Operation Enduring Freedom', 'Operation Iraqi Freedom', 'Operation Desert Storm', 'Operation Desert Shield'],
    correctAnswer: 'Operation Enduring Freedom',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h139',
    question: 'Who was the leader of al-Qaeda during the September 11 attacks?',
    options: ['Osama bin Laden', 'Ayman al-Zawahiri', 'Abu Bakr al-Baghdadi', 'Abu Musab al-Zarqawi'],
    correctAnswer: 'Osama bin Laden',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h140',
    question: 'What was the name of the U.S.-led military operation in Iraq in 2003?',
    options: ['Operation Iraqi Freedom', 'Operation Enduring Freedom', 'Operation Desert Storm', 'Operation Desert Shield'],
    correctAnswer: 'Operation Iraqi Freedom',
    difficulty: 'hard',
    category: 'History'
  },
  {
    id: 'h141',
    question: 'Who was the leader of Iraq during the U.S.-led invasion in 2003?',
    options: ['Saddam Hussein', 'Muammar Gaddafi', 'Bashar al-Assad', 'Mahmoud Ahmadinejad'],
    correctAnswer: 'Saddam Hussein',
    difficulty: 'hard',
    category: 'History'
  },
  // Science
  {
    id: 'sc1',
    question: 'What is the hardest natural substance on Earth?',
    options: ['Diamond', 'Graphene', 'Wurtzite', 'Lonsdaleite'],
    correctAnswer: 'Diamond',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc2',
    question: 'Which organ in the human body produces insulin?',
    options: ['Pancreas', 'Liver', 'Kidney', 'Spleen'],
    correctAnswer: 'Pancreas',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc3',
    question: 'What is the atomic number of Gold (Au)?',
    options: ['79', '47', '29', '82'],
    correctAnswer: '79',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc4',
    question: 'Which planet has the most moons in our solar system?',
    options: ['Jupiter', 'Saturn', 'Uranus', 'Neptune'],
    correctAnswer: 'Jupiter',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc5',
    question: 'What is the smallest unit of life?',
    options: ['Cell', 'Atom', 'Molecule', 'Gene'],
    correctAnswer: 'Cell',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc6',
    question: 'What is the speed of light in vacuum?',
    options: ['299,792 km/s', '300,000 km/s', '199,792 km/s', '250,000 km/s'],
    correctAnswer: '299,792 km/s',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc7',
    question: 'Which blood type is known as the universal donor?',
    options: ['O negative', 'A negative', 'B negative', 'AB negative'],
    correctAnswer: 'O negative',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc8',
    question: 'What is the most abundant element in the universe?',
    options: ['Hydrogen', 'Helium', 'Carbon', 'Oxygen'],
    correctAnswer: 'Hydrogen',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc9',
    question: 'Which part of the human brain is responsible for balance and coordination?',
    options: ['Cerebellum', 'Cerebrum', 'Medulla', 'Hypothalamus'],
    correctAnswer: 'Cerebellum',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc10',
    question: 'What is the chemical symbol for silver?',
    options: ['Ag', 'Si', 'Au', 'Fe'],
    correctAnswer: 'Ag',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc11',
    question: 'What is the process by which plants make their own food?',
    options: ['Photosynthesis', 'Respiration', 'Fermentation', 'Digestion'],
    correctAnswer: 'Photosynthesis',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc12',
    question: 'Which subatomic particle has a negative charge?',
    options: ['Electron', 'Proton', 'Neutron', 'Positron'],
    correctAnswer: 'Electron',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc13',
    question: 'What is the unit of electrical resistance?',
    options: ['Ohm', 'Volt', 'Ampere', 'Watt'],
    correctAnswer: 'Ohm',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc14',
    question: 'Which gas makes up the majority of Earth\'s atmosphere?',
    options: ['Nitrogen', 'Oxygen', 'Carbon Dioxide', 'Argon'],
    correctAnswer: 'Nitrogen',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc15',
    question: 'What is the study of fossils called?',
    options: ['Paleontology', 'Archaeology', 'Geology', 'Anthropology'],
    correctAnswer: 'Paleontology',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc16',
    question: 'What is the process of radioactive decay measured in?',
    options: ['Half-life', 'Frequency', 'Wavelength', 'Amplitude'],
    correctAnswer: 'Half-life',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc17',
    question: 'Which vitamin is produced when skin is exposed to sunlight?',
    options: ['Vitamin D', 'Vitamin C', 'Vitamin A', 'Vitamin E'],
    correctAnswer: 'Vitamin D',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc18',
    question: 'What is the study of weather called?',
    options: ['Meteorology', 'Climatology', 'Astronomy', 'Geography'],
    correctAnswer: 'Meteorology',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc19',
    question: 'Which element has the chemical symbol Fe?',
    options: ['Iron', 'Fluorine', 'Francium', 'Iodine'],
    correctAnswer: 'Iron',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc20',
    question: 'What is the smallest bone in the human body?',
    options: ['Stapes', 'Malleus', 'Incus', 'Hyoid'],
    correctAnswer: 'Stapes',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc21',
    question: 'What force keeps planets in orbit around the Sun?',
    options: ['Gravity', 'Magnetism', 'Friction', 'Nuclear Force'],
    correctAnswer: 'Gravity',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc22',
    question: 'Which metal is liquid at room temperature?',
    options: ['Mercury', 'Lead', 'Aluminum', 'Copper'],
    correctAnswer: 'Mercury',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc23',
    question: 'What type of cells are found in the brain?',
    options: ['Neurons', 'Hepatocytes', 'Myocytes', 'Osteocytes'],
    correctAnswer: 'Neurons',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc24',
    question: 'What is the unit of power in the International System of Units?',
    options: ['Watt', 'Joule', 'Newton', 'Pascal'],
    correctAnswer: 'Watt',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc25',
    question: 'Which galaxy is the Milky Way\'s nearest neighbor?',
    options: ['Andromeda', 'Triangle', 'Whirlpool', 'Sombrero'],
    correctAnswer: 'Andromeda',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc26',
    question: 'What is the most abundant gas in Earth\'s atmosphere?',
    options: ['Nitrogen', 'Oxygen', 'Carbon Dioxide', 'Argon'],
    correctAnswer: 'Nitrogen',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc27',
    question: 'What is the fastest land animal?',
    options: ['Cheetah', 'Lion', 'Gazelle', 'Antelope'],
    correctAnswer: 'Cheetah',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc28',
    question: 'What is the chemical formula for table salt?',
    options: ['NaCl', 'H2O', 'CO2', 'CaCO3'],
    correctAnswer: 'NaCl',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc29',
    question: 'Which particle has neutral charge?',
    options: ['Neutron', 'Proton', 'Electron', 'Ion'],
    correctAnswer: 'Neutron',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc30',
    question: 'What is the main function of white blood cells?',
    options: ['Fight infection', 'Carry oxygen', 'Clot blood', 'Produce antibodies'],
    correctAnswer: 'Fight infection',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc31',
    question: 'What is absolute zero in Celsius?',
    options: ['-273.15°C', '-100°C', '-200°C', '-300°C'],
    correctAnswer: '-273.15°C',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc32',
    question: 'Which element is essential for human bones?',
    options: ['Calcium', 'Iron', 'Potassium', 'Sodium'],
    correctAnswer: 'Calcium',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc33',
    question: 'What is the study of heredity and variation in organisms?',
    options: ['Genetics', 'Biology', 'Physiology', 'Anatomy'],
    correctAnswer: 'Genetics',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc34',
    question: 'What is the largest organ in the human body?',
    options: ['Skin', 'Liver', 'Brain', 'Lungs'],
    correctAnswer: 'Skin',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc35',
    question: 'Which planet is known as the Red Planet?',
    options: ['Mars', 'Venus', 'Jupiter', 'Mercury'],
    correctAnswer: 'Mars',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc36',
    question: 'What is the chemical symbol for gold?',
    options: ['Au', 'Ag', 'Fe', 'Cu'],
    correctAnswer: 'Au',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc37',
    question: 'What type of energy is stored in food?',
    options: ['Chemical', 'Kinetic', 'Potential', 'Thermal'],
    correctAnswer: 'Chemical',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc38',
    question: 'What is the basic unit of temperature?',
    options: ['Kelvin', 'Celsius', 'Fahrenheit', 'Rankine'],
    correctAnswer: 'Kelvin',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc39',
    question: 'Which branch of science studies matter and energy?',
    options: ['Physics', 'Chemistry', 'Biology', 'Geology'],
    correctAnswer: 'Physics',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc40',
    question: 'What is the process by which liquid changes into gas?',
    options: ['Evaporation', 'Condensation', 'Sublimation', 'Freezing'],
    correctAnswer: 'Evaporation',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc41',
    question: 'What is the study of insects called?',
    options: ['Entomology', 'Zoology', 'Ornithology', 'Herpetology'],
    correctAnswer: 'Entomology',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc42',
    question: 'Which planet has the Great Red Spot?',
    options: ['Jupiter', 'Mars', 'Venus', 'Saturn'],
    correctAnswer: 'Jupiter',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc43',
    question: 'What is the powerhouse of the cell?',
    options: ['Mitochondria', 'Nucleus', 'Golgi Body', 'Endoplasmic Reticulum'],
    correctAnswer: 'Mitochondria',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc44',
    question: 'What is the unit of measurement for electric current?',
    options: ['Ampere', 'Volt', 'Watt', 'Ohm'],
    correctAnswer: 'Ampere',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc45',
    question: 'Which gas do plants absorb from the atmosphere?',
    options: ['Carbon Dioxide', 'Oxygen', 'Nitrogen', 'Hydrogen'],
    correctAnswer: 'Carbon Dioxide',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc46',
    question: 'What is the smallest unit of an element that maintains its properties?',
    options: ['Atom', 'Molecule', 'Cell', 'Electron'],
    correctAnswer: 'Atom',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc47',
    question: 'Which blood vessels carry blood away from the heart?',
    options: ['Arteries', 'Veins', 'Capillaries', 'Venules'],
    correctAnswer: 'Arteries',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc48',
    question: 'What is the study of the brain called?',
    options: ['Neurology', 'Cardiology', 'Psychology', 'Physiology'],
    correctAnswer: 'Neurology',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc49',
    question: 'Which element has the atomic number 1?',
    options: ['Hydrogen', 'Helium', 'Lithium', 'Carbon'],
    correctAnswer: 'Hydrogen',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc50',
    question: 'What is the name for a young kangaroo?',
    options: ['Joey', 'Cub', 'Kit', 'Pup'],
    correctAnswer: 'Joey',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc51',
    question: 'What is the SI unit of force?',
    options: ['Newton', 'Joule', 'Pascal', 'Watt'],
    correctAnswer: 'Newton',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc52',
    question: 'Which planet is known as the Ice Giant?',
    options: ['Uranus', 'Neptune', 'Saturn', 'Pluto'],
    correctAnswer: 'Uranus',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc53',
    question: 'What is the study of rocks called?',
    options: ['Geology', 'Geography', 'Oceanography', 'Meteorology'],
    correctAnswer: 'Geology',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc54',
    question: 'Which organ helps regulate body temperature?',
    options: ['Skin', 'Heart', 'Kidney', 'Liver'],
    correctAnswer: 'Skin',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc55',
    question: 'What is the primary component of Earth\'s core?',
    options: ['Iron', 'Nickel', 'Gold', 'Copper'],
    correctAnswer: 'Iron',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc56',
    question: 'Which gas is used by plants for photosynthesis?',
    options: ['Carbon Dioxide', 'Oxygen', 'Nitrogen', 'Hydrogen'],
    correctAnswer: 'Carbon Dioxide',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc57',
    question: 'What is the process of converting sugar into energy called?',
    options: ['Cellular Respiration', 'Photosynthesis', 'Fermentation', 'Digestion'],
    correctAnswer: 'Cellular Respiration',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc58',
    question: 'Which animal has the longest lifespan?',
    options: ['Greenland Shark', 'Giant Tortoise', 'Bowhead Whale', 'African Elephant'],
    correctAnswer: 'Greenland Shark',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc59',
    question: 'What is the unit of measurement for sound intensity?',
    options: ['Decibel', 'Hertz', 'Watt', 'Joule'],
    correctAnswer: 'Decibel',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc60',
    question: 'Which tissue connects muscle to bone?',
    options: ['Tendon', 'Ligament', 'Cartilage', 'Fascia'],
    correctAnswer: 'Tendon',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc61',
    question: 'What is the hardest material produced by the human body?',
    options: ['Tooth Enamel', 'Bone', 'Cartilage', 'Nail'],
    correctAnswer: 'Tooth Enamel',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc62',
    question: 'Which planet rotates clockwise?',
    options: ['Venus', 'Mars', 'Mercury', 'Jupiter'],
    correctAnswer: 'Venus',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc63',
    question: 'What is the study of fungi called?',
    options: ['Mycology', 'Biology', 'Botany', 'Zoology'],
    correctAnswer: 'Mycology',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc64',
    question: 'Which element is most abundant in Earth\'s crust?',
    options: ['Oxygen', 'Silicon', 'Aluminum', 'Iron'],
    correctAnswer: 'Oxygen',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc65',
    question: 'What is the fastest bird in the world?',
    options: ['Peregrine Falcon', 'Golden Eagle', 'Swift', 'Ostrich'],
    correctAnswer: 'Peregrine Falcon',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc66',
    question: 'Which type of rock is formed by cooling magma?',
    options: ['Igneous', 'Sedimentary', 'Metamorphic', 'Composite'],
    correctAnswer: 'Igneous',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc67',
    question: 'What is the study of human populations called?',
    options: ['Demography', 'Sociology', 'Anthropology', 'Psychology'],
    correctAnswer: 'Demography',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc68',
    question: 'Which organ produces bile?',
    options: ['Liver', 'Stomach', 'Pancreas', 'Gallbladder'],
    correctAnswer: 'Liver',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc69',
    question: 'What is the smallest planet in our solar system?',
    options: ['Mercury', 'Mars', 'Pluto', 'Venus'],
    correctAnswer: 'Mercury',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc70',
    question: 'Which gas makes up about 78% of Earth\'s atmosphere?',
    options: ['Nitrogen', 'Oxygen', 'Carbon Dioxide', 'Argon'],
    correctAnswer: 'Nitrogen',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc71',
    question: 'What is the study of weather patterns over time called?',
    options: ['Climatology', 'Meteorology', 'Geography', 'Geology'],
    correctAnswer: 'Climatology',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc72',
    question: 'Which animal has blue blood?',
    options: ['Horseshoe Crab', 'Octopus', 'Lobster', 'All of these'],
    correctAnswer: 'All of these',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc73',
    question: 'What is the unit of measurement for frequency?',
    options: ['Hertz', 'Watt', 'Volt', 'Ampere'],
    correctAnswer: 'Hertz',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc74',
    question: 'Which element is required for nuclear fusion in stars?',
    options: ['Hydrogen', 'Helium', 'Carbon', 'Oxygen'],
    correctAnswer: 'Hydrogen',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc75',
    question: 'What is the study of blood called?',
    options: ['Hematology', 'Cardiology', 'Neurology', 'Oncology'],
    correctAnswer: 'Hematology',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc76',
    question: 'Which vitamin is essential for blood clotting?',
    options: ['Vitamin K', 'Vitamin C', 'Vitamin D', 'Vitamin E'],
    correctAnswer: 'Vitamin K',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc77',
    question: 'What is the largest species of penguin?',
    options: ['Emperor Penguin', 'King Penguin', 'Gentoo Penguin', 'Royal Penguin'],
    correctAnswer: 'Emperor Penguin',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc78',
    question: 'Which molecule stores genetic information?',
    options: ['DNA', 'RNA', 'Protein', 'Lipid'],
    correctAnswer: 'DNA',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc79',
    question: 'What is the unit of electrical potential difference?',
    options: ['Volt', 'Ampere', 'Ohm', 'Watt'],
    correctAnswer: 'Volt',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc80',
    question: 'Which gland regulates metabolism?',
    options: ['Thyroid', 'Pituitary', 'Adrenal', 'Pancreas'],
    correctAnswer: 'Thyroid',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc81',
    question: 'What is the study of stars called?',
    options: ['Astronomy', 'Astrology', 'Cosmology', 'Astrophysics'],
    correctAnswer: 'Astronomy',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc82',
    question: 'Which is the most abundant protein in the human body?',
    options: ['Collagen', 'Keratin', 'Elastin', 'Actin'],
    correctAnswer: 'Collagen',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc83',
    question: 'What is the smallest unit of digital information?',
    options: ['Bit', 'Byte', 'Kilobyte', 'Megabyte'],
    correctAnswer: 'Bit',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc84',
    question: 'Which planet has the strongest magnetic field?',
    options: ['Jupiter', 'Earth', 'Saturn', 'Neptune'],
    correctAnswer: 'Jupiter',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc85',
    question: 'What is the study of the atmosphere called?',
    options: ['Meteorology', 'Geology', 'Hydrology', 'Oceanography'],
    correctAnswer: 'Meteorology',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc86',
    question: 'Which bone is the longest in the human body?',
    options: ['Femur', 'Tibia', 'Humerus', 'Fibula'],
    correctAnswer: 'Femur',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc87',
    question: 'What is the most electronegative element?',
    options: ['Fluorine', 'Oxygen', 'Chlorine', 'Nitrogen'],
    correctAnswer: 'Fluorine',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc88',
    question: 'What is the study of cells called?',
    options: ['Cytology', 'Histology', 'Physiology', 'Biology'],
    correctAnswer: 'Cytology',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc89',
    question: 'Which hormone is known as the "fight or flight" hormone?',
    options: ['Adrenaline', 'Insulin', 'Thyroxine', 'Testosterone'],
    correctAnswer: 'Adrenaline',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc90',
    question: 'What is the main component of natural gas?',
    options: ['Methane', 'Ethane', 'Propane', 'Butane'],
    correctAnswer: 'Methane',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc91',
    question: 'Which particle has a positive charge?',
    options: ['Proton', 'Electron', 'Neutron', 'Positron'],
    correctAnswer: 'Proton',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc92',
    question: 'What is the study of sound called?',
    options: ['Acoustics', 'Optics', 'Dynamics', 'Statics'],
    correctAnswer: 'Acoustics',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc93',
    question: 'Which is the largest species of shark?',
    options: ['Whale Shark', 'Great White Shark', 'Tiger Shark', 'Hammerhead Shark'],
    correctAnswer: 'Whale Shark',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc94',
    question: 'What is the chemical formula for sulfuric acid?',
    options: ['H2SO4', 'HCl', 'HNO3', 'H3PO4'],
    correctAnswer: 'H2SO4',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc95',
    question: 'Which organ produces red blood cells?',
    options: ['Bone Marrow', 'Liver', 'Spleen', 'Thymus'],
    correctAnswer: 'Bone Marrow',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc96',
    question: 'What is the unit of luminous intensity?',
    options: ['Candela', 'Lumen', 'Lux', 'Watt'],
    correctAnswer: 'Candela',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc97',
    question: 'Which gas is used in fluorescent lights?',
    options: ['Mercury Vapor', 'Neon', 'Argon', 'Xenon'],
    correctAnswer: 'Mercury Vapor',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc98',
    question: 'What is the study of birds called?',
    options: ['Ornithology', 'Zoology', 'Entomology', 'Herpetology'],
    correctAnswer: 'Ornithology',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc99',
    question: 'Which blood type is the universal recipient?',
    options: ['AB Positive', 'O Negative', 'A Positive', 'B Positive'],
    correctAnswer: 'AB Positive',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc100',
    question: 'What is the chemical symbol for tungsten?',
    options: ['W', 'Tu', 'Tn', 'Tg'],
    correctAnswer: 'W',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc101',
    question: 'Which planet has the most visible rings?',
    options: ['Saturn', 'Uranus', 'Jupiter', 'Neptune'],
    correctAnswer: 'Saturn',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc102',
    question: 'What is the smallest unit of light?',
    options: ['Photon', 'Electron', 'Proton', 'Neutron'],
    correctAnswer: 'Photon',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc103',
    question: 'Which element is essential for making steel?',
    options: ['Carbon', 'Silicon', 'Sulfur', 'Phosphorus'],
    correctAnswer: 'Carbon',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc104',
    question: 'What is the study of the kidneys called?',
    options: ['Nephrology', 'Cardiology', 'Neurology', 'Hepatology'],
    correctAnswer: 'Nephrology',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc105',
    question: 'Which animal has the best hearing?',
    options: ['Greater Wax Moth', 'Bat', 'Dog', 'Cat'],
    correctAnswer: 'Greater Wax Moth',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc106',
    question: 'What is the study of time called?',
    options: ['Chronology', 'Horology', 'Timeology', 'Chronometry'],
    correctAnswer: 'Chronology',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc107',
    question: 'Which element has the highest melting point?',
    options: ['Tungsten', 'Carbon', 'Platinum', 'Titanium'],
    correctAnswer: 'Tungsten',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc108',
    question: 'What is the largest internal organ in the human body?',
    options: ['Liver', 'Brain', 'Lungs', 'Heart'],
    correctAnswer: 'Liver',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc109',
    question: 'Which scientist discovered penicillin?',
    options: ['Alexander Fleming', 'Louis Pasteur', 'Robert Koch', 'Joseph Lister'],
    correctAnswer: 'Alexander Fleming',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc110',
    question: 'What is the study of fish called?',
    options: ['Ichthyology', 'Marine Biology', 'Aquatics', 'Oceanography'],
    correctAnswer: 'Ichthyology',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc111',
    question: 'Which metal is the best conductor of electricity?',
    options: ['Silver', 'Copper', 'Gold', 'Aluminum'],
    correctAnswer: 'Silver',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc112',
    question: 'What is the study of microscopic organisms called?',
    options: ['Microbiology', 'Zoology', 'Botany', 'Ecology'],
    correctAnswer: 'Microbiology',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc113',
    question: 'Which gas is known as laughing gas?',
    options: ['Nitrous Oxide', 'Carbon Dioxide', 'Nitrogen', 'Helium'],
    correctAnswer: 'Nitrous Oxide',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc114',
    question: 'What is the hardest naturally occurring mineral after diamond?',
    options: ['Corundum', 'Topaz', 'Quartz', 'Ruby'],
    correctAnswer: 'Corundum',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc115',
    question: 'Which part of the brain controls balance?',
    options: ['Cerebellum', 'Cerebrum', 'Medulla', 'Pons'],
    correctAnswer: 'Cerebellum',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc116',
    question: 'What is the study of the liver called?',
    options: ['Hepatology', 'Cardiology', 'Neurology', 'Nephrology'],
    correctAnswer: 'Hepatology',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc117',
    question: 'Which element is a liquid at room temperature?',
    options: ['Mercury', 'Iron', 'Copper', 'Lead'],
    correctAnswer: 'Mercury',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc118',
    question: 'What is the study of bones called?',
    options: ['Osteology', 'Orthopedics', 'Anatomy', 'Physiology'],
    correctAnswer: 'Osteology',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc119',
    question: 'Which gas is responsible for the ozone layer?',
    options: ['Oxygen', 'Nitrogen', 'Carbon Dioxide', 'Helium'],
    correctAnswer: 'Oxygen',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc120',
    question: 'What is the study of blood pressure called?',
    options: ['Sphygmology', 'Cardiology', 'Hematology', 'Angiology'],
    correctAnswer: 'Sphygmology',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc121',
    question: 'Which is the most reactive element?',
    options: ['Fluorine', 'Sodium', 'Chlorine', 'Potassium'],
    correctAnswer: 'Fluorine',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc122',
    question: 'What is the study of heredity called?',
    options: ['Genetics', 'Biology', 'Evolution', 'Genomics'],
    correctAnswer: 'Genetics',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc123',
    question: 'Which vitamin is produced by bacteria in the intestines?',
    options: ['Vitamin K', 'Vitamin C', 'Vitamin D', 'Vitamin A'],
    correctAnswer: 'Vitamin K',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc124',
    question: 'What is the study of the heart called?',
    options: ['Cardiology', 'Neurology', 'Hematology', 'Nephrology'],
    correctAnswer: 'Cardiology',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc125',
    question: 'Which is the most abundant metal in Earth\'s crust?',
    options: ['Aluminum', 'Iron', 'Copper', 'Zinc'],
    correctAnswer: 'Aluminum',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc126',
    question: 'What is the study of weather called?',
    options: ['Meteorology', 'Climatology', 'Atmosphere Science', 'Environmental Science'],
    correctAnswer: 'Meteorology',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc127',
    question: 'Which organ produces melatonin?',
    options: ['Pineal Gland', 'Pituitary Gland', 'Thyroid Gland', 'Adrenal Gland'],
    correctAnswer: 'Pineal Gland',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc128',
    question: 'What is the study of plants called?',
    options: ['Botany', 'Zoology', 'Biology', 'Ecology'],
    correctAnswer: 'Botany',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc129',
    question: 'Which planet has the longest day?',
    options: ['Venus', 'Mars', 'Jupiter', 'Mercury'],
    correctAnswer: 'Venus',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc130',
    question: 'What is the study of fossils called?',
    options: ['Paleontology', 'Archaeology', 'Geology', 'Anthropology'],
    correctAnswer: 'Paleontology',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc131',
    question: 'Which animal has the most complex eyes?',
    options: ['Mantis Shrimp', 'Eagle', 'Octopus', 'Chameleon'],
    correctAnswer: 'Mantis Shrimp',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc132',
    question: 'What is the study of volcanoes called?',
    options: ['Volcanology', 'Geology', 'Seismology', 'Tectonics'],
    correctAnswer: 'Volcanology',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc133',
    question: 'Which element is the main component of pearls?',
    options: ['Calcium Carbonate', 'Sodium Chloride', 'Silicon Dioxide', 'Magnesium Oxide'],
    correctAnswer: 'Calcium Carbonate',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc134',
    question: 'What is the speed of sound in air at sea level?',
    options: ['343 m/s', '300 m/s', '400 m/s', '500 m/s'],
    correctAnswer: '343 m/s',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc135',
    question: 'Which animal has the highest blood pressure?',
    options: ['Giraffe', 'Elephant', 'Blue Whale', 'Horse'],
    correctAnswer: 'Giraffe',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc136',
    question: 'What is the study of human races called?',
    options: ['Anthropology', 'Sociology', 'Ethnology', 'Psychology'],
    correctAnswer: 'Anthropology',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc137',
    question: 'Which element has the chemical symbol "Pb"?',
    options: ['Lead', 'Platinum', 'Palladium', 'Phosphorus'],
    correctAnswer: 'Lead',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc138',
    question: 'What is the smallest known particle of matter?',
    options: ['Quark', 'Atom', 'Electron', 'Proton'],
    correctAnswer: 'Quark',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc139',
    question: 'Which gland is known as the "master gland"?',
    options: ['Pituitary', 'Thyroid', 'Adrenal', 'Pineal'],
    correctAnswer: 'Pituitary',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc140',
    question: 'What is the study of ancient life forms called?',
    options: ['Paleontology', 'Archaeology', 'Biology', 'Anthropology'],
    correctAnswer: 'Paleontology',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc141',
    question: 'Which gas makes up the majority of Jupiters atmosphere?',
    options: ['Hydrogen', 'Helium', 'Nitrogen', 'Oxygen'],
    correctAnswer: 'Hydrogen',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc142',
    question: 'What is the study of the nervous system called?',
    options: ['Neurology', 'Cardiology', 'Psychology', 'Physiology'],
    correctAnswer: 'Neurology',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc143',
    question: 'Which particle has the same mass as a proton but no charge?',
    options: ['Neutron', 'Electron', 'Positron', 'Photon'],
    correctAnswer: 'Neutron',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc144',
    question: 'What is the largest artery in the human body?',
    options: ['Aorta', 'Carotid', 'Femoral', 'Pulmonary'],
    correctAnswer: 'Aorta',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc145',
    question: 'Which force holds atoms together in a molecule?',
    options: ['Covalent Bond', 'Gravity', 'Magnetism', 'Friction'],
    correctAnswer: 'Covalent Bond',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc146',
    question: 'What is the study of maps called?',
    options: ['Cartography', 'Geography', 'Topography', 'Geology'],
    correctAnswer: 'Cartography',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc147',
    question: 'Which element is used in making computer chips?',
    options: ['Silicon', 'Carbon', 'Gold', 'Copper'],
    correctAnswer: 'Silicon',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc148',
    question: 'What is the study of animal behavior called?',
    options: ['Ethology', 'Zoology', 'Biology', 'Psychology'],
    correctAnswer: 'Ethology',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc149',
    question: 'Which planet has the strongest surface winds?',
    options: ['Neptune', 'Jupiter', 'Saturn', 'Uranus'],
    correctAnswer: 'Neptune',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc150',
    question: 'What is the study of radiation called?',
    options: ['Radiology', 'Physics', 'Chemistry', 'Nuclear Science'],
    correctAnswer: 'Radiology',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc151',
    question: 'Which organ in the human body produces antibodies?',
    options: ['Spleen', 'Liver', 'Kidney', 'Heart'],
    correctAnswer: 'Spleen',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc152',
    question: 'What is the study of the ear and its diseases called?',
    options: ['Otology', 'Audiology', 'Neurology', 'Cardiology'],
    correctAnswer: 'Otology',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc153',
    question: 'Which chemical element has the lowest boiling point?',
    options: ['Helium', 'Hydrogen', 'Nitrogen', 'Oxygen'],
    correctAnswer: 'Helium',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc154',
    question: 'What is the study of diseases called?',
    options: ['Pathology', 'Biology', 'Virology', 'Immunology'],
    correctAnswer: 'Pathology',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc155',
    question: 'Which animal has the largest brain relative to body size?',
    options: ['Ant', 'Human', 'Dolphin', 'Elephant'],
    correctAnswer: 'Ant',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc156',
    question: 'What is the study of the atmosphere called?',
    options: ['Atmospheric Science', 'Meteorology', 'Climatology', 'Geography'],
    correctAnswer: 'Atmospheric Science',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc157',
    question: 'Which is the most abundant isotope of hydrogen?',
    options: ['Protium', 'Deuterium', 'Tritium', 'Hydrogen-4'],
    correctAnswer: 'Protium',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc158',
    question: 'What is the study of the skin called?',
    options: ['Dermatology', 'Epidemiology', 'Histology', 'Physiology'],
    correctAnswer: 'Dermatology',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc159',
    question: 'Which planet rotates fastest on its axis?',
    options: ['Jupiter', 'Saturn', 'Mars', 'Venus'],
    correctAnswer: 'Jupiter',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc160',
    question: 'What is the study of teeth called?',
    options: ['Dentistry', 'Orthodontics', 'Endodontics', 'Periodontics'],
    correctAnswer: 'Dentistry',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc161',
    question: 'Which metal is used in making light bulb filaments?',
    options: ['Tungsten', 'Copper', 'Iron', 'Nickel'],
    correctAnswer: 'Tungsten',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc162',
    question: 'What is the study of wine making called?',
    options: ['Oenology', 'Viticulture', 'Fermentation', 'Brewing'],
    correctAnswer: 'Oenology',
    difficulty: 'hard',
    category: 'Science'
  },
  {
    id: 'sc163',
    question: 'Which vitamin helps in blood clotting?',
    options: ['Vitamin K', 'Vitamin C', 'Vitamin D', 'Vitamin E'],
    correctAnswer: 'Vitamin K',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc164',
    question: 'What is the study of metals called?',
    options: ['Metallurgy', 'Chemistry', 'Physics', 'Engineering'],
    correctAnswer: 'Metallurgy',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc165',
    question: 'Which animal has the highest body temperature?',
    options: ['Bird', 'Mammal', 'Reptile', 'Amphibian'],
    correctAnswer: 'Bird',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc166',
    question: 'What is the study of poisons called?',
    options: ['Toxicology', 'Pharmacology', 'Chemistry', 'Biology'],
    correctAnswer: 'Toxicology',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc167',
    question: 'Which element is most commonly found in organic compounds?',
    options: ['Carbon', 'Hydrogen', 'Oxygen', 'Nitrogen'],
    correctAnswer: 'Carbon',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc168',
    question: 'What is the study of light called?',
    options: ['Optics', 'Physics', 'Photonics', 'Radiation'],
    correctAnswer: 'Optics',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc169',
    question: 'Which organ produces the hormone insulin?',
    options: ['Pancreas', 'Liver', 'Kidney', 'Thyroid'],
    correctAnswer: 'Pancreas',
    difficulty: 'easy',
    category: 'Science'
  },
  {
    id: 'sc170',
    question: 'What is the study of the universe called?',
    options: ['Cosmology', 'Astronomy', 'Astrophysics', 'Physics'],
    correctAnswer: 'Cosmology',
    difficulty: 'medium',
    category: 'Science'
  },
  {
    id: 'sc171',
    question: 'Which is the most abundant metal in the Earth\'s crust?',
    options: ['Aluminum', 'Iron', 'Calcium', 'Sodium'],
    correctAnswer: 'Aluminum',
    difficulty: 'medium',
    category: 'Science'
  }
];
