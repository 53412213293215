/**
 * Shuffles an array using the Fisher-Yates algorithm
 */
export function shuffleArray<T>(array: T[]): T[] {
  if (!array || array.length === 0) {
    return [];
  }
  
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
}

/**
 * Filters and shuffles game items by category
 */
export function getShuffledGameItems<T extends { category?: string }>(
  items: T[],
  category?: string
): T[] {
  if (!items || items.length === 0) {
    console.error('No items provided to shuffle');
    return [];
  }

  if (!category || category === 'Mix' || !('category' in items[0])) {
    return shuffleArray(items);
  }

  const filteredItems = items.filter(item => item.category === category);
  if (filteredItems.length === 0) {
    console.error('No items found for category:', category);
    return shuffleArray(items); // Fallback to all items if category is empty
  }

  return shuffleArray(filteredItems);
}

/**
 * Initializes team hints for word scramble game
 */
export function initializeTeamHints(teams: string[], hintsPerTeam: number = 2): { [key: string]: number } {
  if (!teams || teams.length === 0) {
    return {};
  }
  
  return teams.reduce((acc, team) => ({
    ...acc,
    [team]: hintsPerTeam
  }), {});
}