import { useState } from 'react';
import { supabase } from '@/lib/supabase';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

interface AccessCodeFormProps {
  onAccessGranted: (accessCode: string) => void;
}

export function AccessCodeForm({ onAccessGranted }: AccessCodeFormProps) {
  const [accessCode, setAccessCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      const { data, error: dbError } = await supabase
        .from('users')
        .select('*')
        .eq('access_code', accessCode.toUpperCase())
        .single();

      if (dbError || !data) {
        throw new Error('Invalid access code');
      }

      if (!data.has_active_subscription) {
        throw new Error('This access code is no longer valid');
      }

      onAccessGranted(accessCode.toUpperCase());
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to verify access code');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto text-center space-y-4">
      <h3 className="text-lg font-semibold">Already have an access code?</h3>
      <form onSubmit={handleSubmit} className="space-y-4">
        <Input
          type="text"
          placeholder="Enter your access code"
          value={accessCode}
          onChange={(e) => setAccessCode(e.target.value)}
          className="text-center uppercase"
          maxLength={8}
        />
        {error && <p className="text-sm text-red-500">{error}</p>}
        <Button
          type="submit"
          className="btn-secondary w-full"
          disabled={isLoading || accessCode.length !== 8}
        >
          {isLoading ? 'Verifying...' : 'Submit'}
        </Button>
      </form>
    </div>
  );
}