import { Loader2 } from 'lucide-react';

export function PaymentLoading() {
  return (
    <div className="min-h-screen pattern-bg flex items-center justify-center p-4">
      <div className="game-card p-8 text-center max-w-md w-full">
        <Loader2 className="w-12 h-12 animate-spin mx-auto mb-4 text-[#FFD233]" />
        <h2 className="text-2xl font-bold mb-2">Processing Payment</h2>
        <p className="text-black/70">Please wait while we verify your payment...</p>
      </div>
    </div>
  );
}