import { useState, useEffect } from 'react';
import { useLeaderboard } from '@/hooks/useLeaderboard';
import { useToast } from '@/hooks/use-toast';

interface UseGameLogicProps<T> {
  items: T[];
  teams: string[];
  pointsToWin: number;
}

export function useGameLogic<T>({ items, teams, pointsToWin }: UseGameLogicProps<T>) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentTeamIndex, setCurrentTeamIndex] = useState(0);
  const [scores, setScores] = useState<number[]>([]);
  const [hasWinner, setHasWinner] = useState(false);
  const [winningTeam, setWinningTeam] = useState<string | null>(null);
  const { updateWins } = useLeaderboard();
  const { toast } = useToast();

  // Initialize or update scores when teams change
  useEffect(() => {
    setScores(new Array(teams.length).fill(0));
    setHasWinner(false);
    setWinningTeam(null);
  }, [teams]);

  const handleNext = () => {
    if (hasWinner) return;
    
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    setCurrentTeamIndex((prevIndex) => (prevIndex + 1) % teams.length);
  };

  const handleScorePoint = async () => {
    if (hasWinner) return;

    const newScores = [...scores];
    newScores[currentTeamIndex] += 1;
    setScores(newScores);
    
    if (newScores[currentTeamIndex] >= pointsToWin) {
      const winner = teams[currentTeamIndex];
      setHasWinner(true);
      setWinningTeam(winner);
      
      try {
        await updateWins(winner);
        toast({
          title: "Leaderboard Updated",
          description: `${winner}'s win has been recorded!`,
          duration: 3000
        });
      } catch (error) {
        console.error('Failed to update leaderboard:', error);
        toast({
          title: "Update Failed",
          description: "Failed to update the leaderboard. Please try again.",
          variant: "destructive",
          duration: 3000
        });
      }
    }
  };

  return {
    currentIndex,
    currentTeamIndex,
    scores,
    hasWinner,
    winningTeam,
    handleNext,
    handleScorePoint
  };
}