import { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Word } from '@/data/words';
import { Timer } from '@/components/Timer';
import { SkipForward, Lightbulb } from 'lucide-react';
import { cn } from '@/lib/utils';

interface WordScrambleCardProps {
  word?: Word;
  onNextWord: () => void;
  onScorePoint: () => void;
  teamHints: { [key: string]: number };
  currentTeam: string;
  onUseHint: (team: string) => void;
}

export function WordScrambleCard({ 
  word, 
  onNextWord, 
  onScorePoint,
  teamHints,
  currentTeam,
  onUseHint
}: WordScrambleCardProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [guess, setGuess] = useState('');
  const [scrambledWord, setScrambledWord] = useState('');
  const [hasScored, setHasScored] = useState(false);
  const [showIncorrect, setShowIncorrect] = useState(false);
  const [hasAttempted, setHasAttempted] = useState(false);
  const [showHint, setShowHint] = useState(false);

  // Reset states when word changes
  useEffect(() => {
  console.log('Word changed effect running');
  if (!word) return;
  const letters = word.word.split('');
  for (let i = letters.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [letters[i], letters[j]] = [letters[j], letters[i]];
  }
  setScrambledWord(letters.join(''));
  setIsPlaying(true);
}, [word]);

useEffect(() => {
  if (!word) return;
  setGuess('');
  setHasScored(false);
  setShowIncorrect(false);
  setHasAttempted(false);
}, [word]);

  if (!word) {
    return (
      <div className="game-card p-8 text-center">
        <p className="text-lg text-black/70">Loading...</p>
      </div>
    );
  }

  const handleTimeUp = () => {
    setIsPlaying(false);
    setHasAttempted(true);
    setShowIncorrect(true);
    setTimeout(() => {
      handleNext();
    }, 1500);
  };

  const handleGuessSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (hasAttempted) return;
    
    setHasAttempted(true);
    if (guess.toUpperCase() === word.word.toUpperCase()) {
      setHasScored(true);
      onScorePoint();
    } else {
      setShowIncorrect(true);
      setTimeout(() => {
        handleNext();
      }, 1500);
    }
  };

  const handleNext = () => {
    console.log('Next word called');
    setIsPlaying(false);
    setGuess('');
    setHasScored(false);
    setShowIncorrect(false);
    setHasAttempted(false);
    setShowHint(false);
    onNextWord();
  };

  const handleHintClick = () => {
    console.log('Hint clicked');
    if (teamHints[currentTeam] > 0) {
      console.log('Using hint for team:', currentTeam);
      setShowHint(true);
      onUseHint(currentTeam);
      setIsPlaying(true);
    }
  };

  const hintsRemaining = teamHints[currentTeam] || 0;

  return (
    <div className="game-card p-8 space-y-6">
      <div className="flex flex-wrap gap-3 justify-between items-center">
        <span className={cn(
          "difficulty-badge",
          word.difficulty === 'easy' ? 'difficulty-easy' :
          word.difficulty === 'medium' ? 'difficulty-medium' : 'difficulty-hard'
        )}>
          {word.difficulty}
        </span>
        <div className="flex items-center gap-3">
          <span className="genre-badge">{word.category}</span>
          {isPlaying && !hasAttempted && !showHint && hintsRemaining > 0 && (
            <Button
              onClick={handleHintClick}
              className="btn-secondary"
              size="sm"
            >
              <Lightbulb className="mr-2 h-4 w-4" />
              Use Hint ({hintsRemaining})
            </Button>
          )}
        </div>
      </div>

      <div className="min-h-[240px] flex flex-col items-center justify-center gap-6 p-6">
        <div className="text-4xl font-bold tracking-wider">{scrambledWord}</div>
        
        {showHint && (
          <div className="text-lg font-medium text-black/70 animate-fade-in">
            Hint: {word.hint}
          </div>
        )}
        
        {isPlaying && !hasAttempted && (
          <form onSubmit={handleGuessSubmit} className="w-full max-w-sm">
            <Input
              value={guess}
              onChange={(e) => setGuess(e.target.value)}
              placeholder="Type your answer..."
              className="text-center text-xl"
              autoFocus
            />
          </form>
        )}
        
        {hasScored && (
          <div className="text-2xl font-bold text-green-600">Correct!</div>
        )}
        {showIncorrect && (
          <div className="text-2xl font-bold text-red-600">
            Incorrect! The word was: {word.word}
          </div>
        )}
      </div>

      <div className="space-y-6">
        <Timer
          duration={30}
          isRunning={isPlaying}
          onTimeUp={handleTimeUp}
        />

        <div className="flex flex-wrap gap-4 justify-center">
          {(hasScored || (!isPlaying && hasAttempted)) && (
            <Button
              onClick={handleNext}
              className="btn-secondary min-w-[200px]"
            >
              <SkipForward className="mr-2 h-5 w-5" />
              Next Word
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}