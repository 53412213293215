export interface Word {
  id: string;
  word: string;
  hint: string;
  difficulty: 'easy' | 'medium' | 'hard';
  category: string;
}

export const words: Word[] = [
  {
  id: '1',
  word: 'PLATYPUS',
  hint: 'Duck-billed mammal',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '2',
  word: 'KETCHUP',
  hint: 'Tomato condiment',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '3',
  word: 'LIGHTHOUSE',
  hint: 'Ship warning tower',
  difficulty: 'easy',
  category: 'Place'
  },
  
  {
  id: '4',
  word: 'MUSTARD',
  hint: 'Yellow hot sauce',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '5',
  word: 'NARWHAL',
  hint: 'Arctic whale unicorn',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '6',
  word: 'OSTRICH',
  hint: 'Flightless tall bird',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '7',
  word: 'PINWHEEL',
  hint: 'Spinning toy',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '8',
  word: 'QUARRY',
  hint: 'Stone mining pit',
  difficulty: 'easy',
  category: 'Place'
  },
  
  {
  id: '9',
  word: 'RAINDROP',
  hint: 'Water from clouds',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '10',
  word: 'SANDCASTLE',
  hint: 'Beach structure',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '11',
  word: 'TORTOISE',
  hint: 'Land turtle',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '12',
  word: 'UMBRELLA',
  hint: 'Rain shield',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '13',
  word: 'WALRUS',
  hint: 'Tusked sea mammal',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '14',
  word: 'ZUCCHINI',
  hint: 'Green squash',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '15',
  word: 'ARMCHAIR',
  hint: 'Comfortable seat',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '16',
  word: 'BEGONIA',
  hint: 'Flowering plant',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '17',
  word: 'CRANBERRY',
  hint: 'Red tart fruit',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '18',
  word: 'DAFFODIL',
  hint: 'Spring yellow flower',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '19',
  word: 'EGGPLANT',
  hint: 'Purple vegetable',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '20',
  word: 'FIREFLY',
  hint: 'Glowing insect',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '21',
  word: 'GOLDFISH',
  hint: 'Orange pet fish',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '22',
  word: 'HONEYBEE',
  hint: 'Makes sweet food',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '23',
  word: 'ICEBERG',
  hint: 'Floating ice mass',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '24',
  word: 'JASMINE',
  hint: 'Fragrant flower',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '25',
  word: 'KEYBOARD',
  hint: 'Typing device',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '26',
  word: 'LADYBUG',
  hint: 'Spotted beetle',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '27',
  word: 'MARACAS',
  hint: 'Shaking instrument',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '28',
  word: 'NOODLES',
  hint: 'Long pasta food',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '29',
  word: 'ORIGAMI',
  hint: 'Paper folding art',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '30',
  word: 'POPCORN',
  hint: 'Movie snack',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '31',
  word: 'QUOKKA',
  hint: 'Smiling marsupial',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '32',
  word: 'RACCOON',
  hint: 'Masked night animal',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '33',
  word: 'SEAWEED',
  hint: 'Ocean plant',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '34',
  word: 'TEACUP',
  hint: 'Hot drink holder',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '35',
  word: 'UKULELE',
  hint: 'Small guitar',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '36',
  word: 'VULTURE',
  hint: 'Scavenging bird',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '37',
  word: 'WASABI',
  hint: 'Green spicy paste',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '38',
  word: 'XYLOPHONE',
  hint: 'Metal bar music',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '39',
  word: 'YACHT',
  hint: 'Luxury boat',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '40',
  word: 'ZAMBONI',
  hint: 'Ice rink smoother',
  difficulty: 'easy',
  category: 'Thing'
  },
  {
  id: '41',
  word: 'WHISTLE',
  hint: 'Makes a sharp sound when blown',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '42',
  word: 'OCTOPUS',
  hint: 'Sea creature with eight arms',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '43',
  word: 'LIBRARY',
  hint: 'Building full of books',
  difficulty: 'easy',
  category: 'Place'
  },
  
  {
  id: '44',
  word: 'TRUMPET',
  hint: 'Brass musical instrument',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '45',
  word: 'VOLCANO',
  hint: 'Mountain that can erupt',
  difficulty: 'easy',
  category: 'Place'
  },
  
  {
  id: '46',
  word: 'LIGHTNING',
  hint: 'Flash of electricity in sky',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '47',
  word: 'FEATHER',
  hint: 'Light covering on birds',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '48',
  word: 'ALLIGATOR',
  hint: 'Large reptile with strong jaws',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '49',
  word: 'CAMPFIRE',
  hint: 'Outdoor flame for warmth',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '50',
  word: 'TELESCOPE',
  hint: 'Device to view distant objects',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '51',
  word: 'MUSHROOM',
  hint: 'Grows from the ground',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '52',
  word: 'SUITCASE',
  hint: 'Container for travel items',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '53',
  word: 'CRYSTAL',
  hint: 'Natural geometric formation',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '54',
  word: 'DESSERT',
  hint: 'Sweet after-meal food',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '55',
  word: 'PEACOCK',
  hint: 'Bird with colorful tail',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '56',
  word: 'HAMMER',
  hint: 'Tool for hitting nails',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '57',
  word: 'AIRPORT',
  hint: 'Where planes take off',
  difficulty: 'easy',
  category: 'Place'
  },
  
  {
  id: '58',
  word: 'RAINBOW',
  hint: 'Colorful arch after rain',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '59',
  word: 'MAGAZINE',
  hint: 'Periodic publication',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '60',
  word: 'DOLPHIN',
  hint: 'Smart sea mammal',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '61',
  word: 'BRACELET',
  hint: 'Worn around the wrist',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '62',
  word: 'FURNITURE',
  hint: 'Home items for use',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '63',
  word: 'PERFUME',
  hint: 'Scented liquid',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '64',
  word: 'SPIDER',
  hint: 'Eight-legged web maker',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '65',
  word: 'MANSION',
  hint: 'Very large house',
  difficulty: 'easy',
  category: 'Place'
  },
  
  {
  id: '66',
  word: 'COMPASS',
  hint: 'Shows direction',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '67',
  word: 'MAILBOX',
  hint: 'Receives postal items',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '68',
  word: 'SEASHELL',
  hint: 'Ocean creature home',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '69',
  word: 'KITCHEN',
  hint: 'Food preparation room',
  difficulty: 'easy',
  category: 'Place'
  },
  
  {
  id: '70',
  word: 'DIAMOND',
  hint: 'Precious clear stone',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '71',
  word: 'ZEBRA',
  hint: 'Striped African animal',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '72',
  word: 'PUZZLE',
  hint: 'Brain teasing game',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '73',
  word: 'ROCKET',
  hint: 'Space traveling vehicle',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '74',
  word: 'FOREST',
  hint: 'Area with many trees',
  difficulty: 'easy',
  category: 'Place'
  },
  
  {
  id: '75',
  word: 'MAGNET',
  hint: 'Attracts metal objects',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '76',
  word: 'CAMERA',
  hint: 'Takes photographs',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '77',
  word: 'CACTUS',
  hint: 'Desert plant with spines',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '78',
  word: 'TUNNEL',
  hint: 'Underground passage',
  difficulty: 'easy',
  category: 'Place'
  },
  
  {
  id: '79',
  word: 'GORILLA',
  hint: 'Large powerful primate',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '80',
  word: 'PILLOW',
  hint: 'Soft head support',
  difficulty: 'easy',
  category: 'Thing'
  },
  {
  id: '81',
  word: 'BANDAGE',
  hint: 'Covers wounds for healing',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '82',
  word: 'BEETLE',
  hint: 'Hard-shelled insect',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '83',
  word: 'CHIMNEY',
  hint: 'Smoke escape passage',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '84',
  word: 'DOLPHIN',
  hint: 'Playful ocean mammal',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '85',
  word: 'EYELASH',
  hint: 'Protects your eyes',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '86',
  word: 'FLAMINGO',
  hint: 'Pink wading bird',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '87',
  word: 'GARBAGE',
  hint: 'Waste to be thrown away',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '88',
  word: 'HAMSTER',
  hint: 'Small pet rodent',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '89',
  word: 'IGUANA',
  hint: 'Tropical lizard',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '90',
  word: 'JACUZZI',
  hint: 'Bubbling hot tub',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '91',
  word: 'KNUCKLE',
  hint: 'Finger joint',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '92',
  word: 'LANTERN',
  hint: 'Portable light source',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '93',
  word: 'MEADOW',
  hint: 'Grassy open field',
  difficulty: 'easy',
  category: 'Place'
  },
  
  {
  id: '94',
  word: 'NOSTRIL',
  hint: 'Nose breathing opening',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '95',
  word: 'ORCHARD',
  hint: 'Fruit tree garden',
  difficulty: 'easy',
  category: 'Place'
  },
  
  {
  id: '96',
  word: 'PRETZEL',
  hint: 'Twisted baked snack',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '97',
  word: 'QUICKSAND',
  hint: 'Dangerous sinking sand',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '98',
  word: 'RACCOON',
  hint: 'Masked nocturnal animal',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '99',
  word: 'SCARECROW',
  hint: 'Keeps birds away',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '100',
  word: 'TADPOLE',
  hint: 'Baby frog',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '101',
  word: 'UMBRELLA',
  hint: 'Rain protection tool',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '102',
  word: 'VAMPIRE',
  hint: 'Blood-drinking creature',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '103',
  word: 'WALRUS',
  hint: 'Arctic marine mammal',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '104',
  word: 'XYLOPHONE',
  hint: 'Musical percussion bars',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '105',
  word: 'YOGURT',
  hint: 'Fermented dairy food',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '106',
  word: 'ZIPPER',
  hint: 'Clothes fastener',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '107',
  word: 'ANCHOR',
  hint: 'Holds ship in place',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '108',
  word: 'BARNACLE',
  hint: 'Sea shell creature',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '109',
  word: 'CAVERN',
  hint: 'Natural underground room',
  difficulty: 'easy',
  category: 'Place'
  },
  
  {
  id: '110',
  word: 'DANDELION',
  hint: 'Wishmaking flower',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '111',
  word: 'EARRING',
  hint: 'Decorative ear jewelry',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '112',
  word: 'FEATHER',
  hint: 'Bird body covering',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '113',
  word: 'GLACIER',
  hint: 'Moving ice mass',
  difficulty: 'easy',
  category: 'Place'
  },
  
  {
  id: '114',
  word: 'HANDCUFF',
  hint: 'Wrist restraint',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '115',
  word: 'ICEBERG',
  hint: 'Floating ice mass',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '116',
  word: 'JELLYFISH',
  hint: 'Stinging sea creature',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '117',
  word: 'KENNEL',
  hint: 'Dog house',
  difficulty: 'easy',
  category: 'Place'
  },
  
  {
  id: '118',
  word: 'LEOPARD',
  hint: 'Spotted wild cat',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '119',
  word: 'MERMAID',
  hint: 'Half-fish human myth',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '120',
  word: 'NECKLACE',
  hint: 'Neck jewelry chain',
  difficulty: 'easy',
  category: 'Thing'
  },
    {
  id: '121',
  word: 'OCTAGON',
  hint: 'Eight-sided shape',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '122',
  word: 'PADLOCK',
  hint: 'Security locking device',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '123',
  word: 'QUIVER',
  hint: 'Arrow container',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '124',
  word: 'RAINBOW',
  hint: 'Colorful sky arc',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '125',
  word: 'SANDALS',
  hint: 'Open summer shoes',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '126',
  word: 'THUNDER',
  hint: 'Storm sound in sky',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '127',
  word: 'UNICORN',
  hint: 'Magical horned horse',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '128',
  word: 'VOLCANO',
  hint: 'Erupting mountain',
  difficulty: 'easy',
  category: 'Place'
  },
  
  {
  id: '129',
  word: 'WHISKER',
  hint: 'Animal facial hair',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '130',
  word: 'YARDSTICK',
  hint: 'Measuring tool',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '131',
  word: 'ACROBAT',
  hint: 'Circus performer',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '132',
  word: 'BANDAID',
  hint: 'Small wound cover',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '133',
  word: 'CRAYON',
  hint: 'Wax coloring stick',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '134',
  word: 'DOORBELL',
  hint: 'Entrance signal',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '135',
  word: 'ECLIPSE',
  hint: 'Sun or moon cover',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '136',
  word: 'FIREWORK',
  hint: 'Sky explosion show',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '137',
  word: 'GARGOYLE',
  hint: 'Stone building guard',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '138',
  word: 'HAMMOCK',
  hint: 'Hanging rest net',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '139',
  word: 'ICECREAM',
  hint: 'Frozen dairy treat',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '140',
  word: 'JUGGLER',
  hint: 'Throws objects up',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '141',
  word: 'KETTLE',
  hint: 'Water boiling pot',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '142',
  word: 'LOBSTER',
  hint: 'Red sea creature',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '143',
  word: 'MITTEN',
  hint: 'Winter hand cover',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '144',
  word: 'NAPKIN',
  hint: 'Mouth wiping cloth',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '145',
  word: 'OATMEAL',
  hint: 'Hot grain breakfast',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '146',
  word: 'PENGUIN',
  hint: 'Antarctic bird',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '147',
  word: 'QUARTER',
  hint: 'Twenty-five cents',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '148',
  word: 'RABBIT',
  hint: 'Hopping pet animal',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '149',
  word: 'SNOWMAN',
  hint: 'Winter ice figure',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '150',
  word: 'TEAPOT',
  hint: 'Hot drink server',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '151',
  word: 'UNICYCLE',
  hint: 'One-wheeled bike',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '152',
  word: 'VAMPIRE',
  hint: 'Night blood sucker',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '153',
  word: 'WAFFLE',
  hint: 'Grid breakfast food',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '154',
  word: 'XYLOPHONE',
  hint: 'Bar hitting music',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '155',
  word: 'YOGURT',
  hint: 'Cultured milk food',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '156',
  word: 'ZIPPER',
  hint: 'Teeth closer tool',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '157',
  word: 'ANVIL',
  hint: 'Metal work block',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '158',
  word: 'BUBBLE',
  hint: 'Floating air sphere',
  difficulty: 'easy',
  category: 'Thing'
  },
  
  {
  id: '159',
  word: 'CAMEL',
  hint: 'Desert transport',
  difficulty: 'easy',
  category: 'Living'
  },
  
  {
  id: '160',
  word: 'DUCKLING',
  hint: 'Baby water bird',
  difficulty: 'easy',
  category: 'Living'
  }
];