import { ScoreBoard } from '@/components/ScoreBoard';
import { GameCard } from '@/components/GameCard';
import { WordScrambleCard } from '@/components/WordScrambleCard';
import { TriviaCard } from '@/components/TriviaCard';
import { PictionaryCard } from '@/components/PictionaryCard';
import { WordProblemCard } from '@/components/WordProblemCard';
import { GameType } from '@/App';

interface GameBoardProps {
  gameType: GameType;
  teams: string[];
  scores: number[];
  currentTeamIndex: number;
  pointsToWin: number;
  currentItem: any;
  teamHints: { [key: string]: number };
  onNext: () => void;
  onScorePoint: () => void;
  onUseHint: (team: string) => void;
}

export function GameBoard({
  gameType,
  teams,
  scores,
  currentTeamIndex,
  pointsToWin,
  currentItem,
  teamHints,
  onNext,
  onScorePoint,
  onUseHint
}: GameBoardProps) {
  return (
    <div className="min-h-screen pattern-bg">
      <div className="max-w-4xl mx-auto px-4 py-8 space-y-8">
        <div className="grid gap-8">
          <ScoreBoard
            teams={teams}
            scores={scores}
            currentTeamIndex={currentTeamIndex}
            pointsToWin={pointsToWin}
          />
          
          {gameType === 'charades' && (
            <GameCard
              movie={currentItem}
              onNextMovie={onNext}
              onScorePoint={onScorePoint}
            />
          )}
          
          {gameType === 'pictionary' && (
            <PictionaryCard
              word={currentItem}
              onNextWord={onNext}
              onScorePoint={onScorePoint}
            />
          )}
          
          {gameType === 'scramble' && (
            <WordScrambleCard
              word={currentItem}
              onNextWord={onNext}
              onScorePoint={onScorePoint}
              teamHints={teamHints}
              currentTeam={teams[currentTeamIndex]}
              onUseHint={onUseHint}
            />
          )}
          
          {gameType === 'trivia' && (
            <TriviaCard
              question={currentItem}
              onNextQuestion={onNext}
              onScorePoint={onScorePoint}
            />
          )}

          {gameType === 'wordproblems' && (
            <WordProblemCard
              problem={currentItem}
              onNextProblem={onNext}
              onScorePoint={onScorePoint}
            />
          )}
        </div>
      </div>
    </div>
  );
}