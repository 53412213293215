// Custom error class for payment-related errors
export class PaymentError extends Error {
  constructor(
    message: string,
    public code?: string,
    public details?: unknown
  ) {
    super(message);
    this.name = 'PaymentError';
  }
}

export interface PaymentState {
  accessCode: string;
  error: string;
  isLoading: boolean;
  isExisting?: boolean;
}

export interface PaymentContextType extends PaymentState {
  // Add any additional payment-related methods here if needed
}

export interface PaymentSessionResult {
  accessCode: string;
  isExisting?: boolean;
}

// Common payment error types
export const PaymentErrors = {
  NO_SESSION: new PaymentError(
    'No session ID provided',
    'MISSING_SESSION_ID'
  ),
  INVALID_SESSION: new PaymentError(
    'Invalid session ID',
    'INVALID_SESSION_ID'
  ),
  DATABASE_ERROR: new PaymentError(
    'Database error occurred',
    'DATABASE_ERROR'
  )
} as const;