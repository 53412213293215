export interface Movie {
  id: string;
  title: string;
  year: number;
  difficulty: 'easy' | 'medium' | 'hard';
  genre: string[];
}

export const hollywoodMovies: Movie[] = [
  {
    id: 'h1',
    title: 'The Godfather',
    year: 1972,
    difficulty: 'medium',
    genre: ['Crime', 'Drama']
  },
  {
    id: 'h2',
    title: 'Pulp Fiction',
    year: 1994,
    difficulty: 'medium',
    genre: ['Crime', 'Drama']
  },
  {
    id: 'h3',
    title: 'The Dark Knight',
    year: 2008,
    difficulty: 'easy',
    genre: ['Action', 'Crime']
  },
  {
    id: 'h4',
    title: 'Forrest Gump',
    year: 1994,
    difficulty: 'easy',
    genre: ['Drama', 'Romance']
  },
  {
    id: 'h5',
    title: 'Inception',
    year: 2010,
    difficulty: 'medium',
    genre: ['Action', 'Sci-Fi']
  },
  {
    id: 'h6',
    title: 'Avatar',
    year: 2009,
    difficulty: 'easy',
    genre: ['Action', 'Sci-Fi']
  },
  {
    id: 'h7',
    title: 'The Avengers',
    year: 2012,
    difficulty: 'easy',
    genre: ['Action', 'Adventure']
  },
  {
    id: 'h8',
    title: 'Interstellar',
    year: 2014,
    difficulty: 'hard',
    genre: ['Sci-Fi', 'Drama']
  },
  {
    id: 'h9',
    title: 'La La Land',
    year: 2016,
    difficulty: 'easy',
    genre: ['Drama', 'Musical']
  },
  {
    id: 'h10',
    title: 'Black Panther',
    year: 2018,
    difficulty: 'easy',
    genre: ['Action', 'Adventure']
  },
  {
    id: 'h11',
    title: 'The Social Network',
    year: 2010,
    difficulty: 'medium',
    genre: ['Biography', 'Drama']
  },
  {
    id: 'h12',
    title: 'Mad Max: Fury Road',
    year: 2015,
    difficulty: 'medium',
    genre: ['Action', 'Adventure']
  },
  {
    id: 'h13',
    title: 'Get Out',
    year: 2017,
    difficulty: 'medium',
    genre: ['Horror', 'Thriller']
  },
  {
    id: 'h14',
    title: 'The Wolf of Wall Street',
    year: 2013,
    difficulty: 'medium',
    genre: ['Biography', 'Crime']
  },
  {
    id: 'h15',
    title: 'Frozen',
    year: 2013,
    difficulty: 'easy',
    genre: ['Animation', 'Adventure']
  },
  {
    id: 'h16',
    title: 'Spider-Man: Into the Spider-Verse',
    year: 2018,
    difficulty: 'easy',
    genre: ['Animation', 'Action']
  },
  {
    id: 'h17',
    title: 'The Shape of Water',
    year: 2017,
    difficulty: 'hard',
    genre: ['Drama', 'Fantasy']
  },
  {
    id: 'h18',
    title: 'Wonder Woman',
    year: 2017,
    difficulty: 'easy',
    genre: ['Action', 'Adventure']
  },
  {
    id: 'h19',
    title: 'Gone Girl',
    year: 2014,
    difficulty: 'medium',
    genre: ['Drama', 'Mystery']
  },
  {
    id: 'h20',
    title: 'The Martian',
    year: 2015,
    difficulty: 'medium',
    genre: ['Adventure', 'Sci-Fi']
  },
  {
    id: 'h21',
    title: 'Parasite',
    year: 2019,
    difficulty: 'hard',
    genre: ['Drama', 'Thriller']
  },
  {
    id: 'h22',
    title: 'Whiplash',
    year: 2014,
    difficulty: 'medium',
    genre: ['Drama', 'Music']
  },
  {
    id: 'h23',
    title: 'Joker',
    year: 2019,
    difficulty: 'medium',
    genre: ['Crime', 'Drama']
  },
  {
    id: 'h24',
    title: 'Coco',
    year: 2017,
    difficulty: 'easy',
    genre: ['Animation', 'Adventure']
  },
  {
    id: 'h25',
    title: 'A Star Is Born',
    year: 2018,
    difficulty: 'easy',
    genre: ['Drama', 'Music']
  },
  {
    id: 'h26',
    title: 'Gravity',
    year: 2013,
    difficulty: 'medium',
    genre: ['Sci-Fi', 'Thriller']
  },
  {
    id: 'h27',
    title: 'The Grand Budapest Hotel',
    year: 2014,
    difficulty: 'medium',
    genre: ['Adventure', 'Comedy']
  },
  {
    id: 'h28',
    title: 'Inside Out',
    year: 2015,
    difficulty: 'easy',
    genre: ['Animation', 'Adventure']
  },
  {
    id: 'h29',
    title: 'Dunkirk',
    year: 2017,
    difficulty: 'medium',
    genre: ['Action', 'War']
  },
  {
    id: 'h30',
    title: 'The Revenant',
    year: 2015,
    difficulty: 'hard',
    genre: ['Action', 'Adventure']
  },
  {
    id: 'h31',
    title: 'Arrival',
    year: 2016,
    difficulty: 'hard',
    genre: ['Drama', 'Sci-Fi']
  },
  {
    id: 'h32',
    title: 'Moonlight',
    year: 2016,
    difficulty: 'hard',
    genre: ['Drama']
  },
  {
    id: 'h33',
    title: 'Star Wars: The Force Awakens',
    year: 2015,
    difficulty: 'easy',
    genre: ['Action', 'Adventure']
  },
  {
    id: 'h34',
    title: 'The Big Short',
    year: 2015,
    difficulty: 'hard',
    genre: ['Biography', 'Comedy']
  },
  {
    id: 'h35',
    title: 'Thor: Ragnarok',
    year: 2017,
    difficulty: 'easy',
    genre: ['Action', 'Adventure']
  },
  {
    id: 'h36',
    title: 'Room',
    year: 2015,
    difficulty: 'hard',
    genre: ['Drama', 'Thriller']
  },
  {
    id: 'h37',
    title: 'Deadpool',
    year: 2016,
    difficulty: 'easy',
    genre: ['Action', 'Comedy']
  },
  {
    id: 'h38',
    title: 'The Greatest Showman',
    year: 2017,
    difficulty: 'easy',
    genre: ['Biography', 'Musical']
  },
  {
    id: 'h39',
    title: 'Blade Runner 2049',
    year: 2017,
    difficulty: 'hard',
    genre: ['Sci-Fi', 'Thriller']
  },
  {
    id: 'h40',
    title: 'A Quiet Place',
    year: 2018,
    difficulty: 'medium',
    genre: ['Horror', 'Sci-Fi']
  },
  {
    id: 'h41',
    title: 'Green Book',
    year: 2018,
    difficulty: 'medium',
    genre: ['Biography', 'Drama']
  },
  {
    id: 'h42',
    title: 'Captain America: Civil War',
    year: 2016,
    difficulty: 'easy',
    genre: ['Action', 'Adventure']
  },
  {
    id: 'h43',
    title: 'Bohemian Rhapsody',
    year: 2018,
    difficulty: 'easy',
    genre: ['Biography', 'Music']
  },
  {
    id: 'h44',
    title: 'Zootopia',
    year: 2016,
    difficulty: 'easy',
    genre: ['Animation', 'Adventure']
  },
  {
    id: 'h45',
    title: 'Ex Machina',
    year: 2014,
    difficulty: 'hard',
    genre: ['Drama', 'Sci-Fi']
  },
  {
    id: 'h46',
    title: 'Manchester by the Sea',
    year: 2016,
    difficulty: 'hard',
    genre: ['Drama']
  },
  {
    id: 'h47',
    title: 'Logan',
    year: 2017,
    difficulty: 'medium',
    genre: ['Action', 'Drama']
  },
  {
    id: 'h48',
    title: 'The Favourite',
    year: 2018,
    difficulty: 'hard',
    genre: ['Biography', 'Comedy']
  },
  {
    id: 'h49',
    title: 'Moana',
    year: 2016,
    difficulty: 'easy',
    genre: ['Animation', 'Adventure']
  },
  {
    id: 'h50',
    title: 'Black Swan',
    year: 2010,
    difficulty: 'hard',
    genre: ['Drama', 'Thriller']
  },
  {
    id: 'h51',
    title: 'Avengers: Endgame',
    year: 2019,
    difficulty: 'easy',
    genre: ['Action', 'Adventure']
  },
  {
    id: 'h52',
    title: 'The Theory of Everything',
    year: 2014,
    difficulty: 'medium',
    genre: ['Biography', 'Drama']
  },
  {
    id: 'h53',
    title: 'Toy Story 3',
    year: 2010,
    difficulty: 'easy',
    genre: ['Animation', 'Adventure']
  },
  {
    id: 'h54',
    title: 'Django Unchained',
    year: 2012,
    difficulty: 'medium',
    genre: ['Drama', 'Western']
  },
  {
    id: 'h55',
    title: 'Spotlight',
    year: 2015,
    difficulty: 'medium',
    genre: ['Biography', 'Drama']
  },
  {
    id: 'h56',
    title: 'Memento',
    year: 2000,
    difficulty: 'hard',
    genre: ['Mystery', 'Thriller']
  },
  {
    id: 'h57',
    title: 'Gladiator',
    year: 2000,
    difficulty: 'medium',
    genre: ['Action', 'Drama']
  },
  {
    id: 'h58',
    title: 'Cast Away',
    year: 2000,
    difficulty: 'easy',
    genre: ['Adventure', 'Drama']
  },
  {
    id: 'h59',
    title: 'Lord of the Rings: Fellowship of the Ring',
    year: 2001,
    difficulty: 'medium',
    genre: ['Adventure', 'Fantasy']
  },
  {
    id: 'h60',
    title: 'A Beautiful Mind',
    year: 2001,
    difficulty: 'medium',
    genre: ['Biography', 'Drama']
  },
  {
    id: 'h61',
    title: 'Spider-Man',
    year: 2002,
    difficulty: 'easy',
    genre: ['Action', 'Adventure']
  },
  {
    id: 'h62',
    title: 'The Pianist',
    year: 2002,
    difficulty: 'hard',
    genre: ['Biography', 'Drama']
  },
  {
    id: 'h63',
    title: 'Finding Nemo',
    year: 2003,
    difficulty: 'easy',
    genre: ['Animation', 'Adventure']
  },
  {
    id: 'h64',
    title: 'Pirates of the Caribbean',
    year: 2003,
    difficulty: 'easy',
    genre: ['Action', 'Adventure']
  },
  {
    id: 'h65',
    title: 'The Notebook',
    year: 2004,
    difficulty: 'easy',
    genre: ['Drama', 'Romance']
  },
  {
    id: 'h66',
    title: 'Million Dollar Baby',
    year: 2004,
    difficulty: 'medium',
    genre: ['Drama', 'Sport']
  },
  {
    id: 'h67',
    title: 'Batman Begins',
    year: 2005,
    difficulty: 'medium',
    genre: ['Action', 'Adventure']
  },
  {
    id: 'h68',
    title: 'Brokeback Mountain',
    year: 2005,
    difficulty: 'medium',
    genre: ['Drama', 'Romance']
  },
  {
    id: 'h69',
    title: 'The Departed',
    year: 2006,
    difficulty: 'hard',
    genre: ['Crime', 'Drama']
  },
  {
    id: 'h70',
    title: '300',
    year: 2006,
    difficulty: 'easy',
    genre: ['Action', 'Drama']
  },
  {
    id: 'h71',
    title: 'Ratatouille',
    year: 2007,
    difficulty: 'easy',
    genre: ['Animation', 'Adventure']
  },
  {
    id: 'h72',
    title: 'No Country for Old Men',
    year: 2007,
    difficulty: 'hard',
    genre: ['Crime', 'Drama']
  },
  {
    id: 'h73',
    title: 'Iron Man',
    year: 2008,
    difficulty: 'easy',
    genre: ['Action', 'Adventure']
  },
  {
    id: 'h74',
    title: 'WALL·E',
    year: 2008,
    difficulty: 'easy',
    genre: ['Animation', 'Adventure']
  },
  {
    id: 'h75',
    title: 'Up',
    year: 2009,
    difficulty: 'easy',
    genre: ['Animation', 'Adventure']
  },
  {
    id: 'h76',
    title: 'District 9',
    year: 2009,
    difficulty: 'medium',
    genre: ['Action', 'Sci-Fi']
  },
  {
    id: 'h77',
    title: 'The Kings Speech',
    year: 2010,
    difficulty: 'medium',
    genre: ['Biography', 'Drama']
  },
  {
    id: 'h78',
    title: 'Shutter Island',
    year: 2010,
    difficulty: 'hard',
    genre: ['Mystery', 'Thriller']
  },
  {
    id: 'h79',
    title: 'The Help',
    year: 2011,
    difficulty: 'medium',
    genre: ['Drama']
  },
  {
    id: 'h80',
    title: 'Moneyball',
    year: 2011,
    difficulty: 'medium',
    genre: ['Biography', 'Drama']
  },
  {
    id: 'h81',
    title: 'Life of Pi',
    year: 2012,
    difficulty: 'medium',
    genre: ['Adventure', 'Drama']
  },
  {
    id: 'h82',
    title: 'Skyfall',
    year: 2012,
    difficulty: 'easy',
    genre: ['Action', 'Adventure']
  },
  {
    id: 'h83',
    title: 'Her',
    year: 2013,
    difficulty: 'hard',
    genre: ['Drama', 'Sci-Fi']
  },
  {
    id: 'h84',
    title: 'American Hustle',
    year: 2013,
    difficulty: 'medium',
    genre: ['Crime', 'Drama']
  },
  {
    id: 'h85',
    title: 'Birdman',
    year: 2014,
    difficulty: 'hard',
    genre: ['Comedy', 'Drama']
  },
  {
    id: 'h86',
    title: 'The Grand Budapest Hotel',
    year: 2014,
    difficulty: 'medium',
    genre: ['Adventure', 'Comedy']
  },
  {
    id: 'h87',
    title: 'The Danish Girl',
    year: 2015,
    difficulty: 'medium',
    genre: ['Biography', 'Drama']
  },
  {
    id: 'h88',
    title: 'Bridge of Spies',
    year: 2015,
    difficulty: 'medium',
    genre: ['Drama', 'History']
  },
  {
    id: 'h89',
    title: 'Hacksaw Ridge',
    year: 2016,
    difficulty: 'medium',
    genre: ['Biography', 'Drama']
  },
  {
    id: 'h90',
    title: 'Arrival',
    year: 2016,
    difficulty: 'hard',
    genre: ['Drama', 'Sci-Fi']
  },
  {
    id: 'h91',
    title: 'Baby Driver',
    year: 2017,
    difficulty: 'medium',
    genre: ['Action', 'Crime']
  },
  {
    id: 'h92',
    title: 'The Post',
    year: 2017,
    difficulty: 'medium',
    genre: ['Biography', 'Drama']
  },
  {
    id: 'h93',
    title: 'Roma',
    year: 2018,
    difficulty: 'hard',
    genre: ['Drama']
  },
  {
    id: 'h94',
    title: 'First Man',
    year: 2018,
    difficulty: 'medium',
    genre: ['Biography', 'Drama']
  },
  {
    id: 'h95',
    title: 'Once Upon a Time in Hollywood',
    year: 2019,
    difficulty: 'medium',
    genre: ['Comedy', 'Drama']
  },
  {
    id: 'h96',
    title: 'Marriage Story',
    year: 2019,
    difficulty: 'medium',
    genre: ['Drama', 'Romance']
  },
  {
    id: 'h97',
    title: 'Jojo Rabbit',
    year: 2019,
    difficulty: 'medium',
    genre: ['Comedy', 'Drama']
  },
  {
    id: 'h98',
    title: 'Fight Club',
    year: 2000,
    difficulty: 'hard',
    genre: ['Drama']
  },
  {
    id: 'h99',
    title: 'Requiem for a Dream',
    year: 2000,
    difficulty: 'hard',
    genre: ['Drama']
  },
  {
    id: 'h100',
    title: 'Donnie Darko',
    year: 2001,
    difficulty: 'hard',
    genre: ['Drama', 'Mystery']
  },
  {
    id: 'h101',
    title: 'Monsters Inc',
    year: 2001,
    difficulty: 'easy',
    genre: ['Animation', 'Adventure']
  },
  {
    id: 'h102',
    title: 'The Lord of the Rings: The Two Towers',
    year: 2002,
    difficulty: 'medium',
    genre: ['Adventure', 'Fantasy']
  },
  {
    id: 'h103',
    title: 'Catch Me If You Can',
    year: 2002,
    difficulty: 'easy',
    genre: ['Biography', 'Crime']
  },
  {
    id: 'h104',
    title: 'Kill Bill: Vol. 1',
    year: 2003,
    difficulty: 'medium',
    genre: ['Action', 'Crime']
  },
  {
    id: 'h105',
    title: 'The Last Samurai',
    year: 2003,
    difficulty: 'medium',
    genre: ['Action', 'Drama']
  },
  {
    id: 'h106',
    title: 'Eternal Sunshine of the Spotless Mind',
    year: 2004,
    difficulty: 'hard',
    genre: ['Drama', 'Romance']
  },
  {
    id: 'h107',
    title: 'The Incredibles',
    year: 2004,
    difficulty: 'easy',
    genre: ['Animation', 'Action']
  },
  {
    id: 'h108',
    title: 'V for Vendetta',
    year: 2005,
    difficulty: 'medium',
    genre: ['Action', 'Drama']
  },
  {
    id: 'h109',
    title: 'Walk the Line',
    year: 2005,
    difficulty: 'medium',
    genre: ['Biography', 'Drama']
  },
  {
    id: 'h110',
    title: 'Blood Diamond',
    year: 2006,
    difficulty: 'medium',
    genre: ['Adventure', 'Drama']
  },
  {
    id: 'h111',
    title: 'Little Miss Sunshine',
    year: 2006,
    difficulty: 'easy',
    genre: ['Comedy', 'Drama']
  },
  {
    id: 'h112',
    title: 'There Will Be Blood',
    year: 2007,
    difficulty: 'hard',
    genre: ['Drama']
  },
  {
    id: 'h113',
    title: 'Into the Wild',
    year: 2007,
    difficulty: 'medium',
    genre: ['Adventure', 'Biography']
  },
  {
    id: 'h114',
    title: 'Slumdog Millionaire',
    year: 2008,
    difficulty: 'medium',
    genre: ['Drama', 'Romance']
  },
  {
    id: 'h115',
    title: 'The Reader',
    year: 2008,
    difficulty: 'hard',
    genre: ['Drama', 'Romance']
  },
  {
    id: 'h116',
    title: 'The Hurt Locker',
    year: 2009,
    difficulty: 'medium',
    genre: ['Drama', 'War']
  },
  {
    id: 'h117',
    title: '(500) Days of Summer',
    year: 2009,
    difficulty: 'medium',
    genre: ['Comedy', 'Romance']
  },
  {
    id: 'h118',
    title: 'The Fighter',
    year: 2010,
    difficulty: 'medium',
    genre: ['Biography', 'Drama']
  },
  {
    id: 'h119',
    title: 'True Grit',
    year: 2010,
    difficulty: 'medium',
    genre: ['Drama', 'Western']
  },
  {
    id: 'h120',
    title: 'Drive',
    year: 2011,
    difficulty: 'medium',
    genre: ['Crime', 'Drama']
  },
  {
    id: 'h121',
    title: 'Midnight in Paris',
    year: 2011,
    difficulty: 'medium',
    genre: ['Comedy', 'Fantasy']
  },
  {
    id: 'h122',
    title: 'Zero Dark Thirty',
    year: 2012,
    difficulty: 'hard',
    genre: ['Drama', 'History']
  },
  {
    id: 'h123',
    title: 'Silver Linings Playbook',
    year: 2012,
    difficulty: 'medium',
    genre: ['Comedy', 'Drama']
  },
  {
    id: 'h124',
    title: 'Dallas Buyers Club',
    year: 2013,
    difficulty: 'medium',
    genre: ['Biography', 'Drama']
  },
  {
    id: 'h125',
    title: 'Rush',
    year: 2013,
    difficulty: 'medium',
    genre: ['Biography', 'Drama']
  },
  {
    id: 'h126',
    title: 'Gone Girl',
    year: 2014,
    difficulty: 'medium',
    genre: ['Drama', 'Mystery']
  },
  {
    id: 'h127',
    title: 'Nightcrawler',
    year: 2014,
    difficulty: 'medium',
    genre: ['Crime', 'Drama']
  },
  {
    id: 'h128',
    title: 'Room',
    year: 2015,
    difficulty: 'hard',
    genre: ['Drama', 'Thriller']
  },
  {
    id: 'h129',
    title: 'Sicario',
    year: 2015,
    difficulty: 'hard',
    genre: ['Action', 'Crime']
  },
  {
    id: 'h130',
    title: 'Hell or High Water',
    year: 2016,
    difficulty: 'medium',
    genre: ['Crime', 'Drama']
  },
  {
    id: 'h131',
    title: 'Manchester by the Sea',
    year: 2016,
    difficulty: 'hard',
    genre: ['Drama']
  },
  // Add more Hollywood movies as needed
];