export interface PictionaryWord {
  id: string;
  word: string;
  category: PictionaryCategory;
  difficulty: 'easy' | 'medium' | 'hard';
}

export type PictionaryCategory = 
  | 'Mix'
  | 'Animals' 
  | 'Objects' 
  | 'Actions' 
  | 'Nature' 
  | 'Food' 
  | 'Sports' 
  | 'Emotions'
  | 'Places'
  | 'Professions'
  | 'Entertainment';

export const pictionaryWords: PictionaryWord[] = [
  // Animals
  { id: 'a1', word: 'Elephant', category: 'Animals', difficulty: 'easy' },
  { id: 'a2', word: 'Giraffe', category: 'Animals', difficulty: 'easy' },
  { id: 'a3', word: 'Penguin', category: 'Animals', difficulty: 'easy' },
  { id: 'a4', word: 'Octopus', category: 'Animals', difficulty: 'medium' },
  { id: 'a5', word: 'Kangaroo', category: 'Animals', difficulty: 'medium' },
  { id: 'a6', word: 'Lion', category: 'Animals', difficulty: 'easy' },
  { id: 'a7', word: 'Tiger', category: 'Animals', difficulty: 'easy' },
  { id: 'a8', word: 'Bear', category: 'Animals', difficulty: 'easy' },
  { id: 'a9', word: 'Monkey', category: 'Animals', difficulty: 'easy' },
  { id: 'a10', word: 'Zebra', category: 'Animals', difficulty: 'easy' },
  { id: 'a11', word: 'Horse', category: 'Animals', difficulty: 'easy' },
  { id: 'a12', word: 'Cow', category: 'Animals', difficulty: 'easy' },
  { id: 'a13', word: 'Pig', category: 'Animals', difficulty: 'easy' },
  { id: 'a14', word: 'Sheep', category: 'Animals', difficulty: 'easy' },
  { id: 'a15', word: 'Chicken', category: 'Animals', difficulty: 'easy' },
  { id: 'a16', word: 'Dog', category: 'Animals', difficulty: 'easy' },
  { id: 'a17', word: 'Cat', category: 'Animals', difficulty: 'easy' },
  { id: 'a18', word: 'Bird', category: 'Animals', difficulty: 'easy' },
  { id: 'a19', word: 'Fish', category: 'Animals', difficulty: 'easy' },
  { id: 'a20', word: 'Snake', category: 'Animals', difficulty: 'easy' },
  { id: 'a21', word: 'Crocodile', category: 'Animals', difficulty: 'medium' },
  { id: 'a22', word: 'Hippopotamus', category: 'Animals', difficulty: 'medium' },
  { id: 'a23', word: 'Rhinoceros', category: 'Animals', difficulty: 'medium' },
  { id: 'a24', word: 'Camel', category: 'Animals', difficulty: 'medium' },
  { id: 'a25', word: 'Gorilla', category: 'Animals', difficulty: 'medium' },
  { id: 'a26', word: 'Dolphin', category: 'Animals', difficulty: 'medium' },
  { id: 'a27', word: 'Whale', category: 'Animals', difficulty: 'medium' },
  { id: 'a28', word: 'Shark', category: 'Animals', difficulty: 'medium' },
  { id: 'a29', word: 'Jellyfish', category: 'Animals', difficulty: 'medium' },
  { id: 'a30', word: 'Butterfly', category: 'Animals', difficulty: 'easy' },
  { id: 'a31', word: 'Spider', category: 'Animals', difficulty: 'easy' },
  { id: 'a32', word: 'Ant', category: 'Animals', difficulty: 'easy' },
  { id: 'a33', word: 'Bee', category: 'Animals', difficulty: 'easy' },
  { id: 'a34', word: 'Ladybug', category: 'Animals', difficulty: 'easy' },
  { id: 'a35', word: 'Worm', category: 'Animals', difficulty: 'easy' },
  { id: 'a36', word: 'Squirrel', category: 'Animals', difficulty: 'medium' },
  { id: 'a37', word: 'Rabbit', category: 'Animals', difficulty: 'easy' },
  { id: 'a38', word: 'Turtle', category: 'Animals', difficulty: 'easy' },
  { id: 'a39', word: 'Frog', category: 'Animals', difficulty: 'easy' },
  { id: 'a40', word: 'Duck', category: 'Animals', difficulty: 'easy' }, 
  { id: 'a41', word: 'Owl', category: 'Animals', difficulty: 'medium' },
  { id: 'a42', word: 'Eagle', category: 'Animals', difficulty: 'medium' },
  { id: 'a43', word: 'Peacock', category: 'Animals', difficulty: 'medium' },
  { id: 'a44', word: 'Scorpion', category: 'Animals', difficulty: 'hard' },
  { id: 'a45', word: 'Lobster', category: 'Animals', difficulty: 'medium' },
  { id: 'a46', word: 'Starfish', category: 'Animals', difficulty: 'easy' },
  { id: 'a47', word: 'Seahorse', category: 'Animals', difficulty: 'medium' },
  { id: 'a48', word: 'Reindeer', category: 'Animals', difficulty: 'medium' },
  { id: 'a49', word: 'Dragonfly', category: 'Animals', difficulty: 'medium' },
  { id: 'a50', word: 'Caterpillar', category: 'Animals', difficulty: 'medium' },
  { id: 'a51', word: 'Platypus', category: 'Animals', difficulty: 'hard' },
  { id: 'a52', word: 'Koala', category: 'Animals', difficulty: 'medium' },
  { id: 'a53', word: 'Panda', category: 'Animals', difficulty: 'medium' },
  { id: 'a54', word: 'Cheetah', category: 'Animals', difficulty: 'medium' },
  { id: 'a55', word: 'Jaguar', category: 'Animals', difficulty: 'medium' },
  { id: 'a56', word: 'Leopard', category: 'Animals', difficulty: 'medium' },
  { id: 'a57', word: 'Hyena', category: 'Animals', difficulty: 'medium' },
  { id: 'a58', word: 'Walrus', category: 'Animals', difficulty: 'medium' },
  { id: 'a59', word: 'Seal', category: 'Animals', difficulty: 'easy' },
  { id: 'a60', word: 'Penguin', category: 'Animals', difficulty: 'easy' },
  { id: 'a61', word: 'Ostrich', category: 'Animals', difficulty: 'medium' },
  { id: 'a62', word: 'Flamingo', category: 'Animals', difficulty: 'medium' },
  { id: 'a63', word: 'Pelican', category: 'Animals', difficulty: 'medium' },
  { id: 'a64', word: 'Stork', category: 'Animals', difficulty: 'medium' },
  { id: 'a65', word: 'Swan', category: 'Animals', difficulty: 'easy' },
  { id: 'a66', word: 'Goose', category: 'Animals', difficulty: 'easy' },
  { id: 'a67', word: 'Turkey', category: 'Animals', difficulty: 'easy' },
  { id: 'a68', word: 'Rooster', category: 'Animals', difficulty: 'easy' },
  { id: 'a69', word: 'Parrot', category: 'Animals', difficulty: 'easy' },
  { id: 'a70', word: 'Macaw', category: 'Animals', difficulty: 'medium' },
  { id: 'a71', word: 'Toucan', category: 'Animals', difficulty: 'medium' },
  { id: 'a72', word: 'Woodpecker', category: 'Animals', difficulty: 'medium' },
  { id: 'a73', word: 'Hummingbird', category: 'Animals', difficulty: 'medium' },
  { id: 'a74', word: 'Cardinal', category: 'Animals', difficulty: 'medium' },
  { id: 'a75', word: 'Sparrow', category: 'Animals', difficulty: 'easy' },
  { id: 'a76', word: 'Hamster', category: 'Animals', difficulty: 'easy' },
  { id: 'a77', word: 'Gerbil', category: 'Animals', difficulty: 'easy' },
  { id: 'a78', word: 'Guinea Pig', category: 'Animals', difficulty: 'easy' },
  { id: 'a79', word: 'Ferret', category: 'Animals', difficulty: 'medium' },
  { id: 'a80', word: 'Hedgehog', category: 'Animals', difficulty: 'medium' },
  { id: 'a81', word: 'Skunk', category: 'Animals', difficulty: 'medium' },
  { id: 'a82', word: 'Raccoon', category: 'Animals', difficulty: 'medium' },
  { id: 'a83', word: 'Beaver', category: 'Animals', difficulty: 'medium' },
  { id: 'a84', word: 'Otter', category: 'Animals', difficulty: 'medium' },
  { id: 'a85', word: 'Mole', category: 'Animals', difficulty: 'medium' },
  { id: 'a86', word: 'Armadillo', category: 'Animals', difficulty: 'hard' },
  { id: 'a87', word: 'Sloth', category: 'Animals', difficulty: 'medium' },
  { id: 'a88', word: 'Anteater', category: 'Animals', difficulty: 'hard' },
  { id: 'a89', word: 'Tapir', category: 'Animals', difficulty: 'hard' },
  { id: 'a90', word: 'Llama', category: 'Animals', difficulty: 'medium' },
  { id: 'a91', word: 'Alpaca', category: 'Animals', difficulty: 'medium' },
  { id: 'a92', word: 'Gazelle', category: 'Animals', difficulty: 'medium' },
  { id: 'a93', word: 'Antelope', category: 'Animals', difficulty: 'medium' },
  { id: 'a94', word: 'Moose', category: 'Animals', difficulty: 'medium' },
  { id: 'a95', word: 'Elk', category: 'Animals', difficulty: 'medium' },
  { id: 'a96', word: 'Deer', category: 'Animals', difficulty: 'easy' },
  { id: 'a97', word: 'Buffalo', category: 'Animals', difficulty: 'medium' },
  { id: 'a98', word: 'Bison', category: 'Animals', difficulty: 'medium' },
  { id: 'a99', word: 'Yak', category: 'Animals', difficulty: 'hard' },
  { id: 'a100', word: 'Goat', category: 'Animals', difficulty: 'easy' },
  
  // Objects
  { id: 'o1', word: 'Umbrella', category: 'Objects', difficulty: 'easy' },
  { id: 'o2', word: 'Telescope', category: 'Objects', difficulty: 'medium' },
  { id: 'o3', word: 'Clock', category: 'Objects', difficulty: 'easy' },
  { id: 'o4', word: 'Guitar', category: 'Objects', difficulty: 'easy' },
  { id: 'o5', word: 'Backpack', category: 'Objects', difficulty: 'easy' },
  { id: 'o6', word: 'Chair', category: 'Objects', difficulty: 'easy' },
  { id: 'o7', word: 'Table', category: 'Objects', difficulty: 'easy' },
  { id: 'o8', word: 'Lamp', category: 'Objects', difficulty: 'easy' },
  { id: 'o9', word: 'Book', category: 'Objects', difficulty: 'easy' },
  { id: 'o10', word: 'Pen', category: 'Objects', difficulty: 'easy' },
  { id: 'o11', word: 'Key', category: 'Objects', difficulty: 'easy' },
  { id: 'o12', word: 'Phone', category: 'Objects', difficulty: 'easy' },
  { id: 'o13', word: 'Computer', category: 'Objects', difficulty: 'easy' },
  { id: 'o14', word: 'Television', category: 'Objects', difficulty: 'easy' },
  { id: 'o15', word: 'Car', category: 'Objects', difficulty: 'easy' },
  { id: 'o16', word: 'Bicycle', category: 'Objects', difficulty: 'easy' },
  { id: 'o17', word: 'Airplane', category: 'Objects', difficulty: 'easy' },
  { id: 'o18', word: 'Boat', category: 'Objects', difficulty: 'easy' },
  { id: 'o19', word: 'Train', category: 'Objects', difficulty: 'easy' },
  { id: 'o20', word: 'House', category: 'Objects', difficulty: 'easy' },
  { id: 'o21', word: 'Door', category: 'Objects', difficulty: 'easy' },
  { id: 'o22', word: 'Window', category: 'Objects', difficulty: 'easy' },
  { id: 'o23', word: 'Bed', category: 'Objects', difficulty: 'easy' },
  { id: 'o24', word: 'Pillow', category: 'Objects', difficulty: 'easy' },
  { id: 'o25', word: 'Blanket', category: 'Objects', difficulty: 'easy' },
  { id: 'o26', word: 'Spoon', category: 'Objects', difficulty: 'easy' },
  { id: 'o27', word: 'Fork', category: 'Objects', difficulty: 'easy' },
  { id: 'o28', word: 'Knife', category: 'Objects', difficulty: 'easy' },
  { id: 'o29', word: 'Plate', category: 'Objects', difficulty: 'easy' },
  { id: 'o30', word: 'Cup', category: 'Objects', difficulty: 'easy' },
  { id: 'o31', word: 'Bowl', category: 'Objects', difficulty: 'easy' },
  { id: 'o32', word: 'Scissors', category: 'Objects', difficulty: 'easy' },
  { id: 'o33', word: 'Hammer', category: 'Objects', difficulty: 'medium' },
  { id: 'o34', word: 'Screwdriver', category: 'Objects', difficulty: 'medium' },
  { id: 'o35', word: 'Ladder', category: 'Objects', difficulty: 'medium' },
  { id: 'o36', word: 'Bucket', category: 'Objects', difficulty: 'easy' },
  { id: 'o37', word: 'Candle', category: 'Objects', difficulty: 'easy' },
  { id: 'o38', word: 'Mirror', category: 'Objects', difficulty: 'easy' },
  { id: 'o39', word: 'Diamond', category: 'Objects', difficulty: 'easy' },
  { id: 'o40', word: 'Crown', category: 'Objects', difficulty: 'medium' },
  { id: 'o41', word: 'Sword', category: 'Objects', difficulty: 'medium' },
  { id: 'o42', word: 'Flag', category: 'Objects', difficulty: 'easy' },
  { id: 'o43', word: 'Map', category: 'Objects', difficulty: 'easy' },
  { id: 'o44', word: 'Compass', category: 'Objects', difficulty: 'medium' },
  { id: 'o45', word: 'Globe', category: 'Objects', difficulty: 'medium' },
  { id: 'o46', word: 'Camera', category: 'Objects', difficulty: 'easy' },
  { id: 'o47', word: 'Paintbrush', category: 'Objects', difficulty: 'easy' },
  { id: 'o48', word: 'Shovel', category: 'Objects', difficulty: 'medium' },
  { id: 'o49', word: 'Helmet', category: 'Objects', difficulty: 'medium' },
  { id: 'o50', word: 'Suitcase', category: 'Objects', difficulty: 'easy' },

  // Actions
  { id: 'ac1', word: 'Dancing', category: 'Actions', difficulty: 'medium' },
  { id: 'ac2', word: 'Swimming', category: 'Actions', difficulty: 'easy' },
  { id: 'ac3', word: 'Jumping', category: 'Actions', difficulty: 'easy' },
  { id: 'ac4', word: 'Cooking', category: 'Actions', difficulty: 'medium' },
  { id: 'ac5', word: 'Reading', category: 'Actions', difficulty: 'easy' },
  { id: 'ac6', word: 'Running', category: 'Actions', difficulty: 'easy' },
  { id: 'ac7', word: 'Sleeping', category: 'Actions', difficulty: 'easy' },
  { id: 'ac8', word: 'Eating', category: 'Actions', difficulty: 'easy' },
  { id: 'ac9', word: 'Drinking', category: 'Actions', difficulty: 'easy' },
  { id: 'ac10', word: 'Walking', category: 'Actions', difficulty: 'easy' },
  { id: 'ac11', word: 'Talking', category: 'Actions', difficulty: 'easy' },
  { id: 'ac12', word: 'Laughing', category: 'Actions', difficulty: 'easy' },
  { id: 'ac13', word: 'Crying', category: 'Actions', difficulty: 'easy' },
  { id: 'ac14', word: 'Smiling', category: 'Actions', difficulty: 'easy' },
  { id: 'ac15', word: 'Singing', category: 'Actions', difficulty: 'medium' },
  { id: 'ac16', word: 'Playing', category: 'Actions', difficulty: 'easy' },
  { id: 'ac17', word: 'Writing', category: 'Actions', difficulty: 'easy' },
  { id: 'ac18', word: 'Drawing', category: 'Actions', difficulty: 'easy' },
  { id: 'ac19', word: 'Painting', category: 'Actions', difficulty: 'medium' },
  { id: 'ac20', word: 'Building', category: 'Actions', difficulty: 'medium' },
  { id: 'ac21', word: 'Driving', category: 'Actions', difficulty: 'easy' },
  { id: 'ac22', word: 'Flying', category: 'Actions', difficulty: 'easy' },
  { id: 'ac23', word: 'Sailing', category: 'Actions', difficulty: 'medium' },
  { id: 'ac24', word: 'Climbing', category: 'Actions', difficulty: 'medium' },
  { id: 'ac25', word: 'Hiking', category: 'Actions', difficulty: 'medium' },
  { id: 'ac26', word: 'Fishing', category: 'Actions', difficulty: 'medium' },
  { id: 'ac27', word: 'Shopping', category: 'Actions', difficulty: 'medium' },
  { id: 'ac28', word: 'Working', category: 'Actions', difficulty: 'easy' },
  { id: 'ac29', word: 'Studying', category: 'Actions', difficulty: 'easy' },
  { id: 'ac30', word: 'Thinking', category: 'Actions', difficulty: 'medium' },
  { id: 'ac31', word: 'Dreaming', category: 'Actions', difficulty: 'medium' },
  { id: 'ac32', word: 'Whispering', category: 'Actions', difficulty: 'medium' },
  { id: 'ac33', word: 'Shouting', category: 'Actions', difficulty: 'medium' },
  { id: 'ac34', word: 'Hugging', category: 'Actions', difficulty: 'easy' },
  { id: 'ac35', word: 'Kissing', category: 'Actions', difficulty: 'easy' },
  { id: 'ac36', word: 'Fighting', category: 'Actions', difficulty: 'medium' },
  { id: 'ac37', word: 'Helping', category: 'Actions', difficulty: 'medium' },
  { id: 'ac38', word: 'Listening', category: 'Actions', difficulty: 'medium' },
  { id: 'ac39', word: 'Waving', category: 'Actions', difficulty: 'easy' },
  { id: 'ac40', word: 'Pointing', category: 'Actions', difficulty: 'easy' },
  { id: 'ac41', word: 'Yawning', category: 'Actions', difficulty: 'easy' },
  { id: 'ac42', word: 'Sleeping', category: 'Actions', difficulty: 'easy' },
  { id: 'ac43', word: 'Washing', category: 'Actions', difficulty: 'easy' },
  { id: 'ac44', word: 'Brushing', category: 'Actions', difficulty: 'easy' }, 
  { id: 'ac45', word: 'Opening', category: 'Actions', difficulty: 'easy' },
  { id: 'ac46', word: 'Closing', category: 'Actions', difficulty: 'easy' },
  { id: 'ac47', word: 'Throwing', category: 'Actions', difficulty: 'easy' },
  { id: 'ac48', word: 'Catching', category: 'Actions', difficulty: 'easy' },
  { id: 'ac49', word: 'Hiding', category: 'Actions', difficulty: 'medium' },
  { id: 'ac50', word: 'Searching', category: 'Actions', difficulty: 'medium' },
  { id: 'ac51', word: 'Digging', category: 'Actions', difficulty: 'medium' },
  { id: 'ac52', word: 'Knitting', category: 'Actions', difficulty: 'hard' },
  { id: 'ac53', word: 'Skating', category: 'Actions', difficulty: 'medium' },
  { id: 'ac54', word: 'Skiing', category: 'Actions', difficulty: 'medium' },
  { id: 'ac55', word: 'Surfing', category: 'Actions', difficulty: 'medium' },
  { id: 'ac56', word: 'Diving', category: 'Actions', difficulty: 'medium' },
  { id: 'ac57', word: 'Crawling', category: 'Actions', difficulty: 'easy' },
  { id: 'ac58', word: 'Hopping', category: 'Actions', difficulty: 'easy' },
  { id: 'ac59', word: 'Skipping', category: 'Actions', difficulty: 'easy' },
  { id: 'ac60', word: 'Marching', category: 'Actions', difficulty: 'medium' },
  { id: 'ac61', word: 'Stretching', category: 'Actions', difficulty: 'medium' },
  { id: 'ac62', word: 'Juggling', category: 'Actions', difficulty: 'hard' },
  { id: 'ac63', word: 'Spinning', category: 'Actions', difficulty: 'easy' },
  { id: 'ac64', word: 'Twirling', category: 'Actions', difficulty: 'medium' },
  { id: 'ac65', word: 'Baking', category: 'Actions', difficulty: 'medium' },
  { id: 'ac66', word: 'Sewing', category: 'Actions', difficulty: 'hard' },
  { id: 'ac67', word: 'Typing', category: 'Actions', difficulty: 'easy' },
  { id: 'ac68', word: 'Clapping', category: 'Actions', difficulty: 'easy' },
  { id: 'ac69', word: 'Snapping', category: 'Actions', difficulty: 'easy' },
  { id: 'ac70', word: 'Whistling', category: 'Actions', difficulty: 'medium' },
  { id: 'ac71', word: 'Gardening', category: 'Actions', difficulty: 'medium' },
  { id: 'ac72', word: 'Cleaning', category: 'Actions', difficulty: 'easy' },
  { id: 'ac73', word: 'Folding', category: 'Actions', difficulty: 'easy' },
  { id: 'ac74', word: 'Hanging', category: 'Actions', difficulty: 'easy' },
  { id: 'ac75', word: 'Pushing', category: 'Actions', difficulty: 'easy' },
  { id: 'ac76', word: 'Pulling', category: 'Actions', difficulty: 'easy' },
  { id: 'ac77', word: 'Lifting', category: 'Actions', difficulty: 'easy' },
  { id: 'ac78', word: 'Carrying', category: 'Actions', difficulty: 'easy' },
  { id: 'ac79', word: 'Blinking', category: 'Actions', difficulty: 'easy' },
  { id: 'ac80', word: 'Sneezing', category: 'Actions', difficulty: 'easy' },
  { id: 'ac81', word: 'Coughing', category: 'Actions', difficulty: 'easy' },
  { id: 'ac82', word: 'Breathing', category: 'Actions', difficulty: 'easy' },
  { id: 'ac83', word: 'Exercising', category: 'Actions', difficulty: 'medium' },
  { id: 'ac84', word: 'Teaching', category: 'Actions', difficulty: 'medium' },
  { id: 'ac85', word: 'Learning', category: 'Actions', difficulty: 'medium' },
  { id: 'ac86', word: 'Praying', category: 'Actions', difficulty: 'medium' },
  { id: 'ac87', word: 'Meditating', category: 'Actions', difficulty: 'medium' },
  { id: 'ac88', word: 'Cheering', category: 'Actions', difficulty: 'medium' },
  { id: 'ac89', word: 'Booing', category: 'Actions', difficulty: 'easy' },
  { id: 'ac90', word: 'Presenting', category: 'Actions', difficulty: 'medium' },
  { id: 'ac91', word: 'Dancing', category: 'Actions', difficulty: 'medium' },
  { id: 'ac92', word: 'Directing', category: 'Actions', difficulty: 'medium' },
  { id: 'ac93', word: 'Conducting', category: 'Actions', difficulty: 'hard' },
  { id: 'ac94', word: 'Performing', category: 'Actions', difficulty: 'medium' },
  { id: 'ac95', word: 'Balancing', category: 'Actions', difficulty: 'medium' },
  { id: 'ac96', word: 'Swinging', category: 'Actions', difficulty: 'easy' },
  { id: 'ac97', word: 'Sliding', category: 'Actions', difficulty: 'easy' },
  { id: 'ac98', word: 'Racing', category: 'Actions', difficulty: 'easy' },
  { id: 'ac99', word: 'Competing', category: 'Actions', difficulty: 'medium' },
  { id: 'ac100', word: 'Celebrating', category: 'Actions', difficulty: 'medium' },

  // Nature
  { id: 'n1', word: 'Mountain', category: 'Nature', difficulty: 'medium' },
  { id: 'n2', word: 'Rainbow', category: 'Nature', difficulty: 'easy' },
  { id: 'n3', word: 'Volcano', category: 'Nature', difficulty: 'medium' },
  { id: 'n4', word: 'Beach', category: 'Nature', difficulty: 'easy' },
  { id: 'n5', word: 'Forest', category: 'Nature', difficulty: 'medium' },
  { id: 'n6', word: 'Ocean', category: 'Nature', difficulty: 'easy' },
  { id: 'n7', word: 'River', category: 'Nature', difficulty: 'easy' },
  { id: 'n8', word: 'Lake', category: 'Nature', difficulty: 'easy' },
  { id: 'n9', word: 'Island', category: 'Nature', difficulty: 'easy' },
  { id: 'n10', word: 'Desert', category: 'Nature', difficulty: 'easy' },
  { id: 'n11', word: 'Tree', category: 'Nature', difficulty: 'easy' },
  { id: 'n12', word: 'Flower', category: 'Nature', difficulty: 'easy' },
  { id: 'n13', word: 'Grass', category: 'Nature', difficulty: 'easy' },
  { id: 'n14', word: 'Cloud', category: 'Nature', difficulty: 'easy' },
  { id: 'n15', word: 'Sun', category: 'Nature', difficulty: 'easy' },
  { id: 'n16', word: 'Moon', category: 'Nature', difficulty: 'easy' },
  { id: 'n17', word: 'Star', category: 'Nature', difficulty: 'easy' },
  { id: 'n18', word: 'Sky', category: 'Nature', difficulty: 'easy' },
  { id: 'n19', word: 'Rain', category: 'Nature', difficulty: 'easy' },
  { id: 'n20', word: 'Snow', category: 'Nature', difficulty: 'easy' },
  { id: 'n21', word: 'Wind', category: 'Nature', difficulty: 'medium' },
  { id: 'n22', word: 'Lightning', category: 'Nature', difficulty: 'medium' },
  { id: 'n23', word: 'Thunder', category: 'Nature', difficulty: 'medium' },
  { id: 'n24', word: 'Earthquake', category: 'Nature', difficulty: 'hard' },
  { id: 'n25', word: 'Tornado', category: 'Nature', difficulty: 'hard' },
  { id: 'n26', word: ' Waterfall', category: 'Nature', difficulty: 'medium' },
  { id: 'n27', word: 'Cave', category: 'Nature', difficulty: 'medium' },
  { id: 'n28', word: 'Canyon', category: 'Nature', difficulty: 'medium' },
  { id: 'n29', word: 'Glacier', category: 'Nature', difficulty: 'hard' },
  { id: 'n30', word: 'Jungle', category: 'Nature', difficulty: 'medium' },
  { id: 'n31', word: 'Swamp', category: 'Nature', difficulty: 'medium' },
  { id: 'n32', word: 'Garden', category: 'Nature', difficulty: 'easy' },
  { id: 'n33', word: 'Leaf', category: 'Nature', difficulty: 'easy' },
  { id: 'n34', word: 'Rock', category: 'Nature', difficulty: 'easy' },
  { id: 'n35', word: 'Fire', category: 'Nature', difficulty: 'easy' },
  { id: 'n36', word: 'Ice', category: 'Nature', difficulty: 'easy' },
  { id: 'n37', word: 'Sunrise', category: 'Nature', difficulty: 'medium' },
  { id: 'n38', word: 'Sunset', category: 'Nature', difficulty: 'medium' },
  { id: 'n39', word: 'Northern Lights', category: 'Nature', difficulty: 'hard' },
  { id: 'n40', word: 'Solar Eclipse', category: 'Nature', difficulty: 'hard' },
  { id: 'n41', word: 'Meteor Shower', category: 'Nature', difficulty: 'hard' },
  { id: 'n42', word: 'Rainbow', category: 'Nature', difficulty: 'easy' }, 
  { id: 'n43', word: 'Valley', category: 'Nature', difficulty: 'medium' },
  { id: 'n44', word: 'Cliff', category: 'Nature', difficulty: 'medium' },
  { id: 'n45', word: 'Hill', category: 'Nature', difficulty: 'easy' },
  { id: 'n46', word: 'Plain', category: 'Nature', difficulty: 'medium' },
  { id: 'n47', word: 'Geyser', category: 'Nature', difficulty: 'hard' },
  { id: 'n48', word: 'Coral Reef', category: 'Nature', difficulty: 'hard' },
  { id: 'n49', word: 'Tundra', category: 'Nature', difficulty: 'hard' },
  { id: 'n50', word: 'Savanna', category: 'Nature', difficulty: 'hard' },
  { id: 'n51', word: 'Meadow', category: 'Nature', difficulty: 'medium' },
  { id: 'n52', word: 'Lagoon', category: 'Nature', difficulty: 'medium' },
  { id: 'n53', word: 'Marsh', category: 'Nature', difficulty: 'medium' },
  { id: 'n54', word: 'Oasis', category: 'Nature', difficulty: 'medium' },
  { id: 'n55', word: 'Peninsula', category: 'Nature', difficulty: 'hard' },
  { id: 'n56', word: 'Bay', category: 'Nature', difficulty: 'easy' },
  { id: 'n57', word: 'Delta', category: 'Nature', difficulty: 'medium' },
  { id: 'n58', word: 'Dune', category: 'Nature', difficulty: 'medium' },
  { id: 'n59', word: 'Reef', category: 'Nature', difficulty: 'medium' },
  { id: 'n60', word: 'Fog', category: 'Nature', difficulty: 'easy' },
  { id: 'n61', word: 'Mist', category: 'Nature', difficulty: 'easy' },
  { id: 'n62', word: 'Hurricane', category: 'Nature', difficulty: 'hard' },
  { id: 'n63', word: 'Tsunami', category: 'Nature', difficulty: 'hard' },
  { id: 'n64', word: 'Avalanche', category: 'Nature', difficulty: 'hard' },
  { id: 'n65', word: 'Flood', category: 'Nature', difficulty: 'medium' },
  { id: 'n66', word: 'Drought', category: 'Nature', difficulty: 'hard' },
  { id: 'n67', word: 'Spring', category: 'Nature', difficulty: 'easy' },
  { id: 'n68', word: 'Stream', category: 'Nature', difficulty: 'easy' },
  { id: 'n69', word: 'Brook', category: 'Nature', difficulty: 'medium' },
  { id: 'n70', word: 'Pine Tree', category: 'Nature', difficulty: 'medium' },
  { id: 'n71', word: 'Oak Tree', category: 'Nature', difficulty: 'medium' },
  { id: 'n72', word: 'Palm Tree', category: 'Nature', difficulty: 'easy' },
  { id: 'n73', word: 'Willow Tree', category: 'Nature', difficulty: 'medium' },
  { id: 'n74', word: 'Rose', category: 'Nature', difficulty: 'easy' },
  { id: 'n75', word: 'Daisy', category: 'Nature', difficulty: 'easy' },
  { id: 'n76', word: 'Sunflower', category: 'Nature', difficulty: 'easy' },
  { id: 'n77', word: 'Tulip', category: 'Nature', difficulty: 'easy' },
  { id: 'n78', word: 'Moss', category: 'Nature', difficulty: 'easy' },
  { id: 'n79', word: 'Seaweed', category: 'Nature', difficulty: 'medium' },
  { id: 'n80', word: 'Algae', category: 'Nature', difficulty: 'medium' },
  { id: 'n81', word: 'Pollen', category: 'Nature', difficulty: 'medium' },
  { id: 'n82', word: 'Hail', category: 'Nature', difficulty: 'easy' },
  { id: 'n83', word: 'Sleet', category: 'Nature', difficulty: 'medium' },
  { id: 'n84', word: 'Frost', category: 'Nature', difficulty: 'easy' },
  { id: 'n85', word: 'Dew', category: 'Nature', difficulty: 'medium' },
  { id: 'n86', word: 'Crystal', category: 'Nature', difficulty: 'medium' },
  { id: 'n87', word: 'Geode', category: 'Nature', difficulty: 'hard' },
  { id: 'n88', word: 'Fossil', category: 'Nature', difficulty: 'medium' },
  { id: 'n89', word: 'Mineral', category: 'Nature', difficulty: 'medium' },
  { id: 'n90', word: 'Magma', category: 'Nature', difficulty: 'medium' },
  { id: 'n91', word: 'Lava', category: 'Nature', difficulty: 'easy' },
  { id: 'n92', word: 'Ash', category: 'Nature', difficulty: 'easy' },
  { id: 'n93', word: 'Sand', category: 'Nature', difficulty: 'easy' },
  { id: 'n94', word: 'Soil', category: 'Nature', difficulty: 'easy' },
  { id: 'n95', word: 'Mud', category: 'Nature', difficulty: 'easy' },
  { id: 'n96', word: 'Clay', category: 'Nature', difficulty: 'easy' },
  { id: 'n97', word: 'Pearl', category: 'Nature', difficulty: 'medium' },
  { id: 'n98', word: 'Amber', category: 'Nature', difficulty: 'medium' },
  { id: 'n99', word: 'Quicksand', category: 'Nature', difficulty: 'hard' },
  { id: 'n100', word: 'Sandstorm', category: 'Nature', difficulty: 'hard' },

  // Food
  { id: 'f1', word: 'Pizza', category: 'Food', difficulty: 'easy' },
  { id: 'f2', word: 'Ice Cream', category: 'Food', difficulty: 'easy' },
  { id: 'f3', word: 'Spaghetti', category: 'Food', difficulty: 'medium' },
  { id: 'f4', word: 'Hamburger', category: 'Food', difficulty: 'easy' },
  { id: 'f5', word: 'Cake', category: 'Food', difficulty: 'easy' },
  { id: 'f6', word: 'Apple', category: 'Food', difficulty: 'easy' },
  { id: 'f7', word: 'Banana', category: 'Food', difficulty: 'easy' },
  { id: 'f8', word: 'Orange', category: 'Food', difficulty: 'easy' },
  { id: 'f9', word: 'Strawberry', category: 'Food', difficulty: 'easy' },
  { id: 'f10', word: 'Grapes', category: 'Food', difficulty: 'easy' },
  { id: 'f11', word: 'Carrot', category: 'Food', difficulty: 'easy' },
  { id: 'f12', word: 'Broccoli', category: 'Food', difficulty: 'easy' },
  { id: 'f13', word: 'Corn', category: 'Food', difficulty: 'easy' },
  { id: 'f14', word: 'Potato', category: 'Food', difficulty: 'easy' },
  { id: 'f15', word: 'Tomato', category: 'Food', difficulty: 'easy' },
  { id: 'f16', word: 'Bread', category: 'Food', difficulty: 'easy' },
  { id: 'f17', word: 'Cheese', category: 'Food', difficulty: 'easy' },
  { id: 'f18', word: 'Milk', category: 'Food', difficulty: 'easy' },
  { id: 'f19', word: 'Eggs', category: 'Food', difficulty: 'easy' },
  { id: 'f20', word: 'Chicken', category: 'Food', difficulty: 'easy' }, 
  { id: 'f21', word: 'Steak', category: 'Food', difficulty: 'medium' },
  { id: 'f22', word: 'Fish', category: 'Food', difficulty: 'medium' },
  { id: 'f23', word: 'Soup', category: 'Food', difficulty: 'easy' },
  { id: 'f24', word: 'Salad', category: 'Food', difficulty: 'easy' },
  { id: 'f25', word: 'Sandwich', category: 'Food', difficulty: 'easy' },
  { id: 'f26', word: 'Taco', category: 'Food', difficulty: 'easy' },
  { id: 'f27', word: 'Burrito', category: 'Food', difficulty: 'medium' },
  { id: 'f28', word: 'Sushi', category: 'Food', difficulty: 'medium' },
  { id: 'f29', word: 'Pancake', category: 'Food', difficulty: 'medium' },
  { id: 'f30', word: 'Waffle', category: 'Food', difficulty: 'medium' },
  { id: 'f31', word: 'Muffin', category: 'Food', difficulty: 'medium' },
  { id: 'f32', word: 'Cookie', category: 'Food', difficulty: 'easy' },
  { id: 'f33', word: 'Donut', category: 'Food', difficulty: 'easy' },
  { id: 'f34', word: 'Pie', category: 'Food', difficulty: 'easy' },
  { id: 'f35', word: 'Chocolate', category: 'Food', difficulty: 'easy' },
  { id: 'f36', word: 'Candy', category: 'Food', difficulty: 'easy' },
  { id: 'f37', word: 'Popcorn', category: 'Food', difficulty: 'easy' },
  { id: 'f38', word: 'Pretzel', category: 'Food', difficulty: 'medium' },
  { id: 'f39', word: 'Lemonade', category: 'Food', difficulty: 'medium' },
  { id: 'f40', word: 'Coffee', category: 'Food', difficulty: 'easy' },
  { id: 'f41', word: 'Tea', category: 'Food', difficulty: 'easy' },
  { id: 'f42', word: 'Juice', category: 'Food', difficulty: 'easy' },
  { id: 'f43', word: 'Soda', category: 'Food', difficulty: 'easy' },
  { id: 'f44',  word: 'Watermelon', category: 'Food', difficulty: 'easy' },
  { id: 'f45', word: 'Pineapple', category: 'Food', difficulty: 'medium' },
  { id: 'f46', word: 'Avocado', category: 'Food', difficulty: 'medium' },
  { id: 'f47', word: 'Cucumber', category: 'Food', difficulty: 'medium' },
  { id: 'f48', word: 'Onion', category: 'Food', difficulty: 'medium' },
  { id: 'f49', word: 'Garlic', category: 'Food', difficulty: 'medium' },
  { id: 'f50', word: 'Ginger', category: 'Food', difficulty: 'hard' },
  { id: 'f51', word: 'Mango', category: 'Food', difficulty: 'easy' },
  { id: 'f52', word: 'Kiwi', category: 'Food', difficulty: 'medium' },
  { id: 'f53', word: 'Peach', category: 'Food', difficulty: 'easy' },
  { id: 'f54', word: 'Plum', category: 'Food', difficulty: 'easy' },
  { id: 'f55', word: 'Cherry', category: 'Food', difficulty: 'easy' },
  { id: 'f56', word: 'Lemon', category: 'Food', difficulty: 'easy' },
  { id: 'f57', word: 'Lime', category: 'Food', difficulty: 'easy' },
  { id: 'f58', word: 'Raspberry', category: 'Food', difficulty: 'medium' },
  { id: 'f59', word: 'Blueberry', category: 'Food', difficulty: 'medium' },
  { id: 'f60', word: 'Blackberry', category: 'Food', difficulty: 'medium' },
  { id: 'f61', word: 'Spinach', category: 'Food', difficulty: 'medium' },
  { id: 'f62', word: 'Lettuce', category: 'Food', difficulty: 'medium' },
  { id: 'f63', word: 'Celery', category: 'Food', difficulty: 'medium' },
  { id: 'f64', word: 'Asparagus', category: 'Food', difficulty: 'hard' },
  { id: 'f65', word: 'Cauliflower', category: 'Food', difficulty: 'hard' },
  { id: 'f66', word: 'Peas', category: 'Food', difficulty: 'easy' },
  { id: 'f67', word: 'Beans', category: 'Food', difficulty: 'easy' },
  { id: 'f68', word: 'Rice', category: 'Food', difficulty: 'easy' },
  { id: 'f69', word: 'Noodles', category: 'Food', difficulty: 'easy' },
  { id: 'f70', word: 'Pasta', category: 'Food', difficulty: 'easy' },
  { id: 'f71', word: 'Lasagna', category: 'Food', difficulty: 'medium' },
  { id: 'f72', word: 'Ravioli', category: 'Food', difficulty: 'medium' },
  { id: 'f73', word: 'Nachos', category: 'Food', difficulty: 'medium' },
  { id: 'f74', word: 'Quesadilla', category: 'Food', difficulty: 'hard' },
  { id: 'f75', word: 'Enchilada', category: 'Food', difficulty: 'hard' },
  { id: 'f76', word: 'Pork', category: 'Food', difficulty: 'easy' },
  { id: 'f77', word: 'Bacon', category: 'Food', difficulty: 'easy' },
  { id: 'f78', word: 'Ham', category: 'Food', difficulty: 'easy' },
  { id: 'f79', word: 'Turkey', category: 'Food', difficulty: 'easy' },
  { id: 'f80', word: 'Shrimp', category: 'Food', difficulty: 'medium' },
  { id: 'f81', word: 'Crab', category: 'Food', difficulty: 'medium' },
  { id: 'f82', word: 'Lobster', category: 'Food', difficulty: 'medium' },
  { id: 'f83', word: 'Oyster', category: 'Food', difficulty: 'hard' },
  { id: 'f84', word: 'Clam', category: 'Food', difficulty: 'medium' },
  { id: 'f85', word: 'Yogurt', category: 'Food', difficulty: 'medium' },
  { id: 'f86', word: 'Butter', category: 'Food', difficulty: 'easy' },
  { id: 'f87', word: 'Cream', category: 'Food', difficulty: 'easy' },
  { id: 'f88', word: 'Honey', category: 'Food', difficulty: 'easy' },
  { id: 'f89', word: 'Jam', category: 'Food', difficulty: 'easy' },
  { id: 'f90', word: 'Cereal', category: 'Food', difficulty: 'easy' },
  { id: 'f91', word: 'Oatmeal', category: 'Food', difficulty: 'medium' },
  { id: 'f92', word: 'Bagel', category: 'Food', difficulty: 'medium' },
  { id: 'f93', word: 'Toast', category: 'Food', difficulty: 'easy' },
  { id: 'f94', word: 'Biscuit', category: 'Food', difficulty: 'medium' },
  { id: 'f95', word: 'Croissant', category: 'Food', difficulty: 'medium' },
  { id: 'f96', word: 'Pudding', category: 'Food', difficulty: 'medium' },
  { id: 'f97', word: 'Jelly', category: 'Food', difficulty: 'easy' },
  { id: 'f98', word: 'Custard', category: 'Food', difficulty: 'medium' },
  { id: 'f99', word: 'Brownie', category: 'Food', difficulty: 'medium' },
  { id: 'f100', word: 'Cupcake', category: 'Food', difficulty: 'medium' },

  // Sports
  { id: 's1', word: 'Basketball', category: 'Sports', difficulty: 'easy' },
  { id: 's2', word: 'Soccer', category: 'Sports', difficulty: 'easy' },
  { id: 's3', word: 'Tennis', category: 'Sports', difficulty: 'easy' },
  { id: 's4', word: 'Volleyball', category: 'Sports', difficulty: 'medium' },
  { id: 's5', word: 'Surfing', category: 'Sports', difficulty: 'medium' },
  { id: 's6', word: 'Baseball', category: 'Sports', difficulty: 'easy' },
  { id: 's7', word: 'Football', category: 'Sports', difficulty: 'easy' },
  { id: 's8', word: 'Hockey', category: 'Sports', difficulty: 'easy' },
  { id: 's9', word: 'Golf', category: 'Sports', difficulty: 'medium' },
  { id: 's10', word: 'Swimming', category: 'Sports', difficulty: 'easy' },
  { id: 's11', word: 'Running', category: 'Sports', difficulty: 'easy' },
  { id: 's12', word: 'Cycling', category: 'Sports', difficulty: 'easy' },
  { id: 's13', word: 'Skiing', category: 'Sports', difficulty: 'medium' },
  { id: 's14', word: 'Snowboarding', category: 'Sports', difficulty: 'medium' },
  { id: 's15', word: 'Skateboarding', category: 'Sports', difficulty: 'medium' },
  { id: 's16', word: 'Gymnastics', category: 'Sports', difficulty: 'hard' },
  { id: 's17', word: 'Karate', category: 'Sports', difficulty: 'medium' },
  { id: 's18', word: 'Boxing', category: 'Sports', difficulty: 'medium' },
  { id: 's19', word: 'Wrestling', category: 'Sports', difficulty: 'medium' },
  { id: 's20', word: 'Weightlifting', category: 'Sports', difficulty: 'medium' },
  { id: 's21', word: 'Bowling', category: 'Sports', difficulty: 'medium' },
  { id: 's22', word: 'Archery', category: 'Sports', difficulty: 'medium' },
  { id: 's23', word: 'Horse Racing', category: 'Sports', difficulty: 'hard' },
  { id: 's24', word: 'Car Racing', category: 'Sports', difficulty: 'medium' },
  { id: 's25', word: 'Sailing', category: 'Sports', difficulty: 'medium' },
  { id: 's26', word: 'Rock Climbing', category: 'Sports', difficulty: 'hard' },
  { id: 's27', word: 'Hiking', category: 'Sports', difficulty: 'medium' },
  { id: 's28', word: 'Fishing', category: 'Sports', difficulty: 'medium' },
  { id: 's29', word: 'Skydiving', category: 'Sports', difficulty: 'hard' },
  { id: 's30', word: 'Bungee Jumping', category: 'Sports', difficulty: 'hard' },
  { id: 's31', word: 'Table Tennis', category: 'Sports', difficulty: 'medium' },
  { id: 's32', word: 'Badminton', category: 'Sports', difficulty: 'medium' },
  { id: 's33', word: 'Cricket', category: 'Sports', difficulty: 'medium' },
  { id: 's34', word: 'Rugby', category: 'Sports', difficulty: 'medium' },
  { id: 's35', word: 'Fencing', category: 'Sports', difficulty: 'hard' },
  { id: 's36', word: 'Diving', category: 'Sports', difficulty: 'medium' },
  { id: 's37', word: 'Snorkeling', category: 'Sports', difficulty: 'medium' },
  { id: 's38', word: 'Water Polo', category: 'Sports', difficulty: 'hard' },
  { id: 's39', word: 'Synchronized Swimming', category: 'Sports', difficulty: 'hard' },
  { id: 's40', word: 'Cheerleading', category: 'Sports', difficulty: 'medium' },
  { id: 's41', word: 'Parkour', category: 'Sports', difficulty: 'hard' },
  { id: 's42', word: 'Martial Arts', category: 'Sports', difficulty: 'medium' },
  { id: 's43', word: 'Equestrian', category: 'Sports', difficulty: 'hard' },
  { id: 's44', word: 'Shooting', category: 'Sports', difficulty: 'medium' },
  { id: 's45', word: 'Pool', category: 'Sports', difficulty: 'medium' },
  { id: 's46', word: 'Darts', category: 'Sports', difficulty: 'medium' },
  { id: 's47', word: 'Shuffleboard', category: 'Sports', difficulty: 'medium' },
  { id: 's48', word: 'Curling', category: 'Sports', difficulty: 'hard' },
  { id: 's49', word: 'Lacrosse', category: 'Sports', difficulty: 'medium' },
  { id: 's50', word: 'Handball', category: 'Sports', difficulty: 'medium' },
  { id: 's51', word: 'Ultimate Frisbee', category: 'Sports', difficulty: 'medium' },
  { id: 's52', word: 'Cross Country', category: 'Sports', difficulty: 'medium' },
  { id: 's53', word: 'Marathon', category: 'Sports', difficulty: 'hard' },
  { id: 's54', word: 'Triathlon', category: 'Sports', difficulty: 'hard' },
  { id: 's55', word: 'Decathlon', category: 'Sports', difficulty: 'hard' },
  { id: 's56', word: 'Pole Vault', category: 'Sports', difficulty: 'hard' },
  { id: 's57', word: 'High Jump', category: 'Sports', difficulty: 'medium' },
  { id: 's58', word: 'Long Jump', category: 'Sports', difficulty: 'medium' },
  { id: 's59', word: 'Triple Jump', category: 'Sports', difficulty: 'hard' },
  { id: 's60', word: 'Shot Put', category: 'Sports', difficulty: 'medium' },
  { id: 's61', word: 'Discus', category: 'Sports', difficulty: 'medium' },
  { id: 's62', word: 'Javelin', category: 'Sports', difficulty: 'medium' },
  { id: 's63', word: 'Hammer Throw', category: 'Sports', difficulty: 'hard' },
  { id: 's64', word: 'Ice Skating', category: 'Sports', difficulty: 'medium' },
  { id: 's65', word: 'Figure Skating', category: 'Sports', difficulty: 'hard' },
  { id: 's66', word: 'Speed Skating', category: 'Sports', difficulty: 'medium' },
  { id: 's67', word: 'Roller Skating', category: 'Sports', difficulty: 'medium' },
  { id: 's68', word: 'Roller Derby', category: 'Sports', difficulty: 'hard' },
  { id: 's69', word: 'BMX', category: 'Sports', difficulty: 'medium' },
  { id: 's70', word: 'Mountain Biking', category: 'Sports', difficulty: 'medium' },
  { id: 's71', word: 'Rowing', category: 'Sports', difficulty: 'medium' },
  { id: 's72', word: 'Canoeing', category: 'Sports', difficulty: 'medium' },
  { id: 's73', word: 'Kayaking', category: 'Sports', difficulty: 'medium' },
  { id: 's74', word: 'Rafting', category: 'Sports', difficulty: 'medium' },
  { id: 's75', word: 'Paddleboarding', category: 'Sports', difficulty: 'medium' },
  { id: 's76', word: 'Windsurfing', category: 'Sports', difficulty: 'hard' },
  { id: 's77', word: 'Kitesurfing', category: 'Sports', difficulty: 'hard' },
  { id: 's78', word: 'Paragliding', category: 'Sports', difficulty: 'hard' },
  { id: 's79', word: 'Hang Gliding', category: 'Sports', difficulty: 'hard' },
  { id: 's80', word: 'Base Jumping', category: 'Sports', difficulty: 'hard' },
  { id: 's81', word: 'Sumo Wrestling', category: 'Sports', difficulty: 'hard' },
  { id: 's82', word: 'Judo', category: 'Sports', difficulty: 'medium' },
  { id: 's83', word: 'Taekwondo', category: 'Sports', difficulty: 'medium' },
  { id: 's84', word: 'Kickboxing', category: 'Sports', difficulty: 'medium' },
  { id: 's85', word: 'Mixed Martial Arts', category: 'Sports', difficulty: 'hard' },
  { id: 's86', word: 'Paintball', category: 'Sports', difficulty: 'medium' },
  { id: 's87', word: 'Laser Tag', category: 'Sports', difficulty: 'medium' },
  { id: 's88', word: 'Airsoft', category: 'Sports', difficulty: 'medium' },
  { id: 's89', word: 'Dodgeball', category: 'Sports', difficulty: 'easy' },
  { id: 's90', word: 'Kickball', category: 'Sports', difficulty: 'easy' },
  { id: 's91', word: 'Pickleball', category: 'Sports', difficulty: 'medium' },
  { id: 's92', word: 'Racquetball', category: 'Sports', difficulty: 'medium' },
  { id: 's93', word: 'Squash', category: 'Sports', difficulty: 'medium' },
  { id: 's94', word: 'Sepak Takraw', category: 'Sports', difficulty: 'hard' },
  { id: 's95', word: 'Jai Alai', category: 'Sports', difficulty: 'hard' },
  { id: 's96', word: 'Polo', category: 'Sports', difficulty: 'hard' },
  { id: 's97', word: 'Water Skiing', category: 'Sports', difficulty: 'hard' },
  { id: 's98', word: 'Wakeboarding', category: 'Sports', difficulty: 'hard' },
  { id: 's99', word: 'Bodyboarding', category: 'Sports', difficulty: 'medium' },
  { id: 's100', word: 'Ice Hockey', category: 'Sports', difficulty: 'medium' },

  // Emotions
  { id: 'e1', word: 'Happy', category: 'Emotions', difficulty: 'easy' },
  { id: 'e2', word: 'Sad', category: 'Emotions', difficulty: 'easy' },
  { id: 'e3', word: 'Angry', category: 'Emotions', difficulty: 'easy' },
  { id: 'e4', word: 'Surprised', category: 'Emotions', difficulty: 'medium' },
  { id: 'e5', word: 'Scared', category: 'Emotions', difficulty: 'medium' },
  { id: 'e6', word: 'Excited', category: 'Emotions', difficulty: 'easy' },
  { id: 'e7', word: 'Confused', category: 'Emotions', difficulty: 'medium' },
  { id: 'e8', word: 'Worried', category: 'Emotions', difficulty: 'medium' },
  { id: 'e9', word: 'Bored', category: 'Emotions', difficulty: 'easy' },
  { id: 'e10', word: 'Tired', category: 'Emotions', difficulty: 'easy' },
  { id: 'e11', word: 'Hungry', category: 'Emotions', difficulty: 'easy' },
  { id: 'e12', word: 'Thirsty', category: 'Emotions', difficulty: 'easy' },
  { id: 'e13', word: 'Cold', category: 'Emotions', difficulty: 'easy' },
  { id: 'e14', word: 'Hot', category: 'Emotions', difficulty: 'easy' },
  { id: 'e15', word: 'Sick', category: 'Emotions', difficulty: 'medium' },
  { id: 'e16', word: 'Painful', category: 'Emotions', difficulty: 'medium' },
  { id: 'e17', word: 'Embarrassed', category: 'Emotions', difficulty: 'medium' },
  { id: 'e18', word: 'Shy', category: 'Emotions', difficulty: 'medium' },
  { id: 'e19', word: 'Proud', category: 'Emotions', difficulty: 'easy' },
  { id: 'e20', word: 'Jealous', category: 'Emotions', difficulty: 'medium' },
  { id: 'e21', word: 'Anxious', category: 'Emotions', difficulty: 'medium' },
  { id: 'e22', word: 'Stressed', category: 'Emotions', difficulty: 'medium' },
  { id: 'e23', word: 'Depressed', category: 'Emotions', difficulty: 'hard' },
  { id: 'e24', word: 'Angry', category: 'Emotions', difficulty: 'medium' },
  { id: 'e25', word: 'Frustrated', category: 'Emotions', difficulty: 'medium' },
  { id: 'e26', word: 'Disappointed', category: 'Emotions', difficulty: 'medium' },
  { id: 'e27', word: 'Confused', category: 'Emotions', difficulty: 'medium' },
  { id: 'e28', word: 'Surprised', category: 'Emotions', difficulty: 'medium' },
  { id: 'e29', word: 'Shocked', category: 'Emotions', difficulty: 'medium' },
  { id: 'e30', word: 'Afraid', category: 'Emotions', difficulty: 'medium' },
  { id: 'e31', word: 'Scared', category: 'Emotions', difficulty: 'medium' },
  { id: 'e32', word: 'Terrified', category: 'Emotions', difficulty: 'hard' },
  { id: 'e33', word: 'Lonely', category: 'Emotions', difficulty: 'medium' },
  { id: 'e34', word: 'Sad', category: 'Emotions', difficulty: 'easy' },
  { id: 'e35', word: 'Melancholic', category: 'Emotions', difficulty: 'hard' },
  { id: 'e36', word: 'Upset', category: 'Emotions', difficulty: 'medium' },
  { id: 'e37', word: 'Angry', category: 'Emotions', difficulty: 'medium' },
  { id: 'e38', word: 'Furious', category: 'Emotions', difficulty: 'hard' },
  { id: 'e39', word: 'Enraged', category: 'Emotions', difficulty: 'hard' },
  { id: 'e40', word: 'Calm', category: 'Emotions', difficulty: 'easy' },
  { id: 'e41', word: 'Peaceful', category: 'Emotions', difficulty: 'easy' },
  { id: 'e42', word: 'Relaxed', category: 'Emotions', difficulty: 'easy' },
  { id: 'e43', word: 'Content', category: 'Emotions', difficulty: 'easy' },
  { id: 'e44', word: 'Satisfied', category: 'Emotions', difficulty: 'easy' },
  { id: 'e45', word: 'Happy', category: 'Emotions', difficulty: 'easy' },
  { id: 'e46', word: 'Joyful', category: 'Emotions', difficulty: 'easy' },
  { id: 'e47', word: 'Ecstatic', category: 'Emotions', difficulty: 'hard' },
  { id: 'e48', word: 'Euphoric', category: 'Emotions', difficulty: 'hard' },
  { id: 'e49', word: 'Thrilled', category: 'Emotions', difficulty: 'medium' },
  { id: 'e50', word: 'Excited', category: 'Emotions', difficulty: 'easy' },
  { id: 'e51', word: 'Grateful', category: 'Emotions', difficulty: 'medium' },
  { id: 'e52', word: 'Thankful', category: 'Emotions', difficulty: 'medium' },
  { id: 'e53', word: 'Appreciated', category: 'Emotions', difficulty: 'medium' },
  { id: 'e54', word: 'Valued', category: 'Emotions', difficulty: 'medium' },
  { id: 'e55', word: 'Loved', category: 'Emotions', difficulty: 'easy' },
  { id: 'e56', word: 'Adored', category: 'Emotions', difficulty: 'medium' },
  { id: 'e57', word: 'Cherished', category: 'Emotions', difficulty: 'hard' },
  { id: 'e58', word: 'Respected', category: 'Emotions', difficulty: 'medium' },
  { id: 'e59', word: 'Honored', category: 'Emotions', difficulty: 'medium' },
  { id: 'e60', word: 'Proud', category: 'Emotions', difficulty: 'easy' },
  { id: 'e61', word: 'Confident', category: 'Emotions', difficulty: 'medium' },
  { id: 'e62', word: 'Brave', category: 'Emotions', difficulty: 'medium' },
  { id: 'e63', word: 'Courageous', category: 'Emotions', difficulty: 'medium' },
  { id: 'e64', word: 'Determined', category: 'Emotions', difficulty: 'medium' },
  { id: 'e65', word: 'Motivated', category: 'Emotions', difficulty: 'medium' },
  { id: 'e66', word: 'Inspired', category: 'Emotions', difficulty: 'medium' },
  { id: 'e67', word: 'Energetic', category: 'Emotions', difficulty: 'medium' },
  { id: 'e68', word: 'Enthusiastic', category: 'Emotions', difficulty: 'hard' },
  { id: 'e69', word: 'Optimistic', category: 'Emotions', difficulty: 'hard' },
  { id: 'e70', word: 'Hopeful', category: 'Emotions', difficulty: 'medium' },
  { id: 'e71', word: 'Amused', category: 'Emotions', difficulty: 'medium' },
  { id: 'e72', word: 'Playful', category: 'Emotions', difficulty: 'medium' },
  { id: 'e73', word: 'Silly', category: 'Emotions', difficulty: 'easy' },
  { id: 'e74', word: 'Goofy', category: 'Emotions', difficulty: 'medium' },
  { id: 'e75', word: 'Giggly', category: 'Emotions', difficulty: 'medium' },
  { id: 'e76', word: 'Hysterical', category: 'Emotions', difficulty: 'hard' },
  { id: 'e77', word: 'Delighted', category: 'Emotions', difficulty: 'medium' },
  { id: 'e78', word: 'Cheerful', category: 'Emotions', difficulty: 'medium' },
  { id: 'e79', word: 'Merry', category: 'Emotions', difficulty: 'medium' },
  { id: 'e80', word: 'Jolly', category: 'Emotions', difficulty: 'medium' },
  { id: 'e81', word: 'Distressed', category: 'Emotions', difficulty: 'hard' },
  { id: 'e82', word: 'Troubled', category: 'Emotions', difficulty: 'medium' },
  { id: 'e83', word: 'Uneasy', category: 'Emotions', difficulty: 'medium' },
  { id: 'e84', word: 'Tense', category: 'Emotions', difficulty: 'medium' },
  { id: 'e85', word: 'Nervous', category: 'Emotions', difficulty: 'medium' },
  { id: 'e86', word: 'Insecure', category: 'Emotions', difficulty: 'medium' },
  { id: 'e87', word: 'Uncertain', category: 'Emotions', difficulty: 'medium' },
  { id: 'e88', word: 'Doubtful', category: 'Emotions', difficulty: 'medium' },
  { id: 'e89', word: 'Hesitant', category: 'Emotions', difficulty: 'medium' },
  { id: 'e90', word: 'Reluctant', category: 'Emotions', difficulty: 'hard' },
  { id: 'e91', word: 'Overwhelmed', category: 'Emotions', difficulty: 'hard' },
  { id: 'e92', word: 'Exhausted', category: 'Emotions', difficulty: 'medium' },
  { id: 'e93', word: 'Drained', category: 'Emotions', difficulty: 'medium' },
  { id: 'e94', word: 'Weary', category: 'Emotions', difficulty: 'medium' },
  { id: 'e95', word: 'Fatigued', category: 'Emotions', difficulty: 'hard' },
  { id: 'e96', word: 'Nostalgic', category: 'Emotions', difficulty: 'hard' },
  { id: 'e97', word: 'Sentimental', category: 'Emotions', difficulty: 'hard' },
  { id: 'e98', word: 'Wistful', category: 'Emotions', difficulty: 'hard' },
  { id: 'e99', word: 'Homesick', category: 'Emotions', difficulty: 'medium' },
  { id: 'e100', word: 'Heartbroken', category: 'Emotions', difficulty: 'hard' },

  // Places
  { id: 'p1', word: 'Castle', category: 'Places', difficulty: 'medium' },
  { id: 'p2', word: 'Park', category: 'Places', difficulty: 'easy' },
  { id: 'p3', word: 'School', category: 'Places', difficulty: 'easy' },
  { id: 'p4', word: 'Hospital', category: 'Places', difficulty: 'medium' },
  { id: 'p5', word: 'Zoo', category: 'Places', difficulty: 'easy' },
  { id: 'p6', word: 'Library', category: 'Places', difficulty: 'easy' },
  { id: 'p7', word: 'Museum', category: 'Places', difficulty: 'easy' },
  { id: 'p8', word: 'Airport', category: 'Places', difficulty: 'easy' },
  { id: 'p9', word: 'Train Station', category: 'Places', difficulty: 'easy' },
  { id: 'p10', word: 'Bus Station', category: 'Places', difficulty: 'easy' },
  { id: 'p11', word: 'Bank', category: 'Places', difficulty: 'easy' },
  { id: 'p12', word: 'Post Office', category: 'Places', difficulty: 'easy' },
  { id: 'p13', word: 'Police Station', category: 'Places', difficulty: 'easy' },
  { id: 'p14', word: 'Fire Station', category: 'Places', difficulty: 'easy' },
  { id: 'p15', word: 'Church', category: 'Places', difficulty: 'easy' },
  { id: 'p16', word: 'Temple', category: 'Places', difficulty: 'medium' },
  { id: 'p17', word: 'Mosque', category: 'Places', difficulty: 'medium' },
  { id: 'p18', word: 'Synagogue', category: 'Places', difficulty: 'hard' },
  { id: 'p19', word: 'Stadium', category: 'Places', difficulty: 'easy' },
  { id: 'p20', word: 'Theater', category: 'Places', difficulty: 'easy' },
  { id: 'p21', word: 'Cinema', category: 'Places', difficulty: 'easy' },
  { id: 'p22', word: 'Mall', category: 'Places', difficulty: 'easy' },
  { id: 'p23', word: 'Supermarket', category: 'Places', difficulty: 'easy' },
  { id: 'p24', word: 'Cafe', category: 'Places', difficulty: 'easy' },
  { id: 'p25', word: 'Restaurant', category: 'Places', difficulty: 'easy' },
  { id: 'p26', word: 'Bar', category: 'Places', difficulty: 'easy' },
  { id: 'p27', word: 'Hotel', category: 'Places', difficulty: 'easy' },
  { id: 'p28', word: 'Hospital', category: 'Places', difficulty: 'easy' },
  { id: 'p29', word: 'Prison', category: 'Places', difficulty: 'medium' },
  { id: 'p30', word: 'Courthouse', category: 'Places', difficulty: 'medium' },
  { id: 'p31', word: 'Graveyard', category: 'Places', difficulty: 'medium' },
  { id: 'p32', word: 'Factory', category: 'Places', difficulty: 'medium' },
  { id: 'p33', word: 'Farm', category: 'Places', difficulty: 'easy' },
  { id: 'p34', word: 'Amusement Park', category: 'Places', difficulty: 'easy' },
  { id: 'p35', word: 'Aquarium', category: 'Places', difficulty: 'easy' },
  { id: 'p36', word: 'Planetarium', category: 'Places', difficulty: 'medium' },
  { id: 'p37', word: 'Observatory', category: 'Places', difficulty: 'hard' },
  { id: 'p38', word: 'Art Gallery', category: 'Places', difficulty: 'medium' },
  { id: 'p39', word: 'University', category: 'Places', difficulty: 'easy' },
  { id: 'p40', word: 'Playground', category: 'Places', difficulty: 'easy' },
  { id: 'p41', word: 'Gym', category: 'Places', difficulty: 'easy' },
  { id: 'p42', word: 'Spa', category: 'Places', difficulty: 'easy' },
  { id: 'p43', word: 'Hair Salon', category: 'Places', difficulty: 'easy' },
  { id: 'p44', word: 'Laundromat', category: 'Places', difficulty: 'medium' },
  { id: 'p45', word: 'Car Wash', category: 'Places', difficulty: 'medium' },
  { id: 'p46', word: 'Construction Site', category: 'Places', difficulty: 'medium' },
  { id: 'p47', word: 'Military Base', category: 'Places', difficulty: 'hard' },
  { id: 'p48', word: 'Space Station', category: 'Places', difficulty: 'hard' },
  { id: 'p49', word: 'Submarine', category: 'Places', difficulty: 'hard' },
  { id: 'p50', word: 'Lighthouse', category: 'Places', difficulty: 'medium' },
  { id: 'p51', word: 'Bookstore', category: 'Places', difficulty: 'easy' },
  { id: 'p52', word: 'Pharmacy', category: 'Places', difficulty: 'easy' },
  { id: 'p53', word: 'Pet Shop', category: 'Places', difficulty: 'easy' },
  { id: 'p54', word: 'Hardware Store', category: 'Places', difficulty: 'medium' },
  { id: 'p55', word: 'Bakery', category: 'Places', difficulty: 'easy' },
  { id: 'p56', word: 'Butcher Shop', category: 'Places', difficulty: 'medium' },
  { id: 'p57', word: 'Florist', category: 'Places', difficulty: 'medium' },
  { id: 'p58', word: 'Garden Center', category: 'Places', difficulty: 'medium' },
  { id: 'p59', word: 'Skating Rink', category: 'Places', difficulty: 'medium' },
  { id: 'p60', word: 'Bowling Alley', category: 'Places', difficulty: 'medium' },
  { id: 'p61', word: 'Arcade', category: 'Places', difficulty: 'medium' },
  { id: 'p62', word: 'Golf Course', category: 'Places', difficulty: 'medium' },
  { id: 'p63', word: 'Tennis Court', category: 'Places', difficulty: 'medium' },
  { id: 'p64', word: 'Basketball Court', category: 'Places', difficulty: 'medium' },
  { id: 'p65', word: 'Swimming Pool', category: 'Places', difficulty: 'easy' },
  { id: 'p66', word: 'Beach House', category: 'Places', difficulty: 'medium' },
  { id: 'p67', word: 'Mountain Cabin', category: 'Places', difficulty: 'medium' },
  { id: 'p68', word: 'Ranch', category: 'Places', difficulty: 'medium' },
  { id: 'p69', word: 'Vineyard', category: 'Places', difficulty: 'hard' },
  { id: 'p70', word: 'Orchard', category: 'Places', difficulty: 'medium' },
  { id: 'p71', word: 'Greenhouse', category: 'Places', difficulty: 'medium' },
  { id: 'p72', word: 'Barn', category: 'Places', difficulty: 'easy' },
  { id: 'p73', word: 'Silo', category: 'Places', difficulty: 'medium' },
  { id: 'p74', word: 'Windmill', category: 'Places', difficulty: 'medium' },
  { id: 'p75', word: 'Bridge', category: 'Places', difficulty: 'easy' },
  { id: 'p76', word: 'Tunnel', category: 'Places', difficulty: 'easy' },
  { id: 'p77', word: 'Dam', category: 'Places', difficulty: 'medium' },
  { id: 'p78', word: 'Power Plant', category: 'Places', difficulty: 'hard' },
  { id: 'p79', word: 'Oil Rig', category: 'Places', difficulty: 'hard' },
  { id: 'p80', word: 'Mine', category: 'Places', difficulty: 'medium' },
  { id: 'p81', word: 'Quarry', category: 'Places', difficulty: 'hard' },
  { id: 'p82', word: 'Shipyard', category: 'Places', difficulty: 'hard' },
  { id: 'p83', word: 'Dock', category: 'Places', difficulty: 'medium' },
  { id: 'p84', word: 'Marina', category: 'Places', difficulty: 'medium' },
  { id: 'p85', word: 'Harbor', category: 'Places', difficulty: 'medium' },
  { id: 'p86', word: 'Port', category: 'Places', difficulty: 'medium' },
  { id: 'p87', word: 'Pier', category: 'Places', difficulty: 'medium' },
  { id: 'p88', word: 'Boardwalk', category: 'Places', difficulty: 'medium' },
  { id: 'p89', word: 'Carnival', category: 'Places', difficulty: 'medium' },
  { id: 'p90', word: 'Fair Ground', category: 'Places', difficulty: 'medium' },
  { id: 'p91', word: 'Circus', category: 'Places', difficulty: 'medium' },
  { id: 'p92', word: 'Concert Hall', category: 'Places', difficulty: 'medium' },
  { id: 'p93', word: 'Opera House', category: 'Places', difficulty: 'hard' },
  { id: 'p94', word: 'Ballet Studio', category: 'Places', difficulty: 'hard' },
  { id: 'p95', word: 'Recording Studio', category: 'Places', difficulty: 'hard' },
  { id: 'p96', word: 'Broadcasting Station', category: 'Places', difficulty: 'hard' },
  { id: 'p97', word: 'News Studio', category: 'Places', difficulty: 'hard' },
  { id: 'p98', word: 'Film Studio', category: 'Places', difficulty: 'hard' },
  { id: 'p99', word: 'Theme Park', category: 'Places', difficulty: 'easy' },
  { id: 'p100', word: 'Water Park', category: 'Places', difficulty: 'easy' },

  // Professions
  { id: 'pr1', word: 'Doctor', category: 'Professions', difficulty: 'easy' },
  { id: 'pr2', word: 'Chef', category: 'Professions', difficulty: 'easy' },
  { id: 'pr3', word: 'Firefighter', category: 'Professions', difficulty: 'medium' },
  { id: 'pr4', word: 'Teacher', category: 'Professions', difficulty: 'easy' },
  { id: 'pr5', word: 'Artist', category: 'Professions', difficulty: 'medium' },
  { id: 'pr6', word: 'Nurse', category: 'Professions', difficulty: 'easy' },
  { id: 'pr7', word: 'Police Officer', category: 'Professions', difficulty: 'easy' },
  { id: 'pr8', word: 'Pilot', category: 'Professions', difficulty: 'medium' },
  { id: 'pr9', word: 'Astronaut', category: 'Professions', difficulty: 'medium' },
  { id: 'pr10', word: 'Scientist', category: 'Professions', difficulty: 'medium' },
  { id: 'pr11', word: 'Engineer', category: 'Professions', difficulty: 'medium' },
  { id: 'pr12', word: 'Architect', category: 'Professions', difficulty: 'hard' },
  { id: 'pr13', word: 'Lawyer', category: 'Professions', difficulty: 'medium' },
  { id: 'pr14', word: 'Judge', category: 'Professions', difficulty: 'medium' },
  { id: 'pr15', word: 'Accountant', category: 'Professions', difficulty: 'medium' },
  { id: 'pr16', word: 'Veterinarian', category: 'Professions', difficulty: 'medium' },
  { id: 'pr17', word: 'Dentist', category: 'Professions', difficulty: 'medium' },
  { id: 'pr18', word: 'Pharmacist', category: 'Professions', difficulty: 'hard' },
  { id: 'pr19', word: 'Therapist', category: 'Professions', difficulty: 'hard' },
  { id: 'pr20', word: 'Mechanic', category: 'Professions', difficulty: 'medium' },
  { id: 'pr21', word: 'Carpenter', category: 'Professions', difficulty: 'medium' },
  { id: 'pr22', word: 'Electrician', category: 'Professions', difficulty: 'medium' },
  { id: 'pr23', word: 'Plumber', category: 'Professions', difficulty: 'medium' },
  { id: 'pr24', word: 'Hairdresser', category: 'Professions', difficulty: 'medium' },
  { id: 'pr25', word: 'Makeup Artist', category: 'Professions', difficulty: 'hard' },
  { id: 'pr26', word: 'Musician', category: 'Professions', difficulty: 'easy' },
  { id: 'pr27', word: 'Actor', category: 'Professions', difficulty: 'easy' },
  { id: 'pr28', word: 'Writer', category: 'Professions', difficulty: 'easy' },
  { id: 'pr29', word: 'Journalist', category: 'Professions', difficulty: 'medium' },
  { id: 'pr30', word: 'Photographer', category: 'Professions', difficulty: 'medium' },
  { id: 'pr31', word: 'Librarian', category: 'Professions', difficulty: 'medium' },
  { id: 'pr32', word: 'Museum Curator', category: 'Professions', difficulty: 'hard' },
  { id: 'pr33', word: 'Zookeeper', category: 'Professions', difficulty: 'medium' },
  { id: 'pr34', word: 'Park Ranger', category: 'Professions', difficulty: 'hard' },
  { id: 'pr35', word: 'Lifeguard', category: 'Professions', difficulty: 'medium' },
  { id: 'pr36', word: 'Flight Attendant', category: 'Professions', difficulty: 'medium' },
  { id: 'pr37', word: 'Bus Driver', category: 'Professions', difficulty: 'easy' },
  { id: 'pr38', word: 'Taxi Driver', category: 'Professions', difficulty: 'easy' },
  { id: 'pr39', word: 'Truck Driver', category: 'Professions', difficulty: 'easy' },
  { id: 'pr40', word: 'Delivery Driver', category: 'Professions', difficulty: 'easy' },
  { id: 'pr41', word: 'Mail Carrier', category: 'Professions', difficulty: 'medium' },
  { id: 'pr42', word: 'Construction Worker', category: 'Professions', difficulty: 'medium' },
  { id: 'pr43', word: 'Factory Worker', category: 'Professions', difficulty: 'medium' },
  { id: 'pr44', word: 'Farmer', category: 'Professions', difficulty: 'easy' },
  { id: 'pr45', word: 'Fisherman', category: 'Professions', difficulty: 'medium' },
  { id: 'pr46', word: 'Butcher', category: 'Professions', difficulty: 'medium' },
  { id: 'pr47', word: 'Baker', category: 'Professions', difficulty: 'medium' },
  { id: 'pr48', word: 'Salesperson', category: 'Professions', difficulty: 'easy' },
  { id: 'pr49', word: 'Cashier', category: 'Professions', difficulty: 'easy' },
  { id: 'pr50', word: 'Customer Service Representative', category: 'Professions', difficulty: 'hard' },

  // Entertainment
  { id: 'en1', word: 'Circus', category: 'Entertainment', difficulty: 'medium' },
  { id: 'en2', word: 'Movie', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en3', word: 'Concert', category: 'Entertainment', difficulty: 'medium' },
  { id: 'en4', word: 'Theater', category: 'Entertainment', difficulty: 'medium' },
  { id: 'en5', word: 'Carnival', category: 'Entertainment', difficulty: 'medium' },
  { id: 'en6', word: 'Video Game', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en7', word: 'Board Game', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en8', word: 'Card Game', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en9', word: 'Puzzle', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en10', word: 'Book', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en11', word: 'Comic Book', category: 'Entertainment', difficulty: 'medium' },
  { id: 'en12', word: 'Magazine', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en13', word: 'Television Show', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en14', word: 'Radio Show', category: 'Entertainment', difficulty: 'medium' },
  { id: 'en15', word: 'Podcast', category: 'Entertainment', difficulty: 'medium' },
  { id: 'en16', word: 'Music', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en17', word: 'Dance', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en18', word: 'Opera', category: 'Entertainment', difficulty: 'hard' },
  { id: 'en19', word: 'Ballet', category: 'Entertainment', difficulty: 'medium' },
  { id: 'en20', word: 'Musical', category: 'Entertainment', difficulty: 'medium' },
  { id: 'en21', word: 'Magic Show', category: 'Entertainment', difficulty: 'medium' },
  { id: 'en22', word: 'Comedy Show', category: 'Entertainment', difficulty: 'medium' },
  { id: 'en23', word: 'Theme Park', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en24', word: 'Water Park', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en25', word: 'Zoo', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en26', word: 'Museum', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en27', word: 'Art Gallery', category: 'Entertainment', difficulty: 'medium' },
  { id: 'en28', word: 'Festival', category: 'Entertainment', difficulty: 'medium' },
  { id: 'en29', word: 'Parade', category: 'Entertainment', difficulty: 'medium' },
  { id: 'en30', word: 'Fireworks', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en31', word: 'Party', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en32', word: 'Picnic', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en33', word: 'Camping', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en34', word: 'Hiking', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en35', word: 'Fishing', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en36', word: 'Shopping', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en37', word: 'Traveling', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en38', word: 'Photography', category: 'Entertainment', difficulty: 'medium' },
  { id: 'en39', word: 'Painting', category: 'Entertainment', difficulty: 'medium' },
  { id: 'en40', word: 'Drawing', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en41', word: 'Writing', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en42', word: 'Reading', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en43', word: 'Learning', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en44', word: 'Cooking', category: 'Entertainment', difficulty: 'medium' },
  { id: 'en45', word: 'Gardening', category: 'Entertainment', difficulty: 'medium' },
  { id: 'en46', word: 'Playing Sports', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en47', word: 'Watching Sports', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en48', word: 'Going to the Beach', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en49', word: 'Karaoke', category: 'Entertainment', difficulty: 'easy' },
  { id: 'en50', word: 'Stand-up Comedy', category: 'Entertainment', difficulty: 'medium' }
];