import { findUserBySession, createUser } from './api';
import { generateAccessCode } from './utils';
import { validateSessionId } from './validation';
import type { PaymentSessionResult } from '@/types/payment';

export async function createUserFromSession(sessionId: string | null): Promise<PaymentSessionResult> {
  try {
    const validSessionId = validateSessionId(sessionId);
    
    // Check for existing user
    const existingUser = await findUserBySession(validSessionId);
    if (existingUser?.access_code) {
      return {
        accessCode: existingUser.access_code,
        isExisting: true
      };
    }

    // Create new user
    const accessCode = generateAccessCode();
    return await createUser(validSessionId, accessCode);

  } catch (error) {
    console.error('Payment session error:', { error, sessionId });
    throw error;
  }
}