import { useEffect, useRef, useState } from 'react';
import { Button } from '@/components/ui/button';
import { Eraser, Undo } from 'lucide-react';

interface Point {
  x: number;
  y: number;
}

interface DrawingCanvasProps {
  isDrawing: boolean;
  onFinishDrawing?: () => void;
}

export function DrawingCanvas({ isDrawing, onFinishDrawing }: DrawingCanvasProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isDrawingActive, setIsDrawingActive] = useState(false);
  const [lastPoint, setLastPoint] = useState<Point | null>(null);
  const contextRef = useRef<CanvasRenderingContext2D | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Set canvas size
    canvas.width = canvas.offsetWidth * 2;
    canvas.height = canvas.offsetHeight * 2;
    canvas.style.width = `${canvas.offsetWidth}px`;
    canvas.style.height = `${canvas.offsetHeight}px`;

    // Get context
    const context = canvas.getContext('2d');
    if (!context) return;

    // Scale for retina displays
    context.scale(2, 2);
    context.lineCap = 'round';
    context.lineJoin = 'round';
    context.lineWidth = 5;
    context.strokeStyle = '#1A1A1A';
    contextRef.current = context;

    // Set white background
    context.fillStyle = '#FFFFFF';
    context.fillRect(0, 0, canvas.width / 2, canvas.height / 2);
  }, []);

  const startDrawing = (e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    if (!isDrawing) return;
    
    const point = getPoint(e);
    if (!point) return;

    setIsDrawingActive(true);
    setLastPoint(point);
  };

  const draw = (e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    if (!isDrawingActive || !isDrawing || !contextRef.current || !lastPoint) return;

    e.preventDefault();
    const point = getPoint(e);
    if (!point) return;

    contextRef.current.beginPath();
    contextRef.current.moveTo(lastPoint.x, lastPoint.y);
    contextRef.current.lineTo(point.x, point.y);
    contextRef.current.stroke();

    setLastPoint(point);
  };

  const stopDrawing = () => {
    setIsDrawingActive(false);
    setLastPoint(null);
  };

  const getPoint = (e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>): Point | null => {
    const canvas = canvasRef.current;
    if (!canvas) return null;

    const rect = canvas.getBoundingClientRect();
    let x, y;

    if ('touches' in e) {
      x = e.touches[0].clientX - rect.left;
      y = e.touches[0].clientY - rect.top;
    } else {
      x = e.clientX - rect.left;
      y = e.clientY - rect.top;
    }

    return { x, y };
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = contextRef.current;
    if (!canvas || !context) return;

    context.fillStyle = '#FFFFFF';
    context.fillRect(0, 0, canvas.width / 2, canvas.height / 2);
  };

  return (
    <div className="space-y-4">
      <div className="relative border-3 border-black rounded-xl overflow-hidden bg-white shadow-game">
        <canvas
          ref={canvasRef}
          className="w-full h-[300px] touch-none cursor-crosshair"
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={stopDrawing}
          onMouseLeave={stopDrawing}
          onTouchStart={startDrawing}
          onTouchMove={draw}
          onTouchEnd={stopDrawing}
        />
      </div>
      <div className="flex justify-center gap-4">
        <Button
          onClick={clearCanvas}
          className="btn-secondary"
          disabled={!isDrawing}
        >
          <Eraser className="mr-2 h-4 w-4" />
          Clear
        </Button>
        <Button
          onClick={onFinishDrawing}
          className="btn-primary"
          disabled={!isDrawing}
        >
          Done
        </Button>
      </div>
    </div>
  );
}