import { PaymentErrors } from '@/types/payment';

export function validateSessionId(sessionId: string | null): string {
  if (!sessionId) {
    throw PaymentErrors.NO_SESSION;
  }

  const trimmedId = sessionId.trim();
  if (!trimmedId) {
    throw PaymentErrors.INVALID_SESSION;
  }

  return trimmedId;
}