import { useState } from 'react';
import { Timer } from '@/components/Timer';
import { DrawingCanvas } from '@/components/DrawingCanvas';
import { PictionaryWord } from '@/data/pictionary';
import { SkipForward, ThumbsUp, Pencil } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';

interface PictionaryCardProps {
  word?: PictionaryWord;
  onNextWord: () => void;
  onScorePoint: () => void;
}

export function PictionaryCard({ word, onNextWord, onScorePoint }: PictionaryCardProps) {
  const [isDrawing, setIsDrawing] = useState(false);
  const [isRevealed, setIsRevealed] = useState(false);
  const [hasScored, setHasScored] = useState(false);

  if (!word) {
    return (
      <div className="game-card p-8 text-center">
        <p className="text-lg text-black/70">Loading...</p>
      </div>
    );
  }

  const handleStart = () => {
    setIsDrawing(true);
    setHasScored(false);
  };

  const handleTimeUp = () => {
    setIsDrawing(false);
    setIsRevealed(true);
  };

  const handleFinishDrawing = () => {
    setIsDrawing(false);
    setIsRevealed(true);
  };

  const handleNext = () => {
    setIsRevealed(false);
    setIsDrawing(false);
    setHasScored(false);
    onNextWord();
  };

  const handleScore = () => {
    if (!hasScored) {
      setHasScored(true);
      onScorePoint();
    }
  };

  return (
    <div className="game-card p-8 space-y-6">
      <div className="flex flex-wrap gap-3 justify-between items-center">
        <span className={cn(
          "difficulty-badge",
          word.difficulty === 'easy' ? 'difficulty-easy' :
          word.difficulty === 'medium' ? 'difficulty-medium' : 'difficulty-hard'
        )}>
          {word.difficulty}
        </span>
        <span className="genre-badge">{word.category}</span>
      </div>

      <div className="min-h-[320px] flex flex-col items-center justify-center gap-6">
        {!isDrawing && !isRevealed && (
          <div className="text-center space-y-4">
            <h2 className="text-3xl md:text-4xl font-bold">{word.word}</h2>
            <p className="text-lg text-black/70">
              Get ready to draw this word!
            </p>
          </div>
        )}
        
        {isDrawing && (
          <DrawingCanvas 
            isDrawing={isDrawing}
            onFinishDrawing={handleFinishDrawing}
          />
        )}

        {isRevealed && (
          <div className="text-center space-y-4">
            <h2 className="text-3xl font-bold">{word.word}</h2>
            {hasScored && (
              <div className="text-2xl font-bold text-green-600">
                Team got it right!
              </div>
            )}
          </div>
        )}
      </div>

      <div className="space-y-6">
        <Timer
          duration={60}
          isRunning={isDrawing}
          onTimeUp={handleTimeUp}
        />

        <div className="flex flex-wrap gap-4 justify-center">
          {!isDrawing && !isRevealed && (
            <Button 
              onClick={handleStart}
              className="btn-primary min-w-[200px] py-4"
            >
              <Pencil className="mr-2 h-5 w-5" />
              Start Drawing
            </Button>
          )}
          
          {isRevealed && (
            <>
              <Button
                onClick={handleScore}
                className={cn(
                  "min-w-[140px] py-4",
                  hasScored ? "btn-secondary" : "btn-primary"
                )}
                disabled={hasScored}
              >
                <ThumbsUp className="mr-2 h-5 w-5" />
                {hasScored ? 'Scored!' : 'Got It!'}
              </Button>
              <Button
                onClick={handleNext}
                className="btn-secondary min-w-[140px] py-4"
              >
                <SkipForward className="mr-2 h-5 w-5" />
                Next Word
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}