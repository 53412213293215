import { Button } from '@/components/ui/button';
import { XCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export function PaymentCancel() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen pattern-bg flex items-center justify-center">
      <div className="game-card p-8 text-center max-w-md">
        <XCircle className="w-16 h-16 text-red-500 mx-auto mb-6" />
        <h2 className="text-3xl font-bold mb-4">Payment Cancelled</h2>
        <p className="text-lg text-black/70 mb-8">
          Your payment was cancelled. No charges were made.
        </p>
        <Button onClick={() => navigate('/')} className="btn-primary">
          Try Again
        </Button>
      </div>
    </div>
  );
}