import { Users, Trophy, Star } from 'lucide-react';

interface SocialProofItem {
  icon: typeof Users;
  text: string;
}

const socialProofItems: SocialProofItem[] = [
  { icon: Users, text: '17+ Happy Families' },
  { icon: Trophy, text: '500+ Games Played' },
  { icon: Star, text: '4.9/5 Rating' }
];

export function SocialProofBar() {
  return (
    <div className="flex flex-wrap justify-center gap-6 py-4 border-y-2 border-black/10">
      {socialProofItems.map((item, index) => (
        <div key={index} className="flex items-center gap-2">
          <item.icon className="w-5 h-5 text-[#FFD233]" />
          <span className="font-semibold">{item.text}</span>
        </div>
      ))}
    </div>
  );
}