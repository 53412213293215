import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Check } from 'lucide-react';
import { PaymentLoading } from './PaymentLoading';
import { PaymentError } from './PaymentError';
import { usePayment } from '@/contexts/PaymentContext';

export function PaymentSuccess() {
  const navigate = useNavigate();
  const { accessCode, error, isLoading, isExisting } = usePayment();

  if (isLoading) {
    return <PaymentLoading />;
  }

  if (error) {
    return <PaymentError error={error} onRetry={() => navigate('/')} />;
  }

  return (
    <div className="min-h-screen pattern-bg flex items-center justify-center p-4">
      <div className="game-card p-8 text-center max-w-md w-full">
        <Check className="w-16 h-16 text-green-500 mx-auto mb-6" />
        <h2 className="text-3xl font-bold mb-4">Payment Successful!</h2>
        {isExisting ? (
          <p className="text-lg text-black/70 mb-6">
            Welcome back! Here's your access code again:
          </p>
        ) : (
          <p className="text-lg text-black/70 mb-6">
            Thank you for your purchase. Your access code is:
          </p>
        )}
        <div className="bg-cream-dark p-4 rounded-lg mb-8">
          <code className="text-2xl font-bold">{accessCode}</code>
        </div>
        <p className="text-sm text-black/70 mb-6">
          Please save this code. You can use it to access the games on any device.
        </p>
        <Button onClick={() => navigate('/')} className="btn-primary">
          Start Playing
        </Button>
      </div>
    </div>
  );
}