import { useState } from 'react';
import { Play } from 'lucide-react';

interface VideoPreviewProps {
  videoId: string;
  title?: string;
}

export function VideoPreview({ videoId, title }: VideoPreviewProps) {
  const [isPlaying, setIsPlaying] = useState(false);

  if (!videoId) return null;

  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  const embedUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1`;

  return (
    <div className="relative w-full max-w-3xl mx-auto">
      {!isPlaying ? (
        <div 
          className="relative cursor-pointer group"
          onClick={() => setIsPlaying(true)}
        >
          <img
            src={thumbnailUrl}
            alt={title || 'Video preview'}
            className="w-full rounded-xl border-3 border-black shadow-game"
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-16 h-16 bg-yellow rounded-full flex items-center justify-center border-3 border-black shadow-game transition-transform group-hover:scale-110">
              <Play className="w-8 h-8 text-black" />
            </div>
          </div>
        </div>
      ) : (
        <div className="relative pb-[56.25%] h-0">
          <iframe
            src={embedUrl}
            title={title || 'Game preview'}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="absolute top-0 left-0 w-full h-full rounded-xl border-3 border-black shadow-game"
          />
        </div>
      )}
    </div>
  );
}