import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Movie } from '@/data/movies';
import { Timer } from '@/components/Timer';
import { Play, FastForward, ThumbsUp, SkipForward } from 'lucide-react';
import { cn } from '@/lib/utils';

interface GameCardProps {
  movie: Movie;
  onNextMovie: () => void;
  onScorePoint: () => void;
}

export function GameCard({ movie, onNextMovie, onScorePoint }: GameCardProps) {
  const [isRevealed, setIsRevealed] = useState(false);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [hasScored, setHasScored] = useState(false);

  const handleStart = () => {
    setIsTimerRunning(true);
    setHasScored(false);
  };

  const handleTimeUp = () => {
    setIsTimerRunning(false);
    setIsRevealed(true);
  };

  const handleNext = () => {
    setIsRevealed(false);
    setIsTimerRunning(false);
    setHasScored(false);
    onNextMovie();
  };

  const handleScore = () => {
    if (!hasScored) {
      setHasScored(true);
      onScorePoint();
    }
  };

  return (
    <div className="game-card p-8 space-y-6">
      <div className="flex flex-wrap gap-3 justify-between items-center">
        <span className={cn(
          "difficulty-badge",
          movie.difficulty === 'easy' ? 'difficulty-easy' :
          movie.difficulty === 'medium' ? 'difficulty-medium' : 'difficulty-hard'
        )}>
          {movie.difficulty}
        </span>
        <div className="flex flex-wrap gap-2">
          {movie.genre.map((g) => (
            <span key={g} className="genre-badge">{g}</span>
          ))}
        </div>
      </div>

      <div className="min-h-[240px] flex items-center justify-center p-6">
        <h2 className={cn(
          "text-3xl md:text-4xl font-bold text-center transition-opacity duration-300",
          !isRevealed && isTimerRunning ? "opacity-0" : "opacity-100"
        )}>
          {movie.title}
        </h2>
      </div>

      <div className="space-y-6">
        <Timer
          duration={60}
          isRunning={isTimerRunning}
          onTimeUp={handleTimeUp}
        />

        <div className="flex flex-wrap gap-4 justify-center">
          {!isTimerRunning && !isRevealed && (
            <Button 
              onClick={handleStart}
              className="btn-primary min-w-[200px]"
            >
              <Play className="mr-2 h-5 w-5" />
              Start Round
            </Button>
          )}
          {isTimerRunning && !isRevealed && (
            <Button 
              onClick={handleTimeUp}
              className="btn-secondary min-w-[200px]"
            >
              <FastForward className="mr-2 h-5 w-5" />
              Finish Early
            </Button>
          )}
          {isRevealed && (
            <>
              <Button
                onClick={handleScore}
                className={cn(
                  "min-w-[140px]",
                  hasScored ? "btn-secondary" : "btn-primary"
                )}
                disabled={hasScored}
              >
                <ThumbsUp className="mr-2 h-5 w-5" />
                {hasScored ? 'Scored!' : 'Got It!'}
              </Button>
              <Button
                onClick={handleNext}
                className="btn-secondary min-w-[140px]"
              >
                <SkipForward className="mr-2 h-5 w-5" />
                Next Movie
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}