import { useEffect } from 'react';
import confetti from 'canvas-confetti';
import { Trophy, Home } from 'lucide-react';
import { Button } from '@/components/ui/button';

interface WinnerScreenProps {
  winnerName: string;
  onPlayAgain: () => void;
}

export function WinnerScreen({ winnerName, onPlayAgain }: WinnerScreenProps) {
  useEffect(() => {
    // Create celebratory confetti effect
    const duration = 5 * 1000;
    const animationEnd = Date.now() + duration;

    const randomInRange = (min: number, max: number) => {
      return Math.random() * (max - min) + min;
    };

    const interval = setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        clearInterval(interval);
        return;
      }

      const particleCount = 50;

      confetti({
        particleCount,
        startVelocity: 30,
        spread: 360,
        origin: {
          x: randomInRange(0.1, 0.9),
          y: Math.random() - 0.2,
        },
        colors: ['#FFD233', '#FFE066', '#FFC107', '#1A1A1A'],
      });
    }, 250);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="fixed inset-0 bg-background/95 backdrop-blur-sm flex items-center justify-center z-50 pattern-bg">
      <div className="game-card p-12 text-center space-y-8 max-w-lg mx-auto">
        <Trophy className="w-32 h-32 mx-auto text-[#FFD233] animate-bounce" />
        <div className="space-y-4">
          <h1 className="text-6xl font-bold tracking-tight bg-gradient-to-r from-yellow-500 to-yellow-300 text-transparent bg-clip-text">
            Congratulations!
          </h1>
          <p className="text-3xl font-medium">
            {winnerName} wins the game!
          </p>
        </div>
        <Button 
          size="lg" 
          onClick={onPlayAgain} 
          className="btn-primary px-8 py-6 text-xl"
        >
          <Home className="mr-2 h-6 w-6" />
          Back to Leaderboard
        </Button>
      </div>
    </div>
  );
}