import { useState } from 'react';
import { GameMenu } from '@/components/GameMenu';
import { GameSetup } from '@/components/GameSetup';
import { ActiveGame } from '@/components/ActiveGame';
import { getShuffledGameItems, initializeTeamHints } from '@/lib/game-utils';
import { movies } from '@/data/movies';
import { hollywoodMovies } from '@/data/hollywood-movies';
import { pictionaryWords } from '@/data/pictionary';
import { triviaQuestions } from '@/data/trivia';
import { words } from '@/data/words';
import { wordProblems } from '@/data/word-problems';
import type { GameType } from '@/types/game';
import type { GameConfig } from '@/types/game';

export function GameContainer() {
  const [gameType, setGameType] = useState<GameType | null>(null);
  const [gameConfig, setGameConfig] = useState<GameConfig | null>(null);
  const [teamHints, setTeamHints] = useState<{ [key: string]: number }>({});
  const [items, setItems] = useState<any[]>([]);


  const getGameItems = () => {
    if (!gameType || !gameConfig) return [];

    switch (gameType) {
      case 'charades':
        return getShuffledGameItems(
          gameConfig.category === 'hollywood' ? hollywoodMovies : movies,
          gameConfig.category
        );
      case 'pictionary':
        return getShuffledGameItems(pictionaryWords, gameConfig.category);
      case 'scramble':
        return getShuffledGameItems(words);
      case 'trivia':  // Add this case
        return getShuffledGameItems(triviaQuestions, gameConfig.category);
      case 'wordproblems':
        return getShuffledGameItems(wordProblems);
      default:
        return [];
    }
  };

  const handleGameTypeSelect = (type: GameType) => setGameType(type);

  const handleGameStart = (config: GameConfig) => {
  setGameConfig(config);
  setTeamHints(initializeTeamHints(config.teams));
  // Call getGameItems with the new config directly instead of using state
  const gameItems = getShuffledGameItems(
    gameType === 'charades' ? 
      (config.category === 'hollywood' ? hollywoodMovies : movies) :
    gameType === 'pictionary' ? pictionaryWords :
    gameType === 'scramble' ? words :
    gameType === 'trivia' ? triviaQuestions :
    gameType === 'wordproblems' ? wordProblems :
    [],
    config.category
  );
  setItems(gameItems);
};
  
//  const handleGameStart = (config: GameConfig) => {
//    setGameConfig(config);
//    setTeamHints(initializeTeamHints(config.teams));
//  };

  const handlePlayAgain = () => {
    setGameType(null);
    setGameConfig(null);
    setTeamHints({});
    setItems([]);
  };

  const handleUseHint = (team: string) => {
    if (teamHints[team] > 0) {
      setTeamHints(prev => ({
        ...prev,
        [team]: prev[team] - 1
      }));
    }
  };

  if (!gameType) {
    return <GameMenu onGameTypeSelect={handleGameTypeSelect} />;
  }

  if (!gameConfig) {
    return (
      <GameSetup
        gameType={gameType}
        onGameStart={handleGameStart}
        onBack={() => setGameType(null)}
      />
    );
  }

  return (
    <ActiveGame
      gameType={gameType}
      gameConfig={gameConfig}
      items={items}
      //items={getGameItems()}
      teamHints={teamHints}
      onUseHint={handleUseHint}
      onPlayAgain={handlePlayAgain}
    />
  );
}