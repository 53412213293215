export interface WordProblem {
  id: string;
  question: string;
  answer: number;
  difficulty: 'easy' | 'medium' | 'hard';
}

export const wordProblems: WordProblem[] = [
  {
        id: "wp1",
        question: "A farmer has 120 animals, consisting of chickens, pigs, and cows. He has twice as many pigs as cows, and the total number of chickens and pigs is three times the number of cows. How many cows does the farmer have?",
        answer: "24",
        difficulty: "hard"
    },
    {
        id: "wp2",
        question: "In a school, the ratio of boys to girls is 4:5. If there are 360 students in total, and 40% of the girls play sports, how many girls play sports?",
        answer: "72",
        difficulty: "medium"
    },
    {
        id: "wp3",
        question: "A factory produces 840 widgets in 6 days. If they need to produce 2100 widgets, and can add another machine that increases production by 25%, how many days will it take?",
        answer: "12",
        difficulty: "hard"
    },
    {
        id: "wp4",
        question: "Three friends split a bill of $216. The second friend pays twice what the first friend pays, and the third friend pays three times what the first friend pays. How much does the first friend pay?",
        answer: "36",
        difficulty: "medium"
    },
    {
        id: "wp5",
        question: "A train travels 480 km at a speed of 80 km/h. If it then increases its speed by 20% for the return journey, how many hours will the return journey take?",
        answer: "5",
        difficulty: "medium"
    },
    {
        id: "wp6",
        question: "In a bag of marbles, there are blue, red, and green marbles. The ratio of blue to red is 3:4, and the ratio of red to green is 2:3. If there are 72 marbles in total, how many blue marbles are there?",
        answer: "18",
        difficulty: "hard"
    },
    {
        id: "wp7",
        question: "A store offers a 15% discount on a $240 item. If you have a coupon for an additional 20% off the discounted price, how much money will you save in total?",
        answer: "84",
        difficulty: "medium"
    },
    {
        id: "wp8",
        question: "A recipe requires 3 cups of flour to make 24 cookies. If you want to make 168 cookies and need to maintain the same ratio, how many cups of flour will you need?",
        answer: "21",
        difficulty: "medium"
    },
    {
        id: "wp9",
        question: "In a parking lot, there are cars and motorcycles. Each car has 4 wheels, and each motorcycle has 2 wheels. If there are 100 vehicles in total and 280 wheels, how many cars are there?",
        answer: "40",
        difficulty: "hard"
    },
    {
        id: "wp10",
        question: "A company's profit increased by 25% each year for 3 consecutive years. If the final profit was $1250, what was the initial profit?",
        answer: "640",
        difficulty: "hard"
    },
    {
        id: "wp11",
        question: "A swimming pool can be filled by pipe A in 12 hours and pipe B in 15 hours. If both pipes are opened together, how many hours will it take to fill the pool?",
        answer: "7",
        difficulty: "hard"
    },
    {
        id: "wp12",
        question: "In a class, 3/8 of the students are boys and 55% of the girls wear glasses. If there are 80 students in total and 22 girls wear glasses, how many boys are there?",
        answer: "30",
        difficulty: "hard"
    },
    {
        id: "wp13",
        question: "A shop sells notebooks at $3 each. If you buy 5 or more, you get a 20% discount. If you spend $60 on notebooks with the discount applied, how many notebooks did you buy?",
        answer: "25",
        difficulty: "medium"
    },
    {
        id: "wp14",
        question: "Three workers can paint a house in 24 hours. If two more equally skilled workers join them, how many hours will it take to paint the house?",
        answer: "15",
        difficulty: "medium"
    },
    {
        id: "wp15",
        question: "A car travels 360 km using 40 liters of fuel. If it needs to travel 1260 km with 20% better fuel efficiency, how many liters of fuel will it need?",
        answer: "112",
        difficulty: "hard"
    },
    {
        id: "wp16",
        question: "In a box of 150 chocolates, 40% are dark chocolate, 1/3 of the remainder are milk chocolate, and the rest are white chocolate. How many white chocolate pieces are there?",
        answer: "60",
        difficulty: "hard"
    },
    {
        id: "wp17",
        question: "A rope is cut into three pieces. The second piece is twice the length of the first, and the third piece is three times the length of the first. If the total length is 180 meters, how long is the first piece?",
        answer: "30",
        difficulty: "medium"
    },
    {
        id: "wp18",
        question: "In a game, players score 3 points for a win and 1 point for a draw. If a team has played 30 games, has 60 points, and has lost 6 games, how many games did they win?",
        answer: "18",
        difficulty: "hard"
    },
    {
        id: "wp19",
        question: "A baker uses 5 cups of flour to make 4 loaves of bread. If he needs to make 36 loaves and has 50 cups of flour, how many more cups of flour does he need?",
        answer: "5",
        difficulty: "medium"
    },
    {
        id: "wp20",
        question: "In a bag of coins, there are only nickels and dimes. If there are 70 coins in total worth $5.05, how many nickels are there?",
        answer: "45",
        difficulty: "hard"
    },
    {
        id: "wp21",
        question: "A store sells shirts for $25 each. If you buy 3 or more, you get $5 off each shirt. If you spent $180 buying multiple shirts with the discount, how many shirts did you buy?",
        answer: "9",
        difficulty: "medium"
    },
    {
        id: "wp22",
        question: "In a group of 200 people, 45% are children. If 3/5 of the adults are women, how many men are there?",
        answer: "44",
        difficulty: "hard"
    },
    {
        id: "wp23",
        question: "A machine produces 15 parts every 4 minutes. If it operates for 6 hours but has a 20% downtime, how many parts will it produce?",
        answer: "1080",
        difficulty: "medium"
    },
    {
        id: "wp24",
        question: "In a library, 3/8 of the books are fiction, 2/5 of the non-fiction books are biographies. If there are 300 biography books, how many fiction books are there?",
        answer: "450",
        difficulty: "hard"
    },
    {
        id: "wp25",
        question: "A cinema has 250 seats. If 60% are occupied and the ratio of adults to children is 5:3, how many children are in the audience?",
        answer: "60",
        difficulty: "medium"
    },
    {
        id: "wp26",
        question: "A restaurant's daily revenue increased by 20% each month for 3 months. If the final daily revenue was $864, what was the initial daily revenue?",
        answer: "500",
        difficulty: "hard"
    },
    {
        id: "wp27",
        question: "In a box of 144 pens, 25% are blue, 1/3 of the remainder are red, and the rest are black. How many black pens are there?",
        answer: "72",
        difficulty: "medium"
    },
    {
        id: "wp28",
        question: "A tank can be filled by pipe A in 8 hours and emptied by pipe B in 12 hours. If both pipes are opened when the tank is half full, how many hours will it take to empty the tank?",
        answer: "6",
        difficulty: "hard"
    },
    {
        id: "wp29",
        question: "In a school band, the ratio of brass to woodwind to percussion instruments is 3:4:1. If there are 72 instruments in total, how many percussion instruments are there?",
        answer: "9",
        difficulty: "medium"
    },
    {
        id: "wp30",
        question: "A factory works 7 days a week. If it produces 160 units per day and operates at 80% efficiency on weekends, how many units will it produce in 30 days?",
        answer: "4480",
        difficulty: "hard"
    },
    {
        id: "wp31",
        question: "In a garden, 40% of the plants are flowers. If 3/4 of the non-flowers are vegetables and there are 90 vegetable plants, how many plants are there in total?",
        answer: "200",
        difficulty: "hard"
    },
    {
        id: "wp32",
        question: "A store offers a buy-2-get-1-free deal on items costing $15 each. If you spend $180, how many items did you buy?",
        answer: "15",
        difficulty: "medium"
    },
    {
        id: "wp33",
        question: "In a bag of 300 candies, the ratio of red to blue to green is 2:3:5. How many blue candies are there?",
        answer: "90",
        difficulty: "medium"
    },
    {
        id: "wp34",
        question: "A car travels at 60 km/h for 2 hours, then at 80 km/h for 3 hours. If the return journey is made at 75 km/h, how many hours will it take to return?",
        answer: "4",
        difficulty: "hard"
    },
    {
        id: "wp35",
        question: "In a group of 150 athletes, 40% play football, 1/3 of the remainder play basketball, and the rest play tennis. How many play tennis?",
        answer: "60",
        difficulty: "medium"
    },
    {
        id: "wp36",
        question: "A company's expenses are divided into three categories in the ratio 2:3:5. If the total expenses are $3000, how much is spent on the category with the lowest ratio?",
        answer: "600",
        difficulty: "medium"
    },
    {
        id: "wp37",
        question: "In a box of 240 chocolates, 35% are dark chocolate. If 3/5 of the remaining chocolates are milk chocolate, how many white chocolate pieces are there?",
        answer: "62",
        difficulty: "hard"
    },
    {
        id: "wp38",
        question: "A printer prints 25 pages per minute. If it needs to print 900 pages but has a 10% error rate requiring reprints, how many minutes will it take?",
        answer: "40",
        difficulty: "medium"
    },
    {
        id: "wp39",
        question: "In a game, scoring 3 goals earns 2 bonus points. If a team scored 42 goals and earned 28 bonus points, how many sets of 3 goals did they score?",
        answer: "14",
        difficulty: "hard"
    },
    {
        id: "wp40",
        question: "A shop sells items at $8 each. If you buy more than 10 items, each item costs $2 less. If you spent $140, how many items did you buy?",
        answer: "20",
        difficulty: "medium"
    },
    {
        id: "wp41",
        question: "A classroom has 32 students. If 3/4 of the students are girls, how many boys are in the class?",
        answer: "8",
        difficulty: "easy"
    },
    {
        id: "wp42",
        question: "A bakery sells cookies for $2 each. If you buy 10 cookies, you get 2 free. If you spent $40, how many cookies did you get in total?",
        answer: "24",
        difficulty: "easy"
    },
    {
        id: "wp43",
        question: "In a parking lot, there are 15 cars and some motorcycles. If there are 50 wheels in total, how many motorcycles are there?",
        answer: "10",
        difficulty: "easy"
    },
    {
        id: "wp44",
        question: "A store has 200 items. If 25% are sold and half of the remaining items are put on sale, how many items are on sale?",
        answer: "75",
        difficulty: "easy"
    },
    {
        id: "wp45",
        question: "Three friends collect marbles. The first has 12, the second has twice as many as the first, and the third has 10 more than the second. How many marbles do they have in total?",
        answer: "58",
        difficulty: "easy"
    },
    {
        id: "wp46",
        question: "A movie theatre has 150 seats. If 40% are occupied and half of the occupied seats are children, how many children are in the theatre?",
        answer: "30",
        difficulty: "easy"
    },
    {
        id: "wp47",
        question: "A box contains red and blue balls in the ratio 3:2. If there are 30 balls in total, how many are red?",
        answer: "18",
        difficulty: "easy"
    },
    {
        id: "wp48",
        question: "A worker earns $15 per hour. If they work for 6 hours and get a $10 bonus, how much do they earn in total?",
        answer: "100",
        difficulty: "easy"
    },
    {
        id: "wp49",
        question: "In a garden, there are 80 flowers. If 1/4 are roses and 1/2 are tulips, how many other flowers are there?",
        answer: "20",
        difficulty: "easy"
    },
    {
        id: "wp50",
        question: "A store offers a 20% discount on a $50 jacket. How much will you save?",
        answer: "10",
        difficulty: "easy"
    },
    {
        id: "wp51",
        question: "A bus can carry 45 passengers. If it is 80% full, how many empty seats are there?",
        answer: "9",
        difficulty: "easy"
    },
    {
        id: "wp52",
        question: "Tom has 5 boxes with 12 candies in each. If he gives away 2 boxes and eats 6 candies, how many candies does he have left?",
        answer: "30",
        difficulty: "easy"
    },
    {
        id: "wp53",
        question: "A recipe needs 3 eggs for every 2 cups of flour. If you have 12 eggs, how many cups of flour do you need?",
        answer: "8",
        difficulty: "easy"
    },
    {
        id: "wp54",
        question: "In a bag of 60 marbles, the ratio of green to red to blue is 2:3:1. How many red marbles are there?",
        answer: "30",
        difficulty: "easy"
    },
    {
        id: "wp55",
        question: "A printer prints 20 pages per minute. How many minutes will it take to print 180 pages?",
        answer: "9",
        difficulty: "easy"
    },
    {
        id: "wp56",
        question: "A store has 100 shirts. If 30% are blue and half of the non-blue shirts are red, how many red shirts are there?",
        answer: "35",
        difficulty: "easy"
    },
    {
        id: "wp57",
        question: "Three children split 48 stickers equally. Then each child gives 2 stickers to their teacher. How many stickers do they have left in total?",
        answer: "42",
        difficulty: "easy"
    },
    {
        id: "wp58",
        question: "A car travels at 60 km/h. How many kilometers will it travel in 4 hours?",
        answer: "240",
        difficulty: "easy"
    },
    {
        id: "wp59",
        question: "In a box of 40 chocolates, 3/4 are dark chocolate. How many milk chocolates are there?",
        answer: "10",
        difficulty: "easy"
    },
    {
        id: "wp60",
        question: "A shop sells pencils at $2 each. If you buy 5 or more, each pencil costs $1.50. If you buy 8 pencils, how much money will you save?",
        answer: "4",
        difficulty: "easy"
    },
    {
        id: "wp61",
        question: "There are 25 students in a class. If 60% bring lunch from home, how many students buy lunch at school?",
        answer: "10",
        difficulty: "easy"
    },
    {
        id: "wp62",
        question: "A farmer has cows and chickens. If there are 24 legs in total and 7 animals, how many cows are there?",
        answer: "4",
        difficulty: "easy"
    },
    {
        id: "wp63",
        question: "A bookshelf has 5 shelves with 16 books on each. If you remove 20 books, how many remain?",
        answer: "60",
        difficulty: "easy"
    },
    {
        id: "wp64",
        question: "In a group of 50 people, the ratio of adults to children is 4:1. How many children are there?",
        answer: "10",
        difficulty: "easy"
    },
    {
        id: "wp65",
        question: "A pizza is cut into 8 slices. If 3 people eat 2 slices each, how many slices are left?",
        answer: "2",
        difficulty: "easy"
    },
    {
        id: "wp66",
        question: "You have $100 and spend 15% on lunch and 25% on dinner. How much money do you have left?",
        answer: "60",
        difficulty: "easy"
    },
    {
        id: "wp67",
        question: "A train travels for 3 hours at 80 km/h. How far does it travel?",
        answer: "240",
        difficulty: "easy"
    },
    {
        id: "wp68",
        question: "In a bag of 90 candies, 1/3 are chocolate and 1/2 of the remainder are fruit flavored. How many fruit-flavored candies are there?",
        answer: "30",
        difficulty: "easy"
    },
    {
        id: "wp69",
        question: "A store sells notebooks for $4 each. If you spend $36 and get a 10% discount on the total, how many notebooks did you buy?",
        answer: "10",
        difficulty: "easy"
    },
    {
        id: "wp70",
        question: "If 3 cats eat 2 cans of food per day, how many cans will 6 cats eat in 5 days?",
        answer: "20",
        difficulty: "easy"
    },
    {
        id: "wp71",
        question: "There are 120 students in a school. If 1/3 are in grade 5 and half of those in grade 5 play sports, how many grade 5 students play sports?",
        answer: "20",
        difficulty: "easy"
    },
    {
        id: "wp72",
        question: "A factory produces 50 toys per hour. If it operates for 6 hours but has a 20% downtime, how many toys will it produce?",
        answer: "240",
        difficulty: "easy"
    },
    {
        id: "wp73",
        question: "In a box of 60 crayons, 20% are blue and 30% are red. How many crayons are neither blue nor red?",
        answer: "30",
        difficulty: "easy"
    },
    {
        id: "wp74",
        question: "A swimming pool is 25 meters long. If someone swims 4 laps, how many meters have they swum?",
        answer: "100",
        difficulty: "easy"
    },
    {
        id: "wp75",
        question: "You buy 3 shirts for $15 each and get a $10 discount. How much do you pay?",
        answer: "35",
        difficulty: "easy"
    },
    {
        id: "wp76",
        question: "In a game, each win is worth 3 points. If a team has 24 points, how many games did they win?",
        answer: "8",
        difficulty: "easy"
    },
    {
        id: "wp77",
        question: "A library has 150 books. If 40 are borrowed and 25% of the remaining books are reference books, how many reference books are there?",
        answer: "28",
        difficulty: "easy"
    },
    {
        id: "wp78",
        question: "If 6 friends split a bill of $72 equally and each leaves a $2 tip, how much does each person pay in total?",
        answer: "14",
        difficulty: "easy"
    },
    {
        id: "wp79",
        question: "A rope 100 meters long is cut into pieces of 4 meters each. How many pieces are there?",
        answer: "25",
        difficulty: "easy"
    },
    {
        id: "wp80",
        question: "In a box of 40 pens, 3/4 work and 1/3 of the broken pens can be fixed. How many pens cannot be used at all?",
        answer: "7",
        difficulty: "easy"
    },
    {
        id: "wp81",
        question: "A bookstore sells paperbacks for $15 each or 3 for $36. If you buy 7 books using the best possible combination of deals, how much will you spend?",
        answer: "96",
        difficulty: "medium"
    },
    {
        id: "wp82",
        question: "In a school of 200 students, 40% play sports. If 3/4 of the athletes also participate in music, how many athletes don't participate in music?",
        answer: "20",
        difficulty: "medium"
    },
    {
        id: "wp83",
        question: "A printer can print 30 pages per minute. If it operates for 2 hours but has a 25% downtime, how many pages will it print?",
        answer: "2700",
        difficulty: "medium"
    },
    {
        id: "wp84",
        question: "In a box of 160 chocolates, 1/4 are dark chocolate and 40% of the remainder are milk chocolate. How many white chocolates are there?",
        answer: "72",
        difficulty: "medium"
    },
    {
        id: "wp85",
        question: "A worker earns $16 per hour and time-and-a-half for overtime. If they work 8 regular hours and 4 overtime hours, how much do they earn?",
        answer: "224",
        difficulty: "medium"
    },
    {
        id: "wp86",
        question: "Three friends share a meal bill of $75 and want to leave a 20% tip. If they split everything equally, how much should each person pay?",
        answer: "30",
        difficulty: "medium"
    },
    {
        id: "wp87",
        question: "In a garden with 120 plants, 30% are flowers and 1/2 of the non-flowers are vegetables. How many plants are neither flowers nor vegetables?",
        answer: "42",
        difficulty: "medium"
    },
    {
        id: "wp88",
        question: "A car travels at 70 km/h for 2 hours, then at 50 km/h for 1.5 hours. What is the total distance traveled?",
        answer: "215",
        difficulty: "medium"
    },
    {
        id: "wp89",
        question: "In a bag of 150 marbles, the ratio of red to blue to green is 3:2:1. How many blue marbles are there?",
        answer: "50",
        difficulty: "medium"
    },
    {
        id: "wp90",
        question: "A store offers a 30% discount on a $100 item. If you have a $15 coupon to use after the discount, how much will you pay?",
        answer: "55",
        difficulty: "medium"
    },
    {
        id: "wp91",
        question: "If 6 workers can complete a job in 8 hours, how many workers are needed to complete the job in 6 hours?",
        answer: "8",
        difficulty: "medium"
    },
    {
        id: "wp92",
        question: "In a game, players score 2 points for a basket and 1 point for a free throw. If a team scored 36 points with 12 baskets, how many free throws did they make?",
        answer: "12",
        difficulty: "medium"
    },
    {
        id: "wp93",
        question: "A recipe needs 2 eggs for every 3 cups of flour. If you want to use 24 eggs, how many cups of flour do you need?",
        answer: "36",
        difficulty: "medium"
    },
    {
        id: "wp94",
        question: "In a group of 90 students, 1/3 play sports and 3/5 of those who play sports also play music. How many athletes play music?",
        answer: "18",
        difficulty: "medium"
    },
    {
        id: "wp95",
        question: "A store sells shirts for $30 each. If you buy 2, you get the third at half price. How much would 5 shirts cost?",
        answer: "135",
        difficulty: "medium"
    },
    {
        id: "wp96",
        question: "In a box of 240 crayons, 25% are broken. If 2/3 of the broken crayons can be fixed, how many crayons cannot be used?",
        answer: "20",
        difficulty: "medium"
    },
    {
        id: "wp97",
        question: "A machine fills 40 bottles per minute. If it runs for 3 hours but takes a 10-minute break every hour, how many bottles will it fill?",
        answer: "6800",
        difficulty: "medium"
    },
    {
        id: "wp98",
        question: "In a cinema with 180 seats, 75% are occupied. If the ratio of adults to children is 5:1, how many children are there?",
        answer: "27",
        difficulty: "medium"
    },
    {
        id: "wp99",
        question: "A car uses 8 liters of fuel per 100 km. If it travels 300 km with 25% better fuel efficiency, how many liters of fuel will it use?",
        answer: "18",
        difficulty: "medium"
    },
    {
        id: "wp100",
        question: "Four friends buy concert tickets for $35 each and split parking fees of $40. How much does each person pay in total?",
        answer: "45",
        difficulty: "medium"
    },
    {
        id: "wp101",
        question: "In a bag of 100 coins, 35% are quarters and half of the remainder are dimes. How many coins are neither quarters nor dimes?",
        answer: "32",
        difficulty: "medium"
    },
    {
        id: "wp102",
        question: "A bakery sells muffins for $2.50 each or 5 for $10. How much would you save buying 15 muffins using the deal?",
        answer: "12",
        difficulty: "medium"
    },
    {
        id: "wp103",
        question: "In a school band of 60 members, the ratio of brass to woodwind to percussion is 2:2:1. How many brass players are there?",
        answer: "24",
        difficulty: "medium"
    },
    {
        id: "wp104",
        question: "A shop reduces prices by 20%, then offers an additional 10% off. If you spend $72, what was the original price?",
        answer: "100",
        difficulty: "medium"
    },
    {
        id: "wp105",
        question: "In a group of 150 people, 40% are children. If 2/3 of the adults are women, how many men are there?",
        answer: "30",
        difficulty: "medium"
    },
    {
        id: "wp106",
        question: "A phone battery drops by 12% every hour of use. If you use it for 5 hours starting at 100%, what percentage will remain?",
        answer: "40",
        difficulty: "medium"
    },
    {
        id: "wp107",
        question: "If 5 workers can paint a house in 10 hours, how many hours would it take 4 workers to paint the same house?",
        answer: "13",
        difficulty: "medium"
    },
    {
        id: "wp108",
        question: "A store offers buy-3-get-1-free on items costing $20 each. If you spend $300, how many free items did you get?",
        answer: "5",
        difficulty: "medium"
    },
    {
        id: "wp109",
        question: "In a box of 80 pens, 1/4 are black and 1/3 of the remainder are blue. How many pens are neither black nor blue?",
        answer: "40",
        difficulty: "medium"
    },
    {
        id: "wp110",
        question: "Three friends split a restaurant bill in the ratio 2:3:3. If the total with tip is $120, how much does the first person pay?",
        answer: "30",
        difficulty: "medium"
    },
    {
        id: "wp111",
        question: "A factory produces 200 items per day. If efficiency drops by 25% on weekends, how many items will they produce in a week?",
        answer: "1300",
        difficulty: "medium"
    },
    {
        id: "wp112",
        question: "In a garden of 90 flowers, 40% are roses and 1/3 of the non-roses are tulips. How many tulips are there?",
        answer: "18",
        difficulty: "medium"
    },
    {
        id: "wp113",
        question: "A train travels 20% faster than a car. If a car takes 5 hours to reach a destination, how many hours will the train take?",
        answer: "4",
        difficulty: "medium"
    },
    {
        id: "wp114",
        question: "In a bag of 140 candies, the ratio of red to green to yellow is 2:3:2. How many green candies are there?",
        answer: "60",
        difficulty: "medium"
    },
    {
        id: "wp115",
        question: "A store sells DVDs for $18 each or 4 for $60. How much would you save buying 12 DVDs using the deal?",
        answer: "36",
        difficulty: "medium"
    },
    {
        id: "wp116",
        question: "In a class of 50 students, 30% play music. If 2/3 of the musicians also play sports, how many musicians don't play sports?",
        answer: "5",
        difficulty: "medium"
    },
    {
        id: "wp117",
        question: "A printer prints 25 pages per minute. If it needs to print 500 pages but has a 20% error rate, how many minutes will it take?",
        answer: "24",
        difficulty: "medium"
    },
    {
        id: "wp118",
        question: "In a parking lot with 100 spaces, 80% are filled. If the ratio of cars to motorcycles is 3:1, how many motorcycles are there?",
        answer: "20",
        difficulty: "medium"
    },
    {
        id: "wp119",
        question: "A recipe uses 3 cups of sugar for every 4 cups of flour. If you have 18 cups of sugar, how many cups of flour do you need?",
        answer: "24",
        difficulty: "medium"
    },
    {
        id: "wp120",
        question: "Four children split 126 stickers in the ratio 3:3:2:1. How many stickers does the child with the least amount get?",
        answer: "14",
        difficulty: "medium"
    },
    {
        id: "wp121",
        question: "A store sells shirts for $20 each. If you buy 3 or more, you get $5 off each shirt. If you spent $105, how many shirts did you buy?",
        answer: "7",
        difficulty: "medium"
    },
    {
        id: "wp122",
        question: "In a class of 40 students, 30% are absent today. If 2/3 of the present students are girls, how many boys are present?",
        answer: "9",
        difficulty: "medium"
    },
    {
        id: "wp123",
        question: "A baker makes 80 cookies. If 1/4 are chocolate chip and half of the remainder are oatmeal, how many are neither chocolate chip nor oatmeal?",
        answer: "30",
        difficulty: "medium"
    },
    {
        id: "wp124",
        question: "A car uses 5 liters of fuel per 100 km. If it needs to travel 300 km, how many liters of fuel will it need?",
        answer: "15",
        difficulty: "medium"
    },
    {
        id: "wp125",
        question: "In a bag of 90 marbles, the ratio of red to blue is 2:1. How many blue marbles are there?",
        answer: "30",
        difficulty: "medium"
    },
    {
        id: "wp126",
        question: "A movie ticket costs $12. On Tuesdays, there's a 25% discount. How much would 4 tickets cost on Tuesday?",
        answer: "36",
        difficulty: "medium"
    },
    {
        id: "wp127",
        question: "Three friends split a pizza bill of $24. If each leaves a $2 tip, how much does each person pay in total?",
        answer: "10",
        difficulty: "medium"
    },
    {
        id: "wp128",
        question: "In a box of 100 chocolates, 40% are dark chocolate and 1/3 of the remainder are milk chocolate. How many milk chocolates are there?",
        answer: "20",
        difficulty: "medium"
    },
    {
        id: "wp129",
        question: "A printer prints 30 pages per minute. If it needs to print 240 pages but has a 25% downtime, how many minutes will it take?",
        answer: "10",
        difficulty: "medium"
    },
    {
        id: "wp130",
        question: "In a parking lot with 60 vehicles, 2/3 are cars and the rest are motorcycles. How many motorcycles are there?",
        answer: "20",
        difficulty: "medium"
    },
    {
        id: "wp131",
        question: "A store offers a buy-one-get-one-half-off deal on $40 items. How much would 4 items cost?",
        answer: "140",
        difficulty: "medium"
    },
    {
        id: "wp132",
        question: "In a group of 50 students, 60% play sports and 1/3 of those who play sports also play music. How many athletes play music?",
        answer: "10",
        difficulty: "medium"
    },
    {
        id: "wp133",
        question: "A recipe needs 3 eggs for every 2 cups of flour. If you have 18 eggs, how many cups of flour do you need?",
        answer: "12",
        difficulty: "medium"
    },
    {
        id: "wp134",
        question: "Six friends order pizza for $48. If they split the bill equally and each adds a 20% tip, how much does each person pay?",
        answer: "10",
        difficulty: "medium"
    },
    {
        id: "wp135",
        question: "A train travels at 75 km/h. How far will it travel in 3 hours and 20 minutes?",
        answer: "250",
        difficulty: "medium"
    },
    {
        id: "wp136",
        question: "In a box of 120 crayons, 25% are broken. If 1/3 of the broken crayons can be fixed, how many crayons cannot be used?",
        answer: "20",
        difficulty: "medium"
    },
    {
        id: "wp137",
        question: "A worker earns $20 per hour during regular hours and 50% extra during overtime. If they work 8 regular hours and 2 overtime hours, how much do they earn?",
        answer: "220",
        difficulty: "medium"
    },
    {
        id: "wp138",
        question: "In a garden, there are red, yellow, and white roses in the ratio 3:2:1. If there are 24 roses in total, how many are yellow?",
        answer: "8",
        difficulty: "medium"
    },
    {
        id: "wp139",
        question: "A store has 150 items. After selling 40% and donating 30 items, how many items are left?",
        answer: "60",
        difficulty: "medium"
    },
    {
        id: "wp140",
        question: "Four friends buy concert tickets for $30 each and split the parking fee of $20. How much does each person pay in total?",
        answer: "35",
        difficulty: "medium"
    },
    {
        id: "wp141",
        question: "A bakery sells cupcakes for $3 each or 4 for $10. How much would you save buying 12 cupcakes in groups of 4?",
        answer: "6",
        difficulty: "medium"
    },
    {
        id: "wp142",
        question: "In a class of 30 students, 40% have pets. If 3/4 of those with pets have dogs, how many students have dogs?",
        answer: "9",
        difficulty: "medium"
    },
    {
        id: "wp143",
        question: "A machine fills 20 bottles per minute. If it runs for 3 hours but takes a 15-minute break every hour, how many bottles will it fill?",
        answer: "3000",
        difficulty: "medium"
    },
    {
        id: "wp144",
        question: "At a restaurant, the bill is $80 and you want to leave a 15% tip. If four people split the total equally, how much does each person pay?",
        answer: "23",
        difficulty: "medium"
    },
    {
        id: "wp145",
        question: "In a box of 200 pencils, 1/5 are red and 30% of the remainder are blue. How many blue pencils are there?",
        answer: "48",
        difficulty: "medium"
    },
    {
        id: "wp146",
        question: "A phone battery loses 10% of its charge every hour. If it starts at 100%, how much battery will remain after 5 hours?",
        answer: "50",
        difficulty: "medium"
    },
    {
        id: "wp147",
        question: "Three workers can paint a fence in 6 hours. If two more workers join them, how many hours will it take?",
        answer: "4",
        difficulty: "medium"
    },
    {
        id: "wp148",
        question: "A store sells notebooks for $5 each. If you buy 4 or more, each costs $4. How much would 6 notebooks cost?",
        answer: "24",
        difficulty: "medium"
    },
    {
        id: "wp149",
        question: "In a bag of 60 marbles, 1/3 are red and half of the non-red marbles are blue. How many marbles are neither red nor blue?",
        answer: "20",
        difficulty: "medium"
    },
    {
        id: "wp150",
        question: "A cinema has 200 seats. If 3/4 of the seats are occupied and 40% of the occupied seats are children, how many adults are there?",
        answer: "90",
        difficulty: "medium"
    },
    {
        id: "wp151",
        question: "If 4 candy bars cost $5, and you have $40, how many complete sets of 4 candy bars can you buy?",
        answer: "8",
        difficulty: "medium"
    },
    {
        id: "wp152",
        question: "In a game, you score 5 points for each goal and 2 points for each assist. If you scored 4 goals and 3 assists, what's your total score?",
        answer: "26",
        difficulty: "medium"
    },
    {
        id: "wp153",
        question: "A shop offers a 20% discount on a $50 jacket. If you have a $5 coupon to use after the discount, how much will you pay?",
        answer: "35",
        difficulty: "medium"
    },
    {
        id: "wp154",
        question: "Three friends buy a pizza for $24 and two orders of breadsticks for $6 each. If they split the cost equally, how much does each pay?",
        answer: "12",
        difficulty: "medium"
    },
    {
        id: "wp155",
        question: "In a box of 80 chocolates, 25% are dark chocolate and 1/2 of the remainder are milk chocolate. How many white chocolates are there?",
        answer: "30",
        difficulty: "medium"
    },
    {
        id: "wp156",
        question: "A car travels at 60 km/h for 2 hours, then at 40 km/h for 1 hour. What is the total distance traveled?",
        answer: "160",
        difficulty: "medium"
    },
    {
        id: "wp157",
        question: "If 6 workers can complete a job in 8 hours, how many hours would it take 4 workers to complete the same job?",
        answer: "12",
        difficulty: "medium"
    },
    {
        id: "wp158",
        question: "In a group of 100 people, 40% are men and 1/4 of the women play tennis. How many women play tennis?",
        answer: "15",
        difficulty: "medium"
    },
    {
        id: "wp159",
        question: "A store sells shirts for $25 each or 3 for $60. How much would you save buying 9 shirts using the deal?",
        answer: "45",
        difficulty: "medium"
    },
    {
        id: "wp160",
        question: "If a recipe needs 2 eggs for every cup of flour, and you want to use 15 eggs, how many cups of flour do you need?",
        answer: "8",
        difficulty: "medium"
    },
    {
        id: "wp161",
        question: "A gym has 150 members. If 40% attend morning classes and 3/4 of morning attendees also use the weights room, how many morning attendees don't use the weights room?",
        answer: "15",
        difficulty: "medium"
    },
    {
        id: "wp162",
        question: "A store sells books for $20 each or 3 for $45. If you bought 7 books using the best possible combination of deals, how much did you spend?",
        answer: "115",
        difficulty: "medium"
    },
    {
        id: "wp163",
        question: "In a pack of 80 cards, 1/4 are hearts and 1/3 of the non-hearts are diamonds. How many diamonds are there?",
        answer: "20",
        difficulty: "medium"
    },
    {
        id: "wp164",
        question: "A machine fills 25 bottles per minute. If it operates for 2 hours but has a 20% downtime, how many bottles will it fill?",
        answer: "2400",
        difficulty: "medium"
    },
    {
        id: "wp165",
        question: "Three friends buy pizza for $36 and wings for $24. If they split the bill equally and each leaves a $4 tip, how much does each person pay?",
        answer: "24",
        difficulty: "medium"
    },
    {
        id: "wp166",
        question: "In a box of 120 candies, 30% are chocolate and half of the remaining are fruit flavored. How many candies are neither chocolate nor fruit flavored?",
        answer: "42",
        difficulty: "medium"
    },
    {
        id: "wp167",
        question: "A car uses 6 liters of fuel per 100 km. If it travels 250 km with 20% better fuel efficiency, how many liters of fuel will it use?",
        answer: "12",
        difficulty: "medium"
    },
    {
        id: "wp168",
        question: "At a school, there are twice as many girls as boys. If there are 105 students in total, how many boys are there?",
        answer: "35",
        difficulty: "medium"
    },
    {
        id: "wp169",
        question: "A store offers a 25% discount on a $80 jacket. If you have a $10 coupon to use after the discount, how much will you pay?",
        answer: "50",
        difficulty: "medium"
    },
    {
        id: "wp170",
        question: "In a bag of 90 tokens, the ratio of red to blue to green is 2:3:4. How many red tokens are there?",
        answer: "20",
        difficulty: "medium"
    },
    {
        id: "wp171",
        question: "A recipe needs 3 cups of milk for every 4 cups of flour. If you have 18 cups of milk, how many cups of flour do you need?",
        answer: "24",
        difficulty: "medium"
    },
    {
        id: "wp172",
        question: "In a game, each win is worth 3 points and each draw is worth 1 point. If a team has 20 points from 8 games and no losses, how many games did they win?",
        answer: "6",
        difficulty: "medium"
    },
    {
        id: "wp173",
        question: "A worker earns $18 per hour and time-and-a-half for overtime. If they work 8 regular hours and 3 overtime hours, how much do they earn?",
        answer: "225",
        difficulty: "medium"
    },
    {
        id: "wp174",
        question: "In a box of 200 pens, 1/5 are blue and 40% of the remainder are red. How many pens are neither blue nor red?",
        answer: "96",
        difficulty: "medium"
    },
    {
        id: "wp175",
        question: "Five friends order a meal that costs $100. If they split the bill equally and want to leave a 20% tip, how much should each person pay?",
        answer: "24",
        difficulty: "medium"
    },
    {
        id: "wp176",
        question: "A train travels at 80 km/h for 1.5 hours, then at 60 km/h for 2 hours. What is the total distance traveled?",
        answer: "240",
        difficulty: "medium"
    },
    {
        id: "wp177",
        question: "In a class of 45 students, 1/3 play sports and 3/4 of those who play sports also play music. How many athletes don't play music?",
        answer: "4",
        difficulty: "medium"
    },
    {
        id: "wp178",
        question: "A store sells shoes for $60 per pair. If you buy two pairs, you get 30% off the second pair. How much would 2 pairs cost?",
        answer: "102",
        difficulty: "medium"
    },
    {
        id: "wp179",
        question: "In a garden with 160 flowers, 25% are roses and 1/3 of the non-roses are tulips. How many tulips are there?",
        answer: "40",
        difficulty: "medium"
    },
    {
        id: "wp180",
        question: "If 8 workers can complete a job in 6 hours, how many workers are needed to complete the job in 4 hours?",
        answer: "12",
        difficulty: "medium"
    },
    {
        id: "wp181",
        question: "A cinema has 120 seats. If 80% are occupied and the ratio of adults to children is 3:2, how many children are there?",
        answer: "38",
        difficulty: "medium"
    },
    {
        id: "wp182",
        question: "A printer prints 40 pages per minute. If it needs to print 600 pages but has a 15% error rate, how many minutes will it take?",
        answer: "18",
        difficulty: "medium"
    },
    {
        id: "wp183",
        question: "Three friends split a bill in the ratio 2:3:3. If the total bill is $160, how much does the person paying the least pay?",
        answer: "40",
        difficulty: "medium"
    },
    {
        id: "wp184",
        question: "In a box of 90 chocolates, 40% are milk chocolate and 1/3 of the remainder are dark chocolate. How many white chocolates are there?",
        answer: "36",
        difficulty: "medium"
    },
    {
        id: "wp185",
        question: "A store offers buy-2-get-1-free on items costing $15 each. If you buy 8 items, how much money will you save?",
        answer: "45",
        difficulty: "medium"
    },
    {
        id: "wp186",
        question: "In a group of 75 people, 60% are adults. If 2/3 of the adults are women, how many men are there?",
        answer: "15",
        difficulty: "medium"
    },
    {
        id: "wp187",
        question: "A car travels for 3 hours at 70 km/h and uses 7 liters of fuel per 100 km. How many liters of fuel does it use?",
        answer: "15",
        difficulty: "medium"
    },
    {
        id: "wp188",
        question: "In a bag of 140 marbles, the ratio of blue to red to green is 2:3:2. How many red marbles are there?",
        answer: "60",
        difficulty: "medium"
    },
    {
        id: "wp189",
        question: "A shop sells t-shirts for $25 each. If you buy 4 or more, you get 20% off the total. How much would 5 t-shirts cost?",
        answer: "100",
        difficulty: "medium"
    },
    {
        id: "wp190",
        question: "In a school of 250 students, 45% are boys. If 1/3 of the girls play basketball, how many girls play basketball?",
        answer: "46",
        difficulty: "medium"
    },
    {
        id: "wp191",
        question: "Four friends buy concert tickets for $40 each and split a taxi fare of $60. How much does each person pay in total?",
        answer: "55",
        difficulty: "medium"
    },
    {
        id: "wp192",
        question: "In a box of 100 pens, 30% are blue and half of the non-blue pens are black. How many pens are neither blue nor black?",
        answer: "35",
        difficulty: "medium"
    },
    {
        id: "wp193",
        question: "A bakery makes 240 cookies. If 1/3 are chocolate chip and 3/4 of the remainder are oatmeal, how many are neither?",
        answer: "40",
        difficulty: "medium"
    },
    {
        id: "wp194",
        question: "A phone battery loses 15% charge every 2 hours. If it starts at 90% charge, what percentage will remain after 6 hours?",
        answer: "45",
        difficulty: "medium"
    },
    {
        id: "wp195",
        question: "In a parking lot with 80 vehicles, 3/4 are cars and 1/5 of the remainder are motorcycles. How many vehicles are neither cars nor motorcycles?",
        answer: "16",
        difficulty: "medium"
    },
    {
        id: "wp196",
        question: "If 5 workers can paint a house in 12 hours, how many hours would it take 3 workers to paint the same house?",
        answer: "20",
        difficulty: "medium"
    },
    {
        id: "wp197",
        question: "A store sells DVDs for $16 each or 3 for $36. How much would you save buying 9 DVDs using the deal?",
        answer: "36",
        difficulty: "medium"
    },
    {
        id: "wp198",
        question: "In a game, scoring 4 goals earns 1 bonus point. If a team scored 30 goals and earned 7 bonus points, how many goals did not contribute to bonus points?",
        answer: "2",
        difficulty: "medium"
    },
    {
        id: "wp199",
        question: "A factory produces 150 items per day. If efficiency increases by 20% on weekdays but decreases by 10% on weekends, how many items will they produce in a week?",
        answer: "1170",
        difficulty: "medium"
    },
    {
        id: "wp200",
        question: "Three children split 84 stickers in the ratio 2:3:2. How many more stickers does the second child have than the first child?",
        answer: "12",
        difficulty: "medium"
    }
];