import { useAuth } from '@/hooks/useAuth';
import { GameContainer } from '@/components/GameContainer';

export default function App() {
  const { isLoading, isAuthenticated } = useAuth();

  if (isLoading) {
    return <div className="min-h-screen pattern-bg" />;
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="min-h-screen pattern-bg">
      <GameContainer />
    </div>
  );
}