import { supabase } from './supabase';

export async function checkUserAccess(accessCode: string | null) {
  if (!accessCode) return null;

  try {
    const { data, error } = await supabase
      .from('users')
      .select('has_active_subscription')
      .eq('access_code', accessCode)
      .single();

    if (error) throw error;
    return data?.has_active_subscription || false;
  } catch (error) {
    console.error('Error checking access:', error);
    return null;
  }
}

export function getStoredAccessCode() {
  return localStorage.getItem('gameAccessCode');
}

export function setStoredAccessCode(accessCode: string) {
  localStorage.setItem('gameAccessCode', accessCode);
}

export function removeStoredAccessCode() {
  localStorage.removeItem('gameAccessCode');
}