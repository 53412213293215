import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { checkUserAccess, getStoredAccessCode, removeStoredAccessCode } from '@/lib/auth';

export function useAuth() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const verifyAccess = async () => {
      if (location.pathname === '/home') {
        setIsLoading(false);
        return;
      }

      try {
        const accessCode = getStoredAccessCode();
        const hasAccess = await checkUserAccess(accessCode);

        if (!hasAccess) {
          removeStoredAccessCode();
          if (location.pathname !== '/home') {
            navigate('/home', { replace: true });
          }
          return;
        }

        setIsAuthenticated(true);
      } catch (error) {
        console.error('Auth error:', error);
        if (location.pathname !== '/home') {
          navigate('/home', { replace: true });
        }
      } finally {
        setIsLoading(false);
      }
    };

    verifyAccess();
  }, [location.pathname]);

  return { isLoading, isAuthenticated };
}