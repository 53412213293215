import { Shield, ArrowRight, Trophy } from 'lucide-react';
import { stripe } from '@/lib/stripe';

interface PricingSectionProps {
  onCheckout: () => void;
  isLoading: boolean;
}

export function PricingSection({ onCheckout, isLoading }: PricingSectionProps) {
  const pricePerGame = stripe.utils.formatPrice(stripe.config.product.price / 5);
  const totalPrice = stripe.utils.formatPrice(stripe.config.product.price);

  return (
    <div className="text-center space-y-6 max-w-md mx-auto bg-cream-dark p-8 rounded-2xl border-3 border-black">
      <div className="space-y-2">
        <div className="flex justify-center items-baseline gap-2">
          <span className="text-4xl font-bold">{totalPrice}</span>
          <span className="text-lg text-black/70">one-time</span>
        </div>
        <p className="text-black/70">Only {pricePerGame} per game!</p>
      </div>

      <button
        onClick={onCheckout}
        className="btn-primary w-full py-6 text-lg flex items-center justify-center gap-2"
        disabled={isLoading}
      >
        {isLoading ? 'Processing...' : 'Get Instant Access'}
        <ArrowRight className="w-5 h-5" />
      </button>
      
      <div className="space-y-3">
        <div className="flex items-center gap-2 text-sm">
          <Shield className="w-4 h-4 text-green-500" />
          <span>Risk-free 24-hour money-back guarantee.</span>
        </div>
        <div className="flex items-center gap-2 text-sm">
          <ArrowRight className="w-4 h-4 text-green-500" />
          <span>Host unlimited game nights. All games included.</span>
        </div>
        <div className="flex items-center gap-2 text-sm">
          <Trophy className="w-4 h-4 text-green-500" />
          <span>No app downloads - just cast to TV and play.</span>
        </div>
      </div>
    </div>
  );
}