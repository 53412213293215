import {
  Popcorn,
  Puzzle,
  BrainCircuit,
  Pencil,
  Calculator,
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Leaderboard } from '@/components/Leaderboard';
import { GameType } from '@/App';

interface GameMenuProps {
  onGameTypeSelect: (type: GameType) => void;
}

export function GameMenu({ onGameTypeSelect }: GameMenuProps) {
  return (
    <div className="min-h-screen pattern-bg flex items-center justify-center p-4">
      <div className="w-full max-w-lg mx-auto px-4 py-8">
        <div className="game-card p-8 text-center space-y-8">
          <div className="space-y-4">
            <div className="flex justify-center gap-4">
              <Popcorn className="w-16 h-16 text-[#FFD233]" />
              <Puzzle className="w-16 h-16 text-[#FFD233]" />
            </div>
            <h1 className="text-5xl font-bold tracking-tight">MirFam Games</h1>
            <p className="text-xl text-black/70 max-w-md mx-auto">
              Choose a game and challenge your friends and family!
            </p>
          </div>

          <div className="space-y-4">
            <Button
              className="btn-primary text-lg px-8 py-6 w-full"
              onClick={() => onGameTypeSelect('charades')}
            >
              <Popcorn className="mr-2 h-6 w-6" />
              Movie Charades
            </Button>

            <Button
              className="btn-secondary text-lg px-8 py-6 w-full"
              onClick={() => onGameTypeSelect('pictionary')}
            >
              <Pencil className="mr-2 h-6 w-6" />
              Pictionary
            </Button>

            <Button
              className="btn-primary text-lg px-8 py-6 w-full"
              onClick={() => onGameTypeSelect('scramble')}
            >
              <Puzzle className="mr-2 h-6 w-6" />
              Word Scramble
            </Button>

            <Button
              className="btn-secondary text-lg px-8 py-6 w-full"
              onClick={() => onGameTypeSelect('trivia')}
            >
              <BrainCircuit className="mr-2 h-6 w-6" />
              Smart Trivia
            </Button>

            <Button
              className="btn-primary text-lg px-8 py-6 w-full"
              onClick={() => onGameTypeSelect('wordproblems')}
            >
              <Calculator className="mr-2 h-6 w-6" />
              Word Problems
            </Button>
          </div>
        </div>

        <Leaderboard />
      </div>
    </div>
  );
}
