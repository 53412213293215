import { useEffect, useState } from 'react';
import { Trophy } from 'lucide-react';
import { useLeaderboard } from '@/hooks/useLeaderboard';

interface LeaderboardEntry {
  team_name: string;
  total_wins: number;
}

export function Leaderboard() {
  const [leaderboard, setLeaderboard] = useState<LeaderboardEntry[]>([]);
  const { fetchLeaderboard } = useLeaderboard();

  useEffect(() => {
    async function loadLeaderboard() {
      const data = await fetchLeaderboard();
      setLeaderboard(data);
    }

    loadLeaderboard();
  }, []);

  return (
    <div className="game-card p-6 mt-8">
      <div className="text-center mb-6">
        <Trophy className="w-8 h-8 text-[#FFD233] mx-auto mb-2" />
        <h2 className="text-2xl font-bold">Your Champions</h2>
      </div>
      
      <div className="space-y-4">
        {leaderboard.map((entry, index) => (
          <div
            key={`${entry.team_name}-${index}`}
            className="flex items-center justify-between p-4 rounded-lg bg-cream-dark"
          >
            <div className="flex items-center gap-4">
              <span className="text-2xl font-bold">{index + 1}</span>
              <span className="text-lg font-semibold">{entry.team_name}</span>
            </div>
            <div className="text-xl font-bold">{entry.total_wins} wins</div>
          </div>
        ))}
        
        {leaderboard.length === 0 && (
          <div className="text-center text-black/60 py-4">
            No winners yet. Be the first to win!
          </div>
        )}
      </div>
    </div>
  );
}