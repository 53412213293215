import type { PaymentError } from '@/types/payment';

export function generateAccessCode(): string {
  const chars = '0123456789';
  return Array.from(
    { length: 8 },
    () => chars.charAt(Math.floor(Math.random() * chars.length))
  ).join('');
}

export function formatError(error: unknown): string {
  if (error instanceof Error) {
    if ((error as PaymentError).code) {
      return `${error.message} (${(error as PaymentError).code})`;
    }
    return error.message;
  }
  return 'An unexpected error occurred';
}